import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import { useParams } from "react-router";
import Sidebar from "../Includes/Sidebar/Sidebar";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import { addCrads } from "../schemas";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ViewCard from "./ViewCard";

const StaffNewCard = () => {
    const [loading, setLoading] = useState(false);
    const [Name, setName] = useState("");
    const { id, type } = useParams();
    const { state, dispatch: ctxDispatch } = useContext(NoteState);
    const { branch_id } = state;

    const getUsers = async () => {
        let myDataarr;
        setLoading(true);
        try {
          const responce = await UserServices.getStaffInfo(id);
          console.log(responce);
          if (responce.status === 200) {
            myDataarr = responce.data.data.data;
            const res = myDataarr;
    console.log(responce);
            res.map((values) => {
              setName(values.first_name + " " + values.last_name);
            });
    
            setLoading(false);
          }
        } catch (err) {
          console.log(err);
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      useEffect(() => {
        getUsers();
      }, []);
      const addCard = async (values, action) => {
        try {
          setLoading(true);
          const response = await UserServices.addClientCard(
            values,
            id,
            type,
            branch_id
          );
    
          if (response.status === 201) {
            setLoading(false);
            Swal.fire({
              title: "Success!",
              text: "Loan added successfully.",
              icon: "success",
              confirmButtonColor: "#ed1c24",
            });
            action.resetForm();
            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          }
        } catch (err) {
          if (err.response.status === 422) {
          } else if (err.response.status === 500) {
            // toast.error("Unproccessable content!", { position: "top-center" });
          } else if (err.response.status === 400) {
            //  toast.error("Unproccessable content!", { position: "top-center" });
          }
        }
      };
      const initialValues = {
        number: "",
        description: "",
      };
      const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addCrads,
      onSubmit: (values, action) => {
        addCard(values, action);
      },
    });
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Staff: {Name}</h5>
            </div>
            <div className="card-body">
            <h5>Assign a new card</h5>
            {/* <p>
                <i
                  className="bi bi-exclamation-lg"
                  style={{ color: "red" }}
                  aria-hidden="true"
                />
                The Fields With <strong style={{ color: "red" }}>*</strong>
                Must Required Or Fill.
              </p> */}

             
              <form
                className="row g-3 trainee-add mt-3"
                onSubmit={handleSubmit}
              >
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Card number<span className="text-danger">*</span></label>
                    <input
                      type="numbers"
                      className="form-control"
                      placeholder="Card number"
                      name="number"
                      min={0}
                      value={values.number}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.number && touched.number ? (
                      <p className="text-danger">{errors.number}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter description"
                      name="description"
                      value={values.description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description ? (
                      <p className="text-danger">{errors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Card Information</h5>
            </div>
            <ViewCard />
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  )
}

export default StaffNewCard
