import React from "react";
import { useState } from "react";
import UserServices from "../../../UserServices/UserServices";
import { useEffect } from "react";
import { useContext } from "react";
import { NoteState } from "../../../context/notes/NoteState";
import { Link } from "react-router-dom";
import MenuButton from "../../MenuButton/MenuButton";

import MultiMealAdd from "./MultiMealAdd";
import { toast } from "react-toastify";

export default function AddMealPlan() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;

  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [name, setName] = useState([]);

  const [selectedBranch, setselectedBranch] = useState(
    branch_id ? branch_id : ""
  );

  const [selectedStartTime, setselectedStartTime] = useState("");
  const [selectedEndTime, setselectedEndTime] = useState("");

  const [weeks, setWeeks] = useState([
    {
      week: "1",

      breakfast: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      lunch: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      dinner: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      snacks: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      munching: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
    },
    {
      week: "2",
      breakfast: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      lunch: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      dinner: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      snacks: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      munching: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
    },
    {
      week: "3",
      breakfast: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      lunch: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      dinner: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      snacks: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      munching: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
    },
    {
      week: "4",
      breakfast: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      lunch: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      dinner: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      snacks: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      munching: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
    },
  ]);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  useEffect(() => {
    branches();

    nameClients();
  }, []);

  // const adddMeal = async (values, action) => {
  //   let e = document.getElementById("answerInput");
  //   var input = e,
  //     list = input.getAttribute("list"),
  //     options = document.querySelectorAll("#" + list + " option"),
  //     hiddenInput = document.getElementById(
  //       input.getAttribute("id") + "-hidden"
  //     ),
  //     inputValue = input.value;

  //   // hiddenInput.value = inputValue;

  //   for (var i = 0; i < options.length; i++) {
  //     var option = options[i];

  //     if (option.innerText === inputValue) {
  //       hiddenInput.value = await option.getAttribute("data-value");
  //       break;
  //     } else {
  //       hiddenInput.value = "";
  //     }
  //   }

  //   let client_id = await document.getElementById("answerInput-hidden").value;
  //   try {
  //     setLoading(true);
  //     await UserServices.addMealPlan(values, client_id);

  //     setLoading(false);
  //     toast.success("Added Successfully!", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     action.resetForm();
  //   } catch (err) {
  //     setLoading(false);

  //     if (err.response.status === 422) {
  //     } else if (err.response.status === 500) {
  //       toast.error("Unproccessable content!", { position: "top-center" });
  //     } else if (err.response.status === 400) {
  //       // toast.error("Unproccessable content!", { position: "top-center" });
  //     }
  //   }
  // };
  // const handleSubmit = (values, action) => {
  //   adddMeal(values, action);
  // };

  const handleFormChange = (formDataIndex, val, fieldName, catagry) => {
    const updatedFormDataw = [...weeks];

    updatedFormDataw[formDataIndex][catagry][fieldName] = val;

    setWeeks(updatedFormDataw);
  };

  const addMeal = async () => {
    let e = document.getElementById("answerInput");
    var input = e,
      list = input.getAttribute("list"),
      options = document.querySelectorAll("#" + list + " option"),
      hiddenInput = document.getElementById(
        input.getAttribute("id") + "-hidden"
      ),
      inputValue = input.value;

    // hiddenInput.value = inputValue;

    for (var i = 0; i < options.length; i++) {
      var option = options[i];

      if (option.innerText === inputValue) {
        hiddenInput.value = await option.getAttribute("data-value");
        break;
      } else {
        hiddenInput.value = "";
      }
    }

    let client_id = await document.getElementById("answerInput-hidden").value;
    if (client_id !== "" && selectedStartTime !== "") {
      try {
        setLoading(true);
        await UserServices.addMealPlanByMonth(
          weeks,
          selectedBranch,
          client_id,
          selectedStartTime,
          selectedEndTime
        );

        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (err) {
        setLoading(false);

        if (err.response.status === 422) {
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    } else if (client_id === "") {
      toast.error("Please select client!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (selectedStartTime === "") {
      toast.error("Please select start date!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (selectedEndTime === "") {
      toast.error("Please select end date!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Add Meals Plan
                <Link
                  to="/view-meal-plan"
                  className="btn btn-primary d-inline-block float-end btn-sm mt-3 mt-md-0"
                >
                  View Meals Plan
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-lg-3">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={selectedBranch}
                      onChange={(e) => {
                        setselectedBranch(e.target.value);
                      }}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                </div>

                <div className="col-12 col-lg-3">
                  <label className="form-label">
                    Client Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Client Name"
                    name="client_name"
                    list="member_id"
                    id="answerInput"
                  />

                  <input type="hidden" name="answer" id="answerInput-hidden" />
                  <datalist id="member_id">
                    {name.map((el, index) => {
                      return (
                        <option key={index} data-value={el.id} value={el.name}>
                          {el.name}
                        </option>
                      );
                    })}
                  </datalist>
                </div>

                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter date"
                      onChange={(e) => {
                        setselectedStartTime(e.target.value);

                        const value = e.target.value;

                        const startDate = new Date(value);
                        const endDate = new Date(
                          startDate.getTime() + 30 * 24 * 60 * 60 * 1000
                        );

                        const year = endDate.getFullYear();
                        const month = endDate.getMonth() + 1;
                        const day = endDate.getDate();

                        const formattedEndDate = `${year}-${
                          month < 10 ? "0" : ""
                        }${month}-${day < 10 ? "0" : ""}${day}`;

                        setselectedEndTime(formattedEndDate);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter date"
                      value={selectedEndTime}
                      disabled={true}
                      // onChange={(e) => {
                      //   setselectedEndTime(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>

              <div className="overflow-auto w-100 mt-3">
                <table className="table  table-bordered align-middle table-style-1 text-center">
                  <thead>
                    <tr>
                      <th scope="col" width="7%" className="no_sort "></th>
                      <th scope="col" className="no_sort">
                        Meals
                      </th>
                      <th scope="col" width="12%" className="no_sort">
                        Sunday
                      </th>
                      <th scope="col" width="12%" className="no_sort">
                        Monday
                      </th>

                      <th scope="col" width="12%" className="no_sort">
                        Tuesday
                      </th>
                      <th scope="col" width="12%" className="no_sort">
                        Wednesday
                      </th>
                      <th scope="col" width="12%" className="no_sort">
                        Thursday
                      </th>
                      <th scope="col" width="12%" className="no_sort">
                        Friday
                      </th>
                      <th scope="col" width="12%" className="no_sort">
                        Saturday
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {weeks.length
                      ? weeks.map((value, index) => (
                          <MultiMealAdd
                            formDataIndex={index}
                            key={value.week}
                            onFormChange={handleFormChange}
                          />
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <button className="btn btn-primary w-100" onClick={addMeal}>
                Add Meal Plan
              </button>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
