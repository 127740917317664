import React, { useState, useEffect, useContext, useRef } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { Link } from "react-router-dom";
import { NoteState } from "../../context/notes/NoteState";
import moment from "moment";
import { useFormik } from "formik";
import { payAllLiabilities, viewFines } from "../schemas";
import MenuButton from "../MenuButton/MenuButton";
import $ from "jquery";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { addLiabilities } from "../schemas";
import { useMask } from "react-mask-field";

export default function GetLiabilities() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  const [subCatgries, setSubCatgries] = useState([]);
  const [catgries, setCatgries] = useState([]);
  const sMonth = date.getMonth();
  const monthString = (parseInt(sMonth) + 1).toString();
  let strtDate = moment().startOf("month").format("YYYY-MM-DD");
  const [exportLink, setExportLink] = useState("");
  // update
  const [myBranches, setMyBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCreditor, setSelectedCreditor] = useState("");
  const [selectedCreditorContact, setSelectedCreditorContact] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [recordId, setRecordId] = useState("");
  const [sbCatgries, setSbCatgries] = useState([]);
  const [totalAmountOwned, setTotalAmountOwned] = useState("");
  const [totalInstallmentsAmount, setTotalInstallmentsAmount] = useState("");
  // checkBox
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [total, setTotal] = useState("");
  const [liabilityRecord, setLiabilityRecord] = useState("");
  const [bank, setBank] = useState([]);

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    // Check or uncheck all checkboxes based on the "Select All" checkbox
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
    });
  }, [selectAllChecked]);

  const getLiabilitiesPayment = async (selectedRecords) => {
    setLoading(true);
    try {
      const response = await UserServices.getLaibilityBalance(selectedRecords);
      if (response && response.status === 200) {
        setTotal(response.data.liability_pending_amount);
        setLiabilityRecord(response.data.liability_id);

        // $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  var clickEvent = $.Event("click");
  var CurrencyFormat = require("react-currency-format");

  const closeButtonRef = useRef(null);
  const getPkgs = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getLaibilitiesInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setSelectedCategory(values.category_id);
          setSelectedCreditor(values.creditor_name);
          setSelectedCreditorContact(values.creditor_contact);
          setSelectedAmount(values.amount_owned);
          setSelectedDescription(values.description);
          setSelectedDate(values.maturity_date);
          setSelectedSubCategory(values.sub_category_id);
          setRecordId(values.id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const subCtgories = async (e) => {
    var sb_catgry = e;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSbCatgries([{ name: "Select Sub Category", id: "" }, ...results]);
    } catch (err) {
      setSbCatgries([{ name: "No record found", id: "" }]);
      console.log(err);
    }
  };
  const bankDetails = async () => {
    try {
      const responce = await UserServices.bankList(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          bank_name: value.bank_name,
          account_no: value.account_no,
        });
        return null;
      });
      setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setBank([{ bank_name: "No Bank Found", id: "" }]);
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    categories();
    bankDetails();
    branches();
    if (selectedCategory) {
      subCtgories(selectedCategory);
    }
  }, [selectedCategory]);

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.liabilitiesGet(
        branch_id,
        newLimit,
        pageNo,
        values
      );
      let myDataArr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/finance/liabilities/export?branch_id=" +
            branch_id +
            "&start_date=" +
            values.startdate +
            "&end_date=" +
            values.enddate +
            "&status=1"
        );
        myDataArr = responce.data.data.data;
        const res = myDataArr;
        const results = [];
        let totalAmountOwned = 0;
        let totalInstallmentsAmount = 0;
        res.forEach((values) => {
          results.push({
            id: values.id,
            maturity_date: values.maturity_date,
            creditor_contact: values.creditor_contact,
            creditor_name: values.creditor_name,
            category_name: values.category_name,
            sub_category_name: values.sub_category_name,
            amount_owned: values.amount_owned,
            description: values.description,
            installments_detail: values.installments_detail,
          });
          totalAmountOwned += parseFloat(values.amount_owned);

          if (
            values.installments_detail &&
            values.installments_detail.length > 0
          ) {
            values.installments_detail.forEach((inl) => {
              totalInstallmentsAmount += parseFloat(inl.amount);
            });
          }
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setTotalAmountOwned(totalAmountOwned);
        setTotalInstallmentsAmount(totalInstallmentsAmount);
        setLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Liability"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Category Found", id: "" }]);
    }
  };
  const getSubCategories = async (e) => {
    var sb_catgry = e.target.value;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSubCatgries([{ name: "Select Sub Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);
    categories();
  }, []);
  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.liabilitiesSearch(
          branch_id,
          search_name
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              maturity_date: values.maturity_date,
              creditor_contact: values.creditor_contact,
              creditor_name: values.creditor_name,
              category_name: values.category_name,
              sub_category_name: values.sub_category_name,
              amount_owned: values.amount_owned,
              description: values.description,
              installments_detail: values.installments_detail,
            });
            return null;
          });
          setMyData([...results]);
          setLimit(response.data.data.per_page);
          setCurrentPage(response.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteLiabilities(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delLiability = (id) => {
    deleteRecord(id);
  };
  const changeCategory = (values) => {
    const getCategoryTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getLaibilitiesFiltration(
          "1",
          "9999999999999999999999999999999999",
          1,
          branch_id,
          values
        );
        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/finance/liabilities/export?branch_id=" +
              branch_id +
              "&start_date=" +
              values.startdate +
              "&end_date=" +
              values.enddate +
              "&category_id=" +
              values.category_id +
              "&sub_category_id=" +
              values.sub_category_id +
              "&status=1"
          );
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
          let totalAmountOwned = 0;
          let totalInstallmentsAmount = 0;
          res.map((values) => {
            results.push({
              id: values.id,
              maturity_date: values.maturity_date,
              creditor_contact: values.creditor_contact,
              creditor_name: values.creditor_name,
              category_name: values.category_name,
              sub_category_name: values.sub_category_name,
              amount_owned: values.amount_owned,
              description: values.description,
              installments_detail: values.installments_detail,
            });
            totalAmountOwned += parseFloat(values.amount_owned);

            if (
              values.installments_detail &&
              values.installments_detail.length > 0
            ) {
              values.installments_detail.forEach((inl) => {
                totalInstallmentsAmount += parseFloat(inl.amount);
              });
            }
          });

          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setTotalAmountOwned(totalAmountOwned);
          setTotalInstallmentsAmount(totalInstallmentsAmount);
          setLoading(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getCategoryTypeData();
  };

  const deleteSubRecord = async (id) => {
    try {
      const responce = await UserServices.deleteInstallmets(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delInstallment = (id) => {
    deleteSubRecord(id);
  };
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Liabilities.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-borderless table-style-1"
          id="report-POS"
          data-caption="Liabilities
          "
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                <input
                  id="all"
                  name="all"
                  type="checkbox"
                  value="all"
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                />
              </th>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("sub_category_name")}
                className={getClassNamesFor("sub_category_name")}
              >
                Sub Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("creditor_name")}
                className={getClassNamesFor("creditor_name")}
              >
                Creditor Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("creditor_contact")}
                className={getClassNamesFor("creditor_contact")}
              >
                Contact
              </th>
              <th
                scope="col"
                onClick={() => requestSort("amount_owned")}
                className={getClassNamesFor("amount_owned")}
              >
                Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("maturity_date")}
                className={getClassNamesFor("maturity_date")}
              >
                Due Date
              </th>
              <th scope="col" className="no_sort">
                Status
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <>
                  <tr key={index + "main"}>
                    <td>
                      <div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkboxNoLabel"
                          name={el.staff_name}
                          value={el.id}
                          aria-label="..."
                        />
                      </div>
                    </td>
                    <td className="d-flex">
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                      {el.installments_detail.length ? (
                        <div
                          className="ms-2"
                          onClick={() => {
                            showInstalDetails(el.id);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <i className="bi bi-chevron-down fw-bold"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{el.category_name}</td>
                    <td>{el.sub_category_name}</td>
                    <td>{el.creditor_name}</td>
                    <td>{el.creditor_contact}</td>
                    <td>
                      <CurrencyFormat
                        value={el.amount_owned}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>{el.description}</td>
                    <td>{moment(el.maturity_date).format("DD-MM-YYYY")}</td>
                    <td>
                      {el.installments_detail.length ? (
                        <span>
                          {el.installments_detail.reduce(
                            (total, inl) => total + inl.amount,
                            0
                          ) === parseInt(el.amount_owned) ? (
                            <span className="fw-bold text-success">
                              <i className="bi bi-check2-circle text-success me-2"></i>
                              Paid
                            </span>
                          ) : (
                            <span className="fw-bold text-secondary">
                              <i className="bi bi-hourglass-split me-2"></i>
                              In Progress
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className="fw-bold text-danger">
                          {" "}
                          <i className="bi bi-exclamation-circle text-danger me-2"></i>
                          Pending
                        </span>
                      )}
                    </td>

                    <td>
                      {el.installments_detail.length ? (
                        el.installments_detail.reduce(
                          (total, inl) => total + inl.amount,
                          0
                        ) === parseInt(el.amount_owned) ? (
                          <span
                            className="pay-btn disabled"
                            style={{
                              cursor: "disabled",
                            }}
                          >
                            <i className="bi bi-credit-card text-dark"></i>{" "}
                            <span className="text-dark">Pay</span>
                          </span>
                        ) : (
                          <Link
                            to={"/pay-liabilities/" + el.id}
                            className="print-btn"
                          >
                            <i className="bi bi-credit-card"></i>{" "}
                            <span className="inative-text">Pay</span>
                          </Link>
                        )
                      ) : (
                        <Link
                          to={"/pay-liabilities/" + el.id}
                          className="print-btn"
                        >
                          <i className="bi bi-credit-card"></i>{" "}
                          <span className="inative-text">Pay</span>
                        </Link>
                      )}

                      {el.installments_detail.length ? (
                        el.installments_detail.reduce(
                          (total, inl) => total + inl.amount,
                          0
                        ) === parseInt(el.amount_owned) ? (
                          <span
                            className="pay-btn disabled ms-2"
                            style={{
                              cursor: "disabled",
                            }}
                          >
                            <i className="bi bi-repeat text-secondary"></i>{" "}
                            <span className="text-secondary ms-1 updt-text">
                              Update
                            </span>
                          </span>
                        ) : (
                          <button
                            onClick={() => {
                              getPkgs(el.id);
                            }}
                            className="updt-btn inactive-btn ms-2"
                          >
                            <i className="bi bi-repeat text-success"></i>{" "}
                            <span className="text-success ms-1 updt-text">
                              Update
                            </span>
                          </button>
                        )
                      ) : (
                        <button
                          onClick={() => {
                            getPkgs(el.id);
                          }}
                          className="updt-btn inactive-btn ms-2"
                        >
                          <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Update
                          </span>
                        </button>
                      )}

                      <button
                        type="button"
                        onClick={() => delLiability(el.id)}
                        className="ms-2 inactive-btn"
                      >
                        <i className="bi bi-trash3 text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                  {el.installments_detail.length ? (
                    <tr
                      className="instalmnt-info table table-striped"
                      id={el.id}
                    >
                      <td colSpan="10">
                        <tr>
                          <th
                            style={{
                              width: "19%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Amount
                          </th>
                          <th
                            style={{
                              width: "19%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Resource
                          </th>
                          <th
                            style={{
                              width: "18%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Bank Name
                          </th>
                          <th
                            style={{
                              width: "18%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Account Number
                          </th>
                          <th
                            style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "18%",
                            }}
                          >
                            Date
                          </th>
                          <th
                            style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "18%",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                        {el.installments_detail.map((inl, index) => (
                          <tr key={index}>
                            <td>
                              <CurrencyFormat
                                value={inl.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                thousandSpacing={"3"}
                              />
                            </td>
                            {/* <td>
                              {" "}
                              {inl.resource === "1" ? (
                                <span>Bank account</span>
                              ) : inl.resource === "2" ? (
                                <span>Sales counter</span>
                              ) : inl.resource === "3" ? (
                                <span>Office counter</span>
                              ) : inl.resource === "4" ? (
                                <span>Personal</span>
                              ) : null}
                            </td> */}
                            <td>{inl.resource}</td>
                            <td>{inl.banking_detail?.name ?? "N/A"}</td>
                            <td>{inl.banking_detail?.account_no ?? "N/A"}</td>
                            <td>{moment(inl.date).format("DD-MM-YYYY")}</td>
                            <td>
                              {/* <Link   to={"/update-installments/" + inl.id}
                                className="updt-btn ms-2 border-0">
                               <i className="bi bi-repeat text-success"></i>{" "}
                                <span className="text-success ms-1 updt-text">
                                  Update
                                </span>
                            </Link> */}
                              <button
                                type="button"
                                onClick={() => delInstallment(inl.id)}
                                className="ms-2 inactive-btn"
                              >
                                <i className="bi bi-trash3 text-danger"></i>{" "}
                                <span className="text-danger inative-text ms-1">
                                  Delete
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                </>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const initialValues = {
    startdate: strtDate,
    enddate: defaultValue,
    category_id: "",
    sub_category_id: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: viewFines,
      onSubmit: (values) => {
        changeCategory(values);
      },
    });
  const UpdateLiabilitiesManagement = async (updateValues) => {
    setLoading(true);
    try {
      const response = await UserServices.liabilitiesUpdate(
        updateValues,
        recordId
      );
      console.log(response);
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getPkg(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    branch_id: selectedBranches,
    category_id: selectedCategory,
    sub_category_id: selectedSubCategory,
    creditor_name: selectedCreditor,
    creditor_contact: selectedCreditorContact,
    amount_owned: selectedAmount,
    description: selectedDescription,
    maturity_date: selectedDate,
  };
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: addLiabilities,
    onSubmit: (updateValues, action) => {
      UpdateLiabilitiesManagement(updateValues, action);
    },
  });
  const payLiability = async (liabilityRecord, payValues) => {
    try {
      setLoading(true);
      const response = await UserServices.payLiabilityBalance(
        branch_id,
        liabilityRecord,
        payValues
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Liability Paid Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        closeButtonRef.current.click();
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const payinitialValues = {
    date: defaultValue,
    amount: total,
    bank_account_id: "",
    cheque_no: "",
    resource: "",
    description: "",
  };

  const {
    values: payValues,
    errors: payErrors,
    touched: payTouched,
    handleBlur: payHandleBlur,
    handleChange: payHandleChange,
    handleSubmit: payHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: payinitialValues,
    validationSchema: payAllLiabilities,
    onSubmit: (payValues, action) => {
      payLiability(liabilityRecord, payValues, action);
    },
  });
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  View Liabilities
                  <Link
                    to="/liabilities-management"
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    Add Liabilities
                  </Link>
                </h5>
              </div>
              <div className="card-body">
                <div className="row d-flex">
                  <form className="row mb-3" onSubmit={handleSubmit}>
                    <div className="col-md-2 ">
                      <label className="form-label">
                        Filter By Category
                        <span className="text-danger">*</span>
                      </label>
                      {catgries ? (
                        <select
                          className="form-select"
                          name="category_id"
                          value={values.category_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            getSubCategories(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {catgries.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.category_id && touched.category_id ? (
                        <p className="text-danger">{errors.category_id}</p>
                      ) : null}
                    </div>
                    <div className="col-md-2 ">
                      <label className="form-label">
                        Filter By Subcategory
                        <span className="text-danger">*</span>
                      </label>
                      {subCatgries ? (
                        <select
                          className="form-select"
                          name="sub_category_id"
                          value={values.sub_category_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {subCatgries.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.sub_category_id && touched.sub_category_id ? (
                        <p className="text-danger">{errors.sub_category_id}</p>
                      ) : null}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">From</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="date"
                        name="startdate"
                        value={values.startdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.startdate && touched.startdate ? (
                        <p className="text-danger">{errors.startdate}</p>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">To</label>
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="date"
                          name="enddate"
                          value={values.enddate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary "
                          onChange={() => changeCategory(values)}
                        >
                          Generate
                        </button>
                      </div>

                      {errors.enddate && touched.enddate ? (
                        <p className="text-danger">{errors.enddate}</p>
                      ) : null}
                    </div>
                    <div className="col-md-3 text-end mt-4">
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        // target="_blank"
                        className="btn btn-primary mx-2 file-download float-end mb-3"
                      >
                        <i className="bi bi-download"></i> Excel
                      </a>
                      <button
                        className="btn btn-primary mx-2 file-download float-end mb-3"
                        onClick={() => {
                          generatePdf(document.getElementById("report-POS"));
                        }}
                      >
                        <i className="bi bi-file-earmark-pdf"></i> PDF
                      </button>
                    </div>
                  </form>
                  <div className="col-md-2 mb-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Search By Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => {
                          searchRecord(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mt-4">
                    <button
                      className="btn btn-primary mx-2  mb-3"
                      onClick={() => {
                        var selectedRecords = $("input:checkbox:checked")
                          .filter(function () {
                            return this.value !== "all";
                          })
                          .map(function () {
                            return this.value;
                          })
                          .get()
                          .join(",");
                        if (selectedRecords.length) {
                          console.log(selectedRecords);
                          getLiabilitiesPayment(selectedRecords);
                          $("#open").trigger(clickEvent);
                        } else {
                          toast.error("Please Select Record!", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        }
                      }}
                    >
                      Pay Liabilities
                    </button>
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto me-3 mt-4">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
            <div className="grand-total-cart bg-white card">
              <div>
                <div className="cart-entry">
                  Total Liabilities{" "}
                  <CurrencyFormat
                    value={totalAmountOwned}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
              <div>
                <div className="cart-entry">
                  Total Paid{" "}
                  <CurrencyFormat
                    value={totalInstallmentsAmount}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
              <div>
                <div className="cart-entry">
                  Total Pending{" "}
                  <CurrencyFormat
                    value={totalAmountOwned - totalInstallmentsAmount}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* ......Hidden Button....... */}
        <button
          className="btn btn-primary w-100 d-none"
          type="button"
          id="membership"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        ></button>
        {/* Modal */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Update Liability
                </h1>
                <button
                  type="button"
                  className="btn-close me-3  mb-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeButtonRef}
                >
                  <i className="bi bi-x-lg text-dark fs-4"></i>
                </button>
              </div>
              <div className="modal-body">
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    updateHandleSubmit(e);
                  }}
                >
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={updateValues.branch_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.branch_id && updateTouched.branch_id ? (
                        <p className="text-danger">{updateErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Category<span className="text-danger">*</span>
                      </label>
                      {catgries ? (
                        <select
                          className="form-select"
                          name="category_id"
                          value={updateValues.category_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                            subCtgories(e.target.value);
                            // updateValues.sub_category_id = "";
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {catgries.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.category_id && updateTouched.category_id ? (
                        <p className="text-danger">
                          {updateErrors.category_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Sub-Category<span className="text-danger">*</span>
                      </label>
                      {sbCatgries ? (
                        <select
                          className="form-select"
                          name="sub_category_id"
                          value={updateValues.sub_category_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {sbCatgries.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          name="sub_category_id"
                          value={updateValues.sub_category_id}
                          onChange={updateHandleChange}
                          onBlur={updateHandleBlur}
                        >
                          <option>Select Sub Category</option>
                        </select>
                      )}
                      {updateErrors.sub_category_id &&
                      updateTouched.sub_category_id ? (
                        <p className="text-danger">
                          {updateErrors.sub_category_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Creditor Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="creditor_name"
                        value={updateValues.creditor_name}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.creditor_name &&
                      updateTouched.creditor_name ? (
                        <p className="text-danger">
                          {updateErrors.creditor_name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">Creditor Contact</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="(e.g 92xxxxxxxxxx)"
                        name="creditor_contact"
                        value={updateValues.creditor_contact}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                        ref={phone_number}
                      />
                      {updateErrors.creditor_contact &&
                      updateTouched.creditor_contact ? (
                        <p className="text-danger">
                          {updateErrors.creditor_contact}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount<span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className="form-control"
                        placeholder="Enter Amount"
                        name="amount_owned"
                        min={0}
                        value={updateValues.amount_owned}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.amount_owned &&
                      updateTouched.amount_owned ? (
                        <p className="text-danger">
                          {updateErrors.amount_owned}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Description"
                        name="description"
                        value={updateValues.description}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.description && updateTouched.description ? (
                        <p className="text-danger">
                          {updateErrors.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="11-03-2022"
                        name="maturity_date"
                        value={updateValues.maturity_date}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.maturity_date &&
                      updateTouched.maturity_date ? (
                        <p className="text-danger">
                          {updateErrors.maturity_date}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* ///////////// */}
        {/* {Modal 2} */}

        {/* ......Hidden Button....... */}
        <button
          className="btn btn-primary w-100 d-none"
          type="button"
          id="open"
          data-bs-toggle="modal"
          data-bs-target="#newModalID"
        ></button>
        {/* Modal */}
        <div
          className="modal fade"
          id="newModalID" // Change this to your desired new modal ID
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Receive Pending Payments
                </h1>
                <button
                  type="button"
                  className="btn-close me-3  mb-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeButtonRef}
                >
                  <i className="bi bi-x-lg text-dark fs-4"></i>
                </button>
              </div>
              <div className="modal-body">
                <form className="row g-3" onSubmit={payHandleSubmit}>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="11-03-2022"
                        name="date"
                        value={payValues.date}
                        onChange={handleChange}
                        onBlur={payHandleBlur}
                      />
                      {payErrors.date && payTouched.date ? (
                        <p className="text-danger">{payErrors.date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="amount"
                        value={payValues.amount}
                        onChange={payHandleChange}
                        onBlur={payHandleBlur}
                        disabled={true}
                      />
                      {payErrors.amount && payTouched.amount ? (
                        <p className="text-danger">{payErrors.amount}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Resource<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="resource"
                        value={payValues.resource || ""}
                        onChange={(e) => {
                          payHandleChange(e);
                        }}
                        onBlur={payHandleBlur}
                      >
                        <option value="-1">Select Transaction</option>
                        <option value="Bank Account">Bank Account</option>
                        <option value="Sales Counter">Sales Counter</option>
                        <option value="Office Counter">Office Counter</option>
                        <option value="Personal">Personal</option>
                      </select>
                      {payErrors.resource && payTouched.resource ? (
                        <p className="text-danger">{payErrors.resource}</p>
                      ) : null}
                    </div>
                  </div>
                  {payValues.resource === "Bank Account" ? (
                    <>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Bank Details</label>
                          {bank ? (
                            <select
                              className="form-select"
                              name="bank_account_id"
                              value={payValues.bank_account_id || ""}
                              onChange={(e) => {
                                payHandleChange(e);
                              }}
                              onBlur={payHandleBlur}
                            >
                              {bank.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.bank_name} {el.account_no}
                                  </option>
                                );
                              })}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Cheque Number</label>
                          <input
                            onWheel={(e) => e.target.blur()}
                            type="text"
                            className="form-control"
                            placeholder="Enter Cheque Number"
                            name="cheque_no"
                            value={payValues.cheque_no}
                            onChange={payHandleChange}
                            onBlur={payHandleBlur}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Description"
                        name="description"
                        value={payValues.description}
                        onChange={payHandleChange}
                        onBlur={payHandleBlur}
                      />
                      {payErrors.description && payTouched.description ? (
                        <p className="text-danger">{payErrors.description}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Pay
                    </button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
