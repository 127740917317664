import { React, useRef, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { categoriesAdd, fitnessTracker } from "../schemas";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import $ from "jquery";

export default function FitnessTracker() {
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [myPackage, setMyPackage] = useState([]);

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [trainerName, setTrainerName] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);
  const getCtgry = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getCategoriesInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {});
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };

  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.activeCategories(
        branch_id,
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            type: values.type,
            date: values.date,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const packagename = async (e) => {
    var trainer_Id = e;

    try {
      const responce = await UserServices.getOrderName(trainer_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          order_id: value.order_id,
          package_name: value.package_name + " for " + value.client_name,
          client_name: value.client_name,
          client_id: value.client_id,
          client_email: value.client_email,
        });
        return null;
      });
      setMyPackage([
        { package_name: "Select Package", order_id: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);
      setMyPackage([]);
    }
  };

  useEffect(() => {
    branches();
    gymTrainer(branch_id);
    if (role === "9") {
      packagename(UId);
    }
  }, []);

  const addCatgry = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addCatagory(values);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteCategory(id);
      if (responce.status === 204) {
        Swal.fire({
          title: "Deleted !",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delCatgry = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(id);
      }
    });
  };

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("categories.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Categories"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("type")}
                className={getClassNamesFor("type")}
              >
                Type
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>{el.type}</td>
                  <td>
                    <button
                      onClick={() => {
                        getCtgry(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delCatgry(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const initialValues = {
    branch_id: branch_id,
    type: "",
    weight: "",
    remarks: "",
    date: "",
    repetitions: "",
    trainer_id: role === "9" ? UId : "",
    order_id: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: fitnessTracker,
      onSubmit: (values, action) => {
        addCatgry(values, action);
      },
    });
  const CategoryUpdate = async (updateValues, action) => {
    setLoading(true);
    try {
      const response = await UserServices.UpdateCategories(updateValues);
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getPkg(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    branch_id: branch_id,
    type: "",
    weight: "",
    remarks: "",
    date: "",
    repetitions: "",
    trainer_id: role === "9" ? UId : "",
    order_id: "",
  };
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    initialValues: updateinitialValues,
    validationSchema: fitnessTracker,
    onSubmit: (updateValues, action) => {
      CategoryUpdate(updateValues, action);
    },
  });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Add Fitness Record</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          // changeCategory(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    Available Trainers
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={values.trainer_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          packagename(e.target.value);
                        }}
                        disabled={role === "9" && UId ? true : false}
                        onBlur={handleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.trainer_id && touched.trainer_id ? (
                      <p className="text-danger">{errors.trainer_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Package<span className="text-danger">*</span>
                    </label>
                    {myPackage.length ? (
                      <select
                        className="form-select"
                        name="order_id"
                        value={values.order_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myPackage.map((el, index) => {
                          return (
                            <option key={index} value={el.order_id}>
                              {el.package_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="order_id"
                        value={values.order_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Package</option>
                      </select>
                    )}

                    {errors.order_id && touched.order_id ? (
                      <p className="text-danger">{errors.order_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Body Part <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="type"
                      value={values.type || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Body Part</option>
                    </select>
                    {errors.type && touched.type ? (
                      <p className="text-danger">{errors.type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Repetitions<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Name"
                      name="repetitions"
                      autoComplete="off"
                      value={values.repetitions}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.repetitions && touched.repetitions ? (
                      <p className="text-danger">{errors.repetitions}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Weight<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Weight"
                      name="weight"
                      autoComplete="off"
                      value={values.weight}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.weight && touched.weight ? (
                      <p className="text-danger">{errors.weight}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Date"
                      name="date"
                      autoComplete="off"
                      value={values.date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.date && touched.date ? (
                      <p className="text-danger">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Remarks<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Remarks"
                      name="remarks"
                      autoComplete="off"
                      value={values.remarks}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.remarks && touched.remarks ? (
                      <p className="text-danger">{errors.remarks}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">View Tracking Record</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-1 col-lg-1 ms-auto mt-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getPkg("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
                <div className=" col-md-1   text-end mt-3">
                  <button
                    className="btn btn-primary mx-2 file-download"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Categories
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={updateHandleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={updateValues.branch_id || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                          // changeCategory(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.branch_id && updateTouched.branch_id ? (
                      <p className="text-danger">{updateErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Select Type<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="type"
                      value={updateValues.type || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      <option value="">Select Type</option>
                      <option value="Cafe">Cafe</option>
                      <option value="Asset">Asset</option>
                      <option value="Liability">Liability</option>
                      <option value="Expense">Expense</option>
                    </select>
                    {updateErrors.type && updateTouched.type ? (
                      <p className="text-danger">{updateErrors.type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="name"
                      autoComplete="off"
                      value={updateValues.name}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    />

                    {updateErrors.name && updateTouched.name ? (
                      <p className="text-danger">{updateErrors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
