import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { depositPkgSell } from "../schemas";

export default function DepositPkg(props) {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [paySplitError, setPaySplitError] = useState("");

  const [membershipType, setMembershipType] = useState([]);

  const [discountError, setDiscountError] = useState("");

  const [payAfterError, setPayAfterError] = useState("");
  const [clientName, setClientName] = useState("");
  const { id } = useParams();

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(eDate);

  // Add 2 years to the startDate
  const newStartDate = moment(startDate).add(2, "years").format("YYYY-MM-DD");

  // Set the newStartDate in a new state
  // eslint-disable-next-line
  const [updatedStartDate, setUpdatedStartDate] = useState(newStartDate);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getMembershipType = async (br_id) => {
      try {
        const responce = await UserServices.registrationPkgs(br_id, "13");

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMembershipType([{ name: "Select Deposit Package", id: "" }, ...results]);
      } catch (err) {
        console.log(err);

        setMembershipType([{ name: "No Package Found", id: "" }]);
      }
    };

    const nameClients = async () => {
      try {
        const responce = await UserServices.getClientsNameById(branch_id, id);

        setClientName(
          responce.data.data[0].first_name +
            " " +
            responce.data.data[0].last_name
        );
      } catch (err) {
        console.log(err);
        setClientName("");
      }
    };
    getMembershipType(branch_id);
    nameClients();
  }, []);

  const [membershipFee, setMembershipFee] = useState("");
  const [pkgType, setPkgType] = useState(""); // Assuming you need to track package type

  const getPkgFee = async (e) => {
    const pkgType = e.target.value;
    setPkgType(pkgType);

    const sDate = moment(defaultValue).format("YYYY-MM-DD");
    try {
      const response = await UserServices.pkgFee(pkgType, sDate, 1);
      const price = response.data.price;
      setMembershipFee(price);
    } catch (err) {
      console.log(err);
      setMembershipFee("");
    }
  };

  const handleMembershipFeeChange = (e) => {
    setMembershipFee(e.target.value);
  };
  const sellRegistrationPkg = async (values) => {
    if (paySplitError === "" && payAfterError === "" && discountError === "") {
      setLoading(true);
      try {
        const responce = await UserServices.depositPkgSell(
          branch_id,
          id,
          membershipFee,
          "13",
          values
        );
        console.log(responce);
        toast.success("Deposit Added!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setTimeout(() => {
          props.cart();
          props.catagry();
        }, 1500);
      } catch (err) {
        toast.error("Unproccessable content!", { position: "top-center" });
        console.log(err);
        setLoading(false);
      }
    }
  };

  const initialValues = {
    client_name: clientName,
    memType: "",
    salesnotes: "",
    salesdate: dateSelected,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: depositPkgSell,
      onSubmit: (values, action) => {
        sellRegistrationPkg(values);
      },
    });

  return (
    <>
      <>
        <form className="" onSubmit={handleSubmit}>
          <>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Client Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={clientName}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Select Deposit Package<span className="text-danger">*</span>
                    </label>

                    {membershipType.length ? (
                      <select
                        className="form-select"
                        name="memType"
                        value={values.memType}
                        onChange={(e) => {
                          handleChange(e);
                          getPkgFee(e);
                          values.discount_amount = "";
                          setDiscountError("");
                          setPaySplitError("");
                          setPayAfterError("");
                          values.pay_after_dis = "0";
                          values.payment_rec = "";
                          values.payment_pend = "";
                          values.payment_split = "";
                          values.payment_split_pend = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {membershipType.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="memType"
                        value={values.memType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                      </select>
                    )}

                    {errors.memType && touched.memType ? (
                      <p className="text-danger">{errors.memType}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Amount<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={membershipFee}
                      onChange={handleMembershipFeeChange} // Update membershipFee on input change
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Sales Notes
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="salesnotes"
                      value={values.salesnotes}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.salesnotes && touched.salesnotes ? (
                      <p className="text-danger">{errors.salesnotes}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Deposit Date
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="salesdate"
                      value={values.salesdate}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.salesdate && touched.salesdate ? (
                      <p className="text-danger">{errors.salesdate}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>

          <div className="col-12 text-center mb-3">
            <button
              type="submit"
              className="btn btn-primary px-5 mb-1 me-2 addclient-button"
            >
              Deposit Balance
            </button>
          </div>
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </form>
      </>
    </>
  );
}
