import React from "react";

export default function ViewMultiMeal({
  formDataIndex,
  onFormChange,
  initVal,
}) {
  // eslint-disable-next-line

  return (
    <>
      <tr>
        <td rowSpan="5">
          <span className="weekNo">Week {formDataIndex + 1}</span>
        </td>
        <td className="text-uppercase">Breakfast</td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.sunday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "sunday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.monday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "monday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.tuesday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "tuesday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.wednesday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.thursday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "thursday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.friday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "friday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.breakfast?.saturday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "saturday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Lunch</td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.sunday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.monday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.tuesday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "tuesday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.wednesday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "wednesday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.thursday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "thursday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.friday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.lunch?.saturday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "saturday", "lunch");
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Dinner</td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.sunday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.monday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.tuesday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "tuesday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.wednesday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "dinner"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.thursday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "thursday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.friday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.dinner?.saturday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "saturday", "dinner");
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Snacks</td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.sunday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.monday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.tuesday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "tuesday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.wednesday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "snacks"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.thursday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "thursday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.friday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.snacks?.saturday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "saturday", "snacks");
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Munching</td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.sunday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "munching");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.monday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "munching");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.tuesday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "tuesday",
                "munching"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.wednesday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "munching"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.thursday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "thursday",
                "munching"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.friday}
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "munching");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            defaultValue={initVal?.munching?.saturday}
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "saturday",
                "munching"
              );
            }}
          ></textarea>
        </td>
      </tr>
    </>
  );
}
