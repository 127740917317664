import React from 'react'
import { useNavigate } from 'react-router';
import "./../../App.css";

export default function UnAuthorized() {
    const navigate= useNavigate();
  return (
    <div id='unauthorized'>
      <div className="section">
  <img src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404"/>
  <h1 className="error mb-3">403</h1>
  <div className="page">Ooops!!! The page you are looking for is Unauthorized</div>
  <button className="back-home"  onClick={() => {
            navigate("/dashboard");
          }}>Back to home</button>
</div>
    </div>
  )
}