import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import "./Card.css";

const CardBack = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Print Card
                {/* <Link
                  to="/viewtrainer"
                  className="btn btn-danger d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-x" />
                  Done
                </Link> */}
                <button  className="btn btn-danger d-inline-block float-end btn-sm" onClick={() => window.print()}>Print Card</button>
              </h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div
                 id="card"
                  style={{
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    width: "490px",
                    height: "309px",
                    margin: "0 auto",
                    marginTop: "110px",
                  }}
                >
                  <div
                    style={{
                      width: "490px",
                      height: "309px",
                      backgroundImage: "url(/assets/images/card-back1.jpg)",
                      margin: "0 auto",
                      marginTop: "110px",
                      display: "block",
                      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                      WebkitPrintColorAdjust: "exact",
                      WebkitFilter: "opacity(1)",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "200px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        top: "18%",
                        borderTopRightRadius: "30px",
                        borderBottomRightRadius: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "200px",
                        height: "30px",
                        backgroundColor: "black",
                        position: "absolute",
                        right: "0",
                        bottom: "0",
                        borderTopLeftRadius: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        left: " 23%",
                        top: "36%",
                        position: "absolute",
                      }}
                    >
                      <img
                        src="/assets/images/logo-2.png"
                        alt="logo"
                        style={{ width: "64%" }}
                      ></img>
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        position: "absolute",
                        top: " 89%",
                        fontSize: "18px",
                        left: "15%",
                      }}
                    >
                      <i className="bi bi-globe"></i> vostroworld.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CardBack;
