import React, { useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import UserServices from "../../UserServices/UserServices";
import { useState } from "react";

import { NoteState } from "../../context/notes/NoteState";
import Swal from "sweetalert2";

import { Link } from "react-router-dom";

const TrainerClient = () => {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fname, setFname] = useState("");

  const [lname, setLname] = useState("");
  const [email, setemail] = useState("");
  const [prfoImg, setPrfoImg] = useState("");

  const [uid, setuid] = useState("0");

  const { id } = useParams();

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientProfileInfo(id);

        if (responce.status === 200) {
          let data = responce.data.data;
          await data.map((value) => {
            setFname(value.first_name);

            setLname(value.last_name);
            setuid(value.uid);
            setPrfoImg(value.image);
            setemail(value.email);
          });

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.message === "Network Error") {
          setLoading(false);
        } else if (err.response.status === 401) {
          setLoading(false);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No Record Found!",
            confirmButtonColor: "#e72e2e",
            // confirmButtonText: "LogIn Again!",
          });
          navigate("/dashboard");
        } else {
          setLoading(false);
        }
      }
    };

    getUsers();
  }, [id]);

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20 text-capitalize">
                  Client Profile
                  <button
                    className="btn btn-primary d-inline-block btn-sm float-end"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-tools" /> Tools
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/preAssessment/" + id}
                      >
                        Add Assessment
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/client-assessment/" + id}
                      >
                        View Assessments
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={"/account-details/" + id}
                        className="dropdown-item"
                      >
                        Account Details
                      </Link>
                    </li>
                  </ul>
                </h5>
              </div>
              <div className="card-body">
                <div className="row ">
                  <div className="col-md-4">
                    <img
                      src={
                        prfoImg === ""
                          ? "/assets/images/placeholder2.png"
                          : prfoImg
                      }
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body d-flex w-100 h-100 align-items-center justify-content-start">
                      <p className="card-text mb-0 w-100">
                        <label className="form-label fw-bold d-flex justify-content-between">
                          Name
                          <span className="fw-medium">
                            {fname + " " + lname}
                          </span>
                        </label>
                        <label className="form-label fw-bold d-flex justify-content-between">
                          Email <span className="fw-medium">{email}</span>
                        </label>
                        <label className="form-label fw-bold d-flex justify-content-between">
                          Membership No
                          <span className="fw-medium">{uid}</span>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default TrainerClient;
