import React from 'react';
import MenuButton from '../MenuButton/MenuButton';
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import { useFormik } from "formik";
import { signUpSchema } from '../schemas';

const initialValues ={
  message:"",
}
const SendSms = () => {

  const {values, errors, touched, handleBlur, handleChange, handleSubmit } =  useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema, 
    onSubmit : (values) => {
     console.log(
      values
     );
    },
  });

  return (
    <div className='wrapper'>
     <Navbar/>
      <Sidebar/>
      <main className="page-content customer-contnent">
      <div className="site-main-design">
 <MenuButton/>
  <div className="card">
    <div className="card-header py-3">
      <h5 className="mb-0 font-20"> 
        Send Message
      </h5>
    </div>
    <div className="card-body">
      <div className="card">
        <div className="card-header py-3">
          <h5 className="mb-0 font-24"> 
            Create Messages
          </h5>
        </div>
        <div className="card-body">
          <form action="" className="row" onSubmit={handleSubmit}>
            <div className="col-12 col-md-6">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                      { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
            <div className="col-12 col-md-6">
              <label className="form-label">Select Client Or Trainer </label>
              <select className="form-select">
                <option>Clients</option>
                <option>Staff</option>
              </select>
              <button type="button" className="btn btn-primary px-5 rounded-0 mb-1 mt-3">Send</button>
            </div>
          </form>
        </div>
      </div>
      <button type="button" className="btn btn-primary px-5 rounded-0 mb-1 float-end">Send</button>
      <div className="clearfix" />
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Clients
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                       { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Trainers
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
              { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Sell Package
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message" name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                       { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Daily Pass
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                       { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Renewal
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                      { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Expire Package
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
              { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Announcements
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message"  name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                       { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-24"> 
                Events
              </h5>
            </div>
            <div className="card-body">
              <label className="form-label">Message</label>
              <textarea className="form-control" rows={4} cols={4} placeholder="Message" name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
              { errors.message && touched.message ? (<p className='text-danger'>{errors.message}</p>): null }
              {/* <span className="text-danger">Message is required</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </main>
    </div>
  )
}

export default SendSms
