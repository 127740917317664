import React, { useContext } from "react";
import { useState } from "react";
import MenuButton from "../../MenuButton/MenuButton";

import UserServices from "../../../UserServices/UserServices";
import { useEffect } from "react";
import { NoteState } from "../../../context/notes/NoteState";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import ViewMultiMeal from "./ViewMultiMeal";

export default function ViewMealPlansDetail() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [weeks, setWeeks] = useState([]);

  const getRecord = async () => {
    try {
      setLoading(true);

      await setWeeks([]);

      const response = await UserServices.getMealPlan(id);

      setWeeks([
        {
          week: "1",

          breakfast: {
            sunday: response?.data?.data[0]?.plane[0]?.specs?.breakfast?.sunday,
            monday: response?.data?.data[0]?.plane[0]?.specs?.breakfast?.monday,
            tuesday:
              response?.data?.data[0]?.plane[0]?.specs?.breakfast?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[0]?.specs?.breakfast?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[0]?.specs?.breakfast?.thursday,
            friday: response?.data?.data[0]?.plane[0]?.specs?.breakfast?.friday,
            saturday:
              response?.data?.data[0]?.plane[0]?.specs?.breakfast?.saturday,
          },
          lunch: {
            sunday: response?.data?.data[0]?.plane[0]?.specs?.lunch?.sunday,
            monday: response?.data?.data[0]?.plane[0]?.specs?.lunch?.monday,
            tuesday: response?.data?.data[0]?.plane[0]?.specs?.lunch?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[0]?.specs?.lunch?.wednesday,
            thursday: response?.data?.data[0]?.plane[0]?.specs?.lunch?.thursday,
            friday: response?.data?.data[0]?.plane[0]?.specs?.lunch?.friday,
            saturday: response?.data?.data[0]?.plane[0]?.specs?.lunch?.saturday,
          },
          dinner: {
            sunday: response?.data?.data[0]?.plane[0]?.specs?.dinner?.sunday,
            monday: response?.data?.data[0]?.plane[0]?.specs?.dinner?.monday,
            tuesday: response?.data?.data[0]?.plane[0]?.specs?.dinner?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[0]?.specs?.dinner?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[0]?.specs?.dinner?.thursday,
            friday: response?.data?.data[0]?.plane[0]?.specs?.dinner?.friday,
            saturday:
              response?.data?.data[0]?.plane[0]?.specs?.dinner?.saturday,
          },
          snacks: {
            sunday: response?.data?.data[0]?.plane[0]?.specs?.snacks?.sunday,
            monday: response?.data?.data[0]?.plane[0]?.specs?.snacks?.monday,
            tuesday: response?.data?.data[0]?.plane[0]?.specs?.snacks?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[0]?.specs?.snacks?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[0]?.specs?.snacks?.thursday,
            friday: response?.data?.data[0]?.plane[0]?.specs?.snacks?.friday,
            saturday:
              response?.data?.data[0]?.plane[0]?.specs?.snacks?.saturday,
          },
          munching: {
            sunday: response?.data?.data[0]?.plane[0]?.specs?.munching?.sunday,
            monday: response?.data?.data[0]?.plane[0]?.specs?.munching?.monday,
            tuesday:
              response?.data?.data[0]?.plane[0]?.specs?.munching?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[0]?.specs?.munching?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[0]?.specs?.munching?.thursday,
            friday: response?.data?.data[0]?.plane[0]?.specs?.munching?.friday,
            saturday:
              response?.data?.data[0]?.plane[0]?.specs?.munching?.saturday,
          },
        },
        {
          week: "2",
          breakfast: {
            sunday: response?.data?.data[0]?.plane[1]?.specs?.breakfast?.sunday,
            monday: response?.data?.data[0]?.plane[1]?.specs?.breakfast?.monday,
            tuesday:
              response?.data?.data[0]?.plane[1]?.specs?.breakfast?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[1]?.specs?.breakfast?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[1]?.specs?.breakfast?.thursday,
            friday: response?.data?.data[0]?.plane[1]?.specs?.breakfast?.friday,
            saturday:
              response?.data?.data[0]?.plane[1]?.specs?.breakfast?.saturday,
          },
          lunch: {
            sunday: response?.data?.data[0]?.plane[1]?.specs?.lunch?.sunday,
            monday: response?.data?.data[0]?.plane[1]?.specs?.lunch?.monday,
            tuesday: response?.data?.data[0]?.plane[1]?.specs?.lunch?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[1]?.specs?.lunch?.wednesday,
            thursday: response?.data?.data[0]?.plane[1]?.specs?.lunch?.thursday,
            friday: response?.data?.data[0]?.plane[1]?.specs?.lunch?.friday,
            saturday: response?.data?.data[0]?.plane[1]?.specs?.lunch?.saturday,
          },
          dinner: {
            sunday: response?.data?.data[0]?.plane[1]?.specs?.dinner?.sunday,
            monday: response?.data?.data[0]?.plane[1]?.specs?.dinner?.monday,
            tuesday: response?.data?.data[0]?.plane[1]?.specs?.dinner?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[1]?.specs?.dinner?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[1]?.specs?.dinner?.thursday,
            friday: response?.data?.data[0]?.plane[1]?.specs?.dinner?.friday,
            saturday:
              response?.data?.data[0]?.plane[1]?.specs?.dinner?.saturday,
          },
          snacks: {
            sunday: response?.data?.data[0]?.plane[1]?.specs?.snacks?.sunday,
            monday: response?.data?.data[0]?.plane[1]?.specs?.snacks?.monday,
            tuesday: response?.data?.data[0]?.plane[1]?.specs?.snacks?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[1]?.specs?.snacks?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[1]?.specs?.snacks?.thursday,
            friday: response?.data?.data[0]?.plane[1]?.specs?.snacks?.friday,
            saturday:
              response?.data?.data[0]?.plane[1]?.specs?.snacks?.saturday,
          },
          munching: {
            sunday: response?.data?.data[0]?.plane[1]?.specs?.munching?.sunday,
            monday: response?.data?.data[0]?.plane[1]?.specs?.munching?.monday,
            tuesday:
              response?.data?.data[0]?.plane[1]?.specs?.munching?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[1]?.specs?.munching?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[1]?.specs?.munching?.thursday,
            friday: response?.data?.data[0]?.plane[1]?.specs?.munching?.friday,
            saturday:
              response?.data?.data[0]?.plane[1]?.specs?.munching?.saturday,
          },
        },
        {
          week: "3",
          breakfast: {
            sunday: response?.data?.data[0]?.plane[2]?.specs?.breakfast?.sunday,
            monday: response?.data?.data[0]?.plane[2]?.specs?.breakfast?.monday,
            tuesday:
              response?.data?.data[0]?.plane[2]?.specs?.breakfast?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[2]?.specs?.breakfast?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[2]?.specs?.breakfast?.thursday,
            friday: response?.data?.data[0]?.plane[2]?.specs?.breakfast?.friday,
            saturday:
              response?.data?.data[0]?.plane[2]?.specs?.breakfast?.saturday,
          },
          lunch: {
            sunday: response?.data?.data[0]?.plane[2]?.specs?.lunch?.sunday,
            monday: response?.data?.data[0]?.plane[2]?.specs?.lunch?.monday,
            tuesday: response?.data?.data[0]?.plane[2]?.specs?.lunch?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[2]?.specs?.lunch?.wednesday,
            thursday: response?.data?.data[0]?.plane[2]?.specs?.lunch?.thursday,
            friday: response?.data?.data[0]?.plane[2]?.specs?.lunch?.friday,
            saturday: response?.data?.data[0]?.plane[2]?.specs?.lunch?.saturday,
          },
          dinner: {
            sunday: response?.data?.data[0]?.plane[2]?.specs?.dinner?.sunday,
            monday: response?.data?.data[0]?.plane[2]?.specs?.dinner?.monday,
            tuesday: response?.data?.data[0]?.plane[2]?.specs?.dinner?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[2]?.specs?.dinner?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[2]?.specs?.dinner?.thursday,
            friday: response?.data?.data[0]?.plane[2]?.specs?.dinner?.friday,
            saturday:
              response?.data?.data[0]?.plane[2]?.specs?.dinner?.saturday,
          },
          snacks: {
            sunday: response?.data?.data[0]?.plane[2]?.specs?.snacks?.sunday,
            monday: response?.data?.data[0]?.plane[2]?.specs?.snacks?.monday,
            tuesday: response?.data?.data[0]?.plane[2]?.specs?.snacks?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[2]?.specs?.snacks?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[2]?.specs?.snacks?.thursday,
            friday: response?.data?.data[0]?.plane[2]?.specs?.snacks?.friday,
            saturday:
              response?.data?.data[0]?.plane[2]?.specs?.snacks?.saturday,
          },
          munching: {
            sunday: response?.data?.data[0]?.plane[2]?.specs?.munching?.sunday,
            monday: response?.data?.data[0]?.plane[2]?.specs?.munching?.monday,
            tuesday:
              response?.data?.data[0]?.plane[2]?.specs?.munching?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[2]?.specs?.munching?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[2]?.specs?.munching?.thursday,
            friday: response?.data?.data[0]?.plane[2]?.specs?.munching?.friday,
            saturday:
              response?.data?.data[0]?.plane[2]?.specs?.munching?.saturday,
          },
        },
        {
          week: "4",
          breakfast: {
            sunday: response?.data?.data[0]?.plane[3]?.specs?.breakfast?.sunday,
            monday: response?.data?.data[0]?.plane[3]?.specs?.breakfast?.monday,
            tuesday:
              response?.data?.data[0]?.plane[3]?.specs?.breakfast?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[3]?.specs?.breakfast?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[3]?.specs?.breakfast?.thursday,
            friday: response?.data?.data[0]?.plane[3]?.specs?.breakfast?.friday,
            saturday:
              response?.data?.data[0]?.plane[3]?.specs?.breakfast?.saturday,
          },
          lunch: {
            sunday: response?.data?.data[0]?.plane[3]?.specs?.lunch?.sunday,
            monday: response?.data?.data[0]?.plane[3]?.specs?.lunch?.monday,
            tuesday: response?.data?.data[0]?.plane[3]?.specs?.lunch?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[3]?.specs?.lunch?.wednesday,
            thursday: response?.data?.data[0]?.plane[3]?.specs?.lunch?.thursday,
            friday: response?.data?.data[0]?.plane[3]?.specs?.lunch?.friday,
            saturday: response?.data?.data[0]?.plane[3]?.specs?.lunch?.saturday,
          },
          dinner: {
            sunday: response?.data?.data[0]?.plane[3]?.specs?.dinner?.sunday,
            monday: response?.data?.data[0]?.plane[3]?.specs?.dinner?.monday,
            tuesday: response?.data?.data[0]?.plane[3]?.specs?.dinner?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[3]?.specs?.dinner?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[3]?.specs?.dinner?.thursday,
            friday: response?.data?.data[0]?.plane[3]?.specs?.dinner?.friday,
            saturday:
              response?.data?.data[0]?.plane[3]?.specs?.dinner?.saturday,
          },
          snacks: {
            sunday: response?.data?.data[0]?.plane[3]?.specs?.snacks?.sunday,
            monday: response?.data?.data[0]?.plane[3]?.specs?.snacks?.monday,
            tuesday: response?.data?.data[0]?.plane[3]?.specs?.snacks?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[3]?.specs?.snacks?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[3]?.specs?.snacks?.thursday,
            friday: response?.data?.data[0]?.plane[3]?.specs?.snacks?.friday,
            saturday:
              response?.data?.data[0]?.plane[3]?.specs?.snacks?.saturday,
          },
          munching: {
            sunday: response?.data?.data[0]?.plane[3]?.specs?.munching?.sunday,
            monday: response?.data?.data[0]?.plane[3]?.specs?.munching?.monday,
            tuesday:
              response?.data?.data[0]?.plane[3]?.specs?.munching?.tuesday,
            wednesday:
              response?.data?.data[0]?.plane[3]?.specs?.munching?.wednesday,
            thursday:
              response?.data?.data[0]?.plane[3]?.specs?.munching?.thursday,
            friday: response?.data?.data[0]?.plane[3]?.specs?.munching?.friday,
            saturday:
              response?.data?.data[0]?.plane[3]?.specs?.munching?.saturday,
          },
        },
      ]);

      setLoading(false);
    } catch (err) {
      console.log(err);

      setWeeks([]);

      setLoading(false);
    }
  };

  useEffect(() => {
    getRecord();
  }, []);

  const handleFormChange = async (formDataIndex, val, fieldName, catagry) => {
    const updatedFormDataw = [...weeks];
    if (updatedFormDataw[formDataIndex][catagry][fieldName] !== val) {
      updatedFormDataw[formDataIndex][catagry][fieldName] = val;
      setWeeks(updatedFormDataw);

      try {
        const response = await UserServices.updateMealPlanSpec(
          formDataIndex,
          catagry,
          fieldName,
          val,
          id
        );
        toast.success("Record has updated successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                View Meals Plans{" "}
                <Link
                  to="/view-meal-plan"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  Back
                </Link>
              </h5>
            </div>
            <div className="card-body">
              {weeks.length ? (
                <div className="overflow-auto w-100 mt-3">
                  <table className="table  table-bordered align-middle table-style-1 text-center">
                    <thead>
                      <tr>
                        <th scope="col" width="7%" className="no_sort "></th>
                        <th scope="col" className="no_sort">
                          Meals
                        </th>
                        <th scope="col" width="12%" className="no_sort">
                          Sunday
                        </th>
                        <th scope="col" width="12%" className="no_sort">
                          Monday
                        </th>

                        <th scope="col" width="12%" className="no_sort">
                          Tuesday
                        </th>
                        <th scope="col" width="12%" className="no_sort">
                          Wednesday
                        </th>
                        <th scope="col" width="12%" className="no_sort">
                          Thursday
                        </th>
                        <th scope="col" width="12%" className="no_sort">
                          Friday
                        </th>
                        <th scope="col" width="12%" className="no_sort">
                          Saturday
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {weeks.map((value, index) => (
                        <ViewMultiMeal
                          formDataIndex={index}
                          key={value.week}
                          initVal={value}
                          onFormChange={handleFormChange}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
