import React, { useEffect, useState, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import { manageSessionAttendanceForTrainer } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const PTSessionAttendance = () => {
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  // const [startDate, setStartDate] = useState(eDate);
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [name, setName] = useState([]);
  const [myPackage, setMyPackage] = useState([]);
  const [myTimes, setMyTimes] = useState([]);

  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [attendanceError, setAttendanceError] = useState("");
  // for inactive table
  const [inactiveLimit, setInactiveLimit] = useState("25");
  const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
  const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
  const [inactiveData, setInactiveData] = useState([]);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);
  const [dateDisable, setDateDisable] = useState(false);
  // console.log(`type=${role}`);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const nameTrainers = async (branch_id) => {
    try {
      const responce = await UserServices.getTrainerNutritionistName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };
  const getAttend = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getActiveAttendanceForTrainer(
        branch_id,
        "1",
        newLimit,
        pageNo,
        "PT"
      );

      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            trainer_name: values.trainer?.trainer_name,
            package_name: values.order?.name,
            date: values.date,
            trainer_id: values.trainer_id,
            client_name: values.order?.client_name,
            client_id: values.client_id,
            staff_status: values.staff_status,
            client_status: values.client_status,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
    }
  };
  const getAttendence = async (page, limit) => {
    setLoading(true);
    setInactiveCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setInactiveLimit(newLimit);
    try {
      const responce = await UserServices.getActiveAttendanceForTrainer(
        branch_id,
        "0",
        newLimit,
        pageNo,
        "PT"
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            trainer_name: values.trainer?.trainer_name,
            package_name: values.order?.name,
            date: values.date,
            trainer_id: values.trainer_id,
            client_name: values.order?.client_name,
            client_id: values.client_id,
            staff_status: values.staff_status,
            client_status: values.client_status,
          });
          return null;
        });

        setInactiveData([...results]);
        setInactiveLimit(responce.data.data.per_page);
        setInactiveTotalResults(responce.data.totalRecord);
        setInactiveTotalPages(responce.data.totalPages);
        setInactiveCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      setInactiveTotalResults("0");
      setInactiveTotalPages("1");
      setInactiveData([]);
      setLoading(false);
    }
  };
  const changeAttendanceType = (e, page, limit) => {
    let packageTime = e;
    const getClientTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getAttendanceByTypeTrainer(
          branch_id,

          limit,
          page,
          packageTime
        );
        let myDataarr;
        console.log(responce);
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              trainer_name: values.trainer.trainer_name,
              package_name: values.order.name,
              date: values.date,
              trainer_id: values.trainer_id,
              client_name: values.order.client_name,
              client_id: values.client_id,
              staff_status: values.staff_status,
              client_status: values.client_status,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getClientTypeData();
  };
  const changePackageName = (e) => {
    let packageName = e;
    const getClientTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getAttendanceByPackageTrainer(
          branch_id,
          // "1",
          limit,
          1,
          packageName
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              trainer_name: values.trainer.trainer_name,
              package_name: values.order.name,
              date: values.date,
              trainer_id: values.trainer_id,
              client_name: values.order.client_name,
              client_id: values.client_id,
              staff_status: values.staff_status,
              client_status: values.client_status,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getClientTypeData();
  };

  const packagename = async (e) => {
    var trainer_Id = e;

    try {
      const responce = await UserServices.getOrderName(trainer_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          order_id: value.order_id,
          package_name: value.package_name + " for " + value.client_name,
          client_name: value.client_name,
          client_id: value.client_id,
          client_email: value.client_email,
        });
        return null;
      });
      setMyPackage([
        { package_name: "Select Package", order_id: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);
      setMyPackage([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    nameTrainers(branch_id);
    if (role === "9") {
      setDateDisable(true);
      packagename(UId);
      changeAttendanceType(UId, currentPage, limit);
    } else {
      getAttend(currentPage, limit);
      getAttendence(inactiveCurrentPage, inactiveLimit);
    }
  }, []);

  const addSessionAttendance = async (values, action) => {
    if (attendanceError === "") {
      try {
        setLoading(true);
        const response = await UserServices.addAttendanceForTrainer(values);
        if (response.status === 201) {
          setLoading(false);

          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          if (role === "9") {
            changeAttendanceType(UId, currentPage, limit);
          } else {
            getAttend(currentPage, limit);
          }
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.status === 400) {
          toast.error(
            "Email delivery failed. The recipient's email is invalid.",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          if (role === "9") {
            changeAttendanceType(UId, currentPage, limit);
          } else {
            getAttend(currentPage, limit);
          }
        } else if (err?.response?.status === 403) {
          toast.error("This record is Already Exists!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    }
  };

  const completeAttendance = async (e) => {
    values.trainer_schedule_id = "";

    var order_Id = e.target.value;
    const filteredData = await myPackage.filter(
      (item) => item.order_id === parseInt(e.target.value)
    );

    setFieldValue("clientEmail", filteredData[0]?.client_email);
    setFieldValue("clientName", filteredData[0]?.client_name);
    setFieldValue("clientId", filteredData[0]?.client_id);

    // try {
    //   const response = await UserServices.checkCompleteAttendanceForTrainer(
    //     order_Id
    //   );
    //   if (response.status === 200) {
    //     setAttendanceError("");
    //   }
    // } catch (err) {
    //   if (err.response.status === 409) {
    //     setLoading(false);
    //     Swal.fire({
    //       title: "Error!",
    //       text: "Attendance is complete",
    //       icon: "error",
    //       allowOutsideClick: false,
    //       allowEscapeKey: false,
    //       confirmButtonColor: "#ed1c24",
    //     });
    //     setAttendanceError("Attendance is complete");
    //   }
    // }
    try {
      const response = await UserServices.checkScheduleForTrainerPkg(
        order_Id,
        values.trainer_id
      );

      if (response.status === 200) {
        const res = response.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            trainer_schedules_id: value.trainer_schedules_id,
            time: value.start_time + " To " + value.end_time,
          });
          return null;
        });
        setMyTimes([
          { time: "Select Time", trainer_schedules_id: "" },
          ...results,
        ]);
      }
    } catch (err) {
      setMyTimes([]);
      setLoading(false);
    }
  };
  const verifyAttendance = async (values, action) => {
    try {
      setLoading(true);

      const response = await UserServices.checkSessiuonAttendanceForTrainer(
        values
      );
      if (response.status === 200) {
        addSessionAttendance(values, action);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 409) {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Already Exists.",
          icon: "error",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        });
      }
    }
  };

  const makeInActive = async (attend_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.inactiveAttendance(attend_Id);

      if (responce.status === 204) {
        setLoading(false);

        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getAttend(currentPage, limit);
        getAttendence(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveAtendnc = (attend_Id) => {
    makeInActive(attend_Id);
  };

  const makeActive = async (attend_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.activeAttendance(attend_Id);

      if (responce.status === 204) {
        setLoading(false);

        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getAttend(currentPage, limit);
        getAttendence(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeAttendance = (attend_Id) => {
    makeActive(attend_Id);
  };
  const deleteRecord = async (attend_Id) => {
    try {
      const responce = await UserServices.deleteAttendance(attend_Id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getAttendence(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delAttendance = (attend_Id) => {
    deleteRecord(attend_Id);
  };
  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    order_id: "",
    trainer_status: "Delivered",
    client_status: "",
    clientName: "",
    clientId: "",
    clientEmail: "",
    date: eDate,
    trainer_schedule_id: "",
    type: "PT",
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: manageSessionAttendanceForTrainer,
    onSubmit: (values, action) => {
      verifyAttendance(values, action);
    },
  });

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("session-attendance.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Session Attendance"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer_name")}
                className={getClassNamesFor("trainer_name")}
              >
                Trainer Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("staff_status")}
                className={getClassNamesFor("staff_status")}
              >
                Trainer Attendance
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_status")}
                className={getClassNamesFor("client_status")}
              >
                Client Attendance
              </th>

              <th scope="col" className="no_sort">
                Date
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.trainer_name}</td>
                  <td>
                    <Link to={"/trainer-client/" + el.client_id}>
                      {el.client_name}
                    </Link>
                  </td>
                  <td>{el.package_name}</td>
                  <td>{el.staff_status}</td>
                  <td>{el.client_status}</td>
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                  <td>
                    {role === "9" ? null : (
                      <Link
                        className="updt-btn"
                        to={"/update-session-attendance/" + el.id}
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </Link>
                    )}
                    <button
                      type="button"
                      onClick={() => inactiveAtendnc(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Inactive
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              if (role === "9") {
                changeAttendanceType(UId, e, limit);
              } else {
                getAttend(e, limit);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const InactiveTable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer_name")}
                className={getClassNamesFor("trainer_name")}
              >
                Trainer Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("staff_status")}
                className={getClassNamesFor("staff_status")}
              >
                Trainer Attendance
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_status")}
                className={getClassNamesFor("client_status")}
              >
                Client Attendance
              </th>

              <th scope="col" className="no_sort">
                Date
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.trainer_name}</td>
                  <td>
                    <Link to={"/trainer-client/" + el.client_id}>
                      {el.client_name}
                    </Link>
                  </td>
                  <td>{el.package_name}</td>
                  <td>{el.staff_status}</td>
                  <td>{el.client_status}</td>
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => activeAttendance(el.id)}
                      className="active-btn"
                    >
                      <i className="bi bi-check-circle text-success"></i>{" "}
                      <span className="text-success inative-text ms-1">
                        Active
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delAttendance(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
          <Pagination
            activePage={inactiveCurrentPage}
            itemsCountPerPage={parseInt(inactiveLimit)}
            totalItemsCount={inactiveTotalResults}
            onChange={(e) => {
              getAttendence(e, inactiveLimit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Add session Attendance</h5>
            </div>
            <div className="card-body">
              <form className="row g-3 trainee-add" onSubmit={handleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          nameTrainers(e.target.value);
                          values.trainer_id = "";
                          values.order_id = "";
                          values.trainer_schedule_id = "";
                          setMyTimes([]);
                          setMyPackage([]);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Trainer</label>
                    <span className="text-danger">*</span>
                    {name ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={values.trainer_id}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                          packagename(e.target.value);
                          values.order_id = "";
                          values.trainer_schedule_id = "";
                          setMyTimes([]);
                        }}
                        onBlur={handleBlur}
                      >
                        <option>Select Name</option>
                        {name.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}

                    {errors.trainer_id && touched.trainer_id ? (
                      <p className="text-danger">{errors.trainer_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Package<span className="text-danger">*</span>
                    </label>
                    {myPackage.length ? (
                      <select
                        className="form-select"
                        name="order_id"
                        value={values.order_id}
                        onChange={(e) => {
                          handleChange(e);
                          completeAttendance(e);
                          values.trainer_schedule_id = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {myPackage.map((el, index) => {
                          return (
                            <option key={index} value={el.order_id}>
                              {el.package_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="order_id"
                        value={values.order_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Package</option>
                      </select>
                    )}
                    {attendanceError ? (
                      <div className="errorMsg text-danger">
                        {attendanceError}
                      </div>
                    ) : null}
                    {errors.order_id && touched.order_id ? (
                      <p className="text-danger">{errors.order_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Time<span className="text-danger">*</span>
                    </label>
                    {myTimes.length ? (
                      <select
                        className="form-select"
                        name="trainer_schedule_id"
                        value={values.trainer_schedule_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myTimes.map((el, index) => {
                          return (
                            <option key={index} value={el.trainer_schedules_id}>
                              {el.time}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="trainer_schedule_id"
                        value={values.trainer_schedule_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Time</option>
                      </select>
                    )}

                    {errors.trainer_schedule_id &&
                    touched.trainer_schedule_id ? (
                      <p className="text-danger">
                        {errors.trainer_schedule_id}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Trainer Attendance <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select"
                      autoComplete="off"
                      name="trainer_status"
                      value={values.trainer_status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {/* <option value="">Select Trainer Attendance</option> */}
                      <option value="Delivered">Delivered</option>
                      <option value="Cancel">Cancel</option>
                      <option value="No Show">No Show</option>
                    </select>

                    {errors.trainer_status && touched.trainer_status ? (
                      <p className="text-danger">{errors.trainer_status}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Client Attendance <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select"
                      autoComplete="off"
                      name="client_status"
                      value={values.client_status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Client Attendance</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Cancel">Cancel</option>
                      <option value="No Show">No Show</option>
                    </select>

                    {errors.client_status && touched.client_status ? (
                      <p className="text-danger">{errors.client_status}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="15-02-2022"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={dateDisable}
                    />

                    {errors.date && touched.date ? (
                      <p className="text-danger">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Active Session Attendance</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-1 col-lg-1 mb-2 mb-md-0">
                  <label className="form-label text-secondary">Limit :</label>
                  <select
                    className="form-select"
                    value={limit}
                    onChange={(e) => {
                      if (role === "9") {
                        changeAttendanceType(UId, currentPage, e.target.value);
                      } else {
                        getAttend(currentPage, e.target.value);
                      }
                    }}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
                {role !== "9" ? (
                  <div className="col-md-3 ">
                    <label className="text-secondary form-label">
                      Filter By Trainer :
                    </label>
                    {name ? (
                      <select
                        className="form-select"
                        onChange={(e) => {
                          changeAttendanceType(
                            e.target.value,
                            currentPage,
                            limit
                          );
                          packagename(e.target.value);
                        }}
                      >
                        <option value="-1">Select Trainer</option>
                        {name.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                ) : null}
                <div className="col-md-3 my-2 my-md-0">
                  <label className="text-secondary form-label">
                    Filter By Package :
                  </label>
                  {myPackage.length ? (
                    <select
                      className="form-select"
                      onChange={(e) => {
                        changePackageName(e.target.value);
                      }}
                    >
                      {myPackage.map((el, index) => {
                        return (
                          <option key={index} value={el.order_id}>
                            {el.package_name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select className="form-select">
                      <option value="-1">Select Package</option>
                    </select>
                  )}
                </div>
                <div className=" col-md-2 ms-auto text-end pt-md-4 mb-3">
                  <button
                    className="btn btn-primary  file-download"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
          {role !== "9" ? (
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Inactive Session Attendance</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-lg-6 ms-3"></div>
                  {/* {totalResults > limit ? ( */}
                  <div className="col-md-1 col-lg-1 mb-2 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={inactiveLimit}
                        onChange={(e) => getAttendence("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <InactiveTable
                      data={inactiveData}
                      rowsPerPage={inactiveLimit}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default PTSessionAttendance;
