import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import "./StaffCard.css";

const PrintVisitorCard = () => {
  return (
    <div className="wrapper">
    <Navbar />
    <Sidebar />
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">
              Print Card
              {/* <Link
                to="/viewtrainer"
                className="btn btn-danger d-inline-block float-end btn-sm"
              >
                <i className="bi bi-x" />
                Done
              </Link> */}
              <button  className="btn btn-danger d-inline-block float-end btn-sm" onClick={() => window.print()}>Print Card</button>
            </h5>
          </div>
          <div className="card-body">
            <div className="container">
              <div
                id="card"
                style={{
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                  width: "490px",
                  height: "309px",
                  margin: "0 auto",
                  marginTop: "110px",
                }}
              >
                <div
                  style={{
                    width: "490px",
                    height: "309px",
                    backgroundImage: "url(/assets/images/card.jpg)",
                    margin: "0 auto",
                    marginTop: "110px",
                    display: "block",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    WebkitPrintColorAdjust: "exact",
                    WebkitFilter: "opacity(1)",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "200px",
                      backgroundColor: "#E8002B",
                      position: "absolute",
                      top: "18%",
                      borderTopRightRadius: "30px",
                      borderBottomRightRadius: "30px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "200px",
                      height: "30px",
                      backgroundColor: "black",
                      position: "absolute",
                      right: "0",
                      bottom: "0",
                      borderTopLeftRadius: "30px",
                    }}
                  ></div>
                  <div
                    style={{
                      left: "8%",
                      top: "23%",
                      position: "absolute",
                    }}
                  >
                    <img
                      src="/assets/images/logo.png"
                      alt="logo"
                      style={{ width: "75%" }}
                    ></img>
                  </div>
                  <div
                    style={{
                      left: "9%",
                      top: "44%",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      Member ID:
                    </div>
                  </div>
                  <div
                    style={{
                      left: "31%",
                      top: "44%",
                      position: "absolute",
                      color: "black",
                    }}
                  >
                    <p>N/A</p>
                  </div>
                  <div
                    style={{
                      left: "9%",
                      top: "55%",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                     Membership Type:
                    </div>
                    <div
                      style={{
                        left: "110%",
                        top: "0",
                        position: "absolute",
                        color: "black",
                      }}
                    >
                        <p>Visitor</p>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      left: "9%",
                      top: "66%",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      Joining Date:
                    </div>
                  </div> */}
                  {/* <div
                    style={{
                      left: "31%",
                      top: "66%",
                      position: "absolute",
                      color: "black",
                    }}
                  >
                    <p>{date}</p>
                  </div> */}
                  <div
                    style={{
                      width: "173px",
                      height: "173px",
                      backgroundColor: "#E8002B",
                      position: "absolute",
                      borderRadius: "50%",
                      top: "8%",
                      left: "61%",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "170px",
                      height: "170px",
                      position: "absolute",
                      borderRadius: "50%",
                      top: "10%",
                      left: "61%",
                      overflow: "hidden",
                      backgroundImage: "url('/assets/images/placeholder2.png')",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {/* <img
                      alt="img"
                      style={{
                        width: "170px",
                        height: "170px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        backgroundSize: "cover",
                        backgroundPosition: " center center",
                      }}
                    ></img> */}
                  </div>
                  <div
                    style={{
                      top: " 72%",
                      left: "71%",
                      position: "absolute",
                      color: "#E8002B",
                      fontWeight: "500",
                      fontSize: "22px",
                    }}
                  >
                   Visitor
                  </div>
                </div>
              </div>
            </div>
            {/* <button  className="btn btn-danger d-inline-block btn-sm" onClick={() => window.print()}>Print Card</button> */}
          </div>
        </div>
      </div>
    </main>
  </div>
  )
}

export default PrintVisitorCard
