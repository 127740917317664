import React, { useContext, useEffect, useState, useRef } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import useTable from "../../hooks/useTable";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useFormik } from "formik";
import { viewFines } from "../schemas";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import $ from "jquery";
import { toast } from "react-toastify";
import { manageExpens } from "../schemas";

const ViewExpenses = () => {
  var CurrencyFormat = require("react-currency-format");
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(defaultValue);
  const [catgries, setCatgries] = useState([]);
  const [subCatgries, setSubCatgries] = useState([]);
  const [exportLink, setExportLink] = useState("");
  const [yearExpense, setYearExpense] = useState([]);
  const [todayExpense, setTodayExpense] = useState([]);
  const [monthExpense, setMonthExpense] = useState([]);
  const month = date.getMonth();
  const monthString = (parseInt(month) + 1).toString();
  let sDate = moment().startOf("month").format("YYYY-MM-DD");
// update
const [myBranches, setMyBranches] = useState([]);
const [myPayment, setMyPayment] = useState([]);
const [sbCatgries, setSbCatgries] = useState([]);
const [branches, setBranches] = useState("");
const [paymnt, setPaymnt] = useState("");
const [occranceDate, setOccranceDate] = useState("");
const [amounts, setAmounts] = useState("");
const [discriptions, setDiscriptions] = useState("");
const [myCheque, setMyCheque] = useState("");
const [category, setCategory] = useState("");
const [categoryName, setCategoryName] = useState("");
const [selectedCategory, setSelectedCategory] = useState("");
const [subCategory, setSubCategory] = useState("");
const [transection, setTransection] = useState("");
const [bankAccount, setBankAccount] = useState("");
const [recordId, setRecordId] = useState("");
const [bank, setBank] = useState([]);
const [isLiability, setIsLiability] = useState("0");
const [isLiabilityShow, setIsLiabilityShow] = useState(false);
const handleCheckboxChange = (event) => {
  setIsLiability(event.target.checked ? "1" : "0");
};
// const payment = async (e) => {
//   var br_Id = e;

//   try {
//     const responce = await UserServices.paymentType(br_Id);
//     const res = responce.data.data;
//     const results = [];
//     res.map((value) => {
//       results.push({
//         id: value.id,
//         name: value.name,
//       });
//     });
//     setMyPayment([{ name: "Select Payment", id: "" }, ...results]);
//   } catch (err) {
//     console.log(err);
//     setMyPayment([]);
//   }
// };
const payment = async (e) => {
  var br_Id = e;

  try {
    const responce = await UserServices.paymentType(br_Id);
    const res = responce.data.data;
    const results = res.filter((value) => value.id !== 81 && value.id !== 82); // Filter out payment method with id 81
    const paymentOptions = [{ name: "Select Payment", id: "" }, ...results];
    setMyPayment(paymentOptions);
  } catch (err) {
    console.log(err);
    setMyPayment([]);
  }
};

var clickEvent = $.Event("click");

const closeButtonRef = useRef(null);
const getExpenc = async (id) => {
  setLoading(true);
  try {
    const responce = await UserServices.getExpencesInfo(id);
    if (responce.status === 200) {
      let data = responce.data.data.data;
      data.map((values) => {
        setBranches(values.branch_id);
        setOccranceDate(values.occurrence_date);
        setPaymnt(values.payment_type_id);
        setAmounts(values.amount);
        setMyCheque(values.cheque_number);
        setDiscriptions(values.description);
        setCategory(values.category_id);
        setCategoryName(values.category_name);
        setSubCategory(values.sub_category_id);
        setTransection(values.transaction_type);
        setBankAccount(values.bank_account_id);
        setRecordId(values.id);
        setIsLiability(values.is_liability);
        setIsLiabilityShow(true);
        setSelectedCategory();
      });
      setTimeout(() => {
        payment(branches);
      }, 2000);
      $("#membership").trigger(clickEvent);
      setLoading(false);
    }
  } catch (err) {
    if (err.response.status === 404) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
};
const subCtgories = async (e) => {
  var sb_catgry = e;
  try {
    const responce = await UserServices.SubCategoriesDropdoenName(
      sb_catgry,
      branch_id
    );
    const res = responce.data.data;
    const results = [];
    res.map((value) => {
      results.push({
        id: value.id,
        name: value.name,
      });
      return null;
    });
    setSbCatgries([{ name: "Select Sub Category", id: "" }, ...results]);
  } catch (err) {
    setSbCatgries([{ name: "No record found", id: "" }]);
    console.log(err);
  }
};
const branchesList = async () => {
  try {
    const responce = await UserServices.branchName();
    const res = responce.data.data;
    const results = [];

    res.map((value) => {
      results.push({
        id: value.id,
        name: value.name,
      });
    });
    setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
  } catch (err) {
    console.log(err);
  }
};

const bankDetails = async () => {
  try {
    const responce = await UserServices.bankList(branch_id);
    const res = responce.data.data;
    const results = [];
    res.map((value) => {
      results.push({
        id: value.id,
        bank_name: value.bank_name,
        account_no: value.account_no,
      });
      return null;
    });
    setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
  } catch (err) {
    console.log(err);
    setBank([{ bank_name: "No Bank Found", id: "" }]);
  }
};
useEffect(() => {
  categories();
  branchesList();
  bankDetails();

  if (category) {
    subCtgories(category);
  }
}, [category]);
  const getExpence = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getExpences(
        branch_id,
        newLimit,
        pageNo,
        values
      );
      let myDataarr;
      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/expense/export?branch_id=" +
            branch_id +
            "&start_date=" +
            values.startdate +
            "&end_date=" +
            values.enddate +
            "&category_id=" +
            values.category_id +
            "&sub_category_id=" +
            values.sub_category_id +
            "&status=1"
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            occurrence_date: values.occurrence_date,
            category_name: values.category_name,
            amount: values.amount,
            description: values.description,
            releated_things_name: values.releated_things_name,
            expenses_name: values.expenses_name,
            payment_type: values.payment_type,
            sub_category_name: values.sub_category_name,
            transaction_type: values.transaction_type,
            bank_account_number: values.bank_account_number,
          });
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getSubCategories = async (e) => {
    var sb_catgry = e.target.value;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSubCatgries([{ name: "Select Sub Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(branch_id, "Expense");
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const yearExpenseCounter = async () => {
    try {
      const responce = await UserServices.getCurrentYearExpences(branch_id);
      if (responce.status === 200) {
        setYearExpense(responce.data.amount);
      }
    } catch (err) {}
  };
  const todayExpenseCounter = async () => {
    try {
      const responce = await UserServices.getCurrentTodayExpences(branch_id);
      if (responce.status === 200) {
        setTodayExpense(responce.data.amount);
      }
    } catch (err) {}
  };
  const monthExpenseCounter = async () => {
    try {
      const responce = await UserServices.getCurrentMonthExpences(branch_id);
      if (responce.status === 200) {
        setMonthExpense(responce.data.amount);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getExpence(currentPage, limit);
    categories();
    yearExpenseCounter();
    todayExpenseCounter();
    monthExpenseCounter();
  }, []);
  const deleteRecord = async (Expnse_Id) => {
    try {
      const responce = await UserServices.deleteExpenses(Expnse_Id);
      if (responce.status === 204) {
        Swal.fire({
          title: "Deleted !",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getExpence(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delExpense = (Expnse_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(Expnse_Id);
      }
    });
  };
  const findFine = async (values) => {
    setLoading(true);
    try {
      const response = await UserServices.getExpenseDataByYear(
        "1",
        "9999999999999999999999",
        // 1,
        branch_id,
        values
      );
      let myDataarr;
      if (response.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/expense/export?branch_id=" +
            branch_id +
            "&start_date=" +
            values.startdate +
            "&end_date=" +
            values.enddate +
            "&category_id=" +
            values.category_id +
            "&sub_category_id=" +
            values.sub_category_id +
            "&status=1"
        );
        myDataarr = response.data.data.data;
        const res = myDataarr;
        const results = [];
        res.map((values) => {
          results.push({
            id: values.id,
            occurrence_date: values.occurrence_date,
            category_name: values.category_name,
            amount: values.amount,
            description: values.description,
            releated_things_name: values.releated_things_name,
            expenses_name: values.expenses_name,
            payment_type: values.payment_type,
            sub_category_name: values.sub_category_name,
            transaction_type: values.transaction_type,
            bank_account_number: values.bank_account_number,
          });
        });
        setMyData([...results]);
        setTotalResults(response.data.totalRecord);
        setLimit(response.data.totalRecord);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const initialValues = {
    startdate: sDate,
    enddate: startDate,
    category_id: "",
    sub_category_id: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: viewFines,
      onSubmit: (values) => {
        findFine(values);
      },
    });

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("expenses.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Expenses"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("transaction_type")}
                className={getClassNamesFor("transaction_type")}
              >
                Transaction Type
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("sub_category_name")}
                className={getClassNamesFor("sub_category_name")}
              >
                Sub-Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("payment_type_id")}
                className={getClassNamesFor("payment_type_id")}
              >
                Payment Method
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>

              <th
                scope="col"
                onClick={() => requestSort("amount")}
                className={getClassNamesFor("amount")}
              >
                Amount
              </th>

              <th
                scope="col"
                onClick={() => requestSort("occurrence_date")}
                className={getClassNamesFor("occurrence_date")}
              >
                Date
              </th>

              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    {el.transaction_type}
                    {el.bank_account_number === null ||
                    el.bank_account_number === "" ? null : (
                      <>
                        <i
                          className="bi bi-info-square note-here"
                          data-tooltip-id={el.bank_account_number}
                          data-tooltip-content={el.bank_account_number}
                        ></i>
                        {el.bank_account_number && (
                          <Tooltip
                            id={el.bank_account_number}
                            place="right"
                            className="note-tooltip"
                            clickable
                          />
                        )}
                      </>
                    )}
                  </td>
                  <td>{el.category_name}</td>
                  <td>{el.sub_category_name}</td>
                  <td>{el.payment_type}</td>
                  <td>{el.description}</td>
                  <td>
                    <CurrencyFormat
                      value={el.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td>{moment(el.occurrence_date).format("DD-MM-YYYY")}</td>
                  <td>
                  <button
                      onClick={() => {
                        getExpenc(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delExpense(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getExpence(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const UpdateExpnc = async (updateValues) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateExp(updateValues, recordId, isLiability);
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setLoading(false);
        getExpence(currentPage, limit);
          // Trigger the close button click event
          closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    branch_id: branches,
    occurrence_date: occranceDate,
    amount: amounts,
    cheque_number: myCheque,
    description: discriptions,
    payment_type_id: paymnt,
    category_id: category,
    sub_category_id: subCategory,
    transaction_type: transection,
    bank_account_id: bankAccount,
  };

  //.........................................................................
    const {
      values: updateValues,
      errors: updateErrors,
      touched: updateTouched,
      handleBlur: updateHandleBlur,
      handleChange: updateHandleChange,
      handleSubmit: updateHandleSubmit,
    } = useFormik({
      enableReinitialize: true,
      initialValues: updateinitialValues,
      validationSchema: manageExpens,
      onSubmit: (updateValues, action) => {
        UpdateExpnc(updateValues, action);
      },
    });
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  View Expense
                  <Link
                    to={-1}
                    className="btn btn-primary d-inline-block float-end btn-sm ms-2"
                  >
                    Go Back
                  </Link>
                  <Link
                    to="/add-expenses"
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    Add Expense
                  </Link>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="expenses-num">
                      Today's Expense :
                      <CurrencyFormat
                        value={String(todayExpense)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="expenses-num">
                      Monthly Expense :
                      <CurrencyFormat
                        value={String(monthExpense)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="expenses-num">
                      Total Expense :
                      <CurrencyFormat
                        value={String(yearExpense)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </div>
                  </div>
                </div>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionClient"
                >
                  <div className="row d-flex">
                    <div className="col-md-9">
                      <form className="row" onSubmit={handleSubmit}>
                        <div className="col-md-2 mt-3 ms-3">
                          <label className="form-label">
                            Filter By Category
                            <span className="text-danger">*</span>
                          </label>
                          {catgries ? (
                            <select
                              className="form-select"
                              name="category_id"
                              value={values.category_id || ""}
                              onChange={(e) => {
                                handleChange(e);
                                getSubCategories(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {catgries.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : null}
                          {errors.category_id && touched.category_id ? (
                            <p className="text-danger">{errors.category_id}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3 mt-3 ms-3">
                          <label className="form-label">
                            Filter By Subcategory
                            <span className="text-danger">*</span>
                          </label>
                          {subCatgries ? (
                            <select
                              className="form-select"
                              name="sub_category_id"
                              value={values.sub_category_id || ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {subCatgries.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : null}
                          {errors.sub_category_id && touched.sub_category_id ? (
                            <p className="text-danger">
                              {errors.sub_category_id}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-3 mt-3 ms-2">
                          <label className="form-label">From</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="date"
                            name="startdate"
                            value={values.startdate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.startdate && touched.startdate ? (
                            <p className="text-danger">{errors.startdate}</p>
                          ) : null}
                        </div>
                        <div className="col-md-3 mt-3 ms-2">
                          <label className="form-label">To</label>
                          <div className="input-group">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="date"
                              name="enddate"
                              value={values.enddate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <button
                              type="submit"
                              className="btn btn-primary "
                              onChange={() => findFine(values)}
                            >
                              Generate
                            </button>
                          </div>

                          {errors.enddate && touched.enddate ? (
                            <p className="text-danger">{errors.enddate}</p>
                          ) : null}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-1 col-lg-1 mt-5">
                      <div className="input-group">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => getExpence("1", e.target.value)}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                    <div className=" col-md-2 text-end d-flex">
                      <button
                        className="btn btn-primary mx-2 file-download mt-5"
                        onClick={() => {
                          generatePdf(document.getElementById("report-POS"));
                        }}
                      >
                        <i className="bi bi-file-earmark-pdf"></i> PDF
                      </button>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        // target="_blank"
                        className="btn btn-primary mx-2 file-download mt-5"
                      >
                        <i className="bi bi-download" /> Excel
                      </a>
                    </div>
                  </div>
                  <div className="accordion-body">
                    <div className="table-responsive mt-3">
                      <Table data={myData} rowsPerPage={limit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="ms-auto d-flex">
                <div className="mt-1">
                  <h5>Total Expense :</h5>
                </div>
                <div className="ms-2  fs-5">
                  <CurrencyFormat
                    value={myData.reduce((a, v) => (a = a + v.amount), 0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    thousandSpacing={"3"}
                  />
                </div>
              </div>
            </div>
          </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Expense
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
            <form className="row g-3" onSubmit={updateHandleSubmit}>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={updateValues.branch_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                            // changeCategory(e);
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.branch_id && updateTouched.branch_id ? (
                        <p className="text-danger">{updateErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Category<span className="text-danger">*</span>
                      </label>
                      {catgries ? (
                        <select
                          className="form-select"
                          name="category_id"
                          value={updateValues.category_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                            subCtgories(e.target.value);
                            // updateValues.sub_category_id ="";
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {catgries.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.category_id && updateTouched.category_id ? (
                        <p className="text-danger">{updateErrors.category_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Sub-Category<span className="text-danger">*</span>
                      </label>
                      {sbCatgries ? (
                        <select
                          className="form-select"
                          name="sub_category_id"
                          value={updateValues.sub_category_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {sbCatgries.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.sub_category_id && updateTouched.sub_category_id ? (
                        <p className="text-danger">{updateErrors.sub_category_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Transaction<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="transaction_type"
                        value={updateValues.transaction_type || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        <option value="-1">Select Transaction</option>
                        <option value="Bank Account">Bank Account</option>
                        <option value="Sales Counter">Sales Counter</option>
                        <option value="Office Counter">Office Counter</option>
                        <option value="Personal">Personal</option>
                        <option value="Mr Waqas Personal (Account)">
                          Mr Waqas Personal (Account)
                        </option>
                        <option value="Mr Waqas Credit Card">
                          Mr Waqas Credit Card
                        </option>
                        <option value="Mr Arif">Mr Arif</option>
                        <option value="Mr Bilal">Mr Bilal</option>
                      </select>
                      {updateErrors.transaction_type && updateTouched.transaction_type ? (
                        <p className="text-danger">{updateErrors.transaction_type}</p>
                      ) : null}
                    </div>
                  </div>
                  {updateValues.transaction_type === "Bank Account" ? (
                    <div className="col-12 col-md-6">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Bank Details<span className="text-danger">*</span>
                        </label>
                        {bank ? (
                          <select
                            className="form-select"
                            name="bank_account_id"
                            value={updateValues.bank_account_id || ""}
                            onChange={(e) => {
                              updateHandleChange(e);
                            }}
                            onBlur={updateHandleBlur}
                          >
                            {bank.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.bank_name} {el.account_no}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                        {updateErrors.bank_account_id && updateTouched.bank_account_id ? (
                          <p className="text-danger">
                            {updateErrors.bank_account_id}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Amount"
                        name="amount"
                        value={updateValues.amount}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.amount && updateTouched.amount ? (
                        <p className="text-danger">{updateErrors.amount}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Payment Type<span className="text-danger">*</span>
                      </label>
                      {myPayment.length ? (
                        <select
                          className="form-select"
                          name="payment_type_id"
                          value={updateValues.payment_type_id}
                          onChange={updateHandleChange}
                          onBlur={updateHandleBlur}
                        >
                          {myPayment.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          autoComplete="off"
                          name="payment_type_id"
                          value={updateValues.payment_type_id}
                          onChange={updateHandleChange}
                          onBlur={updateHandleBlur}
                        >
                          <option value="">Select Payment</option>
                        </select>
                      )}
                      {updateErrors.payment_type_id && updateTouched.payment_type_id ? (
                        <p className="text-danger">{updateErrors.payment_type_id}</p>
                      ) : null}
                    </div>
                  </div>
                  {updateValues.payment_type_id === "32" ||
                  updateValues.payment_type_id === 32 ? (
                    <>
                      <div className="col-12 col-md-6">
                        <div className="form-group custom-group">
                          <label className="form-label">Cheque number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount"
                            name="cheque_number"
                            value={updateValues.cheque_number}
                            onChange={updateHandleChange}
                            onBlur={updateHandleBlur}
                          />
                          {updateErrors.cheque_number && updateTouched.cheque_number ? (
                            <p className="text-danger">
                              {updateErrors.cheque_number}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="11-03-2022"
                        name="occurrence_date"
                        value={updateValues.occurrence_date}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.occurrence_date && updateTouched.occurrence_date ? (
                        <p className="text-danger">{updateErrors.occurrence_date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Detail/Source"
                        name="description"
                        value={updateValues.description}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.description && updateTouched.description ? (
                        <p className="text-danger">{updateErrors.description}</p>
                      ) : null}
                    </div>
                  </div>
                  {isLiabilityShow ? (
                    <div className="col-12 col-md-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={isLiability === "1" ? true : false}
                          onChange={handleCheckboxChange}
                          style={{ width: "17px", height: "17px" }}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor="flexCheckDefault"
                        >
                          Add to liability
                        </label>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Update
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ViewExpenses;
