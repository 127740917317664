import React, { useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import { updtRol } from "../schemas";
import {useNavigate, useParams } from "react-router";
import UserServices from "../../UserServices/UserServices";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Swal from "sweetalert2/dist/sweetalert2.js";

const UpdateRole = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedRoll, setSelectedRoll] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getUsers = async (id) => {
      setLoading(true);
      try {
        const responce = await UserServices.getRoleInfo(id);
        if (responce.status === 200) {
          let data = responce.data.data.data;
          data.map((values) => {
            setSelectedName(values.name);
            setSelectedEmail(values.email);
            setSelectedRoll(values.roll);
            setSelectedStatus(values.role_status);
            setPassword(values.password);
            return null;
          });

          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getUsers(id);
  }, []);

  const updateRoll = async (values) => {

    setLoading(true);

    try {
      const response = await UserServices.UpdateUserRoll(values, id);
      console.log(response);
      if (response.status === 204) {
        setLoading(false);
        Swal.fire({
          title: "Updated !",
          text: "Record updated successfully.",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.reload(false);
            navigate("/user-roles");
          }
        });
        
        // navigate("/manage-branches");
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    name: selectedName,
    email: selectedEmail,
    role: selectedRoll,
    role_status:selectedStatus,
    password: password,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: updtRol,
      onSubmit: (values, action) => {
         updateRoll(values, action);
      },
    });

  return (
    <div className="wrapper">
      <ToastContainer />
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Update Roles</h5>
            </div>
            <form className="" onSubmit={handleSubmit}>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <div className="row g-3">
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        User Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        autoComplete="off"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        autoComplete="off"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p className="text-danger">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Roll <span className="text-danger">*</span>
                      </label>
                      <select
                          className="form-select"
                          name="role"
                          value={values.role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="-1">Select Role</option>
                          <option value="1">Admin</option>
                          <option value="2">Fitness Manager</option>
                          <option value="3">Sales Representative</option>
                        </select>
                      {errors.role && touched.role ? (
                        <p className="text-danger">{errors.role}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                        name="password"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                        />
                        <span
                          className="input-group-text"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </div>
                      {errors.password && touched.password ? (
                        <p className="text-danger">{errors.password}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                         Roll Status <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="role_status"
                          value={values.role_status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                        {errors.role_status && touched.role_status ? (
                          <p className="text-danger">{errors.role_status}</p>
                        ) : null}
                      </div>
                    </div>
                  <div className="col-4">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0  mt-4"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default UpdateRole;
