import { useFormik } from "formik";
import moment from "moment";

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { groupPkgSell } from "../schemas";

export default function SellSmallPtGroup(props) {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [showGroup, setShowGroup] = useState(false);

  const [paySplitError, setPaySplitError] = useState("");

  const [timeSlot, settimeSlot] = useState([]);
  const [ptPkgs, setptPkgs] = useState([]);

  const [discountError, setDiscountError] = useState("");

  const [payAfterError, setPayAfterError] = useState("");
  const [clientName, setClientName] = useState("");
  const { id } = useParams();

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  const edDate = new Date();
  const futureEDate = edDate.getDate() + 30;
  edDate.setDate(futureEDate);
  const defaultEValue = edDate.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  var dateESelected = moment(defaultEValue).format("YYYY-MM-DD");
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(dateSelected);

  const [endDate, setEndDate] = useState(dateESelected);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const nameClients = async () => {
      try {
        const responce = await UserServices.getClientsNameById(branch_id, id);

        setClientName(
          responce.data.data[0].first_name +
            " " +
            responce.data.data[0].last_name
        );
      } catch (err) {
        console.log(err);
        setClientName("");
      }
    };

    const activeGymPkg = async () => {
      try {
        const responce = await UserServices.getClientActiveGymPkg(id);
        console.log(responce);
        if (responce.status === 200) {
          setShowGroup(true);
          nameClients();
        }
      } catch (err) {
        setShowGroup(false);

        console.log(err);
      }
    };

    activeGymPkg();
  }, []);

  const verifyDiscount = async (e) => {
    var discount_price = e.target.value;
    if (discount_price !== "") {
      if (
        values.discount_type === "Percentage" &&
        values.discount_type !== "" &&
        values.total_fee !== ""
      ) {
        if (discount_price > 100) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or less than 100%"
          );
        } else if (discount_price < 0) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or geater than 0%"
          );
        } else {
          values.pay_after_dis =
            values.total_fee - (values.total_fee / 100) * discount_price;
          setDiscountError("");
        }
      } else if (values.total_fee !== "") {
        if (discount_price > values.total_fee) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or less than Price:" +
              values.total_fee
          );
        } else if (discount_price < 0) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or geater than 0"
          );
        } else {
          values.pay_after_dis = values.total_fee - discount_price;
          setDiscountError("");
        }
      } else if (discount_price < 0) {
        values.pay_after_dis = "0";
        setDiscountError("Maximum discount must be equal to or geater than 0");
      } else {
        setDiscountError("");
      }
    } else if (discount_price < 0) {
      values.pay_after_dis = "0";
      setDiscountError("Maximum discount must be equal to or geater than 0");
    } else {
      values.pay_after_dis = "0";
      setDiscountError("");
    }
  };

  const getPtPkgList = async (e) => {
    var pt_group = e.target.value;
    settimeSlot([]);
    setFieldValue("time_slot", "");
    setFieldValue("total_fee", "");
    setFieldValue("ptPackages", "");

    try {
      const responce = await UserServices.ptGroupPkgs(branch_id, "4", pt_group);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setptPkgs([{ name: "Select Package", id: "" }, ...results]);
    } catch (err) {
      setptPkgs([{ name: "No Package Found", id: "" }]);

      console.log(err);
    }
  };

  const getTimeSlot = async (e) => {
    var pt_pkg = e.target.value;

    try {
      const responce = await UserServices.timeSlot(branch_id, pt_pkg);
      const res = responce.data.data;

      setFieldValue("total_fee", res[0]?.price);
      setFieldValue("time_slot", res[0]?.id);
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.start_time + " TO " + value.end_time,
        });
        return null;
      });
      settimeSlot([...results]);
    } catch (err) {
      settimeSlot([{ name: "No Time Slot Found", id: "" }]);
      setFieldValue("time_slot", "");
      setFieldValue("total_fee", "");

      console.log(err);
    }
  };

  const changeStartDate = async (e) => {
    var date = e.target.value;

    const edDate = new Date(date);
    const futureEDate = edDate.getDate() + 30;
    edDate.setDate(futureEDate);
    const defaultEValue = edDate.toLocaleDateString("en-CA");
    setEndDate(defaultEValue);
  };

  const sellSmallGroupPt = async (values) => {
    if (paySplitError === "" && payAfterError === "" && discountError === "") {
      setLoading(true);
      try {
        const responce = await UserServices.smallGroupSell(
          branch_id,
          id,
          endDate,
          "4",
          values
        );
        console.log(responce);
        toast.success("Package Added!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setTimeout(() => {
          props.cart();
          props.catagry();
        }, 1500);
      } catch (err) {
        toast.error("Unproccessable content!", { position: "top-center" });
        console.log(err);
        setLoading(false);
      }
    }
  };

  const initialValues = {
    client_name: clientName,
    Group: "",
    time_slot: "",
    ptPackages: "",

    discount_type: "Amount",
    discount_amount: "0",
    total_fee: "",
    pay_after_dis: "0",

    sdate: startDate,
    approveBy: "",
    referencedBy: "",
    salesnotes: "",
    salesdate: dateSelected,
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: groupPkgSell,
    onSubmit: (values, action) => {
      sellSmallGroupPt(values);
    },
  });
  return (
    <>
      {showGroup ? (
        <form className="" onSubmit={handleSubmit}>
          <>
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Sell Small Group Personal Training
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      New Clients <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={clientName}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Group <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select"
                      name="Group"
                      value={values.Group}
                      onChange={(e) => {
                        handleChange(e);
                        getPtPkgList(e);

                        values.discount_amount = "";
                        setDiscountError("");
                        setPaySplitError("");
                        setPayAfterError("");
                        values.pay_after_dis = "0";
                        values.payment_rec = "";
                        values.payment_pend = "";
                        values.payment_split = "";
                        values.payment_split_pend = "";
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="-1">Select Group</option>
                      <option value="MWF" title="Monday,Wednesday,Friday">
                        Group MWF
                      </option>
                      <option value="TTS" title="Tuesday,Thursday,Saturday">
                        Group TTS
                      </option>
                    </select>

                    {errors.Group && touched.Group ? (
                      <p className="text-danger">{errors.Group}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Select Packages <span className="text-danger">*</span>
                    </label>

                    {ptPkgs.length ? (
                      <select
                        className="form-select"
                        name="ptPackages"
                        value={values.ptPackages}
                        onChange={(e) => {
                          handleChange(e);
                          getTimeSlot(e);
                          values.discount_amount = "";
                          setDiscountError("");
                          setPaySplitError("");
                          setPayAfterError("");
                          values.pay_after_dis = "0";
                          values.payment_rec = "";
                          values.payment_pend = "";
                          values.payment_split = "";
                          values.payment_split_pend = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {ptPkgs.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="ptPackages"
                        value={values.ptPackages}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Please select a group to view packages</option>
                      </select>
                    )}

                    {errors.ptPackages && touched.ptPackages ? (
                      <p className="text-danger">{errors.ptPackages}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Select Time Slot <span className="text-danger">*</span>
                    </label>

                    {timeSlot.length ? (
                      <select
                        className="form-select"
                        name="time_slot"
                        value={values.time_slot}
                        onChange={(e) => {
                          handleChange(e);
                          values.discount_amount = "";
                          setDiscountError("");
                          setPaySplitError("");
                          setPayAfterError("");
                          values.pay_after_dis = "0";
                          values.payment_rec = "";
                          values.payment_pend = "";
                          values.payment_split = "";
                          values.payment_split_pend = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {timeSlot.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="time_slot"
                        value={values.time_slot}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>
                          Please select a group package to view available time
                        </option>
                      </select>
                    )}

                    {errors.time_slot && touched.time_slot ? (
                      <p className="text-danger">{errors.time_slot}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Start Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={(e) => {
                        handleChange(e);
                        changeStartDate(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      End Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter End date"
                      value={endDate}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Price <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="total_fee"
                      value={values.total_fee}
                      onChange={(e) => {
                        handleChange(e);

                        values.discount_amount = "";
                        setDiscountError("");
                        setPaySplitError("");
                        setPayAfterError("");
                        values.pay_after_dis = "0";
                        values.payment_rec = "";
                        values.payment_pend = "";
                        values.payment_split = "";
                        values.payment_split_pend = "";
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.total_fee && touched.total_fee ? (
                      <p className="text-danger">{errors.total_fee}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Discount Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="discount_type"
                      value={values.discount_type}
                      onChange={(e) => {
                        handleChange(e);
                        values.discount_amount = "";
                        setDiscountError("");
                        setPaySplitError("");
                        setPayAfterError("");
                        values.pay_after_dis = "0";
                        values.payment_rec = "";
                        values.payment_pend = "";
                        values.payment_split = "";
                        values.payment_split_pend = "";
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Discount Type</option>
                      <option value="Percentage">Percentage</option>
                      <option value="Amount">Amount</option>
                    </select>
                    {errors.discount_type && touched.discount_type ? (
                      <p className="text-danger">{errors.discount_type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Discount <span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      type="number"
                      name="discount_amount"
                      value={values.discount_amount}
                      onChange={(e) => {
                        handleChange(e);
                        verifyDiscount(e);
                        setPaySplitError("");
                        setPayAfterError("");
                        values.payment_rec = "";
                        values.payment_pend = "";
                        values.payment_split = "";
                        values.payment_split_pend = "";
                      }}
                      onBlur={handleBlur}
                    />

                    {discountError !== "" ? (
                      <p className="text-danger">{discountError}</p>
                    ) : null}

                    {errors.discount_amount && touched.discount_amount ? (
                      <p className="text-danger">{errors.discount_amount}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Payable Amount After Discount
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="pay_after_dis"
                      value={values.pay_after_dis}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      disabled={true}
                    />

                    {errors.pay_after_dis && touched.pay_after_dis ? (
                      <p className="text-danger">{errors.pay_after_dis}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Discount Approve By
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="approveBy"
                      value={values.approveBy}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.approveBy && touched.approveBy ? (
                      <p className="text-danger">{errors.approveBy}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Referenced By
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="referencedBy"
                      value={values.referencedBy}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.referencedBy && touched.referencedBy ? (
                      <p className="text-danger">{errors.referencedBy}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Sales Notes
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="salesnotes"
                      value={values.salesnotes}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.salesnotes && touched.salesnotes ? (
                      <p className="text-danger">{errors.salesnotes}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label htmlFor="form-label" className="form-label">
                      Sale Date
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="salesdate"
                      value={values.salesdate}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.salesdate && touched.salesdate ? (
                      <p className="text-danger">{errors.salesdate}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>

          <div className="col-12 text-center mb-3">
            <button
              type="submit"
              className="btn btn-primary px-5 mb-1 me-2 addclient-button"
            >
              Sell Small Pt Group Package
            </button>
          </div>
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </form>
      ) : (
        <>
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">Sell Small Group Personal Traning:</h5>
          </div>
          <div className="card-body">
            <div className="alert alert-danger m-0" role="alert">
              The client must have an <strong>active gym package</strong> to buy
              a<strong> small group PT.</strong>
            </div>
          </div>
        </>
      )}
    </>
  );
}
