/* eslint-disable no-unused-vars */
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

import UserServices from "../../../UserServices/UserServices";

import MenuButton from "../../MenuButton/MenuButton";

const DetailPlan = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const { id } = useParams();
  const [day, setDay] = useState("monday");
  const [tanningType, settanningType] = useState("115");
  const [exerciseTypes, setExerciseTypes] = useState([]);

  const getPlans = async (id, day, tanningType) => {
    setLoading(true);
    setDay(day);
    settanningType(tanningType);
    setExerciseTypes([]);
    try {
      const responce = await UserServices.getClientsFitnessPlansDetail(
        id,
        day,
        tanningType
      );

      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];
        await res.map((value) => {
          results.push({
            id: value.id,
            branch: value.branch,
            client: value.client,
            trainer: value.trainer,
            start_date: value.start_date,
            end_date: value.end_date,
            training_detail: value.training_detail,
          });
          return null;
        });

        setMyData([...results]);
        const distinctExerciseTypes = [];

        res.forEach((innerArray) => {
          innerArray.training_detail.forEach((value) => {
            if (!distinctExerciseTypes.includes(value.exercise_type)) {
              distinctExerciseTypes.push(value.exercise_type);
            }
          });
        });

        setExerciseTypes([...distinctExerciseTypes]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setMyData([]);
      setExerciseTypes([]);

      setLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPlans(id, day, tanningType);
  }, []);

  //..........................................................................................................

  const trainingData = [
    {
      type: "Weight Training",
      category: "Muscle",
      list: ["Chest", "Shoulder", "Biceps", "Hips", "Lower Back"],
    },
    {
      type: "Cardio Training",
      category: "Equipment",
      list: [],
    },
    {
      type: "Crossfit",
      category: "Modality",
      list: [],
    },
  ];
  const sampleData = [
    {
      muscles: "Chest",
      exercise: ["Flat Bench Press", "Decline Bench Press", "Push Ups"],
      sets: 3,
      reps: "7-11",
    },
    {
      muscles: "Shoulder",
      exercise: ["Milleray Press", "Dumble front Press"],
      sets: 5,
      reps: 2,
    },
    {
      muscles: "Biceps",
      exercise: ["Barbell Wide Curl", "Cable Curl"],
      sets: 5,
      reps: 3,
    },
    {
      muscles: "Hips",
      exercise: ["Lying Hip Thursters"],
      sets: 5,
      reps: "5-8",
    },
    {
      muscles: "Lower Back",
      exercise: ["Hyper-Exersion", "Superman"],
      sets: 4,
      reps: "10-12",
    },
  ];

  return (
    <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div id="training">
              <section className="card">
                <div className="card-header py-3 d-flex ">
                  <h5 className="mb-0 font-20">Training Log Pattern</h5>
                  {myData.length ? (
                    <div className="d-flex ms-auto align-items-center">
                      <h6 className="mb-0 ">
                        <strong>Branch:</strong>{" "}
                        <span>{myData[0]?.branch}</span>
                      </h6>
                    </div>
                  ) : null}
                </div>
                <div className="card-body px-md-5">
                  <div className="mybtnGroup mb-4 pb-1 mt-2">
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "monday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "monday") {
                          getPlans(id, "monday", tanningType);
                        }
                      }}
                    >
                      Monday
                    </button>
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "tuesday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "tuesday") {
                          getPlans(id, "tuesday", tanningType);
                        }
                      }}
                    >
                      Tuesday
                    </button>
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "wednesday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "wednesday") {
                          getPlans(id, "wednesday", tanningType);
                        }
                      }}
                    >
                      Wednesday
                    </button>
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "thursday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "thursday") {
                          getPlans(id, "thursday", tanningType);
                        }
                      }}
                    >
                      Thursday
                    </button>
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "friday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "friday") {
                          getPlans(id, "friday", tanningType);
                        }
                      }}
                    >
                      Friday
                    </button>
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "saturday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "saturday") {
                          getPlans(id, "saturday", tanningType);
                        }
                      }}
                    >
                      Saturday
                    </button>
                    <button
                      type="button"
                      className={`btn  me-1    ${
                        day === "sunday" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (day !== "sunday") {
                          getPlans(id, "sunday", tanningType);
                        }
                      }}
                    >
                      Sunday
                    </button>
                  </div>
                  {myData.length ? (
                    myData?.map((el, index) => (
                      <div key={index}>
                        <div className="row" id="cards">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div
                              className={`training_card ${
                                tanningType === "115" ? "active" : ""
                              }`}
                              onClick={() => {
                                if (tanningType !== "115") {
                                  getPlans(id, day, "115");
                                }
                              }}
                            >
                              <div className="d-flex mb-2">
                                <h6 className="me-auto">Training</h6>
                                <h6 className="exercise_type">
                                  Weight Training
                                </h6>
                              </div>
                              <h6 className="black_clr">
                                Muscle
                                {/* {training.name === "Weight Training"
                                    ? "Muscle"
                                    : training.name === "Cardio"
                                    ? "Equipment"
                                    : training.name === "Crossfit"
                                    ? "Modality"
                                    : ""} */}
                              </h6>
                              {tanningType === "115" && (
                                <ul>
                                  {exerciseTypes?.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-dot"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                      </svg>
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div
                              className={`training_card ${
                                tanningType === "116" ? "active" : ""
                              }`}
                              onClick={() => {
                                if (tanningType !== "116") {
                                  getPlans(id, day, "116");
                                }
                              }}
                            >
                              <div className="d-flex mb-2">
                                <h6 className="me-auto">Training</h6>
                                <h6 className="exercise_type">Cardio</h6>
                              </div>
                              <h6 className="black_clr">Equipment</h6>
                              {tanningType === "116" && (
                                <ul>
                                  {exerciseTypes?.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-dot"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                      </svg>
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div
                              className={`training_card ${
                                tanningType === "117" ? "active" : ""
                              }`}
                              onClick={() => {
                                if (tanningType !== "117") {
                                  getPlans(id, day, "117");
                                }
                              }}
                            >
                              <div className="d-flex mb-2">
                                <h6 className="me-auto">Training</h6>
                                <h6 className="exercise_type">Crossfit</h6>
                              </div>
                              <h6 className="black_clr">
                                Modality
                                {/* {training.name === "Weight Training"
                                    ? "Muscle"
                                    : training.name === "Cardio"
                                    ? "Equipment"
                                    : training.name === "Crossfit"
                                    ? "Modality"
                                    : ""} */}
                              </h6>
                              {tanningType === "117" && (
                                <ul>
                                  {exerciseTypes?.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-dot"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                      </svg>
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mt-4">
                            <div className="table_header align-items-center">
                              <div className="d-flex">
                                <h6 className="me-auto">
                                  Name : <span>{el?.client}</span>
                                </h6>
                                <div className="d-flex ms-auto align-items-center">
                                  <h6 className="me-5 mb-0 ">
                                    Start Date:{" "}
                                    <span>
                                      {moment(el.start_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </span>
                                  </h6>
                                  <h6 className="mb-0 ">
                                    End Date:{" "}
                                    <span>
                                      {moment(el.end_date).format("DD-MM-YYYY")}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <table className="mb-5">
                              <thead>
                                <tr>
                                  <th scope="col">Muscles</th>
                                  <th scope="col">Exercise</th>
                                  {tanningType === "115" ? (
                                    <>
                                      <th scope="col">Sets</th>
                                      <th scope="col">Reps</th>
                                    </>
                                  ) : tanningType === "116" ? (
                                    <>
                                      <th scope="col">Duration</th>
                                      <th scope="col">Intensity</th>
                                      <th scope="col">Work Interval</th>
                                      <th scope="col">Rest Interval</th>
                                    </>
                                  ) : tanningType === "117" ? null : null}

                                  <th scope="col">Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {el?.training_detail.length ? (
                                  el?.training_detail?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.exercise_type}</td>
                                      <td>
                                        <ul>
                                          <li>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-dot"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                            </svg>
                                            {item.exercise_name}
                                          </li>
                                        </ul>
                                        {/* <ul>
                                      {item.exercise.map(
                                        (exercise, exerciseIndex) => (
                                          <li key={exerciseIndex}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-dot"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                            </svg>
                                            {exercise}
                                          </li>
                                        )
                                      )}
                                    </ul> */}
                                      </td>
                                      {tanningType === "115" ? (
                                        <>
                                          <td>{item.sets}</td>
                                          <td>{item.reps}</td>
                                        </>
                                      ) : tanningType === "116" ? (
                                        <>
                                          <td>{item.duration}</td>
                                          <td>{item.intensity}</td>
                                          <td>{item.work_interval}</td>
                                          <td>{item.rest_interval}</td>
                                        </>
                                      ) : tanningType === "117" ? null : null}

                                      <td>{item.description}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={
                                        tanningType === "115"
                                          ? "5"
                                          : tanningType === "116"
                                          ? "7"
                                          : tanningType === "117"
                                          ? "3"
                                          : "3"
                                      }
                                      align="center"
                                    >
                                      No Record Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5 className="font-20 text-danger">No Record Found</h5>
                  )}
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default DetailPlan;
