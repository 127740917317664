import React, { useEffect, useState } from "react";

import UserServices from "../../../UserServices/UserServices";

export default function Exercises({ data, dataIndex, onFormChange, catagrey }) {
  const subValue = data.subCatagroyId?.split(":");
  const [exercises, setExercises] = useState([]);
  const [exerciseDetails, setExerciseDetails] = useState([]);

  const getExerciseListing = async (e) => {
    var sb_catgry = e;
    if (sb_catgry !== "") {
      try {
        const responce = await UserServices.exercisesDropdoenName(sb_catgry);

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setExercises([...results]);
        const results2 = [];
        res.map((value) => {
          results2.push({
            index: value.id,
          });
          return null;
        });

        setExerciseDetails([...results2]);
      } catch (err) {
        setExercises([]);
        setExerciseDetails([]);
        console.log(err);
      }
    } else {
      setExercises([]);
      setExerciseDetails([]);
    }
  };

  useEffect(() => {
    getExerciseListing(subValue[0]);
  }, [data]);

  const handleFormChange = (formDataIndex, val, fieldName) => {
    const updatedFormDataw = [...exerciseDetails];

    updatedFormDataw[formDataIndex][fieldName] = val;
    if (fieldName === "id") {
      const name = exercises.filter((value) => value.id === parseInt(val));
      updatedFormDataw[formDataIndex]["name"] = name[0].name;
    }

    setExerciseDetails(updatedFormDataw);

    onFormChange(dataIndex, updatedFormDataw, "exercises");
  };

  return (
    <div className="col-md-6">
      <div className="card">
        <div className="card-header py-3">
          <h5 className="mb-0 font-20">{subValue[1]}</h5>
        </div>

        <div style={{ overflow: "auto" }}>
          <div className="adj-hight position-relative">
            <table className="table-bordered w-100 table-striped add-expense-table">
              <thead style={{ position: "sticky", top: "0", zIndex: "10" }}>
                <tr>
                  <th scope="col" className="no_sort" width="30%">
                    Exercise Name
                  </th>
                  {catagrey === 115 ? (
                    <>
                      <th scope="col" className="no_sort">
                        Sets
                      </th>
                      <th scope="col" className="no_sort">
                        Reps
                      </th>
                    </>
                  ) : catagrey === 116 ? (
                    <>
                      <th scope="col" className="no_sort">
                        Duration
                      </th>
                      <th scope="col" className="no_sort">
                        Intensity
                      </th>
                      <th scope="col" className="no_sort">
                        Work Interval
                      </th>
                      <th scope="col" className="no_sort">
                        Rest Interval
                      </th>
                    </>
                  ) : catagrey === 117 ? null : null}

                  <th scope="col" className="no_sort">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {exercises.length ? (
                  exercises.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {/* <input
                          type="text"
                          className="form-control"
                          value={el.name}
                          readOnly={true}
                        /> */}

                        {exercises ? (
                          <select
                            className="form-select"
                            onChange={(e) => {
                              handleFormChange(index, e.target.value, "id");
                            }}
                          >
                            <option value="">Select Exercise</option>
                            {exercises.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                      </td>
                      {catagrey === 115 ? (
                        <>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Sets"
                              defaultValue={data.sets ? data.sets : ""}
                              min="0"
                              onBlur={(e) => {
                                if (e.target.value !== "") {
                                  handleFormChange(
                                    index,
                                    e.target.value,
                                    "sets"
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Reps"
                              defaultValue={data.reps ? data.reps : ""}
                              min="0"
                              onBlur={(e) => {
                                if (e.target.value !== "") {
                                  handleFormChange(
                                    index,
                                    e.target.value,
                                    "reps"
                                  );
                                }
                              }}
                            />
                          </td>
                        </>
                      ) : catagrey === 116 ? (
                        <>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Duration"
                              defaultValue={data.duration ? data.duration : ""}
                              min="0"
                              onBlur={(e) => {
                                if (e.target.value !== "") {
                                  handleFormChange(
                                    index,
                                    e.target.value,
                                    "duration"
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>
                            <select
                              className="form-select"
                              defaultValue={
                                data.intensity ? data.intensity : ""
                              }
                              onBlur={(e) => {
                                if (e.target.value !== "") {
                                  handleFormChange(
                                    index,
                                    e.target.value,
                                    "intensity"
                                  );
                                }
                              }}
                            >
                              <option value="">Select Intensity</option>
                              <option value="55% - 65% of MHR">
                                55% - 65% of MHR
                              </option>
                              <option value="65% - 75% of MHR">
                                65% - 75% of MHR
                              </option>
                              <option value="75% - 85% of MHR">
                                75% - 85% of MHR
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Work Interval"
                              defaultValue={
                                data.work_interval ? data.work_interval : ""
                              }
                              min="0"
                              onBlur={(e) => {
                                if (e.target.value !== "") {
                                  handleFormChange(
                                    index,
                                    e.target.value,
                                    "work_interval"
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Rest Interval"
                              defaultValue={
                                data.rest_interval ? data.rest_interval : ""
                              }
                              min="0"
                              onBlur={(e) => {
                                if (e.target.value !== "") {
                                  handleFormChange(
                                    index,
                                    e.target.value,
                                    "rest_interval"
                                  );
                                }
                              }}
                            />
                          </td>
                        </>
                      ) : catagrey === 117 ? null : null}

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Description"
                          defaultValue={
                            data.description ? data.description : ""
                          }
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              handleFormChange(
                                index,
                                e.target.value,
                                "description"
                              );
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        catagrey === 115
                          ? "4"
                          : catagrey === 116
                          ? "6"
                          : catagrey === 117
                          ? "2"
                          : "2"
                      }
                      className="text-center text-capitalize"
                    >
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
