import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { clientReport } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";

export default function RecievePendings() {
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  useEffect(() => {
    const getPaymentMethodinitial = async () => {
      try {
        const responce = await UserServices.paymentNames();
        const res = responce.data.data;
        const results = res.filter(
          (value) => value.id !== 81 && value.id !== 82
        ); // Filter out payment method with id 81
        setPaymentMethodsSplit([{ name: "Select Payment Method", id: "" },...results]);
      } catch (err) {
        console.log(err);

        setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
      }
    };
    
    getPaymentMethodinitial(branch_id);
  }, []);

  const initialValues = {
    date: defaultValue,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        // generateReport(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Receive Pending Payments</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="11-03-2022"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.date && touched.date ? (
                      <p className="text-danger">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Payment Method</label>
                    <span className="text-danger">*</span>
                    {paymentMethodsSplit.length ? (
                      <select
                        className="form-select"
                        name="payment_type_id"
                        value={values.payment_type_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {paymentMethodsSplit.map((el, index) => {
                          return (
                            <option key={index} value={el.id}  disabled={el.id === ""}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="payment_type_id"
                        value={values.payment_type_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option>Select Payment Method</option>
                      </select>
                    )}
                    {errors.payment_type_id && touched.payment_type_id ? (
                      <p className="text-danger">{errors.payment_type_id}</p>
                    ) : null}
                  </div>
                </div>
                {values.payment_type_id === "32" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <div className="form-group custom-group">
                              <label
                                htmlFor="form-label"
                                className="form-label"
                              >
                                Cheque Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="cheque_number"
                                value={values.cheque_number}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />

                              {errors.cheque_number && touched.cheque_number ? (
                                <p className="text-danger">
                                  {errors.cheque_number}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Receivable Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.amount && touched.amount ? (
                      <p className="text-danger">{errors.amount}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 ">
                        <button
                          type="submit"
                          className="btn btn-primary px-5 rounded-0 mb-1"
                        >
                          Add
                        </button>
                      </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
