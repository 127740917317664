import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { payLoans } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";


export default function PayLoans() {
    const navigate = useNavigate();
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const [myBranches, setMyBranches] = useState([]);
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString("en-CA");
    // eslint-disable-next-line
    const [startDate, setStartDate] = useState(defaultValue);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [amount, setAmount] = useState("0");
    const [receivedAmount, setReceivedAmount] = useState("0");
    const [remainingAmount, setRemainingAmount] = useState("0");
    const [errorMessage, setErrorMessage] = useState("");

    const handleAmountChange = (e) => {
        const enteredAmount = e.target.value;
        if (enteredAmount > remainingAmount) {
          setErrorMessage("Entered amount exceeds the remaining amount.");
        } else {
          setErrorMessage("");
        }
      };

      const branches = async () => {
        try {
          const responce = await UserServices.branchName();
          const res = responce.data.data;
          const results = [];
    
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.name,
            });
            return null;
          });
          setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
        }
      };
      const totalAmount = async () => {
        try {
          const response = await UserServices.totalLoans(id);
          const res = response.data;
          console.log(response);
          if (res && typeof res === "object") {
            const {
                loan_amount,
                loan_paid,
                loan_pending,
            } = res;
            setAmount(loan_amount);
            setReceivedAmount(loan_paid);
            setRemainingAmount(loan_pending);
          } else {
            console.log("Invalid response format:", res);
          }
        } catch (err) {
          console.log(err);
        }
      };
      useEffect(() => {
        branches();
        totalAmount();
      }, []);
      const payLoan = async (values, action) => {
        if(errorMessage === ""){
          try {
            setLoading(true);
            const response = await UserServices.loanPay(values);
      
            if (response.status === 201) {
              setLoading(false);
              toast.success("Added Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              action.resetForm();
              setTimeout(() => {
                navigate("/staff-loans")
               }, 1500);
            }
          } catch (err) {
            setLoading(false);
            if (err.response.status === 422) {
            } else if (err.response.status === 500) {
              toast.error("Network Error!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 400) {
              toast.error("Unproccessable content!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          }
        }
      };
      const initialValues = {
        branch_id: branch_id,
        loan_id: id,
        amount: "",
        date: startDate,
      };
      const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
          initialValues: initialValues,
          validationSchema: payLoans,
          onSubmit: (values, action) => {
            payLoan(values, action);
          },
        });
  return (
    <div className="wrapper">
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">
              Pay Loan
              <Link
                to="/staff-loans"
                className="btn btn-primary d-inline-block float-end btn-sm"
              >
                View Loans
              </Link>
            </h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-lg-3 mb-3">
                <div className="expenses-num">
                  Total Amount :{" "}
                  <span>
                    <CurrencyFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"3"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <>{value}</>}
                    />
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 mb-3">
                <div className="expenses-num">
                  Received Amount :{" "}
                  <span>
                    <CurrencyFormat
                      value={receivedAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"3"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <>{value}</>}
                    />
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 mb-3">
                <div className="expenses-num">
                  Remaining Amount :{" "}
                  <span>
                    <CurrencyFormat
                      value={remainingAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"3"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <>{value}</>}
                    />
                  </span>
                </div>
              </div>
            </div>
            <p>
              <i
                className="bi bi-exclamation-lg text-danger"
                aria-hidden="true"
              />
              The Fields With <strong className="text-danger">*</strong>
              Must Required Or Fill.
            </p>
            <form
              className="row g-3"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={values.branch_id || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {errors.branch_id && touched.branch_id ? (
                    <p className="text-danger">{errors.branch_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Amount<span className="text-danger">*</span>
                  </label>
                  <input
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                    name="amount"
                    min={0}
                    value={values.amount}
                    onChange={(e) => {
                      handleChange(e);
                      handleAmountChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}
                  {errors.amount && touched.amount ? (
                    <p className="text-danger">{errors.amount}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="11-03-2022"
                    name="date"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.date && touched.date ? (
                    <p className="text-danger">{errors.date}</p>
                  ) : null}
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-12 ">
                <button
                  type="submit"
                  className="btn btn-primary px-5 rounded-0 mb-1"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  </div>
  )
}
