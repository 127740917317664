import React, { useEffect, useState, useContext } from "react";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import useSortableData from "../../hooks/useSortableData";
import MenuButton from "../MenuButton/MenuButton";
import moment from "moment";
import { toast } from "react-toastify";

export default function Approvals() {
  const { state } = useContext(NoteState);
  const { branch_id, role } = state;
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Pending");
  const [selectedType, setSelectedType] = useState("All");

  const getPendingRecord = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getApprovals(branch_id, "Pending");
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        // eslint-disable-next-line array-callback-return
        res.map((values) => {
          results.push({
            id: values.id,
            order_id: values.order_id,
            type: values.type,
            new_date: values.new_date,
            old_date: values.old_date,
            new_payment_method: values.new_payment_method,
            new_payment_method_name: values.new_payment_method_name,
            old_payment_method: values.old_payment_method,
            old_payment_method_name: values.old_payment_method_name,
            status: values.status,
            branches_name: values.branches_name,
            client_name: values.client_name,
            package_name: values.package_name,
          });
        });

        setMyData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPendingRecord();
  }, []);
  const makeInActive = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.rejectRecord(id);
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Successfully Denied!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPendingRecord();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveRecord = (id) => {
    makeInActive(id);
  };
  const makeActive = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.approveRecord(id);
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Successfully Approved!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPendingRecord();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeRecord = (id) => {
    makeActive(id);
  };
  const changeApprovalStatus = (e) => {
    setSelectedOption(e);
    let approvalStatus = e;
    const getApprovalTypeData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getApprovals(
          branch_id,
          approvalStatus
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              order_id: values.order_id,
              type: values.type,
              new_date: values.new_date,
              old_date: values.old_date,
              new_payment_method: values.new_payment_method,
              new_payment_method_name: values.new_payment_method_name,
              old_payment_method: values.old_payment_method,
              old_payment_method_name: values.old_payment_method_name,
              status: values.status,
              branches_name: values.branches_name,
              client_name: values.client_name,
              package_name: values.package_name,
            });
            return null;
          });

          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getApprovalTypeData();
  };
  const changeApprovalType = (e) => {
    setSelectedType(e);
    let approvalType = e;
    const getApprovalTypeData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getPkgApprovals(
          branch_id,
          approvalType
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              order_id: values.order_id,
              type: values.type,
              new_date: values.new_date,
              old_date: values.old_date,
              new_payment_method: values.new_payment_method,
              new_payment_method_name: values.new_payment_method_name,
              old_payment_method: values.old_payment_method,
              old_payment_method_name: values.old_payment_method_name,
              status: values.status,
              branches_name: values.branches_name,
              client_name: values.client_name,
              package_name: values.package_name,
            });
            return null;
          });

          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getApprovalTypeData();
  };
  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Active Membership Package"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("type")}
                className={getClassNamesFor("type")}
              >
                Request Type
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("order_id")}
                className={getClassNamesFor("order_id")}
              >
                Order Id
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("type")}
                className={getClassNamesFor("type")}
              >
                Switch From
              </th>
              <th
                scope="col"
                onClick={() => requestSort("type")}
                className={getClassNamesFor("type")}
              >
                Switch To
              </th>

              <th scope="col" className="no_sort">
                Status
              </th>
              {role === "3" ? (
                <th scope="col" className="no_sort">
                  Actions
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{1 ? index + 1 : -1 + index + 1}</td>
                  <td>{el.branches_name}</td>
                  <td>
                    {el.type === "Date" ? (
                      <span>{el.type}</span>
                    ) : el.type === "PaymentMethod" ? (
                      <span>{el.type.replace(/([a-z])([A-Z])/g, "$1 $2")}</span>
                    ) : el.type === "DeleteRecode" ? (
                      <span>{el.type.replace(/([a-z])([A-Z])/g, "$1 $2")}</span>
                    ) : (
                      el.type
                    )}
                  </td>
                  <td>{el.client_name ? el.client_name : "N/A"}</td>
                  <td>{el.order_id}</td>
                  <td>{el.package_name ? el.package_name : "N/A"}</td>
                  {el.type === "Date" ? (
                    <>
                      <td>{moment(el.old_date).format("DD-MM-YYYY")}</td>
                      <td>{moment(el.new_date).format("DD-MM-YYYY")}</td>
                    </>
                  ) : el.type === "PaymentMethod" ? (
                    <>
                      <td>{el.old_payment_method_name}</td>
                      <td>{el.new_payment_method_name}</td>
                    </>
                  ) : (
                    <>
                      {" "}
                      <td>N/A</td>
                      <td>N/A</td>
                    </>
                  )}
                  <td>
                    <span className="d-inline-flex" style={{ width: "70px" }}>
                      {el.status === "Pending" ? (
                        <span className="badge bg-warning text-dark w-100">
                          {el.status}
                        </span>
                      ) : el.status === "Denied" ? (
                        <span className="badge bg-danger w-100">
                          {el.status}
                        </span>
                      ) : (
                        <span className="badge bg-success w-100">
                          {el.status}
                        </span>
                      )}
                    </span>
                  </td>
                  {role === "3" ? (
                    <td>
                      {el.status === "Pending" ? (
                        <>
                          <button
                            onClick={() => {
                              activeRecord(el.id);
                            }}
                            className="updt-btn inactive-btn"
                          >
                            <i className="bi bi-check-circle text-success"></i>{" "}
                            <span className="text-success ms-1 updt-text">
                              Approve
                            </span>
                          </button>
                          <button
                            type="button"
                            onClick={() => inactiveRecord(el.id)}
                            className="ms-2 inactive-btn"
                          >
                            <i className="bi bi-x-circle text-danger"></i>{" "}
                            <span className="text-danger inative-text ms-1">
                              Reject
                            </span>
                          </button>
                        </>
                      ) : el.status === "Approved" ? (
                        <span className=" text-success fw-bold">
                          Request Approved
                        </span>
                      ) : (
                        <span className="text-danger fw-bold">
                          Request Denied
                        </span>
                      )}
                    </td>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Approvals</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2 mb-2">
                  <label className="form-label">Filter By Status</label>
                  <div className="input-group">
                    <select
                      className="form-select"
                      onChange={(e) => changeApprovalStatus(e.target.value)}
                      value={selectedOption}
                    >
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Denied">Denied</option>
                      <option value="Approved">Approved</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 mb-2">
                  <label className="form-label">Filter By Type</label>
                  <div className="input-group">
                    <select
                      className="form-select"
                      onChange={(e) => changeApprovalType(e.target.value)}
                      value={selectedType}
                    >
                      <option value="">All</option>
                      <option value="PaymentMethod">Payment Method</option>
                      <option value="Date">Date</option>
                      <option value="DeleteRecode">Delete Record</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
