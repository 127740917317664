import React, { useContext, useState, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import moment from "moment";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export default function ClientAssmentCompre() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);


  const getRecord = async (lim, page, bId, TrId, status) => {
    try {
      setLoading(true);
      const response = await UserServices.postAssesmentComparison(
        bId,
        lim,
        page,
        TrId,
        status
      );

      let myDataarr = response?.data?.data?.data;
      const results = [];

      myDataarr.map((value) => {
        return results.push({
          Trainer_name: value.Trainer_name,
          id: value.id,
          name: value.name,
          start: value.start_time,
          end: value.end_time,
          assigned_time_slots: value.assigned_time_slots,
        });
      });
      setMyData([...results]);
      setTotalResults(response?.data?.totalRecord);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(response?.data?.data?.current_page);
      setLimit(response?.data?.data?.per_page);
      setLoading(false);
    } catch (err) {
      console.log(err);

      setMyData([]);
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage("1");
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecord(
      limit,
      currentPage,
      addPkgValues.branch_id,
      addPkgValues.trainer_id,
      addPkgValues.status
    );
  }, []);

  const TableOne = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    let act = 0;
    let exp = 0;
    return slice.length ? (
      <>
        <div className="card-body p-0 pt-2">
          <span className="d-none">{(act = 0)}</span>
          <span className="d-none">{(exp = 0)}</span>
          <div
            className="bg-black row"
            style={{ padding: "20px 0px", margin: "0" }}
          >
            <div className="text-white col-2 col-md-6 fw-bold">
              Name: <span style={{ fontWeight: "100" }}>sdsd</span>
            </div>
            <div className="text-white col-2 col-md-3 fw-bold">
              Class: <span style={{ fontWeight: "100" }}> Trainer</span>
            </div>
            <div className="text-white col-2 col-md-3 fw-bold text-end">
              Date: <span style={{ fontWeight: "100" }}>00-00-0000</span>
            </div>
          </div>

          <div className="table-container-dairy">
            <div className="headings">
              <div className="headings-dairy time-dairy">Details</div>
              <div className="headings-dairy client-dairy">Client Name</div>
              <div className="headings-dairy">Session Count</div>
              <div className="headings-dairy bg-body-tertiary">Paid Date</div>
              <div className="headings-dairy">Renewal Date</div>
            </div>
            <div className="data-dairy">
              <div className="data-row">
                <div className="data-cell time-dairy">test</div>
                <div className="data-cell client-dairy">sdsd</div>
                <div className="data-cell">sds</div>
                <div className="data-cell bg-body-tertiary">sdsd</div>
                <div className="data-cell">xxcx</div>
              </div>
              <div className="data-row">
                <div className="data-cell time-dairy">test</div>
                <div className="data-cell client-dairy">sdsd</div>
                <div className="data-cell">sds</div>
                <div className="data-cell bg-body-tertiary">sdsd</div>
                <div className="data-cell">xxcx</div>
              </div>
            </div>

            <div className="grey-for-bg first"></div>
            <div className="grey-for-bg"></div>
          </div>
        </div>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getRecord(
                limit,
                e,
                addPkgValues.branch_id,
                addPkgValues.trainer_id,
                addPkgValues.status
              );
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    ) : (
      ""
    );
  };

  const addPkginitialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    status: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: "",
    onSubmit: (addPkgValues) => {
      getRecord(
        limit,
        "1",
        addPkgValues.branch_id,
        addPkgValues.trainer_id,
        addPkgValues.status
      );
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Client Assessment</h5>
            </div>

            <div className="card-body">
              <TableOne data={myData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
