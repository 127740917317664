import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import moment from "moment";
import jsPDF from "jspdf";
import $ from "jquery";

const Dashboard = () => {
  const [clientCount, setclientCount] = useState("");
  const [Catagry, setCatagry] = useState("1");
  const [inactiveclientCount, setinavtiveClientCount] = useState("");
  const [activeclientCount, setavtiveClientCount] = useState("");
  const [dormantclientCount, setdormantClientCount] = useState("");
  const [count, setCount] = useState("");
  const [packageCount, setPackageCount] = useState("");
  const [sellpackageCount, setSellPackageCount] = useState("");
  const [expenceCount, setExpenceCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const { role } = state;
  var CurrencyFormat = require("react-currency-format");

  const clientCounter = async () => {
    try {
      const responce = await UserServices.clientCount(branch_id);
      if (responce.status === 200) {
        setclientCount(responce.data.all_clients);
        setavtiveClientCount(responce.data.active_clients);
        setinavtiveClientCount(responce.data.inactive_clients);
        setdormantClientCount(responce.data.dormant_clients);
      }
    } catch (err) {
      setclientCount("No record found");
    }
  };
  const staffCounter = async () => {
    try {
      const responce = await UserServices.staffCount(branch_id);
      if (responce.status === 200) {
        setCount(responce.data.data);
      }
    } catch (err) {}
  };
  const packageCounter = async () => {
    try {
      const responce = await UserServices.packageCount(branch_id);
      if (responce.status === 200) {
        setPackageCount(responce.data.data);
      }
    } catch (err) {}
  };
  const sellpackageCounter = async () => {
    try {
      const responce = await UserServices.sellpackageCount(branch_id);
      if (responce.status === 200) {
        setSellPackageCount(responce.data.data);
      }
    } catch (err) {}
  };
  const expensesCounter = async () => {
    try {
      const responce = await UserServices.expensesCount(branch_id);
      if (responce.status === 200) {
        setExpenceCount(responce.data.data);
      }
    } catch (err) {}
  };
  const getUsers = async (page, limit, category) => {
    setLoading(true);
    let pageNo = page;
    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getGymPackages(
        branch_id,
        category,
        newLimit,
        pageNo
      );

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((value) => {
          results.push({
            client_id: value.client_id,
            name: value.name,
            package_name: value.package_name,
            price: value.price,
            end_date: value.end_date,
            orderStatus: value.orderStatus,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    clientCounter();
    staffCounter();
    packageCounter();
    sellpackageCounter();
    expensesCounter();
    getUsers(currentPage, limit, Catagry);
    $(".tabs-stage div.tab-data").hide();
    $(".tabs-stage div.tab-data:first").show();
    $(".tabs-nav li:first").addClass("tab-active");
  }, []);
  const changePackageTime = (e) => {
    let packageTime = e;
    const getClientTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getGymPackagesByTime(
          branch_id,
          Catagry,
          limit,
          1,
          packageTime
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              client_id: value.client_id,
              name: value.name,
              package_name: value.package_name,
              price: value.price,
              end_date: value.end_date,
              orderStatus: value.orderStatus,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getClientTypeData();
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("section_tables.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption={
            Catagry === "1"
              ? "RENEWALS GYM PACKAGES"
              : Catagry === "2"
              ? "RENEWALS TRAINER PACKAGES"
              : Catagry === "7"
              ? "RENEWALS BOOTCAMP CLASSES"
              : null
          }
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Client
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Package Price
              </th>
              <th
                scope="col"
                onClick={() => requestSort("end_date")}
                className={getClassNamesFor("end_date")}
              >
                Renewal Date
              </th>

              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    {" "}
                    <Link to={"/client-home/" + el.client_id}>{el.name}</Link>
                  </td>
                  <td>{el.package_name}</td>
                  <td>
                    <CurrencyFormat
                      value={el.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"2s"}
                    />
                  </td>
                  <td>{moment(el.end_date).format("DD-MM-YYYY")}</td>
                  <td>
                    {el.orderStatus === "1" ? (
                      <button className="renew-pkg custom-active-button">
                        <i className="bi bi-check2-circle"></i> Active
                      </button>
                    ) : (
                      <Link
                        to={"/sell-package/" + el.client_id + "/renew"}
                        className="renew-pkg custom-block-button"
                      >
                        <i className="bi bi-plus-circle"></i> Renew
                      </Link>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit, Catagry);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      {/* wrapper strart  */}
      <div className="wrapper">
        {/* <!--start content--> */}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="row">
              {role === "3" ||role === "1" || role === "4" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/view-clients" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items dashboard-items">
                          <div>
                            <h4 className="mb-1">Manage Clients</h4>
                            <span className="num-ox">{clientCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-person-fill"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/view-staff" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Manage Staff</h4>
                            <span className="num-ox">{count}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-person-workspace"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "4" || role === "5" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/manage-sales" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Manage Sales</h4>
                            <span className="num-ox">{sellpackageCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-graph-up-arrow"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "5" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/view-expenses" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Manage Expenses</h4>
                            <span className="num-ox">{expenceCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-currency-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "4" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/gym-package" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Manage Gym Packages</h4>
                            <span className="num-ox">{packageCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-shield-fill-check"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "4" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/search-client" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Sell Package</h4>
                            <span className="num-ox">{sellpackageCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-ui-checks"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "4" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/view-clients" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Active Clients</h4>
                            <span className="num-ox">{activeclientCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-person-check"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "4" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/view-clients" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Inactive Clients</h4>
                            <span className="num-ox">
                              {inactiveclientCount}
                            </span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-person-dash-fill"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
              {role === "3" ||role === "1" || role === "4" ? (
                <div className="col-12 col-lg-4">
                  <div className="card radius-10 custom-shadow">
                    <Link to="/view-clients" className="manage-task">
                      <div className="card-body">
                        <div className="d-flex align-items-center dashboard-items">
                          <div>
                            <h4 className="mb-1">Dormant</h4>
                            <span className="num-ox">{dormantclientCount}</span>
                          </div>
                          <div className="ms-auto widget-icon bg-orange text-white">
                            <i className="bi bi-person-lock"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="tabs chooce-one">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-12 ">
                        <ul className="tabs-nav ">
                          <li className={Catagry === "1" ? "tab-active" : ""}>
                            <button
                              onClick={() => {
                                if (Catagry !== "1") {
                                  $("#filtered").prop("selectedIndex", 0);

                                  getUsers("1", "25", "1");
                                  setCatagry("1");
                                }
                              }}
                            >
                              GYM Packages
                            </button>
                          </li>
                          <li className={Catagry === "2" ? "tab-active" : ""}>
                            <button
                              onClick={() => {
                                if (Catagry !== "2") {
                                  $("#filtered").prop("selectedIndex", 0);
                                  getUsers("1", "25", "2");
                                  setCatagry("2");
                                }
                              }}
                            >
                              Trainer Packages
                            </button>
                          </li>
                          <li className={Catagry === "7" ? "tab-active" : ""}>
                            <button
                              onClick={() => {
                                if (Catagry !== "7") {
                                  $("#filtered").prop("selectedIndex", 0);
                                  getUsers("1", "25", "7");
                                  setCatagry("7");
                                }
                              }}
                            >
                              Classes
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="tabs-stage">
                    <div id="tab-1" className="tab-data">
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="mb-0 text-uppercase">
                            {Catagry === "1"
                              ? "RENEWALS GYM PACKAGES"
                              : Catagry === "2"
                              ? "RENEWALS TRAINER PACKAGES"
                              : Catagry === "7"
                              ? "RENEWALS BOOTCAMP CLASSES"
                              : ""}
                          </h5>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <select
                            id="filtered"
                            className="form-select"
                            onChange={(e) => changePackageTime(e.target.value)}
                          >
                            <option>Filter Renewals</option>
                            <option value="">All</option>
                            <option value="Today">Today</option>
                            <option value="Yesterday">Yesterday</option>
                            <option value="Coming5Days">Coming 5 Days</option>
                            <option value="Previous5Days">
                              Previous 5 Days
                            </option>
                          </select>
                        </div>
                      </div>
                      <hr />
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-1 col-lg-1 mb-2">
                              <div className="input-group">
                                <select
                                  className="form-select"
                                  value={limit}
                                  onChange={(e) =>
                                    getUsers("1", e.target.value, Catagry)
                                  }
                                >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="250">250</option>
                                  <option value="500">500</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 ms-auto text-end">
                              <button
                                className="btn btn-primary mx-2 file-download"
                                onClick={() => {
                                  generatePdf(
                                    document.getElementById("report-POS")
                                  );
                                }}
                              >
                                <i className="bi bi-file-earmark-pdf"></i> PDF
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <Table data={myData} rowsPerPage={limit} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* <div className="overlay nav-toggle-icon"></div> */}
      </div>
      {/* wrapper end  */}
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default Dashboard;
