import React from 'react'
import { Link } from 'react-router-dom'
import MenuButton from '../MenuButton/MenuButton'
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";

const ManageProducts = () => {
  return (
    <div className='wrapper'>
     <Navbar/>
      <Sidebar/>
        <main className="page-content customer-contnent">
       <div className="site-main-design">
  <MenuButton/>
  <div className="card">
    <div className="card-header py-3">
      <h5 className="mb-0 font-20"> 
        Manage Products 
        <button className="btn btn-primary d-inline-block btn-sm float-end" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bi bi-tools" /> Tools
        </button>
        <ul className="dropdown-menu">
          <li><Link className="dropdown-item" to="/prodsizes">Product Sizes</Link>
          </li>
          <li><Link className="dropdown-item" to="/productcolor">Product Colors</Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li><button className="dropdown-item">Separated link</button>
          </li>
        </ul>
        <Link to="/add-new-product" className="btn btn-primary me-md-2 d-inline-block float-end btn-sm">
          <i className="bi bi-plus" /> Add New
        </Link>
      </h5>
    </div>
    <div className="card-body">
      <div className="">
        <h5 className="mb-0 d-md-inline-block">
          Search for a product to view or edit 
        </h5>
        <div className="custom-raio d-md-inline-block ms-2 mng-prd">  
          <ul>
            <li>
              <input type="checkbox" id="f-option[17]" name="option[17]" className="option[17]" defaultValue="ad-srch" />
              <label htmlFor="f-option[17]">
                Advanced Search 
              </label>
              <div className="check" />
            </li>
          </ul>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12 box ad-srch">
            <h5>Show   
            </h5>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <select className="form-select ">
              <option value="ongoing">All suppliers</option>
              <option value="custom">Options</option>
            </select>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <select className="form-select ">
              <option value="ongoing">
                All Categories/Subcategories
              </option>
              <option value="custom">Options</option>
            </select>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <select className="form-select ">
              <option value="ongoing">All products</option>
              <option value="custom">Options</option>
            </select>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="custom-raio">  
              <ul>
                <li>
                  <input type="checkbox" id="f-option[171]" name="option[171]" className="option[17]" />
                  <label htmlFor="f-option[171]">
                    Member discount
                  </label>
                  <div className="check" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="custom-raio">  
              <ul>
                <li>
                  <input type="checkbox" id="f-option[172]" name="option[172]" className="option[17]" />
                  <label htmlFor="f-option[172]">
                    Sell online only?
                  </label>
                  <div className="check" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="custom-raio">  
              <ul>
                <li>
                  <input type="checkbox" id="f-option[173]" name="option[173]" className="option[17]" />
                  <label htmlFor="f-option[173]">
                    POS favorite products only?
                  </label>
                  <div className="check" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="custom-raio">  
              <ul>
                <li>
                  <input type="checkbox" id="f-option[271]" name="option[271]" className="option[17]" />
                  <label htmlFor="f-option[271]">
                    CSI quick-cash products only?
                  </label>
                  <div className="check" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="custom-raio">  
              <ul>
                <li>
                  <input type="checkbox" id="f-option[272]" name="option[272]" className="option[17]" />
                  <label htmlFor="f-option[272]">
                    Include unused products?
                  </label>
                  <div className="check" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="custom-raio">  
              <ul>
                <li>
                  <input type="checkbox" id="f-option[273]" name="option[273]" className="option[17]" />
                  <label htmlFor="f-option[273]">
                    Created at POS only?
                  </label>
                  <div className="check" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="input-group">
              <label className="form-label open-tic font-14">Search by</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 box ad-srch">
            <div className="input-group">
              <label className="form-label open-tic font-14">Group by </label>
              <select className="form-select ">
                <option value="ongoing">Product Name</option>
                <option value="custom">Options</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="input-group">
              <input type="text" className="form-control" />
              <button className="btn btn-primary srch-prd" type="button">Search</button>
            </div>
          </div>
        </div>
        <hr />
        <div className="mtch-prd">
          <div className="table-responsive ">
            <table className="table table-hover mb-0 align-middle  table-borderless">
              <caption className="caption-top font-20 fw-bold text-center">Matching Products</caption>
              <thead>
                <tr><th />
                  <th>Discontinued</th>
                  <th>Product Name</th>
                  <th>Variants</th>
                  <th>Retail Price</th>
                  <th>Online Price</th>
                  <th>Our Cost</th>
                  <th>Shipping Weight</th>
                  <th>Sold Online</th>
                  <th>Favorite Product</th>
                  <th>Created By</th>
                  <th>Creation Date</th>
                  <th>Modification Date</th>
                </tr></thead>
              <tbody>
                <tr>
                  <td />
                  <td>
                    <button>check all</button> 
                    <button>uncheck all</button> 
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                  <button>check all</button> 
                    <button>uncheck all</button> 
                  </td>
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="fdslexCheckDefault" />
                  </td>
                  <td>
                    <Link to="/editproduct">
                      Cafe Meal Package Full  
                    </Link>
                  </td>
                  <td>1</td>
                  <td>
                    <input className="form-control" defaultValue="15,000.00" />
                  </td>
                  <td>
                    <input className="form-control" defaultValue="15,000.00" />
                  </td>
                  <td>
                    <input className="form-control" defaultValue={0.00} />
                  </td>
                  <td>
                    <input className="form-control" defaultValue={0.00} />
                  </td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheffckDefault" />
                  </td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                  </td>
                  <td>Bilal Arshed  	</td>
                  <td>05/02/2021	</td>
                  <td>05/02/2021	</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="fdslexCheckDefault" />
                  </td>
                  <td>
                    <Link to="/editproduct">
                      Replacement Card  
                    </Link>
                  </td>
                  <td>1</td>
                  <td>
                    <input className="form-control" defaultValue="15,000.00" />
                  </td>
                  <td>
                    <input className="form-control" defaultValue="15,000.00" />
                  </td>
                  <td>
                    <input className="form-control" defaultValue={0.00} />
                  </td>
                  <td>
                    <input className="form-control" defaultValue={0.00} />
                  </td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheffckDefault" />
                  </td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                  </td>
                  <td>Bilal Arshed  	</td>
                  <td>05/02/2021	</td>
                  <td>05/02/2021	</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="fdslexCheckDefault" />
                  </td>
                  <td>
                    <Link to="/editproduct">
                      Fittest Member  
                    </Link>
                  </td>
                  <td>1</td>
                  <td>
                    <input className="form-control" defaultValue="15,000.00" />
                  </td>
                  <td>
                    <input className="form-control" defaultValue="15,000.00" />
                  </td>
                  <td>
                    <input className="form-control" defaultValue={0.00} />
                  </td>
                  <td>
                    <input className="form-control" defaultValue={0.00} />
                  </td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheffckDefault" />
                  </td>
                  <td>
                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                  </td>
                  <td>Bilal Arshed  	</td>
                  <td>05/02/2021	</td>
                  <td>05/02/2021	</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="col-md-12 mt-3">
            <button type="button" className="btn btn-primary px-5 float-end rounded-0 mb-1">Save</button>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </main>
    </div>
  )
}

export default ManageProducts
