import React, { useContext, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { switchTimeForClient } from "../schemas";
import { useEffect } from "react";

import { useNavigate } from "react-router";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import jsPDF from "jspdf";
import moment from "moment";

export default function SwitchTimeForClient() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);

  const [trainerName, setTrainerName] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [duration, setDuration] = useState(30);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTrainer, setSelectedTrainer] = useState("");

  const getSwitchedTime = async (page, limit, trainer_id) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.switchedTimeSlot(
        newLimit,
        pageNo,
        branch_id,
        role === "9" ? UId : trainer_id
      );

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_name: values.branch_name,
            request_status: values.request_status,
            reason: values.reason,
            remarks: values.remarks,
            new_time: values.time?.start_time + " To " + values.time?.end_time,
            trainer_name: values.time?.trainer_name,
            client_name: values.detail?.order_detail?.client_name,
            old_time:
              values.detail?.time_slot?.start_time +
              " To " +
              values.detail?.time_slot?.end_time,

            reason: values.reason,
            date: values.date,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const getTimeSolts = async (trainer_id) => {
    try {
      const responce = await UserServices.trainerTimeList(trainer_id);

      const res = responce.data.data;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          time: values.start_time + " To " + values.end_time,
        });
        return null;
      });
      setTimeSlot([{ time: "Select Time Slot", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTimeSlot([{ time: "No Time Slot Found", id: "" }]);
    }
  };
  const getBookings = async (trainer_id) => {
    try {
      const responce = await UserServices.getBookingsForTrainer(
        addPkgValues.branch_id,
        trainer_id,
        "true"
      );
      const res = responce.data.data.data;
      const results = [];

      res.map((values) => {
        values.status === "1" &&
          results.push({
            id: values.id,
            time: values.time + " For " + values.order_detail?.client_name,
            duration: values.order_detail?.duration,
          });
      });
      setBookings([
        { time: "Select Time Slot", id: "", duration: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);
      setBookings([{ time: "No Time Slot Found", id: "" }]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    gymTrainer(branch_id);
    getSwitchedTime(currentPage, limit, "");
    if (role === "9") {
      getTimeSolts(UId);
      getBookings(UId);
    }
  }, []);

  const handleStartChange = (value) => {
    const startDate = new Date(value);
    const endDate = new Date(
      startDate.getTime() + duration * 24 * 60 * 60 * 1000
    );

    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();

    const formattedEndDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    addFieldValue("end", formattedEndDate);
  };

  const checkAvaliability = async (time_id, sDate) => {
    if (time_id !== "" && sDate !== "") {
      try {
        setLoading(true);
        const response = await UserServices.getTrainerAvaliability(
          time_id,
          sDate
        );

        if (response.status === 200) {
          setLoading(false);
          handleStartChange(sDate);
        }
      } catch (err) {
        setLoading(false);
        toast.error("Time slot unavailable", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        addFieldValue("time_id", "");
        addFieldValue("start", "");
        addFieldValue("end", "");
      }
    } else if (sDate !== "") {
      handleStartChange(sDate);
    }
  };

  const timeSlotsSwitch = async (addPkgValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.switchSlotToClient(addPkgValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getSwitchedTime(currentPage, limit, "");
      }
    } catch (err) {
      setLoading(false);
      toast.error("Unproccessable content!", { position: "top-center" });
    }
  };
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "-" + month + "-" + date;

  const addPkginitialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    time_id: "",
    start: "",
    end: "",
    schedule_id: "",

    reason: "",
    date: currentDate,
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    setFieldValue: addFieldValue,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: switchTimeForClient,
    onSubmit: (addPkgValues, action) => {
      timeSlotsSwitch(addPkgValues, action);
    },
  });

  const getDuration = async (id) => {
    if (id !== "") {
      const filteredArray = bookings.filter((item) => item.id === parseInt(id));
      setDuration(filteredArray[0]?.duration ? filteredArray[0]?.duration : 30);
    } else {
      setDuration(30);
    }
  };

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Switched-Time-Slots.pdf");
  };

  const makeDelete = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.switchedTimeSlotAction(id, "2");
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getSwitchedTime(currentPage, limit, "");
        // getInactivePkg(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const processRequest = async (id, status) => {
    try {
      setLoading(true);
      const response = await UserServices.switchedTimeProcess(id, status);

      if (response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        getSwitchedTime(currentPage, limit, selectedTrainer);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Unproccessable content!", { position: "top-center" });
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Switched Time Slots"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer_name")}
                className={getClassNamesFor("trainer_name")}
              >
                Trainer Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("old_time")}
                className={getClassNamesFor("old_time")}
              >
                Old Time
              </th>
              <th
                scope="col"
                onClick={() => requestSort("new_time")}
                className={getClassNamesFor("new_time")}
              >
                New Time
              </th>
              <th
                scope="col"
                onClick={() => requestSort("reason")}
                className={getClassNamesFor("reason")}
              >
                Reason
              </th>

              <th
                scope="col"
                onClick={() => requestSort("request_status")}
                className={getClassNamesFor("request_status")}
              >
                Request Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>{el.trainer_name}</td>
                  <td>{el.client_name}</td>
                  <td>{el.old_time}</td>
                  <td>{el.new_time}</td>
                  <td>{el.reason}</td>

                  <td>
                    <span className="d-inline-flex" style={{ width: "70px" }}>
                      {el.request_status === "Pending" ? (
                        <span className="badge bg-warning text-dark w-100">
                          {el.request_status}
                        </span>
                      ) : el.request_status === "Rejected" ? (
                        <span className="badge bg-danger w-100">
                          {el.request_status}
                        </span>
                      ) : (
                        <span className="badge bg-success w-100">
                          {el.request_status}
                        </span>
                      )}
                    </span>
                  </td>
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => makeDelete(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                    {role === "3" || role === "1" || role === "11"
                      ? el.request_status === "Pending" && (
                          <>
                            <button
                              className="updt-btn inactive-btn"
                              onClick={() => {
                                processRequest(el.id, "Approved");
                              }}
                            >
                              <i className="bi bi-check-circle text-success"></i>{" "}
                              <span className="text-success ms-1 updt-text">
                                Approve
                              </span>
                            </button>
                            <button
                              type="button"
                              className="ms-2 inactive-btn"
                              onClick={() => {
                                processRequest(el.id, "Rejected");
                              }}
                            >
                              <i className="bi bi-x-circle text-danger"></i>{" "}
                              <span className="text-danger inative-text ms-1">
                                Reject
                              </span>
                            </button>
                          </>
                        )
                      : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getSwitchedTime(e, limit, "");
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Switch Time Slots</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    addPkgHandleSubmit(e);
                  }}
                >
                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={addPkgValues.branch_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            gymTrainer(e.target.value);
                            addPkgValues.trainer_id = "";
                            addPkgValues.time_id = "";
                            addPkgValues.schedule_id = "";
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                        <p className="text-danger">{addPkgErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group">
                      Available Trainers<span className="text-danger">*</span>
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={addPkgValues.trainer_id || ""}
                          disabled={role === "9" && UId ? true : false}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            getBookings(e.target.value);
                            getTimeSolts(e.target.value);
                            addPkgValues.time_id = "";
                            addPkgValues.schedule_id = "";
                          }}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.trainer_id && addPkgTouched.trainer_id ? (
                        <p className="text-danger">{addPkgErrors.trainer_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group">
                      Available Bookings<span className="text-danger">*</span>
                      {bookings ? (
                        <select
                          className="form-select"
                          name="schedule_id"
                          value={addPkgValues.schedule_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            getDuration(e.target.value);
                          }}
                        >
                          {bookings.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.time}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.schedule_id && addPkgTouched.schedule_id ? (
                        <p className="text-danger">
                          {addPkgErrors.schedule_id}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group add-time-slot-time">
                      Available Time Slots<span className="text-danger">*</span>
                      {timeSlot ? (
                        <select
                          className="form-select"
                          name="time_id"
                          value={addPkgValues.time_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            checkAvaliability(
                              e.target.value,
                              addPkgValues.start
                            );
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {timeSlot.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.time}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.time_id && addPkgTouched.time_id ? (
                        <p className="text-danger">{addPkgErrors.time_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group add-time-slot-time">
                      Start Date <span className="text-danger">*</span>
                      <input
                        type="date"
                        className="form-control"
                        name="start"
                        value={addPkgValues.start || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                          checkAvaliability(
                            addPkgValues.time_id,
                            e.target.value
                          );
                        }}
                        onBlur={addPkgHandleBlur}
                      />
                      {addPkgErrors.start && addPkgTouched.start ? (
                        <p className="text-danger">{addPkgErrors.start}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group add-time-slot-time">
                      End Date <span className="text-danger">*</span>
                      <input
                        type="date"
                        className="form-control"
                        name="end"
                        value={addPkgValues.end || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                        disabled={true}
                      />
                      {addPkgErrors.end && addPkgTouched.end ? (
                        <p className="text-danger">{addPkgErrors.end}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group add-time-slot-time">
                      Reason
                      <input
                        type="text"
                        className="form-control"
                        name="reason"
                        value={addPkgValues.reason || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      />
                      {addPkgErrors.reason && addPkgTouched.reason ? (
                        <p className="text-danger">{addPkgErrors.reason}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Switch Time
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Switched Time Slots</h5>
              </div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-4 col-lg-4">
                    <div className="form-group custom-group">
                      Available Trainers
                      {trainerName ? (
                        <select
                          className="form-select"
                          disabled={role === "9" && UId ? true : false}
                          value={selectedTrainer}
                          onChange={(e) => {
                            setSelectedTrainer(e.target.value);
                            getSwitchedTime(currentPage, limit, e.target.value);
                          }}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto mt-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) =>
                          getSwitchedTime("1", e.target.value, "")
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className=" col-md-1 text-end mt-3">
                    <button
                      className="btn btn-primary  file-download"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
