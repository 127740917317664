import React, { useEffect } from "react";
import $ from "jquery";

export default function CafeMenu() {
  useEffect(() => {
    $(window).on("scroll", function () {
      $(this).scrollTop() > 300
        ? $(".back-to-top").addClass("showbtn")
        : $(".back-to-top").removeClass("showbtn");
    });
  }, []);

  const handleMenu = () => {
    $(".site-main-design").toggleClass("full-show");
    $(".site-main-design").removeClass("site-main-design2");
    $("#cartbar").removeClass("show");
  };
  $(".generate_report").click(function () {
    $(".generate_report").removeClass("active");
    $(this).addClass("active");
    $(".multi-button").addClass("active");
  });

  return (
    <div className="wrapper">
      <div className=" mb-4 mt-2">
        <button
          id="menu-open-close"
          data-bs-target="#sidebar"
          data-bs-toggle="collapse"
          className="border d-none d-lg-inline-block rounded-0 p-1 text-decoration-none bg-danger text-white"
          onClick={handleMenu}
        >
          <i className="bi bi-list bi-lg py-2 p-1" /> Menu
        </button>

        <button
          id="menu-open-close"
          data-bs-target="#sidebar"
          data-bs-toggle="collapse"
          className="border rounded-0 p-1 text-decoration-none bg-danger text-white d-lg-none float-end"
          onClick={handleMenu}
        >
          <i className="bi bi-list bi-lg py-2 p-1" /> Menu
        </button>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}
