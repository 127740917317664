import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import MenuButton from "../MenuButton/MenuButton";
import { postAssesment } from "../schemas";

export default function AddPostAssmnt() {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [clientName, setclientName] = useState("...");
  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      const responce = await UserServices.getClientsNameById(branch_id, id);

      setclientName(
        responce.data.data[0].first_name + " " + responce.data.data[0].last_name
      );
    } catch (err) {}
  };
  useEffect(() => {
    getUsers();
  }, []);

  const initialValues = {
    Weight: "",
    Calf: "",
    Neck: "",
    BMI: "",
    Shoulder: "",
    Fat: "",
    Chest: "",
    Rhr: "",
    Wrist: "",
    Mhr: "",
    Abs: "",
    Vfat: "",
    Arm: "",
    Bp: "",
    Waist: "",
    Systolic: "",
    Glutes: "",
    Diastolic: "",
    Thigh: "",
  };

  const addClientAssessment = async (values, action) => {
    try {
      setLoading(true);

      const response = await UserServices.addPostAssessment(values, id);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false);
      navigate("/viewAssessment/" + id);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: postAssesment,
      onSubmit: (values, action) => {
        addClientAssessment(values, action);
      },
    });
  return (
    <>
      <ToastContainer />
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Client: {clientName}</h5>
              </div>
              <form className="" onSubmit={handleSubmit}>
                <div className="card-body">
                  <p>
                    <i
                      className="bi bi-exclamation-lg text-danger"
                      aria-hidden="true"
                    />
                    The Fields With <strong className="text-danger">*</strong>
                    Must Required Or Fill.
                  </p>

                  <div className="row g-3">
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Weight <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Weight"
                          autoComplete="off"
                          name="Weight"
                          value={values.Weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Weight && touched.Weight ? (
                          <p className="text-danger">{errors.Weight}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Calf <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Calf"
                          autoComplete="off"
                          name="Calf"
                          value={values.Calf}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Calf && touched.Calf ? (
                          <p className="text-danger">{errors.Calf}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Neck <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Neck"
                          autoComplete="off"
                          name="Neck"
                          value={values.Neck}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Neck && touched.Neck ? (
                          <p className="text-danger">{errors.Neck}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          BMI <span className="text-danger">*</span>
                        </label>
                        <input
                          type="NUMBER"
                          className="form-control"
                          placeholder="Enter BMI"
                          autoComplete="off"
                          name="BMI"
                          value={values.BMI}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.BMI && touched.BMI ? (
                          <p className="text-danger">{errors.BMI}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Shoulder <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Shoulder"
                          autoComplete="off"
                          name="Shoulder"
                          value={values.Shoulder}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Shoulder && touched.Shoulder ? (
                          <p className="text-danger">{errors.Shoulder}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          FAT% <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Fat%"
                          autoComplete="off"
                          name="Fat"
                          value={values.Fat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Fat && touched.Fat ? (
                          <p className="text-danger">{errors.Fat}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Chest <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Chest"
                          autoComplete="off"
                          name="Chest"
                          value={values.Chest}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Chest && touched.Chest ? (
                          <p className="text-danger">{errors.Chest}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          RHR <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter RHR"
                          autoComplete="off"
                          name="Rhr"
                          value={values.Rhr}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Rhr && touched.Rhr ? (
                          <p className="text-danger">{errors.Rhr}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Wrist <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Wrist"
                          autoComplete="off"
                          name="Wrist"
                          value={values.Wrist}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Wrist && touched.Wrist ? (
                          <p className="text-danger">{errors.Wrist}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          MHR <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter MHR"
                          autoComplete="off"
                          name="Mhr"
                          value={values.Mhr}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Mhr && touched.Mhr ? (
                          <p className="text-danger">{errors.Mhr}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Abs <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Abs"
                          autoComplete="off"
                          name="Abs"
                          value={values.Abs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Abs && touched.Abs ? (
                          <p className="text-danger">{errors.Abs}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          V-FAT <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter V-FAT"
                          autoComplete="off"
                          name="Vfat"
                          value={values.Vfat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Vfat && touched.Vfat ? (
                          <p className="text-danger">{errors.Vfat}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Arm <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Arm"
                          autoComplete="off"
                          name="Arm"
                          value={values.Arm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Arm && touched.Arm ? (
                          <p className="text-danger">{errors.Arm}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          BP <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter BP"
                          autoComplete="off"
                          name="Bp"
                          value={values.Bp}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Bp && touched.Bp ? (
                          <p className="text-danger">{errors.Bp}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Waist <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Waist"
                          autoComplete="off"
                          name="Waist"
                          value={values.Waist}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Waist && touched.Waist ? (
                          <p className="text-danger">{errors.Waist}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Systolic <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Systolic"
                          autoComplete="off"
                          name="Systolic"
                          value={values.Systolic}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Systolic && touched.Systolic ? (
                          <p className="text-danger">{errors.Systolic}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Glutes <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Glutes"
                          autoComplete="off"
                          name="Glutes"
                          value={values.Glutes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Glutes && touched.Glutes ? (
                          <p className="text-danger">{errors.Glutes}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Diastolic <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Diastolic"
                          autoComplete="off"
                          name="Diastolic"
                          value={values.Diastolic}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Diastolic && touched.Diastolic ? (
                          <p className="text-danger">{errors.Diastolic}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Thigh <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Thigh"
                          autoComplete="off"
                          name="Thigh"
                          value={values.Thigh}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Thigh && touched.Thigh ? (
                          <p className="text-danger">{errors.Thigh}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />

                <div className="col-12 text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 mb-1 me-2 addclient-button"
                  >
                    Add Results
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
