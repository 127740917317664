import React, { useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import UserServices from "../../UserServices/UserServices";

const ExcelImport = () => {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event, action) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    try {
      const response = await UserServices.uploadFile(formData);
      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Upload successfully.",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
            if (result.isConfirmed) {
              action.resetForm();
            //   window.location.reload(false);
            }
          });
      }
    } catch (err) {}
  };
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Clients Import</h5>
            </div>
            <form className="" onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Import File <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control custom-form-control"
                        placeholder="Upload File"
                        accept=".xlsx, .xls"
                        name="file"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button
                      type="submit"
                      className="btn btn-primary mt-4 px-5 mb-1 addclient-button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>       
      </div>
    </div>
  );
};

export default ExcelImport;
