import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { useState } from "react";
import { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import useSortableData from "../../hooks/useSortableData";
import { useFormik } from "formik";
import { clientReport } from "../schemas";
import moment from "moment/moment";
import Swal from "sweetalert2";
import jsPDF from "jspdf";

export default function DailyOfficeClosing() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const sMonth = date.getMonth();
  const monthString = (parseInt(sMonth) + 1).toString();
  let strtDate = moment().startOf("month").format("YYYY-MM-DD");
  /* eslint-disable no-unused-vars */
  const [startDate, setStartDate] = useState(strtDate);
  const [endDate, setEndDate] = useState(dateSelected);
  const [grandTotalExpenses, setGrandTotalExpenses] = useState(0);

  const getRecord = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        const response = await UserServices.getDailyClosingExpense(
          branch_id,
          values
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data;
          const bankResults = [];
          const salesCounterResults = [];
          const officeResults = [];
          const personalResults = [];

          myDataarr.map((values) => {
            const result = {
              description: values.description,
              amount: values.amount,
              category_name: values.category_name,
              sub_category_name: values.sub_category_name,
              transaction_type: values.transaction_type,
            };

            const totalExpenses = myDataarr.reduce(
              (total, item) => total + item.amount,
              0
            );

            setGrandTotalExpenses(totalExpenses);

            switch (values.transaction_type) {
              case "Bank Account":
                bankResults.push(result);
                break;
              case "Sales Counter":
                salesCounterResults.push(result);
                break;
              case "Office Counter":
                officeResults.push(result);
                break;
              case "Personal":
                personalResults.push(result);
                break;
              default:
                break;
            }

            return null;
          });
          setMyData([
            ...bankResults,
            ...salesCounterResults,
            ...officeResults,
            ...personalResults,
          ]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getRecord(values);
  }, []);

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(   captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("daily-office-closing.pdf");
  };

  const BankTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    // Filter the items to show only bank data
    const bankItems = items.filter(
      (el) => el.transaction_type === "Bank Account"
    );

    return (
      <div className="adj-hight">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Bank Details"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("amount")}
                className={getClassNamesFor("amount")}
              >
                Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                onClick={() => requestSort("sub-category_name")}
                className={getClassNamesFor("sub-category_name")}
              >
                Subcategory
              </th>
            </tr>
          </thead>

          <tbody>
            {bankItems.length ? (
              <>
                {bankItems.map((el, index) => (
                  <tr key={index}>
                    <td>{el.description ? el.description : "N/A"}</td>
                    <td>
                      <CurrencyFormat
                        value={el.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>{el.category_name}</td>
                    <td>{el.sub_category_name}</td>
                  </tr>
                ))}
                <tr className="total-fot">
                  <td className="font-20">Total Expenses</td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={bankItems.reduce(
                        (total, item) => total + parseInt(item.amount),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td></td>
                  <td className="font-20"></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-capitalize">
                  No bank records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const SalesCounterTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    // Filter the items to show only bank data
    const SalesCounterItems = items.filter(
      (el) => el.transaction_type === "Sales Counter"
    );

    return (
      <div className="adj-hight">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Sales Counter Details"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("amount")}
                className={getClassNamesFor("amount")}
              >
                Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                onClick={() => requestSort("sub-category_name")}
                className={getClassNamesFor("sub-category_name")}
              >
                Subcategory
              </th>
            </tr>
          </thead>

          <tbody>
            {SalesCounterItems.length ? (
              <>
                {SalesCounterItems.map((el, index) => (
                  <tr key={index}>
                    <td>{el.description ? el.description : "N/A"}</td>
                    <td>
                      <CurrencyFormat
                        value={el.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>{el.category_name}</td>
                    <td>{el.sub_category_name}</td>
                  </tr>
                ))}
                <tr className="total-fot">
                  <td className="font-20">Total Expenses</td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={SalesCounterItems.reduce(
                        (total, item) => total + parseInt(item.amount),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td></td>
                  <td className="font-20"></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-capitalize">
                  No sales records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const OfficeTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    // Filter the items to show only bank data
    const OfficeItems = items.filter(
      (el) => el.transaction_type === "Office Counter"
    );

    return (
      <div className="adj-hight">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Office Details"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("amount")}
                className={getClassNamesFor("amount")}
              >
                Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                onClick={() => requestSort("sub-category_name")}
                className={getClassNamesFor("sub-category_name")}
              >
                Subcategory
              </th>
            </tr>
          </thead>

          <tbody>
            {OfficeItems.length ? (
              <>
                {OfficeItems.map((el, index) => (
                  <tr key={index}>
                    <td>{el.description ? el.description : "N/A"}</td>
                    <td>
                      <CurrencyFormat
                        value={el.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>{el.category_name}</td>
                    <td>{el.sub_category_name}</td>
                  </tr>
                ))}
                <tr className="total-fot">
                  <td className="font-20">Total Expenses</td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={OfficeItems.reduce(
                        (total, item) => total + parseInt(item.amount),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td></td>
                  <td className="font-20"></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-capitalize">
                  No office records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const PersonalTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    // Filter the items to show only bank data
    const PersonalItems = items.filter(
      (el) => el.transaction_type === "Personal"
    );

    return (
      <div className="adj-hight">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Personal Details"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("amount")}
                className={getClassNamesFor("amount")}
              >
                Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                onClick={() => requestSort("sub-category_name")}
                className={getClassNamesFor("sub-category_name")}
              >
                Subcategory
              </th>
            </tr>
          </thead>

          <tbody>
            {PersonalItems.length ? (
              <>
                {PersonalItems.map((el, index) => (
                  <tr key={index}>
                    <td>{el.description ? el.description : "N/A"}</td>
                    <td>
                      <CurrencyFormat
                        value={el.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>{el.category_name}</td>
                    <td>{el.sub_category_name}</td>
                  </tr>
                ))}
                <tr className="total-fot">
                  <td className="font-20">Total Expenses</td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={PersonalItems.reduce(
                        (total, item) => total + parseInt(item.amount),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td></td>
                  <td className="font-20"></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-capitalize">
                  No personal records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const initialValues = {
    sdate: startDate,
    edate: endDate,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        getRecord(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <form className="row g-3 mb-3" onSubmit={handleSubmit}>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter Start date"
                  name="sdate"
                  value={values.sdate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.sdate && touched.sdate ? (
                  <p className="text-danger">{errors.sdate}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4 ">
              <div className="form-group custom-group">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter End date"
                  name="edate"
                  value={values.edate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.edate && touched.edate ? (
                  <p className="text-danger">{errors.edate}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-2 mt-3 pt-4">
              <button type="submit" className="btn btn-primary px-5 rounded-0 ">
                Genrate
              </button>
            </div>
            <div className="col-md-2 mt-3 pt-4 text-end">
              <button
                className="btn btn-primary mx-2 file-download "
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </form>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Bank Details</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive ">
                    <BankTable data={myData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Sales Counter Details</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive ">
                    <SalesCounterTable data={myData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Office Details</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive ">
                    <OfficeTable data={myData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Personal Details</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive ">
                    <PersonalTable data={myData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="ms-auto d-flex">
              <div className="mt-1">
                <h5>Total Overall Expense :</h5>
              </div>
              <div className="ms-2  fs-5">
                <CurrencyFormat
                  value={grandTotalExpenses}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  thousandSpacing={"3"}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
