import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";

const Discounts = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">View Discount Details</h5>
            </div>
            <div className="card-body">

            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Discounts;
