import React, { useContext, useState, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { addGfxPkg } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-js-pagination";

import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import jsPDF from "jspdf";
import $ from "jquery";

const AddGfxPackage = () => {
  const { state } = useContext(NoteState);
  const [loading, setLoading] = useState(false);

  const [myBranches, setMyBranches] = useState([]);

  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { branch_id } = state;

  // update

  const [classesNames, setClassesNames] = useState([]);

  var CurrencyFormat = require("react-currency-format");

  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getGfxPackages(
        branch_id,

        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branches_name: values.branches_name,
            package_name: values.package_name,
            duration: values.duration,
            price: values.price,
            category: values.category,
            user_first_name: values.user_first_name,
            user_last_name: values.user_last_name,
            user_id: values.user_id,

            session_count: values.session_count,
            booking_capacity: values.booking_capacity,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const getClassesList = async (br_Id, tr_id) => {
    try {
      const responce = await UserServices.allClassesList(br_Id, "15", tr_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setClassesNames([{ name: "Select Class ", id: "" }, ...results]);
    } catch (err) {
      console.log(err);

      setClassesNames([{ name: "No Class Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);
    branches();
    getClassesList(values.branch_id, "");
  }, []);

  let searchTimer;
  const searchPkg = async (e) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);

      try {
        const response = await UserServices.MemPkgSearch(
          search_name,
          branch_id,
          "1",
          limit,
          1,
          "15"
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              branch_id: values.branch_id,
              branches_name: values.branches_name,
              package_name: values.package_name,
              duration: values.duration,
              price: values.price,
              membership_types_name: values.membership_types_name,
              category: values.category,
              user_first_name: values.user_first_name,
              user_last_name: values.user_last_name,
              user_id: values.user_id,
              start_time: values.start_time,
              end_time: values.end_time,

              trainer_membership_id: values.trainer_membership_id,
              session_count: values.session_count,
            });
            return null;
          });
          setMyData([...results]);

          setLimit(response.data.data.per_page);
          setTotalResults(response.data.totalRecord);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const addGymPackage = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.UpdateGfxPackages(
        values,
        values.class_id
      );

      if (response.status === 204) {
        setLoading(false);

        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);

      toast.error("Unproccessable content!", { position: "top-center" });
    }
  };
  const initialValues = {
    branch_id: branch_id,
    price: "",
    class_id: "",

    duration: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addGfxPkg,
      onSubmit: (values, action) => {
        addGymPackage(values, action);
      },
    });

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("GX-Packages.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Active GX Package"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Class Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer")}
                className={getClassNamesFor("trainer")}
              >
                Trainer
              </th>

              <th
                scope="col"
                onClick={() => requestSort("session_count")}
                className={getClassNamesFor("session_count")}
              >
                Session Count
              </th>
              <th
                scope="col"
                onClick={() => requestSort("duration")}
                className={getClassNamesFor("duration")}
              >
                Duration
              </th>
              <th
                scope="col"
                onClick={() => requestSort("booking_capacity")}
                className={getClassNamesFor("booking_capacity")}
              >
                Booking Space
              </th>
              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branches_name}</td>
                  <td>{el.package_name}</td>
                  <td>
                    {el.user_first_name} {el.user_last_name}
                  </td>
                  <td>{el.session_count}</td>
                  <td>{el.duration} Days</td>
                  <td>{el.booking_capacity}</td>

                  <td>
                    <CurrencyFormat
                      value={el.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className="wrapper">
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">GX Package</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name <span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          value={values.branch_id || ""}
                          disabled={branch_id ? true : false}
                          onChange={(e) => {
                            handleChange(e);

                            getClassesList(e.target.value, "");
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      Available Classes <span className="text-danger">*</span>
                      {classesNames ? (
                        <select
                          className="form-select"
                          name="class_id"
                          value={values.class_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {classesNames.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.class_id && touched.class_id ? (
                        <p className="text-danger">{errors.class_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Price <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className="form-control"
                        placeholder="Enetr Price"
                        min={0}
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.price && touched.price ? (
                        <p className="text-danger">{errors.price}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Enter Duration <span className="text-danger">*</span>
                        <span className="text-black-50">
                          ( Please enter the duration in form of days )
                        </span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        min="0"
                        className="form-control"
                        placeholder="Enter Duration"
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.duration && touched.duration ? (
                        <p className="text-danger">{errors.duration}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <button
              className="btn btn-primary mx-2 file-download float-end mb-3"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Active GX Package</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 col-lg-2 ms-2 mb-3">
                    <div className="form-group custom-group">
                      <label className="form-label">Search By Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => {
                          handleChange(e);
                          searchPkg(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-1 mt-4 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default AddGfxPackage;
