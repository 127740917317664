import React, { useContext, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { clientReport } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ClientsReports = () => {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [DAteSeted, setDAteSeted] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);

  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [membershipType, setMembershipType] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [option, setOption] = useState("");
  const [radioOption, setRadioOption] = useState("Detail");

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    const getMembershipType = async (br_Id) => {
      try {
        const responce = await UserServices.membershipType(br_Id);

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMembershipType([
          { name: "Select Membership Type", id: "" },
          ...results,
        ]);
      } catch (err) {
        console.log(err);
        setMembershipType([]);
      }
    };
    getMembershipType(branch_id);
  }, []);

  const setDates = async (param) => {
    setDAteSeted(true);
    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
      if (err.message === "Network Error") {
        navigate("/dashboard");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };

  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      setShow(true);
      try {
        const responce = await UserServices.getClientReport(
          branch_id,
          values,
          limit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/clients/exportclients?status=" +
              values.status +
              "&branch_id=" +
              branch_id +
              "&gender=" +
              values.report +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&membership_type_id=" +
              values.memType
          );
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              date: value.date,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              country: value.country,
              postal_code: value.postal_code,
              city: value.city,
              uid: value.uid,
              birthday: value.birthday,
              status: value.status,
              membership_type: value.membership_type,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);

          if (radioOption === "Detail") {
            setOption("Detail");
          } else {
            setOption("Summary");
          }
        }
      } catch (err) {
        if (radioOption === "Detail") {
          setOption("Detail");
        } else {
          setOption("Summary");
        }

        if (err.response.status === 401) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    let pageNo = e;

    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientReport(
          branch_id,
          values,
          limit,
          pageNo
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              date: value.date,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              country: value.country,
              postal_code: value.postal_code,
              city: value.city,
              uid: value.uid,
              birthday: value.birthday,
              status: value.status,
              membership_type: value.membership_type,
            });
            return null;
          });
          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };

  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    // console.log(limit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientReport(
          branch_id,
          values,
          newLimit,
          "1"
        );
        console.log(responce);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              date: value.date,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              country: value.country,
              postal_code: value.postal_code,
              city: value.city,
              uid: value.uid,
              birthday: value.birthday,
              status: value.status,
              membership_type: value.membership_type,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    //   const canvas = await html2canvas(el, {
    //     scale: 2, // Set the scale to 1 to get the actual size of the element
    //     allowTaint: true, // Allow cross-origin images to be drawn on the canvas
    //     useCORS: true, // Use CORS to fetch the images if needed
    //   });
    //   const image = canvas.toDataURL("image/png", 1.0);
    //   const invoiceWidth = 150; // in mm
    //   const invoiceHeight = 297; // in mm
    //   const pdf = new jsPDF("p", "mm", [invoiceWidth, invoiceHeight]);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = pdf.internal.pageSize.getHeight();
    //   const canvasWidth = canvas.width;
    //   const canvasHeight = canvas.height;
    //   const scaleX = pdfWidth / canvasWidth;
    //   const scaleY = pdfHeight / canvasHeight;
    //   const scale = Math.min(scaleX, scaleY);
    //   const canvasScaledWidth = canvasWidth * scale;
    //   const canvasScaledHeight = canvasHeight * scale;
    //   const pdfX = (pdfWidth - canvasScaledWidth) / 2;
    //   const pdfY = (pdfHeight - canvasScaledHeight) / 2;
    //   const imgData = canvas.toDataURL("image/png");
    //   pdf.addImage(
    //     imgData,
    //     "PNG",
    //     pdfX,
    //     pdfY,
    //     canvasScaledWidth,
    //     canvasScaledHeight
    //   );
    //   const pdfData = pdf.output("blob");
    //   const pdfURL = URL.createObjectURL(pdfData);
    //   setLoading(false);
    //   window.open(pdfURL, "_blank");
    // };

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");

      // Loop through all the tables in the section and add them to the PDF

      // Add a new page for each table (skip first table as it is already on the first page)
      const tableOptions = {
        headStyles: { fillColor: [6, 161, 128], textColor: 255 },
        styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });

      doc.save("clients-reports.pdf");
    };

    return (
      <>
        <div className="row mb-2 ">
          <div className="col-md-1 col-lg-1 ms-auto">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div className="col-2 col-md-2 text-end ">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <a
              href={exportLink}
              rel="noreferrer"
              // target="_blank"
              className="btn btn-primary file-download"
            >
              <i className="bi bi-download" /> Excel
            </a>
          </div>
        </div>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("index")}
                className={getClassNamesFor("index")}
              >
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("uid")}
                className={getClassNamesFor("uid")}
              >
                Membership Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("membership_types_name")}
                className={getClassNamesFor("membership_types_name")}
              >
                Membership Type
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>

              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Phone
              </th>

              <th
                scope="col"
                onClick={() => requestSort("gender")}
                className={getClassNamesFor("gender")}
              >
                Gender
              </th>

              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Address
              </th>

              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
                City
              </th>
              {/* <th
                scope="col"
                onClick={() => requestSort("country")}
                className={getClassNamesFor("country")}
              >
                Country
              </th>
              <th
                scope="col"
                onClick={() => requestSort("postal_code")}
                className={getClassNamesFor("postal_code")}
              >
                Postal Code
              </th> */}

              <th
                scope="col"
                onClick={() => requestSort("birthday")}
                className={getClassNamesFor("birthday")}
              >
                Birthday
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Registration Date
              </th>

              <th
                scope="col"
                onClick={() => requestSort("status")}
                className={getClassNamesFor("status")}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.uid}</td>
                  <td>
                    {/* {el.membership_type.map((val, index) =>
                      val?.get_package_name?.name === "Super" ? (
                        <span className="memb_type super" key={index}>
                          {val?.get_package_name?.name}
                        </span>
                      ) : val?.get_package_name?.name === "Elite" ? (
                        <span className="memb_type elite" key={index}>
                          {val?.get_package_name?.name}
                        </span>
                      ) : val?.get_package_name?.name === "Platinum" ? (
                        <span className="memb_type platinum" key={index}>
                          {val?.get_package_name?.name}
                        </span>
                      ) : (
                        <span className="memb_type others" key={index}>
                          {val?.get_package_name?.name}
                        </span>
                      )
                    )} */}
                    {el.membership_type && el.membership_type.length > 0 ? (
                      el.membership_type.map((val, index) =>
                        val?.get_package_name?.name === "Super" ? (
                          <span className="memb_type super" key={index}>
                            {val?.get_package_name?.name}
                          </span>
                        ) : val?.get_package_name?.name === "Elite" ? (
                          <span className="memb_type elite" key={index}>
                            {val?.get_package_name?.name}
                          </span>
                        ) : val?.get_package_name?.name === "Platinum" ? (
                          <span className="memb_type platinum" key={index}>
                            {val?.get_package_name?.name}
                          </span>
                        ) : (
                          <span className="memb_type others" key={index}>
                            {val?.get_package_name?.name || "N/A"}
                          </span>
                        )
                      )
                    ) : (
                      <span className="memb_type">N/A</span>
                    )}
                  </td>
                  <td>
                    <Link to={"/client-home/" + el.id}>
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>
                  <td>{el.email}</td>
                  <td>{el.phone}</td>
                  <td className="text-capitalize">
                    {el.gender ? el.gender : "N/A"}
                  </td>
                  <td>{el.address}</td>
                  <td>{el.city}</td>

                  {/* <td>{el.country}</td>
                  <td>{el.postal_code}</td> */}
                  {/* <td>{el.birthday}</td> */}
                  <td>
                    {el.birthday !== "0000-00-00"
                      ? moment(el.birthday).format("MM-DD-YYYY")
                      : "N/A"}
                  </td>
                  {/* <td>{el.date}</td> */}
                  <td>{moment(el.date).format("MM-DD-YYYY")}</td>
                  <td>
                    {el.status === "1" ? (
                      <span className="active-btn-2">
                        {" "}
                        <i class="bi bi-check-circle text-success"></i>{" "}
                        <span className="text-success inative-text ms-1">
                          Active
                        </span>
                      </span>
                    ) : (
                      <span className="inactive-btn-2">
                        <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Inactive
                        </span>
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
    report: "",
    options: "Detail",
    memType: "",
    status: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Clients Report </h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="link-hirarcy">
                  <span className="text-success">Reports</span> » Clients
                </div>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Filter By Gender</label>
                    <select
                      className="form-select"
                      name="report"
                      value={values.report}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">All Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Others</option>
                    </select>
                    {/* <span className="text-danger">error message</span> */}
                    {errors.report && touched.report ? (
                      <p className="text-danger">{errors.report}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Filter By Client Status
                    </label>
                    <select
                      className="form-select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">All Client</option>
                      <option value="1">Active Clients</option>
                      <option value="0">Inactive Clients</option>
                    </select>
                    {/* <span className="text-danger">error message</span> */}
                    {errors.status && touched.status ? (
                      <p className="text-danger">{errors.status}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Filter By Membership Type
                    </label>

                    {membershipType.length ? (
                      <select
                        className="form-select"
                        name="memType"
                        value={values.memType}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {membershipType.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="memType"
                        value={values.memType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Membership type</option>
                      </select>
                    )}

                    {errors.memType && touched.memType ? (
                      <p className="text-danger">{errors.memType}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="options"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="options"
                          className="option[17]"
                          value="Detail"
                          defaultChecked={true}
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="" />
                <div className="col-md-4 col-lg-5" />
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5" />
              </form>
              {show && (
                <div className="row">
                  {option === "Detail" ? (
                    <>
                      <div className="table-responsive mt-3">
                        <Table data={myData} rowsPerPage={limit} />
                      </div>
                    </>
                  ) : (
                    <div className="table-responsive mt-3">
                      <table className="table table-striped table-borderless table-style-1">
                        <thead>
                          <tr>
                            <th scope="col" className="no_sort">
                              Sr#
                            </th>
                            <th scope="col" className="no_sort">
                              Total Clients
                            </th>
                            <th scope="col" className="no_sort">
                              Start Date
                            </th>
                            <th scope="col" className="no_sort">
                              End Date
                            </th>
                            <th scope="col" className="no_sort">
                              Client Type
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>{totalResults} Clients</td>
                            <td>{moment(values.sdate).format("MM-DD-YYYY")}</td>
                            <td>{moment(values.edate).format("MM-DD-YYYY")}</td>
                            <td>
                              {values.report === "" ? "All" : values.report}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ClientsReports;
