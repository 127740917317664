import { createContext, useReducer } from "react";
import secureLocalStorage from "react-secure-storage";
// import NoteContext from "./noteContext";

export const NoteState = createContext();
const accessToken = localStorage.getItem("access-token");
const branch_id = secureLocalStorage.getItem("branchId");
const UId = secureLocalStorage.getItem("UId");
const role = secureLocalStorage.getItem("role");

const initialState = {
  accessToken: accessToken ? JSON.parse(accessToken) : null,
  branch_id: branch_id ? JSON.parse(branch_id) : null,
  UId: UId ? JSON.parse(UId) : null,
  role: role ? JSON.parse(role) : null,
};

function reducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("access-token", JSON.stringify(action.accessToken));
      secureLocalStorage.setItem("branchId", JSON.stringify(action.branchId));
      secureLocalStorage.setItem("UId", JSON.stringify(action.UId));
      secureLocalStorage.setItem("role", JSON.stringify(action.role));
      return {
        ...state,
        accessToken: action.accessToken,
        branch_id: action.branchId,
        UId: action.UId,
        role: action.role,
      };
    case "LOGOUT":
      localStorage.removeItem("access-token"); 
      localStorage.removeItem("branchId"); 
      localStorage.removeItem("UId"); 
      localStorage.removeItem("role"); 
      return { ...state, accessToken: null, branch_id: null, UId: null, role: null };
    default:
      return state;
  }
}
export function UserProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <NoteState.Provider value={value}>{props.children}</NoteState.Provider>
  );
}
