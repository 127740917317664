import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";

import UserServices from "../../../UserServices/UserServices";
import { NoteState } from "../../../context/notes/NoteState";
import { planMulti } from "../../schemas";

export default function UpdateFitnessPlanForm({
  formDataIndex,
  onFormChange,
  fitnessPlanError,
  idUnit,
  removeForm,
  catgries,
  initValue,
}) {
  const [subCatgries, setSubCatgries] = useState([]);
  const [exercises, setExercises] = useState([]);

  const { state } = useContext(NoteState);
  const { branch_id } = state;

  const getSubCategories = async (e) => {
    var sb_catgry = e;
    if (sb_catgry !== "") {
      try {
        const responce = await UserServices.SubCategoriesDropdoenName(
          sb_catgry,
          branch_id
        );
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setSubCatgries([...results]);
      } catch (err) {
        setSubCatgries([{ name: "No record found", id: "" }]);
        console.log(err);
      }
    } else {
      setSubCatgries([{ name: "No record found", id: "" }]);
    }
  };

  const getExerciseListing = async (e) => {
    var sb_catgry = e;
    if (sb_catgry !== "") {
      try {
        const responce = await UserServices.exercisesDropdoenName(sb_catgry);
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setExercises([...results]);
      } catch (err) {
        setExercises([{ name: "No record found", id: "" }]);
        console.log(err);
      }
    } else {
      setExercises([{ name: "No record found", id: "" }]);
    }
  };

  useEffect(() => {
    if (initValue?.category_id) {
      getSubCategories(initValue.category_id);
    }
    if (initValue?.sub_category_id) {
      getExerciseListing(initValue.sub_category_id);
    }
  }, []);

  const initialValues = initValue;

  const { values, errors, touched, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: planMulti,
    onSubmit: "",
  });

  return (
    <>
      {formDataIndex === 0 && (
        <thead>
          <tr>
            <th scope="col" className="no_sort">
              Day
            </th>
            <th scope="col" className="no_sort" width="8%">
              Training Type
            </th>
            <th scope="col" className="no_sort" width="8%">
              Exercise type
            </th>
            <th scope="col" className="no_sort">
              Exercise
            </th>
            <th scope="col" className="no_sort">
              Sets
            </th>
            <th scope="col" className="no_sort">
              Reps
            </th>
            <th scope="col" className="no_sort">
              Duration
            </th>
            <th scope="col" className="no_sort">
              Intensity
            </th>
            <th scope="col" className="no_sort">
              Work Interval
            </th>
            <th scope="col" className="no_sort">
              Rest Interval
            </th>
            <th scope="col" className="no_sort">
              Description
            </th>
            <th scope="col" className="no_sort"></th>
          </tr>
        </thead>
      )}
      <tbody>
        <tr>
          <td>
            <select
              className="form-select"
              name="day"
              value={values.day || ""}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "day");
              }}
            >
              <option value="">Select Day</option>
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </select>

            {errors.day && touched.day ? (
              <p className="text-danger">{errors.day}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["day"] &&
                  fitnessPlanError["day"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            {catgries ? (
              <select
                className="form-select"
                name="category_id"
                value={values.category_id || ""}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "category_id");

                  getSubCategories(e.target.value);
                  values.sub_category_id = "";
                  onFormChange(formDataIndex, "", "sub_category_id");
                  values.exercise_id = "";
                  onFormChange(formDataIndex, "", "exercise_id");
                }}
              >
                {catgries.map((el, index) => {
                  return (
                    <option key={index} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            ) : null}
            {errors.category_id && touched.category_id ? (
              <p className="text-danger">{errors.category_id}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["category_id"] &&
                  fitnessPlanError["category_id"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            {subCatgries ? (
              <select
                className="form-select"
                name="sub_category_id"
                value={values.sub_category_id || ""}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(
                    formDataIndex,
                    e.target.value,
                    "sub_category_id"
                  );
                  getExerciseListing(e.target.value);
                  values.exercise_id = "";
                  onFormChange(formDataIndex, "", "exercise_id");
                }}
              >
                <option value="">Select Option</option>
                {subCatgries.map((el, index) => {
                  return (
                    <option key={index} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <select
                className="form-select"
                name="sub_category_id"
                value={values.sub_category_id}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(
                    formDataIndex,
                    e.target.value,
                    "sub_category_id"
                  );
                }}
              >
                <option>Select Sub Category</option>
              </select>
            )}
            {errors.sub_category_id && touched.sub_category_id ? (
              <p className="text-danger">{errors.sub_category_id}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["sub_category_id"] &&
                  fitnessPlanError["sub_category_id"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            {exercises ? (
              <select
                className="form-select"
                name="exercise_id"
                value={values.exercise_id || ""}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "exercise_id");
                }}
              >
                <option value="">Select Option</option>
                {exercises.map((el, index) => {
                  return (
                    <option key={index} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <select
                className="form-select"
                name="exercise_id"
                value={values.exercise_id}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "exercise_id");
                }}
              >
                <option>Select Sub Category</option>
              </select>
            )}
            {errors.exercise_id && touched.exercise_id ? (
              <p className="text-danger">{errors.exercise_id}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["exercise_id"] &&
                  fitnessPlanError["exercise_id"].map((err) => err)}
              </span>
            ) : null}
          </td>

          <td>
            <input
              type="number"
              className="form-control"
              placeholder="Enter Sets"
              min={0}
              onWheel={(e) => e.target.blur()}
              name="sets"
              value={values.sets}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "sets");
              }}
            />
            {errors.sets && touched.sets ? (
              <p className="text-danger">{errors.sets}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["sets"] &&
                  fitnessPlanError["sets"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            <input
              onWheel={(e) => e.target.blur()}
              type="number"
              className="form-control"
              placeholder="Enter Reps"
              name="reps"
              min={0}
              value={values.reps}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "reps");
              }}
            />
            {errors.reps && touched.reps ? (
              <p className="text-danger">{errors.reps}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["reps"] &&
                  fitnessPlanError["reps"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            <input
              onWheel={(e) => e.target.blur()}
              type="number"
              className="form-control"
              placeholder="Enter Duration"
              name="duration"
              min={0}
              value={values.duration}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "duration");
              }}
            />
            {errors.duration && touched.duration ? (
              <p className="text-danger">{errors.duration}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["duration"] &&
                  fitnessPlanError["duration"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            <select
              className="form-select"
              name="intensity"
              value={values.intensity || ""}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "intensity");
              }}
            >
              <option value="">Select Option</option>
              <option value="55% - 65% of MHR">55% - 65% of MHR</option>
              <option value="65% - 75% of MHR">65% - 75% of MHR</option>
              <option value="75% - 85% of MHR">75% - 85% of MHR</option>
            </select>

            {errors.intensity && touched.intensity ? (
              <p className="text-danger">{errors.intensity}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["intensity"] &&
                  fitnessPlanError["intensity"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            <input
              onWheel={(e) => e.target.blur()}
              type="number"
              className="form-control"
              placeholder="Enter Work Interval"
              name="work_interval"
              min={0}
              value={values.work_interval}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "work_interval");
              }}
            />
            {errors.work_interval && touched.work_interval ? (
              <p className="text-danger">{errors.work_interval}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["work_interval"] &&
                  fitnessPlanError["work_interval"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            <input
              onWheel={(e) => e.target.blur()}
              type="number"
              className="form-control"
              placeholder="Enter Rest Interval"
              name="rest_interval"
              min={0}
              value={values.rest_interval}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "rest_interval");
              }}
            />
            {errors.rest_interval && touched.rest_interval ? (
              <p className="text-danger">{errors.rest_interval}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["rest_interval"] &&
                  fitnessPlanError["rest_interval"].map((err) => err)}
              </span>
            ) : null}
          </td>
          <td>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Description"
              name="description"
              value={values.description}
              onChange={(e) => {
                handleChange(e);
                onFormChange(formDataIndex, e.target.value, "description");
              }}
            />
            {errors.description && touched.description ? (
              <p className="text-danger">{errors.description}</p>
            ) : fitnessPlanError ? (
              <span className="text-danger">
                {fitnessPlanError["description"] &&
                  fitnessPlanError["description"].map((err) => err)}
              </span>
            ) : null}
          </td>

          <td>
            {formDataIndex === 0 ? null : (
              <button
                type="button"
                className="ms-2 inactive-btn"
                onClick={() => {
                  removeForm(idUnit);
                }}
              >
                <i className="bi bi-x-circle text-danger"></i>{" "}
              </button>
            )}
          </td>
        </tr>
      </tbody>
    </>
  );
}
