import React, { useContext, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { clientPreAssesment } from "../schemas";
import $ from "jquery";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { Link } from "react-router-dom";

const AddPreAssmnt = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [heartCond, setheartCond] = useState("");
  const [cheastPain, setcheastPain] = useState("");
  const [lightHeated, setlightHeated] = useState("");
  const [injuries, setinjuries] = useState("");
  const [medicalSupervision, setmedicalSupervision] = useState("");
  const [pregnant, setpregnant] = useState("");
  const [takingMedicine, settakingMedicine] = useState("");
  const [smokingHabit, setsmokingHabit] = useState("");
  const [healthClubMember, sethealthClubMember] = useState("");
  const [havePersonalTrainer, sethavePersonalTrainer] = useState("");
  const [pulseRate, setpulseRate] = useState("");
  const [recoveryRate, setrecoveryRate] = useState("");
  const [exerciseDays, setexerciseDays] = useState("");
  const [clientName, setclientName] = useState("...");
  const [GoalError, setGoalError] = useState("");
  const [QuesError, setQuesError] = useState("");
  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      const responce = await UserServices.getClientsNameById(branch_id, id);

      setclientName(
        responce.data.data[0].first_name + " " + responce.data.data[0].last_name
      );
    } catch (err) {}
  };
  useEffect(() => {
    const getAssessedClient = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getAssessedClient(id);
        console.log(responce);
        if (responce.status === 200) {
          navigate("/viewAssessment/" + id);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);

        getUsers();
      }
    };
    getAssessedClient();
  }, []);

  const initialValues = {
    Height: "",
    Weight: "",
    Waist: "",
    Glutes: "",
    Fat: "",
    BMI: "",
    Recovery: "",
    Resting: "",
  };

  const addClientAssessment = async (values, goals) => {
    try {
      setLoading(true);

      const response = await UserServices.clientPreAssessment(
        values,
        goals,
        id,
        heartCond,
        cheastPain,
        lightHeated,
        injuries,
        medicalSupervision,
        pregnant,
        takingMedicine,
        smokingHabit,
        healthClubMember,
        havePersonalTrainer,
        exerciseDays,
        pulseRate,
        recoveryRate
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false);
      navigate("/viewAssessment/" + id);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  //validation........................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: clientPreAssesment,
      onSubmit: (values) => {
        var goals = $(".you_want.active")
          .map(function () {
            return this.value;
          })
          .get()
          .join(",");

        if (
          goals === "" &&
          heartCond === "" &&
          cheastPain === "" &&
          pregnant === "" &&
          lightHeated === "" &&
          takingMedicine === "" &&
          injuries === "" &&
          medicalSupervision === "" &&
          smokingHabit === "" &&
          healthClubMember === "" &&
          havePersonalTrainer === "" &&
          exerciseDays === ""
        ) {
          setGoalError(
            "Please selet atleast one result that you want to achieve"
          );
          setQuesError("Please awnser all questions");
        } else if (goals === "") {
          setGoalError(
            "Please selet atleast one result that you want to achieve"
          );
          setQuesError("");
        } else if (
          heartCond === "" ||
          cheastPain === "" ||
          pregnant === "" ||
          lightHeated === "" ||
          takingMedicine === "" ||
          injuries === "" ||
          medicalSupervision === "" ||
          smokingHabit === "" ||
          healthClubMember === "" ||
          havePersonalTrainer === "" ||
          exerciseDays === ""
        ) {
          setQuesError("Please awnser all questions");
          setGoalError("");
        } else {
          setGoalError("");
          setQuesError("");
          addClientAssessment(values, goals);
        }
      },
    });

  const selectGoat = (e) => {
    $(".multi-button").addClass("active");
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
    } else {
      e.currentTarget.classList.add("active");
    }
  };

  // $(".you_want").click(function (e) {
  //   $(this).addClass("active");

  //   $(".multi-button").addClass("active");
  // });

  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Client: {clientName}
                <Link
                  to={-1}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </Link>
                <Link
                  to={"/invoice/assessment/" + id}
                  className="btn btn-primary d-inline-block float-end btn-sm me-2"
                >
                  <i className="bi bi-printer" /> Quick Slip
                </Link>
                <div className="clearfix" />
              </h5>
            </div>
            <div className="card-body">
              <div className="col-12">
                <h5 className="mb-3 font-20">Normal Ranges</h5>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Fat% Male : <span>10% - 20%</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Recovery Pulse : <span>97 - 115</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Sit & Reach : <span>100 -+05</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Fat% Female : <span>15% - 25%</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    PUSG Up : <span>15 - 25</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Sit Up : <span>15 - 30</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Resting Pulse: <span>15 - 25</span>
                  </div>
                </div>
                <hr />
                <div className="col-12 mb-2">
                  <h5>
                    What result would you like to achieve with your fitness
                    training ? Please Select
                  </h5>
                </div>
                <div className="col-12 mb-3">
                  <div className="row quick multi-button custom-multi-button ">
                    <div className="col-12 want-wht">
                      <input
                        type="button"
                        defaultValue="Reduce Body FA%"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Agility"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Body Tone"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Balance"
                        check-val="lastYear"
                        className="you_want "
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Strength Training"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Core Strength"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Reshaping"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Weight Gain"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                      <input
                        type="button"
                        defaultValue="Flexibility"
                        check-val="lastYear"
                        className="you_want"
                        onClick={selectGoat}
                      />
                    </div>
                  </div>
                  {GoalError === "" ? null : (
                    <div className="alert alert-danger">
                      <strong>{GoalError}</strong>
                    </div>
                  )}
                </div>
                <hr />

                <form onSubmit={handleSubmit}>
                  <div className="col-12 mb-2">
                    <div className="row">
                      <div className="col-lg-12">
                        <h5 className="mb-3 mt-3 mt-lg-0">
                          Fitness Appointment Details
                        </h5>
                        <div className="row">
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Height </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Height"
                                  name="Height"
                                  value={values.Height || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                              {errors.Height && touched.Height ? (
                                <p className="text-danger">{errors.Height}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Weight </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Weight"
                                  name="Weight"
                                  value={values.Weight || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="input-group-text">Kg</span>
                              </div>
                              {errors.Weight && touched.Weight ? (
                                <p className="text-danger">{errors.Weight}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Waist </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Waist"
                                  name="Waist"
                                  value={values.Waist || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="input-group-text">Inches</span>
                              </div>
                              {errors.Waist && touched.Waist ? (
                                <p className="text-danger">{errors.Waist}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Glutes </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Glutes"
                                  name="Glutes"
                                  value={values.Glutes || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                              {errors.Glutes && touched.Glutes ? (
                                <p className="text-danger">{errors.Glutes}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Body Fat </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Body Fat"
                                  name="Fat"
                                  value={values.Fat || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="input-group-text">
                                  Body Fat %
                                </span>
                              </div>
                              {errors.Fat && touched.Fat ? (
                                <p className="text-danger">{errors.Fat}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4  mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">BMI </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="BMI"
                                  name="BMI"
                                  value={values.BMI || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="input-group-text">kg/m2.</span>
                              </div>
                              {errors.BMI && touched.BMI ? (
                                <p className="text-danger">{errors.BMI}</p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-2">
                    {QuesError === "" ? null : (
                      <div className="alert alert-danger">
                        <strong>{QuesError}</strong>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-6">
                        <h5 className="mb-3">Physical Activity Readiness</h5>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Have you ever been diagnosed with a heart
                                condition?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="heart"
                                    onClick={() => {
                                      setheartCond("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="heart"
                                    onClick={() => {
                                      setheartCond("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have any chest pain brought on by
                                physical activity?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="physical"
                                    onClick={() => {
                                      setcheastPain("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="physical"
                                    onClick={() => {
                                      setcheastPain("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you feel light headed or dizzy when
                                excercising?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="excercising"
                                    onClick={() => {
                                      setlightHeated("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="excercising"
                                    onClick={() => {
                                      setlightHeated("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have any bone or joint problems /
                                injuries?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="injuries"
                                    onClick={() => {
                                      setinjuries("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="injuries"
                                    onClick={() => {
                                      setinjuries("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you aware of any reason against excercise
                                without medical supervision?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="supervision"
                                    onClick={() => {
                                      setmedicalSupervision("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="supervision"
                                    onClick={() => {
                                      setmedicalSupervision("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Women only: Are you pregnant or nursing?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="nursing"
                                    onClick={() => {
                                      setpregnant("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="nursing"
                                    onClick={() => {
                                      setpregnant("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you taking any prescribed medications that
                                could effect you during excercise (Women;
                                excluded birth control)?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="medications"
                                    onClick={() => {
                                      settakingMedicine("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="medications"
                                    onClick={() => {
                                      settakingMedicine("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-3 mt-lg-0">Healthy Habbits</h5>
                        <table className="table table-bordered mb-2">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Do you smoke or use tobacco? ? </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="smoke"
                                    onClick={() => {
                                      setsmokingHabit("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="smoke"
                                    onClick={() => {
                                      setsmokingHabit("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Are you a member of a health club?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="health"
                                    onClick={() => {
                                      sethealthClubMember("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="health"
                                    onClick={() => {
                                      sethealthClubMember("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Do you use a personal trainer?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="trainer"
                                    onClick={() => {
                                      sethavePersonalTrainer("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="trainer"
                                    onClick={() => {
                                      sethavePersonalTrainer("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                How many times do you excercise in a typical
                                week?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="typical"
                                    onClick={() => {
                                      setexerciseDays("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="typical"
                                    onClick={() => {
                                      setexerciseDays("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="col-12 col-lg-12 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Resting Pulse </label>
                            <div className="input-group ">
                              <input
                                type="number"
                                className="form-control"
                                name="Resting"
                                value={values.Resting || ""}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setpulseRate(e.target.value);
                                }}
                              />
                              <span className="input-group-text">
                                1.5 sec. count
                              </span>
                            </div>
                            {errors.Resting && touched.Resting ? (
                              <p className="text-danger">{errors.Resting}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-lg-12 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Recovery Rate </label>
                            <div className="input-group ">
                              <input
                                type="number"
                                className="form-control"
                                name="Recovery"
                                value={values.Recovery || ""}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setrecoveryRate(e.target.value);
                                }}
                              />
                              <span className="input-group-text">
                                1 minute count after
                              </span>
                            </div>
                            {errors.Recovery && touched.Recovery ? (
                              <p className="text-danger">{errors.Recovery}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-end">
                    <button type="submit" className="btn btn-primary px-5 mb-1">
                      ADD
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default AddPreAssmnt;

