import React from "react";

export default function MultiMealAdd({ formDataIndex, onFormChange }) {
  // eslint-disable-next-line

  return (
    <>
      <tr>
        <td rowSpan="5">
          <span className="weekNo">Week {formDataIndex + 1}</span>
        </td>
        <td className="text-uppercase">Breakfast</td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "sunday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "monday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "tuesday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "thursday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "friday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "saturday",
                "breakfast"
              );
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Lunch</td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "tuesday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "wednesday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "thursday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "lunch");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "saturday", "lunch");
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Dinner</td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "tuesday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "dinner"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "thursday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "dinner");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "saturday", "dinner");
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Snacks</td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "tuesday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "snacks"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "thursday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "snacks");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "saturday", "snacks");
            }}
          ></textarea>
        </td>
      </tr>
      <tr>
        <td className="text-uppercase">Munching</td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "sunday", "munching");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "monday", "munching");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "tuesday",
                "munching"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "wednesday",
                "munching"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "thursday",
                "munching"
              );
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(formDataIndex, e.target.value, "friday", "munching");
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control meal-field"
            onBlur={(e) => {
              onFormChange(
                formDataIndex,
                e.target.value,
                "saturday",
                "munching"
              );
            }}
          ></textarea>
        </td>
      </tr>
    </>
  );
}
