import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import Pagination from "react-js-pagination";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { salaryFilter } from "../schemas";
import moment from "moment";

const Renewal = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;

  // Get the current date
  const currentDate = new Date();

  currentDate.setDate(1);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;

  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const getSales = async (e) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        const responce = await UserServices.getSales(
          branch_id,
          values,
          limit,
          "!"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              client_id: values.client_id,
              name: values.client_first_name + " " + values.client_last_name,
              price: values.price,
              date: values.date,
              sale_type: values.sale_type,
            });
          });

          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  useEffect(() => {
    getSales();
  }, []);

  const initialValues = {
    sdate: formattedMonth,
    edate: year,
  };
  //.......................................................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: salaryFilter,
      onSubmit: (values) => {
        getSales();
      },
    });

  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getSales(
          branch_id,
          values,
          limit,
          pageNo
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              client_id: values.client_id,
              name: values.client_first_name + " " + values.client_last_name,
              price: values.price,
              date: values.date,
              sale_type: values.sale_type,
            });
          });

          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getSales(
          branch_id,
          values,
          newLimit,
          "!"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              client_id:values.client_id,
              name: values.client_first_name + " " + values.client_last_name,
              price: values.price,
              date: values.date,
              sale_type: values.sale_type,
            });
          });

          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        {/* {totalResults > limit ? ( */}
          
        {/* ) : null} */}
        <div className="table-responsive mt-3">
          <table className="table table-striped table-borderless table-style-1">
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("id")}
                  className={getClassNamesFor("id")}
                >
                  Order No
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("client_first_name")}
                  className={getClassNamesFor("client_first_name")}
                >
                  Client Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("price")}
                  className={getClassNamesFor("price")}
                >
                  Payment
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("date")}
                  className={getClassNamesFor("date")}
                >
                  Add / Renewal Date
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("sale_type")}
                  className={getClassNamesFor("sale_type")}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                <>
                  {items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td>{el.id}</td>
                      <td>
                      <Link to={"/client-home/" + el.client_id}>
                      {el.name}</Link></td>
                      <td>
                        <CurrencyFormat
                          value={el.price}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td>{moment(el.date).format("DD-MM-YYYY")}</td>

                      <td>
                        <span
                          className={
                            el.sale_type === "Renew"
                              ? "memb_type "
                              : "memb_type_2"
                          }
                        >
                          {el.sale_type}
                        </span>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="6" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}

        <div className="grand-totl-fine">
          <h5>Total Sales</h5>
          <CurrencyFormat
            value={myData.reduce((a, v) => (a = a + v.price), 0)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rs "}
            suffix={"/-"}
            thousandSpacing={"2s"}
          />
        </div>
      </>
    );
  };
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Sales Report{" "}
                <span className="text-danger">
                  {values.sdate === "01"
                    ? "January"
                    : values.sdate === "02"
                    ? "February"
                    : values.sdate === "03"
                    ? "March"
                    : values.sdate === "04"
                    ? "April"
                    : values.sdate === "05"
                    ? "May"
                    : values.sdate === "06"
                    ? "June"
                    : values.sdate === "07"
                    ? "July"
                    : values.sdate === "08"
                    ? "August"
                    : values.sdate === "09"
                    ? "September"
                    : values.sdate === "10"
                    ? "October"
                    : values.sdate === "11"
                    ? "November"
                    : values.sdate === "12"
                    ? "December"
                    : null}{" "}
                  {values.edate}
                </span>
              </h5>
            </div>
            <div className="card-body">
              <form className="row g-3 trainee-add" onSubmit={handleSubmit}>
                <div className="col-12 col-md-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Select Month</label>
                    <select
                      className="form-select"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>

                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Enter Year</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <button
                    type="submit"
                    className="btn btn-primary d-inline-block mt-4"
                  >
                    Generate
                  </button>
                </div>
                <div className="col-md-1 col-lg-1 mt-5 ms-auto">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
              {/* <button
                className="btn btn-primary"
                type="button"
                onClick={() => window.location.reload(false)}
              >
                Reset
              </button> */}
            </div>
          </div>
              </form>
              <Table data={myData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default Renewal;
