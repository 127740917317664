import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { befitPkgSell, trainerPkgSell } from "../schemas";
import moment from "moment";

export default function SellBeFitPkg(props) {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);

  const [clientName, setClientName] = useState("");
  const { id } = useParams();
  const [pkgId, setpkgId] = useState("");

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);

  useEffect(() => {
    const nameClients = async () => {
      try {
        const responce = await UserServices.getClientsNameById(branch_id, id);

        setClientName(
          responce.data.data[0].first_name +
            " " +
            responce.data.data[0].last_name
        );
      } catch (err) {
        console.log(err);
        setClientName("");
      }
    };
    nameClients();
    const getPkgTypes = async () => {
      try {
        const responce = await UserServices.befitPkgTypesList("16");

        const res = responce.data.data;
        getPkgFee(responce?.data?.data[0]?.id);
        setpkgId(responce?.data?.data[0]?.id);
        setFieldValue("pkgType", responce?.data?.data[0]?.name);
      } catch (err) {
        setFieldValue("pkgType", "");
        setpkgId("");
        console.log(err);
      }
    };
    getPkgTypes();
  }, []);

  const getPkgFee = async (e) => {
    var pkg_type = e;
    var sDate = moment(values.sdate).format("YYYY-MM-DD");
    try {
      const responce = await UserServices.pkgFee(pkg_type, sDate, values.qty);

      const sdate = responce.data.startDate;
      const eDate = responce.data.endDate;
      setEndDate(eDate);
      setStartDate(sdate);
    } catch (err) {
      console.log(err);
    }
  };

  const changeStartDate = async (e) => {
    var date = e.target.value;
    var sDate = moment(date).format("YYYY-MM-DD");

    try {
      const responce = await UserServices.pkgFee("761", sDate, values.qty);

      const sdate = responce.data.startDate;
      const eDate = responce.data.endDate;
      setEndDate(eDate);
      setStartDate(sdate);
    } catch (err) {
      console.log(err);
    }
  };

  const sellBeFitPkg = async (values) => {
    setLoading(true);
    try {
      const responce = await UserServices.befitPkgSell(
        branch_id,
        id,
        endDate,
        "16",
        values,
        pkgId
      );
      console.log(responce);
      toast.success("Package Added!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        props.cart();
        props.catagry();
        // window.location.reload(true);
      }, 1500);
    } catch (err) {
      toast.error("Unproccessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };

  const initialValues = {
    client_name: clientName,

    pkgType: "",
    qty: "1",
    discount_type: "Amount",
    discount_amount: "0",
    pay_after_dis: "0",
    sdate: startDate,
    approveBy: "",
    referencedBy: "",
    salesnotes: "",
    salesdate: dateSelected,
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: befitPkgSell,
    onSubmit: (values, action) => {
      sellBeFitPkg(values);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <>
        <div className="card-header py-3">
          <h5 className="mb-0 font-20">Sell Befit Package</h5>
        </div>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  New Clients <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={clientName}
                  disabled={true}
                />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Package <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={values.pkgType}
                  disabled={true}
                />

                {errors.pkgType && touched.pkgType ? (
                  <p className="text-danger">{errors.pkgType}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Start Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter Start date"
                  name="sdate"
                  value={values.sdate}
                  onChange={(e) => {
                    handleChange(e);
                    changeStartDate(e);
                  }}
                  onBlur={handleBlur}
                />

                {errors.sdate && touched.sdate ? (
                  <p className="text-danger">{errors.sdate}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  End Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter End date"
                  value={endDate}
                  disabled={true}
                />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  Referenced By
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="referencedBy"
                  value={values.referencedBy}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />

                {errors.referencedBy && touched.referencedBy ? (
                  <p className="text-danger">{errors.referencedBy}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  Sales Notes
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="salesnotes"
                  value={values.salesnotes}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />

                {errors.salesnotes && touched.salesnotes ? (
                  <p className="text-danger">{errors.salesnotes}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  Sale Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="salesdate"
                  value={values.salesdate}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />

                {errors.salesdate && touched.salesdate ? (
                  <p className="text-danger">{errors.salesdate}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>

      <div className="col-12 text-center mb-3">
        <button
          type="submit"
          className="btn btn-primary px-5 mb-1 me-2 addclient-button"
        >
          Sell Befit Package
        </button>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </form>
  );
}
