/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import CurrencyFormat from "react-currency-format";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import CafeNav from "../Includes/Navbar/CafeNav";
import CafeSidebar from "../Includes/Sidebar/CafeSidebar";
import CafeMenu from "./CafeMenu";

export default function CafeCart() {

  const [loading, setLoading] = useState(false);
  const [myCart, setMyCart] = useState([]);

  const getCart = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getCafeCart();
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            package_name: value.package_name,
            price: value.price,
            package_price: value.package_price,
            image: value.image,
            quantity: value.quantity,
          });
          return null;
        });

        setMyCart([...results]);

        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setMyCart([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getCart();
  }, []);

  const deleteRecord = async (record_id) => {
    try {
      setLoading(true);
      const responce = await UserServices.deleteClientCartItem(record_id);

      toast.success("Item removed successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getCart();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleQuantityChange = async (itemId, value, price) => {
    if (value < 1) {
      value = 1; // Set minimum quantity to 1
      deleteRecord(itemId);
    } else {
      setMyCart((prevCartProducts) =>
        prevCartProducts.map((product) =>
          product.id === itemId ? { ...product, quantity: value } : product
        )
      );

      try {
        const responce = await UserServices.updateCafeCart(
          itemId,
          value,
          price
        );
        let myDataarr;

        if (responce.status === 200) {
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  let total = 0;

  return (
    <div className="wrapper">
      <CafeNav />
      <CafeSidebar />
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <CafeMenu />
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  <caption className="cart-caption">Cart</caption>
                  <table className="table align-middle mb-0 border cart-detail-table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myCart.length ? (
                        <>
                          {myCart.map((el, index) => (
                            <tr key={index}>
                              <td>
                                <div className="orderlist">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="product-box">
                                      <img src={el.image} alt="" />
                                    </div>
                                    <div>
                                      <p className="mb-0 product-title">
                                        {el.package_name}
                                        <span>
                                          <CurrencyFormat
                                            value={el.package_price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandSpacing={"2s"}
                                            prefix={"Rs "}
                                            suffix={"/-"}
                                            renderText={(value) => <>{value}</>}
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="qty-number">
                                  <span
                                    className="minus"
                                    onClick={() =>
                                      handleQuantityChange(
                                        el.id,
                                        el.quantity - 1,
                                        el.package_price
                                      )
                                    }
                                  >
                                    -
                                  </span>
                                  <input
                                    type="number"
                                    value={el.quantity}
                                    readOnly={true}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        el.id,
                                        parseInt(e.target.value),
                                        el.package_price
                                      )
                                    }
                                    className="quantity"
                                  />
                                  <span
                                    className="plus"
                                    onClick={() =>
                                      handleQuantityChange(
                                        el.id,
                                        el.quantity + 1,
                                        el.package_price
                                      )
                                    }
                                  >
                                    +
                                  </span>
                                </div>
                              </td>
                              <td className="position-relative">
                                <span className="d-none">
                                  {
                                    (total =
                                      total + el.package_price * el.quantity)
                                  }
                                </span>
                                <CurrencyFormat
                                  value={el.package_price * el.quantity}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  thousandSpacing={"2s"}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <>{value}</>}
                                />
                                <button
                                  className="remove-item-cafe"
                                  onClick={() => {
                                    deleteRecord(el.id);
                                  }}
                                >
                                  <i className="bi bi-x"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <td colSpan="3" align="center">
                          No product added in cart
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4 position-relative">
                  <div className="cart-sticky">
                    <table className="table align-middle mb-0 border cart-detail-side caption-top">
                      <caption>Cart Totals</caption>
                      <tbody>
                        <tr>
                          <td>Stotal</td>
                          <td>
                            <strong>
                              <CurrencyFormat
                                value={myCart.reduce(
                                  (a, v) => (a = a + v.price),
                                  0
                                )}
                                displayType={"text"}
                                thousandSpacing={"3s"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Toal</td>
                          <td>
                            <strong>
                              <CurrencyFormat
                                value={total}
                                displayType={"text"}
                                thousandSpacing={"3s"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Link to="/cafe_checkout" className="add-cart cart-proceed">
                      Proceed To Checkout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
