import React, { useContext, useState, useEffect } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NoteState } from "../../context/notes/NoteState";
import useTable from "../../hooks/useTable";
import moment from "moment";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const ViewStfCrd = () => {
  const { state } = useContext(NoteState);
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const { branch_id } = state;

  const getUsers = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getStfCrd(branch_id);

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            number: values.number,
            description: values.description,
            date: values.date,
            status: values.status,
            first_name: values.first_name,
            last_name: values.last_name,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers();
  }, []);
  const makeActive = async (card_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.activeCard(card_Id);
      if (responce.status === 204) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Unblocked successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeCard = (card_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to Unblock this card ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Unblock it!",
    }).then((result) => {
      if (result.isConfirmed) {
        makeActive(card_Id);
      }
    });
  };
  const makeInActive = async (card_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inactiveCard(card_Id);
      console.log(responce);
      if (responce.status === 204) {
        setLoading(false);

        Swal.fire({
          title: "Success!",
          text: " Blocked successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveCard = (package_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to block this card ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Block it!",
    }).then((result) => {
      if (result.isConfirmed) {
        makeInActive(package_Id);
      }
    });
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteCards(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  let searchTimer;
  const searchPkg = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_numbr = e.target.value;
      setLoading(true);

      try {
        const response = await UserServices.searchStaffCards(
          search_numbr,
          branch_id
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              number: values.number,
              description: values.description,
              date: values.date,
              status: values.status,
              member_type: values.member_type,
              first_name: values.first_name,
              last_name: values.last_name,
            });
            return null;
          });
          setMyData([...results]);

          setLimit(response.data.data.per_page);
          setTotalResults(response.data.totalRecord);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getStfCrdData(
          limit,
          // "1",
          pageNo,
          branch_id
        );
        console.log(responce);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              number: values.number,
              description: values.description,
              date: values.date,
              status: values.status,
              first_name: values.first_name,
              last_name: values.last_name,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getStfCrdData(
          newLimit,
          "1",
          1,
          branch_id
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              number: values.number,
              description: values.description,
              date: values.date,
              status: values.status,
              first_name: values.first_name,
              last_name: values.last_name,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("section_tables.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS-2"
          data-caption="Staff Cards
          "
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("number")}
                className={getClassNamesFor("number")}
              >
                Card Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("occurrence_date")}
                className={getClassNamesFor("occurrence_date")}
              >
                Assigning Date
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    {el.first_name} {el.last_name}
                  </td>
                  <td>{el.number}</td>
                  <td>{el.description ? el.description : "N/A"}</td>
                  {/* <td>{el.date}</td> */}
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>

                  <td>
                    {el.status === "1" ? (
                      <button
                        className="block-btn"
                        onClick={() => inactiveCard(el.id)}
                      >
                        <i class="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Block
                        </span>
                      </button>
                    ) : (
                      <button
                        className="active-btn"
                        onClick={() => activeCard(el.id)}
                      >
                        <i className="bi bi-check2-circle text-success"></i>
                        <span className="text-success inative-text ms-1">
                          Unblock
                        </span>
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <div className="card-body">
        <div className="row">
          <div className="col-md-2 col-lg-2 ms-2 ">
            <div className="form-group custom-group">
              <label className="form-label">Search By Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Number"
                onChange={(e) => {
                  searchPkg(e);
                }}
              />
            </div>
          </div>
          <div className="col-md-1 col-lg-1 mt-4">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div className=" col-md-2 mt-4 ms-auto text-end">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS-2"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
          </div>
        </div>
        <div className="table-responsive mt-3">
          <Table data={myData} rowsPerPage={limit} />
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ViewStfCrd;
