import React from 'react';
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import Webcam from 'react-webcam';
import {useState } from 'react';
import MenuButton from '../MenuButton/MenuButton'; 
import {  Link, useNavigate } from 'react-router-dom';



const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}
const Capture = () => {
  const [picture, setPicture] = useState('')
  const webcamRef = React.useRef(null)
  const capture = (() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc)
  })
  const navigate = useNavigate();

  return (
    <div className='wrapper'>
       <Navbar/>
      <Sidebar/>
      <main className="page-content customer-contnent">
      <div className="site-main-design">
      <MenuButton/>
      <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Capture with webcam
                <Link to="/addclients"
                  className="btn btn-danger d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-x" /> Cancel
                </Link>
              </h5>
            </div>
             <div className='card-body'>
      <div>
        {picture === '' ? (
          <Webcam
            audio={false}
            height={400}
            ref={webcamRef}
            width={400}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            className="webcam"
          />
        ) : (
          <img src={picture} alt="" />
        )}
      </div>
      <div>
        {picture !== '' ? (
          <button
            onClick={()=>{navigate("/addclients")
            capture()}}
            className="btn btn-primary mt-2"
          >
            Retake
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault()
              capture()
            }}
            className="btn btn-danger custom-butn3"
          >
            Capture
          </button>
        )}
      </div>
    </div>
          </div>
     
      </div>
      </main>
    </div>

  )
}

export default Capture