import React, { useContext, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { clientPreAssesment, searchClint } from "../schemas";
import $ from "jquery";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { Link } from "react-router-dom";

const ClientAssesment = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  // const [clients, setClients] = useState([]);

  const [heartCond, setheartCond] = useState("");
  const [cheastPain, setcheastPain] = useState("");
  const [pastPain, setpastPain] = useState("");
  const [lightHeated, setlightHeated] = useState("");
  const [injuries, setinjuries] = useState("");
  const [medicalSupervision, setmedicalSupervision] = useState("");
  const [pregnant, setpregnant] = useState("");
  const [currentMedicine, setcurrentMedicine] = useState("");

  const [takingMedicine, settakingMedicine] = useState("");
  const [smokingHabit, setsmokingHabit] = useState("");
  const [healthClubMember, sethealthClubMember] = useState("");
  const [havePersonalTrainer, sethavePersonalTrainer] = useState("");
  // const [pulseRate, setpulseRate] = useState("");
  // const [recoveryRate, setrecoveryRate] = useState("");
  const [exerciseDays, setexerciseDays] = useState("");
  const [clientName, setclientName] = useState("...");
  const [GoalError, setGoalError] = useState("");
  const [QuesError, setQuesError] = useState("");

  const [triedPlan, setTriedPlan] = useState("");

  const [gymMember, setGymMember] = useState("");

  const [followingDiet, setFollowingDiet] = useState("");
  const [trainingType, setTrainingType] = useState("");
  const [currentSupliments, setCurrentSupliments] = useState("");

  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      const responce = await UserServices.getClientsNameById(branch_id, id);

      setclientName(
        responce.data.data[0].first_name + " " + responce.data.data[0].last_name
      );
    } catch (err) {}
  };
  useEffect(() => {
    const getAssessedClient = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getAssessedClient(id);

        if (responce.status === 200) {
          navigate("/viewAssessment/" + id);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);

        getUsers();
      }
    };

    getAssessedClient();
  }, []);

  const initialValues1 = {
    bySearch: "",
    name: "",
    branch_id: branch_id,
  };

  const initialValues2 = {
    branch_id: branch_id,
    reason: "no",
    timeBreakfast: "",
    timeLunch: "",
    timeDinner: "",
    timeSnack: "",
    timeMunching: "",
    specBreakfast: "",
    specLunch: "",
    specDinner: "",
    specSnack: "",
    specMunching: "",
    waterIntake: "",
    foodNotPrefer: "",
    foodAllergies: "",
    foodPrefer: "",
  };

  // const searchClient = async (values) => {
  //   values.searchVal = values.name;
  //   const response = await UserServices.clientSearch(
  //     values,
  //     branch_id,
  //     "1",
  //     1000,
  //     "1"
  //   );
  //   const { data } = response;
  //   const { data: innerData } = data;
  //   const { data: nestedData } = innerData;
  //   console.log(nestedData);
  //   setClients(nestedData);
  // };

  const addClientAssessment = async (values, goals) => {
    try {
      setLoading(true);

      const response = await UserServices.clientPreAssessment(
        values,
        goals,
        id,
        heartCond,
        cheastPain,
        lightHeated,
        injuries,
        medicalSupervision,
        pregnant,
        takingMedicine,
        smokingHabit,
        healthClubMember,
        havePersonalTrainer,
        exerciseDays,
        pastPain,
        currentMedicine,
        triedPlan,
        gymMember,
        followingDiet,
        currentSupliments,
        trainingType
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false);
      navigate("/viewAssessment/" + id);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  //validation........................................................................................
  // const { values, errors, touched, handleBlur, handleChange, handleSubmit } =

  // const form1 = useFormik({
  //   initialValues: initialValues1,
  //   validationSchema: searchClint,
  //   onSubmit: (values) => {
  //     searchClient(values);
  //   },
  // });

  const form2 = useFormik({
    initialValues: initialValues2,
    // validationSchema: clientPreAssesment,
    onSubmit: (values) => {
      var goals = $(".you_want.active")
        .map(function () {
          return this.value;
        })
        .get()
        .join(",");

      if (
        heartCond === "" ||
        cheastPain === "" ||
        pregnant === "" ||
        lightHeated === "" ||
        takingMedicine === "" ||
        injuries === "" ||
        medicalSupervision === "" ||
        smokingHabit === "" ||
        healthClubMember === "" ||
        havePersonalTrainer === "" ||
        exerciseDays === "" ||
        pastPain === "" ||
        currentMedicine === "" ||
        triedPlan === "" ||
        gymMember === "" ||
        followingDiet === "" ||
        currentSupliments === "" ||
        trainingType === ""
      ) {
        setQuesError("Please awnser all questions");
        setGoalError("");
      } else if (goals === "") {
        setGoalError(
          "Please selet atleast one result that you want to achieve"
        );
        setQuesError("");
      } else {
        setGoalError("");
        setQuesError("");
        addClientAssessment(values, goals);
      }
    },
  });

  const selectGoat = (e) => {
    $(".multi-button").addClass("active");
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
    } else {
      e.currentTarget.classList.add("active");
    }
  };

  // $(".you_want").click(function (e) {
  //   $(this).addClass("active");

  //   $(".multi-button").addClass("active");
  // });

  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Client: {clientName}
                <Link
                  to={-1}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </Link>
                {/* <Link
                  to={"/invoice/assessment/" + id}
                  className="btn btn-primary d-inline-block float-end btn-sm me-2"
                >
                  <i className="bi bi-printer" /> Quick Slip
                </Link> */}
                <div className="clearfix" />
              </h5>
            </div>
            <div className="card-body">
              <div className="col-12">
                <h5 className="mb-3 font-20">Normal Ranges</h5>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Fat% Male : <span>10% - 16%</span>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Fat% Female : <span>14% - 22%</span>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    BMI Male: <span>18 - 25</span>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    BMI Female: <span>18 - 25</span>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="expenses-num">
                    Resting HR: <span>60Bpm to 80Bpm</span>
                  </div>
                </div>

                <form onSubmit={form2.handleSubmit}>
                  {/* <div className="col-12 mb-2">
                    <div className="row">
                      <div className="col-lg-12">
                        <h5 className="mb-3 mt-3 mt-lg-0">
                          Fitness Appointment Details
                        </h5>
                        <div className="row">
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Height </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Height"
                                  name="Height"
                                  value={form2.values.Height || ""}
                                  onChange={form2.handleChange}
                                  onBlur={form2.handleBlur}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                              {form2.errors.Height && form2.touched.Height ? (
                                <p className="text-danger">
                                  {form2.errors.Height}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Weight </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Weight"
                                  name="Weight"
                                  value={form2.values.Weight || ""}
                                  onChange={form2.handleChange}
                                  onBlur={form2.handleBlur}
                                />
                                <span className="input-group-text">Kg</span>
                              </div>
                              {form2.errors.Weight && form2.touched.Weight ? (
                                <p className="text-danger">
                                  {form2.errors.Weight}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Waist </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Waist"
                                  name="Waist"
                                  value={form2.values.Waist || ""}
                                  onChange={form2.handleChange}
                                  onBlur={form2.handleBlur}
                                />
                                <span className="input-group-text">Inches</span>
                              </div>
                              {form2.errors.Waist && form2.touched.Waist ? (
                                <p className="text-danger">
                                  {form2.errors.Waist}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Glutes </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Glutes"
                                  name="Glutes"
                                  value={form2.values.Glutes || ""}
                                  onChange={form2.handleChange}
                                  onBlur={form2.handleBlur}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                              {form2.errors.Glutes && form2.touched.Glutes ? (
                                <p className="text-danger">
                                  {form2.errors.Glutes}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Body Fat </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Body Fat"
                                  name="Fat"
                                  value={form2.values.Fat || ""}
                                  onChange={form2.handleChange}
                                  onBlur={form2.handleBlur}
                                />
                                <span className="input-group-text">
                                  Body Fat %
                                </span>
                              </div>
                              {form2.errors.Fat && form2.touched.Fat ? (
                                <p className="text-danger">
                                  {form2.errors.Fat}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4  mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">BMI </label>
                              <div className="input-group ">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="BMI"
                                  name="BMI"
                                  value={form2.values.BMI || ""}
                                  onChange={form2.handleChange}
                                  onBlur={form2.handleBlur}
                                />
                                <span className="input-group-text">kg/m2.</span>
                              </div>
                              {form2.errors.BMI && form2.touched.BMI ? (
                                <p className="text-danger">
                                  {form2.errors.BMI}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr /> */}
                  <div className="col-12 mb-2">
                    {QuesError === "" ? null : (
                      <div className="alert alert-danger">
                        <strong>{QuesError}</strong>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-6">
                        <h5 className="mb-3">Physical Activity Readiness</h5>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Has your doctor ever said you have a heart
                                condition and that you should only do physical
                                activity recommended by a doctor?
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="heart"
                                    onClick={() => {
                                      setheartCond("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="heart"
                                    onClick={() => {
                                      setheartCond("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you feel pain in your chest when you do
                                physical activity?
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="physical"
                                    onClick={() => {
                                      setcheastPain("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="physical"
                                    onClick={() => {
                                      setcheastPain("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                In the past month, have you had chest pain when
                                you were not doing physical activity?
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pastPain"
                                    onClick={() => {
                                      setpastPain("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pastPain"
                                    onClick={() => {
                                      setpastPain("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you lose balance because of dizziness or do
                                you ever lose consciousness?
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="excercising"
                                    onClick={() => {
                                      setlightHeated("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="excercising"
                                    onClick={() => {
                                      setlightHeated("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have a bone or joint problem (for example
                                back, knee, or hip) that could be made worse by
                                a change in your physical activity?
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="injuries"
                                    onClick={() => {
                                      setinjuries("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="injuries"
                                    onClick={() => {
                                      setinjuries("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you aware of any reason against excercise
                                without medical supervision?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="supervision"
                                    onClick={() => {
                                      setmedicalSupervision("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="supervision"
                                    onClick={() => {
                                      setmedicalSupervision("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Women only: Are you pregnant or nursing?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="nursing"
                                    onClick={() => {
                                      setpregnant("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="nursing"
                                    onClick={() => {
                                      setpregnant("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Is your doctor currently prescribing medication
                                for your blood pressure or heart condition?
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currently"
                                    onClick={() => {
                                      setcurrentMedicine("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currently"
                                    onClick={() => {
                                      setcurrentMedicine("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                Are you taking any prescribed medications that
                                could effect you during excercise (Women;
                                excluded birth control)?{" "}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="medications"
                                    onClick={() => {
                                      settakingMedicine("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="medications"
                                    onClick={() => {
                                      settakingMedicine("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-3 mt-lg-0">Healthy Habbits</h5>
                        <table className="table table-bordered mb-2">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Do you smoke or use tobacco? ? </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="smoke"
                                    onClick={() => {
                                      setsmokingHabit("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="smoke"
                                    onClick={() => {
                                      setsmokingHabit("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Are you a member of a health club?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="health"
                                    onClick={() => {
                                      sethealthClubMember("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="health"
                                    onClick={() => {
                                      sethealthClubMember("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Do you use a personal trainer?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="trainer"
                                    onClick={() => {
                                      sethavePersonalTrainer("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="trainer"
                                    onClick={() => {
                                      sethavePersonalTrainer("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="col-12 col-lg-12 mt-4">
                          <div className="form-group custom-group">
                            <label className="form-label d-block">
                              How many times do you excercise in a typical week?
                            </label>

                            <div class="form-check d-inline-block">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typical"
                                onClick={() => {
                                  setexerciseDays("1");
                                }}
                              />
                              1 Day
                            </div>
                            <div class="form-check d-inline-block ms-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typical"
                                onClick={() => {
                                  setexerciseDays("2");
                                }}
                              />
                              2 Days
                            </div>

                            <div class="form-check d-inline-block ms-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typical"
                                onClick={() => {
                                  setexerciseDays("3");
                                }}
                              />
                              3 Days
                            </div>
                            <div class="form-check d-inline-block ms-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typical"
                                onClick={() => {
                                  setexerciseDays("4");
                                }}
                              />
                              4 Days
                            </div>
                            <div class="form-check d-inline-block ms-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typical"
                                onClick={() => {
                                  setexerciseDays("5");
                                }}
                              />
                              5 Days
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-lg-12 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Do you know of <strong>any other reason</strong>{" "}
                              why you should not take part in physical activity?
                            </label>

                            <textarea
                              className="form-control "
                              name="reason"
                              value={form2.values.reason || ""}
                              onBlur={form2.handleBlur}
                              onChange={(e) => {
                                form2.handleChange(e);
                              }}
                              style={{ height: "202px" }}
                            ></textarea>

                            {form2.errors.reason && form2.touched.reason ? (
                              <p className="text-danger">
                                {form2.errors.reason}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-4">
                          The Objective Of The Plan{" "}
                        </h5>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Have you tried any diet plans before?</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="triedPlan"
                                    onClick={() => {
                                      setTriedPlan("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="triedPlan"
                                    onClick={() => {
                                      setTriedPlan("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Gym member or not</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gymMember"
                                    onClick={() => {
                                      setGymMember("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gymMember"
                                    onClick={() => {
                                      setGymMember("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Already following any diet:</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="followingDiet"
                                    onClick={() => {
                                      setFollowingDiet("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="followingDiet"
                                    onClick={() => {
                                      setFollowingDiet("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Undergoing any type of training</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="trainingType"
                                    onClick={() => {
                                      setTrainingType("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="trainingType"
                                    onClick={() => {
                                      setTrainingType("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Currently using any medicine or supplements:
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currentSupliments"
                                    onClick={() => {
                                      setCurrentSupliments("yes");
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="currentSupliments"
                                    onClick={() => {
                                      setCurrentSupliments("no");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-4">Your Daily Dietary Intake</h5>
                        <table className="table table-bordered mb-2 align-middle">
                          <thead>
                            <tr>
                              <th>Meals</th>
                              <th>Time</th>
                              <th>Specifications</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Breakfast</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeBreakfast"
                                  value={form2.values.timeBreakfast || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specBreakfast"
                                  value={form2.values.specBreakfast || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Lunch</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeLunch"
                                  value={form2.values.timeLunch || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specLunch"
                                  value={form2.values.specLunch || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Dinner</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeDinner"
                                  value={form2.values.timeDinner || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specDinner"
                                  value={form2.values.specDinner || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Snack</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeSnack"
                                  value={form2.values.timeSnack || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specSnack"
                                  value={form2.values.specSnack || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Munching</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeMunching"
                                  value={form2.values.timeMunching || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specMunching"
                                  value={form2.values.specMunching || ""}
                                  onBlur={form2.handleBlur}
                                  onChange={(e) => {
                                    form2.handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Daily Water Intake
                          </label>

                          <input
                            type="text"
                            className="form-control "
                            name="waterIntake"
                            value={form2.values.waterIntake || ""}
                            onBlur={form2.handleBlur}
                            onChange={(e) => {
                              form2.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Food you don’t prefer
                          </label>

                          <input
                            type="text"
                            className="form-control "
                            name="foodNotPrefer"
                            value={form2.values.foodNotPrefer || ""}
                            onBlur={form2.handleBlur}
                            onChange={(e) => {
                              form2.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Food Allergies if any
                          </label>

                          <input
                            type="text"
                            className="form-control "
                            name="foodAllergies"
                            value={form2.values.foodAllergies || ""}
                            onBlur={form2.handleBlur}
                            onChange={(e) => {
                              form2.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Foods you prefer</label>

                          <input
                            type="text"
                            className="form-control "
                            name="foodPrefer"
                            value={form2.values.foodPrefer || ""}
                            onBlur={form2.handleBlur}
                            onChange={(e) => {
                              form2.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-2">
                    <h5>
                      Goal Setting
                      {/* What result would you like to achieve with your fitness
                      training ? Please Select */}
                    </h5>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="row quick multi-button custom-multi-button ">
                      <div className="col-12 want-wht">
                        <input
                          type="button"
                          defaultValue="Reduce Body FA%"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Agility"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Body Tone"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Balance"
                          check-val="lastYear"
                          className="you_want "
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Improve Strength And Endurance"
                          check-val="lastYear"
                          className="you_want w-auto px-3"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Core Strength"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Reshaping"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Muscle Gain"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Weight Gain"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Weight Lose"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                        <input
                          type="button"
                          defaultValue="Flexibility"
                          check-val="lastYear"
                          className="you_want"
                          onClick={selectGoat}
                        />
                      </div>
                    </div>
                    {GoalError === "" ? null : (
                      <div className="alert alert-danger">
                        <strong>{GoalError}</strong>
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="col-12 text-end">
                    <button type="submit" className="btn btn-primary px-5 mb-1">
                      ADD
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ClientAssesment;
