import React , {useEffect, useState, useContext} from 'react';
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import { useParams } from "react-router";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import moment from "moment/moment";
import { Link } from 'react-router-dom';
import { NoteState } from "../../context/notes/NoteState";


const SingleClientAttendance = () => {
    const [loading, setLoading] = useState(false);
    const [myData, setMyData] = useState([]);
    const [limit, setLimit] = useState("25");
    const [totalResults, setTotalResults] = useState("0");
    const [totalPages, setTotalPages] = useState("1");
    const [currentPage, setCurrentPage] = useState(1);
    const [clientName, setClientName] = useState("");
    const { id } = useParams();
    const { state } = useContext(NoteState);
    const { branch_id } = state;

    const getClientsNames = async () => {
      try {
        const response = await UserServices.getClientName(branch_id, id);
        const myDataarr = response.data.data.data;
        
        if (myDataarr.length > 0) {
          const firstName = myDataarr[0].first_name;
          const lastName = myDataarr[0].last_name;
          
          setClientName(`${firstName} ${lastName}`);
        } else {
          setClientName('');
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          // Handle 404 error if needed
        }
        console.log(err);
        setClientName('');
      }
    };
    useEffect(() => {
      getClientsNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    const getUsers = async () => {
        try {
          setLoading(true);
        const responce = await UserServices.getAttendance(id);
        let myDataarr;
        if (responce.status === 200) {
            myDataarr = responce.data.data.data;
            const res = myDataarr;
            const results = [];
  
            // eslint-disable-next-line array-callback-return
            res.map((value) => {
              results.push({
                member_id: value.client_id,
                first_name: value.attendee.first_name,
                last_name: value.attendee.last_name,
                checkin_time_12h: value.checkin_time_12h,
                date: value.date,
                checkout_time_12h: value.checkout_time_12h,
                gender: value.gender,
                number: value.number,
                verified_by: value.verified_by,
                id:value.attendee.id,
                card_number: value.card_number,
              });
            });
  
            setMyData([...results]);
            setLimit(responce.data.data.per_page);
            setTotalResults(responce.data.totalRecord);
            setTotalPages(responce.data.totalPages);
            setCurrentPage(responce.data.data.current_page);
            setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.message === "Network Error") {
          setLoading(false);
        } else if (err.response.status === 401) {
          setLoading(false);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setLoading(false);
        //   navigate("/dashboard");
        } else {
          setLoading(false);
        }
      }
    };

    getUsers();
  }, [id]);
  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getAttendanceInfo(
            id,
          limit,
          pageNo
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              member_id: value.client_id,
              first_name: value.attendee.first_name,
              last_name: value.attendee.last_name,
              checkin_time_12h: value.checkin_time_12h,
              date: value.date,
              checkout_time_12h: value.checkout_time_12h,
              gender: value.gender,
              number: value.number,
              verified_by: value.verified_by,
              id:value.attendee.id,
              card_number: value.card_number,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getAttendanceInfo(
            id,
          newLimit,
          1
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              member_id: value.client_id,
              first_name: value.attendee.first_name,
              last_name: value.attendee.last_name,
              checkin_time_12h: value.checkin_time_12h,
              date: value.date,
              checkout_time_12h: value.checkout_time_12h,
              gender: value.gender,
              number: value.number,
              verified_by: value.verified_by,
              id:value.attendee.id,
              card_number: value.card_number,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="row mb-2">
          <div className="col-md-1 col-lg-1">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-1 ms-auto">
            {/* <a
              href={exportLink}
              rel="noreferrer"
              target="_blank"
              className="btn btn-primary d-inline-block btn-sm "
            >
              <i className="bi bi-download" /> Export
            </a> */}
          </div>
        </div>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>

              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Check Date
              </th>

              <th
                scope="col"
                onClick={() => requestSort("checkin_time_12h")}
                className={getClassNamesFor("checkin_time_12h")}
              >
                Check In Time
              </th>

              <th
                scope="col"
                onClick={() => requestSort("checkout_time_12h")}
                className={getClassNamesFor("checkout_time_12h")}
              >
                Check Out Time
              </th>

              <th
                scope="col"
                onClick={() => requestSort("card_number")}
                className={getClassNamesFor("card_number")}
              >
                Card Number
              </th>
              <th scope="col" className="no_sort">
                Verified By
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    <Link to={"/client-home/" + el.id}>
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>
                  <td>{moment(el.date).format("MM-DD-YYYY")}</td>
                  <td>{el.checkin_time_12h}</td>
                  <td>{el.checkout_time_12h}</td>
                  <td>{el.card_number}</td>
                  <td>{el.verified_by}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className='wrapper'>
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
      <div className="site-main-design">
      <MenuButton />
      <div className="card">
      <div className="card-header py-3">
      <div className='row'>
      <div className='col-6'>
              <h5 className="mb-0 font-20">{clientName} Attendance Report</h5>
            </div>
            <div className='col-6'>
            <Link
                  to={-1}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                 <i class="bi bi-arrow-left"></i> Back
                </Link>
            </div>
            </div>
            </div>
            <div className="card-body">
            <div className="table-responsive mt-3">
                          <Table data={myData} rowsPerPage={limit} />
                        </div>
            </div>
      </div>
      </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  )
}

export default SingleClientAttendance
