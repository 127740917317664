import React from 'react'
import MenuButton from '../MenuButton/MenuButton';
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import { useFormik } from "formik";
import { signUpSchema } from '../schemas';

const initialValues ={
  product_name:"",
  price:""
}
const AddnewProduct = () => {
  const {values, errors, touched, handleBlur, handleChange, handleSubmit } =  useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema, 
    onSubmit : (values) => {
     console.log(
      values
     );
    },
  });
  return (
    <div className='wrapper'>
    <Navbar/>
      <Sidebar/>
       <main className="page-content customer-contnent">
     <div className="site-main-design">
 <MenuButton/>
  <div className="card">
    <div className="card-header py-3">
      <h5 className="mb-0 font-20"> 
        Add a New Product
      </h5>
    </div>
    <div className="card-body">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item ">
          <h2 className="accordion-header" id="flush-headingOne">
            <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
              Basic Information
            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne">
            <div className="accordion-body">
              <p><i className="bi bi-exclamation-lg text-danger" aria-hidden="true" />The Fields With <strong className='text-danger'>*</strong> Must Required Or Fill.</p>
              <form  onSubmit={handleSubmit}>
              <div className="row">
              
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Product name <span className="text-danger">*</span></label>
                  <input type="text" className="form-control" placeholder="Product name" name="product_name"
                      value={values.product_name}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                      { errors.product_name && touched.product_name ? (<p className='text-danger'>{errors.product_name}</p>): null }
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Product Price <span className="text-danger">*</span></label>
                  <div className="input-group mb-3"> <span className="input-group-text" id="inputGroup-sizing-default">RS</span>
                    <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" name="price"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
                     
                  </div>
                  { errors.price && touched.price ? (<p className='text-danger'>{errors.price}</p>): null }
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Wholesale cost
                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="The amount the the supllier charged you for the product" aria-label="Views" />
                  </label>
                  <div className="input-group mb-3"> <span className="input-group-text" id="inputGroup-sizing-default">RS</span>
                    <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Barcode 
                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="For example, a UPC or ISBN number. If barcode is left blank, MINDBODY will generate one for you." aria-label="Views" />
                  </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Category</label>
                  <select className="form-select">
                    <option>Not assigned</option>
                    <option>v</option>
                    <option>b</option>
                    <option>b</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4 div-sbcat">
                  <label className="form-label">Sub Category</label>
                  <select className="form-select">
                    <option>None</option>
                    <option>v</option>
                    <option>b</option>
                    <option>b</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <span className="add-sbcat "> <i className="bi bi-plus" />Add a subcategory </span>
                </div>
               
              </div>
              </form>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              Color & Size
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo">
            <div className="accordion-body">
              <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
  <label className="form-label">Color</label>
  <select className="multiple-select" data-placeholder="Choose anything" multiple="multiple">
    <option value="United Kingdom">red</option>
    <option value="United States selected" >black</option>
    <option value="United Kingdom selected" >blue</option>
    <option value="United Kingdom">yellow</option>
    <option value="United Kingdom">green</option>
    <option value="United Kingdom">gold</option>
    <option value="United Kingdom">silver</option>
  </select>
  <span className="add-sbcat mt-1" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i className="bi bi-plus" />Add a subcategory </span>
</div>

                {/* Modal */}
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Add a New Color</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                      </div>
                      <div className="modal-body">
                        <label className="form-label">Color name</label>
                        <input type="text" className="form-control" placeholder="Color name" />
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-link text-danger text-decoration-none" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Add</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Size</label>
                  <select className="multiple-select" data-placeholder="Choose anything" multiple="multiple">
                    <option value="United Kingdom">All sizes</option>
                    <option value="United States selected" >One Size</option>
                    <option value="United Kingdom selected" >Extra Small</option>
                    <option value="United Kingdom">Small</option>
                    <option value="United Kingdom">Medium</option>
                    <option value="United Kingdom">Large</option>
                    <option value="United Kingdom">Extra Large</option>
                  </select>
                  <span className="add-sbcat mt-1" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"> <i className="bi bi-plus" />Add a subcategory </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Add a New Size</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <label className="form-label">Size name</label>
                <input type="text" className="form-control" placeholder="Size name" />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-link text-danger text-decoration-none" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Add</button>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
              Inventory
            </button>
          </h2>
          <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree">
            <div className="accordion-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Number in stock
                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="The number of units you have available for sale." aria-label="Views" />
                  </label>
                  <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="form-label">Manufacturer ID 
                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="The ID number attributed to the product by the manufacturer. Use if you would like the ID displayed on receipts." aria-label="Views" />
                  </label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree1" aria-expanded="false" aria-controls="flush-collapseThree1">
              Membership Settings &amp; Discounts
            </button>
          </h2>
          <div id="flush-collapseThree1" className="accordion-collapse collapse" aria-labelledby="flush-headingThree1">
            <div className="accordion-body">
              <div className="col-12">
                <h5>Restrict who can purchase this product?
                </h5>
              </div>
              <div className="col-md-10 col-lg-5 mt-2 mb-3 restrict_p">
                <div className="custom-raio">  
                  <ul>
                    <li>
                      <input type="radio" id="f-option[17]" name="option[17]" className="option[17]" defaultValue="yes_restrict" />
                      <label htmlFor="f-option[17]">Yes, restrict purchases to certain memberships</label>
                      <div className="check" />
                    </li>
                    <li>
                      <input type="radio" id="s-option[17]" name="option[17]" className="option[17]" defaultValue="no_restrict" />
                      <label htmlFor="s-option[17]">No</label>
                      <div className="check" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5  mb-3 box yes_restrict">
                <label className="form-label">Who can purchase this?</label>
                <select className="multiple-select" data-placeholder="Choose anything" multiple="multiple">
                  <option value="United Kingdom">All memberships</option>
                  <option value="United States selected" >3 Months Membership</option>
                  <option value="United Kingdom selected" >6 Months Membership</option>
                  <option value="United Kingdom">Annual Membership</option>
                  <option value="United Kingdom">Couples Membership</option>
                  <option value="United Kingdom">Family 6 months</option>
                  <option value="United Kingdom">Group Membership</option>
                </select>
              </div>
              <div className="col-12">
                <h5>Do any member discounts apply to this product?
                </h5>
              </div>
              <div className="col-md-10 col-lg-5 mt-2 discount_c">
                <div className="custom-raio">  
                  <ul>
                    <li>
                      <input type="radio" id="f-option[127]" name="option[127]" className="option[17]" defaultValue="yes_discount" />
                      <label htmlFor="f-option[127]">Yes, certain members receive a discount</label>
                      <div className="check" />
                    </li>
                    <li>
                      <input type="radio" id="s-option[137]" name="option[127]" className="option[17]" defaultValue="no_discount" />
                      <label htmlFor="s-option[137]">No</label>
                      <div className="check" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5 mt-3 mb-3 box1 yes_discount">
                <label className="form-label">Who gets discounts on this?</label>
                <select className="multiple-select" data-placeholder="Choose anything" multiple="multiple">
                  <option value="United Kingdom">All memberships</option>
                  <option value="United States selected" >3 Months Membership</option>
                  <option value="United Kingdom selected" >6 Months Membership</option>
                  <option value="United Kingdom">Annual Membership</option>
                  <option value="United Kingdom">Couples Membership</option>
                  <option value="United Kingdom">Family 6 months</option>
                  <option value="United Kingdom">Group Membership</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree2">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree2" aria-expanded="false" aria-controls="flush-collapseThree2">
              Product Commissions
            </button>
          </h2>
          <div id="flush-collapseThree2" className="accordion-collapse collapse" aria-labelledby="flush-headingThree2">
            <div className="accordion-body">
              <div className="col-12">
                <h5>Do staff members receive standard commission for this product? <i className="bi bi-info-circle-fill " data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Staff members will only receive this if a standard commission rate of 0.00 or more has been set for them on the Class/Hourly Pay Rates screen." aria-label="Views" />
                </h5>
              </div>
              <div className="col-md-10 col-lg-5 mt-2 mb-4 commission_std">
                <div className="custom-raio">  
                  <ul>
                    <li>
                      <input type="radio" id="f-option[47]" name="option[47]" className="option[17]" defaultValue="yes_commission" />
                      <label htmlFor="f-option[47]">Yes, staff members receive standard commission</label>
                      <div className="check" />
                    </li>
                    <li>
                      <input type="radio" id="s-option[47]" name="option[47]" className="option[17]" defaultValue="no_commission" />
                      <label htmlFor="s-option[47]">No</label>
                      <div className="check" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5 mt-3 mb-3 box yes_commission">
                <label className="form-label">Standard commission 
                  <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="If you want staff members to receive their standard commission rate (as set on the Class/Hourly Pay Rates screen), then simply leave this as 0.00. If you enter a number other than 0.00, then staff members will receive their standard commission in addition to the commission rate that you set here." aria-label="Views" aria-describedby="tooltip288077" />
                </label>
                <div className="input-group">
                  <select className="form-select">
                    <option value="flat">Flat</option>
                    <option value="percentage">Percentage</option>
                  </select>
                  <span className="input-group-text custom-box flat" id="inputGroup-sizing-default">RS</span>
                  <input type="text" className="form-control custom-box flat" placeholder={0.00} />
                  <input type="text" className="form-control custom-box percentage" placeholder={0.00} />
                  <span className="input-group-text custom-box percentage" id="inputGroup2-sizing-default">%</span>
                </div>
              </div>
              <div className="col-12">
                <h5>Do staff members receive promotional commission for this product? <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Staff members will only receive this if a promotional commission rate of 0.00 or more has been set for them on the Class/Hourly Pay Rates screen." aria-label="Views" />
                </h5>
              </div>
              <div className="col-md-10 col-lg-5 mt-2 mb-4 promotional_std">
                <div className="custom-raio">  
                  <ul>
                    <li>
                      <input type="radio" id="f-option[427]" name="option[427]" className="option[17]" defaultValue="yes_promotional" />
                      <label htmlFor="f-option[427]">Yes, staff members receive promotional</label>
                      <div className="check" />
                    </li>
                    <li>
                      <input type="radio" id="s-option[437]" name="option[427]" className="option[17]" defaultValue="no_promotional" />
                      <label htmlFor="s-option[437]">No</label>
                      <div className="check" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5 mt-3 mb-3 box12 yes_promotional">
                <label className="form-label">Promotional commission
                  <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="If you want staff members to receive their promotional commission rate (as set on the Class/Hourly Pay Rate screen), then simply leave this as 0.00. If you enter a number other than 0.00, then staff members will receive their promotional commission in addition to the commission rate that you set here." aria-label="Views" aria-describedby="tooltip288077" />
                </label>
                <div className="input-group">
                  <select className="form-select">
                    <option value="flat">Flat</option>
                    <option value="percentage">Percentage</option>
                  </select>
                  <span className="input-group-text custom-box22 flat" id="inputGroup33-sizing-default">RS</span>
                  <input type="text" className="form-control custom-box22 flat" placeholder={0.00} />
                  <input type="text" className="form-control custom-box22 percentage" placeholder={0.00} />
                  <span className="input-group-text custom-box22 percentage" id="inputGroup23-sizing-default">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree3">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree3" aria-expanded="false" aria-controls="flush-collapseThree3">
              Additional Information
            </button>
          </h2>
          <div id="flush-collapseThree3" className="accordion-collapse collapse" aria-labelledby="flush-headingThree3">
            <div className="accordion-body">
              <div className="col-md-12">
                <label className="form-label">Product Notes <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="These notes will only be visible to staff when they’re selling this item on the Retail tab." aria-label="Views" /> </label>
                <textarea className="form-control" id="inputAddress4" rows={3} placeholder="Notes" defaultValue={""} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-12 text-end">
    <button type="button" className="btn btn-link text-danger text-decoration-none">Cancel</button>
    <button type="button" className="btn btn-primary me-2">Add</button>
    <button type="button" className="btn btn-primary">Save & Add Next</button>
  </div>
</div>

       </main>
    </div>
  )
}

export default AddnewProduct
