import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addAssets } from "../schemas";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useMask } from "react-mask-field";

export default function UpdateAssets() {
  const navigate = useNavigate();
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [catgries, setCatgries] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [selectedBranches, setSelectedBranches] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedCost, setSelectedCost] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedTotalCost, setSelectedTotalCost] = useState("");
  const [selectedVendorName, setSelectedVendorName] = useState("");
  const [selectedVendorContact, setSelectedVendorContact] = useState("");
  const [selectedSubCategory, setselectedSubCategory] = useState("");
  const [subCatgries, setSubCatgries] = useState([]);
  const [category, setCategory] = useState("");

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(branch_id, "Asset");
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Category Found", id: "" }]);
    }
  };
  const subCtgories = async (e) => {
    var sb_catgry = e;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSubCatgries([{ name: "Select Sub Category", id: "" }, ...results]);
    } catch (err) {
      setSubCatgries([{ name: "No record found", id: "" }]);
      console.log(err);
    }
  };
  const getPkg = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getAssetsInfo(id);
      console.log(responce);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setSelectedCategory(values.category_id);
          setSelectedName(values.name);
          setSelectedCost(values.purchase_cost);
          setSelectedValue(values.current_value);
          setSelectedDate(values.acquisition_date);
          setSelectedDescription(values.description);
          setSelectedQuantity(values.quantity);
          setSelectedTotalCost(values.total_cost);
          setSelectedVendorName(values.vendor_name);
          setSelectedVendorContact(values.vendor_contact);
          setselectedSubCategory(values.sub_category_id);
          setCategory(values.category_id);
        });
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    categories();
    getPkg();

    if (category) {
      subCtgories(category);
    }
  }, [category]);

  const AssetsUpdate = async (values, action) => {
    setLoading(true);
    try {
      const response = await UserServices.assetsUpdate(values, id);
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/manage-assets");
        }, 1500);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const initialValues = {
    branch_id: selectedBranches,
    category_id: selectedCategory,
    name: selectedName,
    purchase_cost: selectedCost,
    current_value: selectedValue,
    acquisition_date: selectedDate,
    description: selectedDescription,
    quantity: selectedQuantity,
    total_cost: selectedTotalCost,
    vendor_name: selectedVendorName,
    vendor_contact: selectedVendorContact,
    sub_category_id: selectedSubCategory,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: addAssets,
      onSubmit: (values, action) => {
        AssetsUpdate(values, action);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Update Assets</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    {catgries ? (
                      <select
                        className="form-select"
                        name="category_id"
                        value={values.category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          subCtgories(e.target.value);
                          // values.sub_category_id = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {catgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.category_id && touched.category_id ? (
                      <p className="text-danger">{errors.category_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Sub-Category<span className="text-danger">*</span>
                    </label>
                    {subCatgries ? (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={values.sub_category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {subCatgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={values.sub_category_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Sub Category</option>
                      </select>
                    )}
                    {errors.sub_category_id && touched.sub_category_id ? (
                      <p className="text-danger">{errors.sub_category_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="text-danger">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Purchase Cost<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Purchase Cost"
                      name="purchase_cost"
                      min={0}
                      value={values.purchase_cost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.purchase_cost && touched.purchase_cost ? (
                      <p className="text-danger">{errors.purchase_cost}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Quantity<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Quantity"
                      name="quantity"
                      min={0}
                      value={values.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.quantity && touched.quantity ? (
                      <p className="text-danger">{errors.quantity}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Total Cost<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Total Cost"
                      name="total_cost"
                      disabled={true}
                      value={values.quantity * values.purchase_cost}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Current Value<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Purchase Cost"
                      name="current_value"
                      min={0}
                      value={values.current_value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.current_value && touched.current_value ? (
                      <p className="text-danger">{errors.current_value}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Acquisition Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="11-03-2022"
                      name="acquisition_date"
                      value={values.acquisition_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.acquisition_date && touched.acquisition_date ? (
                      <p className="text-danger">{errors.acquisition_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Vendor Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="vendor_name"
                      value={values.vendor_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.vendor_name && touched.vendor_name ? (
                      <p className="text-danger">{errors.vendor_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Vendor Contact<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="(e.g 92xxxxxxxxxx)"
                      name="vendor_contact"
                      value={values.vendor_contact}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      ref={phone_number}
                    />
                    {errors.vendor_contact && touched.vendor_contact ? (
                      <p className="text-danger">{errors.vendor_contact}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Description<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description ? (
                      <p className="text-danger">{errors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
