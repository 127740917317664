import React, { useRef, useEffect, useContext, useState } from "react";
import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import UserServices from "../../UserServices/UserServices";
import moment from "moment";
import Swal from "sweetalert2";
import { NoteState } from "../../context/notes/NoteState";
import $ from "jquery";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { updateType } from "../schemas";

export default function PaymentInfo() {
  const [loading, setLoading] = useState(false);
  const { state } = useContext(NoteState);
  const { branch_id } = state;

  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("10");
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  let sDate = moment("01-01-" + date.getFullYear()).format("YYYY-MM-DD");

  const [startDate, setstartDate] = useState(sDate);
  const [endDate, setendDate] = useState(eDate);
  const [packageId, setPackageId] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  var clickEvent = $.Event("click");
  var CurrencyFormat = require("react-currency-format");
  const closeButtonRef = useRef(null);

  const getRecord = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsPurchasesinfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {
          setSelectedType(values.payment_type);
          setPackageId(values.payment_history[0].id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getPayments = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsPayments(
        id,
        limit,
        "1",
        startDate,
        endDate
      );

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;

        const results = [];

        // eslint-disable-next-line array-callback-return
        res.map((value) => {
          results.push({
            id: value.id,
            pending: value.pending,
            payment_type: value.payment_type,
            cheque_number: value.cheque_number,
            received: value.received,
            receiving_date: value.receiving_date,
            order_id: value.order_id,
            payment_status: value.payment_status,
            net_price: value.net_price,
          });
        });

        setMyData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getPaymentMethodinitial = async () => {
    try {
      const responce = await UserServices.paymentNames();
      const res = responce.data.data;
      const results = [];
      // eslint-disable-next-line array-callback-return
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPayments();
    getPaymentMethodinitial(branch_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePagination = (e) => {
    setCurrentPage(e);

    let pageNo = e;

    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientsPayments(
          id,
          limit,
          pageNo,
          startDate,
          endDate
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;

          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              pending: value.pending,
              payment_type: value.payment_type,
              cheque_number: value.cheque_number,
              id: value.id,
              received: value.received,
              receiving_date: value.receiving_date,
              order_id: value.order_id,
              payment_status: value.payment_status,
              net_price: value.net_price,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeStartDate = async (e) => {
    var NewSDdte = moment(e.target.value).format("YYYY-MM-DD");
    if (Date.parse(NewSDdte) <= Date.parse(endDate)) {
      setstartDate(NewSDdte);
      setLoading(true);
      try {
        const responce = await UserServices.getClientsPayments(
          id,
          limit,
          "1",
          NewSDdte,
          endDate
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;

          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              pending: value.pending,
              payment_type: value.payment_type,
              cheque_number: value.cheque_number,
              id: value.id,
              received: value.received,
              receiving_date: value.receiving_date,
              order_id: value.order_id,
              payment_status: value.payment_status,
              net_price: value.net_price,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start date should be less then end date!",
      });
    }
  };
  const changeEndDate = async (e) => {
    var NewEDdte = moment(e.target.value).format("YYYY-MM-DD");
    if (Date.parse(NewEDdte) >= Date.parse(startDate)) {
      setendDate(NewEDdte);
      setLoading(true);
      try {
        const responce = await UserServices.getClientsPayments(
          id,
          limit,
          "1",
          startDate,
          NewEDdte
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;

          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              pending: value.pending,
              payment_type: value.payment_type,
              cheque_number: value.cheque_number,
              id: value.id,
              received: value.received,
              receiving_date: value.receiving_date,
              order_id: value.order_id,
              payment_status: value.payment_status,
              net_price: value.net_price,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  useEffect(() => {
    const getPaymentMethodinitial = async () => {
      try {
        const responce = await UserServices.paymentNames();
        const res = responce.data.data;
        const results = [];
        // eslint-disable-next-line array-callback-return
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
        });
        setPaymentMethods([...results]);
        setPaymentMethodsSplit([...results]);
      } catch (err) {
        console.log(err);

        setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
        setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
      }
    };

    if (!branch_id) {
      Swal.fire({
        title: "Branch has null?",
        text: "Hit logout and login again to enter in branch!",
        icon: "error",
        confirmButtonColor: "#ed1c24",
        confirmButtonText: "Login again",
      });
    } else {
      getPaymentMethodinitial(branch_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="row mb-4">
          <div className="col-md-4  col-lg-4">
            <label className="form-label">From</label>
            <input
              type="date"
              className="form-control"
              placeholder="date"
              value={startDate}
              onChange={(e) => {
                changeStartDate(e);
              }}
            />
          </div>

          <div className="col-md-4  col-lg-4">
            <label className="form-label">To</label>
            <input
              type="date"
              className="form-control"
              placeholder="date"
              value={endDate}
              onChange={(e) => {
                changeEndDate(e);
              }}
            />
          </div>
        </div>
        <table className="table table-striped table-borderless table-style-1 ">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("receiving_date")}
                className={getClassNamesFor("receiving_date")}
              >
                Received Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("order_id")}
                className={getClassNamesFor("order_id")}
              >
                Order Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("net_price")}
                className={getClassNamesFor("net_price")}
              >
                Net Price
              </th>
              <th
                scope="col"
                onClick={() => requestSort("received")}
                className={getClassNamesFor("received")}
              >
                Payment Received
              </th>
              <th
                scope="col"
                onClick={() => requestSort("pending")}
                className={getClassNamesFor("pending")}
              >
                Remaining Balance
              </th>

              <th
                scope="col"
                onClick={() => requestSort("payment_type")}
                className={getClassNamesFor("payment_type")}
              >
                Payment Method
              </th>
              <th scope="col" onClick={() => requestSort("payment_type")}
                className={getClassNamesFor("payment_type")}>
                Edit Payment Method
              </th>
              <th scope="col" className="no_sort">
                Get Payment
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{moment(el.receiving_date).format("DD-MM-YYYY")}</td>
                  <td>{el.order_id}</td>

                  <td>
                    <CurrencyFormat
                      value={el.net_price}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>

                  <td>
                    <CurrencyFormat
                      value={el.received}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.pending}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    {el.payment_type}

                    {el.payment_type !== "" && el.payment_type === "Cheque" ? (
                      <>
                        <i
                          className="bi bi-card-text note-here"
                          data-tooltip-id={el.id}
                          data-tooltip-content={el.cheque_number}
                        ></i>
                        <Tooltip
                          id={el.id}
                          place="right"
                          className="note-tooltip"
                          clickable
                        />
                      </>
                    ) : null}
                  </td>
                  <td>
                    {" "}
                    <button
                      onClick={() => {
                        getRecord(el.order_id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">Edit</span>
                    </button>
                  </td>
                  <td>
                    {el.payment_status === "Completed" ? (
                      <div className="updt-btn">
                        <i className="bi bi-check text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Completed
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={"/get_pending/" + el.order_id}
                        className="recieve-btn"
                      >
                        <i className="bi bi-arrow-right-circle text-danger me-2"></i>
                        <span className="text-danger updt-text">Receive</span>
                      </Link>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const UpdateRecord = async (values) => {
    setLoading(true);
    try {
      const response = await UserServices.UpdateClientPurchaseMethod(
        values,
        packageId
      );
      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Your request has been submitted please wait for approval!",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            getPayments();
            // Trigger the close button click event
            closeButtonRef.current.click();
          }
        });
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    payment_type: selectedType,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: updateType,
      onSubmit: (values) => {
        UpdateRecord(values);
      },
    });
  return (
    <div className="card">
      <div className="card-header py-3">
        <h5 className="mb-0 font-22">Payment Information</h5>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <Table data={myData} rowsPerPage={limit} />
        </div>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="staticBackdropLabel">
                Edit Payment Method
              </h2>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="form-group custom-group">
                    <label className="form-label">Payment Method</label>
                    {paymentMethods.length ? (
                      <select
                        className="form-select"
                        name="payment_type"
                        value={values.payment_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {paymentMethods.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="payment_type"
                        value={values.payment_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Payment Method</option>
                      </select>
                    )}

                    {errors.payment_type && touched.payment_type ? (
                      <p className="text-danger">{errors.payment_type}</p>
                    ) : null}
                  </div>
                </div>
                {values.payment_type === "32" ? (
                  <div className="col-12">
                    <div className="form-group custom-group">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Cheque Number <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="cheque_no"
                          value={values.cheque_no}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />

                        {errors.cheque_no && touched.cheque_no ? (
                          <p className="text-danger">{errors.cheque_no}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Edit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
