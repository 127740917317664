import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { genralPkgSell } from "../schemas";

export default function SellCFTpkg(props) {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [membershipType, setMembershipType] = useState([]);
  const [clientName, setClientName] = useState("");
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [membershipFee, setMembershipFee] = useState("0");
  const [discountError, setDiscountError] = useState("");
  const [paySplitError, setPaySplitError] = useState("");
  const [payAfterError, setPayAfterError] = useState("");

  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getMembershipType = async (br_Id) => {
      try {
        const responce = await UserServices.registrationPkgs(br_Id, "11");

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMembershipType([{ name: "Select Package", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setMembershipType([{ name: "No Package Found", id: "" }]);
      }
    };

    const nameClients = async () => {
      try {
        const responce = await UserServices.getClientsNameById(branch_id, id);

        setClientName(
          responce.data.data[0].first_name +
            " " +
            responce.data.data[0].last_name
        );
      } catch (err) {
        console.log(err);
        setClientName("");
      }
    };
    nameClients();

    getMembershipType(branch_id);
  }, []);
  const getPkgFee = async (e) => {
    var pkg_type = e.target.value;

    var sDate = moment(values.sdate).format("YYYY-MM-DD");

    try {
      const responce = await UserServices.pkgFee(pkg_type, sDate, 1);

      const price = responce.data.price;
      const sdate = responce.data.startDate;
      const eDate = responce.data.endDate;
      setEndDate(eDate);
      setStartDate(sdate);

      setMembershipFee(price);
    } catch (err) {
      console.log(err);
      setMembershipFee("");
    }
  };
  const verifyDiscount = async (e) => {
    var discount_price = e.target.value;
    if (discount_price !== "") {
      if (
        values.discount_type === "Percentage" &&
        values.discount_type !== "" &&
        membershipFee !== ""
      ) {
        if (discount_price > 100) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or less than 100%"
          );
        } else if (discount_price < 0) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or geater than 0%"
          );
        } else {
          values.pay_after_dis =
            membershipFee - (membershipFee / 100) * discount_price;
          setDiscountError("");
        }
      } else if (membershipFee !== "") {
        if (discount_price > membershipFee) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or less than:" + membershipFee
          );
        } else if (discount_price < 0) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or geater than 0"
          );
        } else {
          values.pay_after_dis = membershipFee - discount_price;
          setDiscountError("");
        }
      } else if (discount_price < 0) {
        values.pay_after_dis = "0";
        setDiscountError("Maximum discount must be equal to or geater than 0");
      } else {
        setDiscountError("");
      }
    } else if (discount_price < 0) {
      values.pay_after_dis = "0";
      setDiscountError("Maximum discount must be equal to or geater than 0");
    } else {
      values.pay_after_dis = "0";
      setDiscountError("");
    }
  };

  const changeStartDate = async (e) => {
    var date = e.target.value;
    var sDate = moment(date).format("YYYY-MM-DD");
    try {
      const responce = await UserServices.pkgFee(values.gymPkgType, sDate, 1);

      const price = responce.data.price;
      const sdate = responce.data.startDate;
      const eDate = responce.data.endDate;
      setEndDate(eDate);
      setStartDate(sdate);
      setMembershipFee(price);
    } catch (err) {
      console.log(err);

      setMembershipFee("0");
    }
  };
  const sellGenralPkg = async (values) => {
    if (paySplitError === "" && payAfterError === "" && discountError === "") {
      setLoading(true);
      try {
        const responce = await UserServices.CFTpkgSell(
          branch_id,
          id,
          endDate,
          membershipFee,
          "11",
          values
        );
        console.log(responce);
        toast.success("Package Added!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setTimeout(() => {
          props.cart();
          props.catagry();
        }, 1500);
      } catch (err) {
        toast.error("Unproccessable content!", { position: "top-center" });
        console.log(err);
        setLoading(false);
      }
    }
  };
  const initialValues = {
    client_name: clientName,
    memType: "",
    discount_type: "Amount",
    discount_amount: "0",
    pay_after_dis: "0",
    approveBy: "",
    referencedBy: "",
    salesnotes: "",
    sdate: startDate,
    salesdate: dateSelected,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: genralPkgSell,
      onSubmit: (values, action) => {
        sellGenralPkg(values);
      },
    });

  return (
    <div>
      <ToastContainer />
      <form className="" onSubmit={handleSubmit}>
        <>
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">Sell CFT Package</h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    New Clients <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={clientName}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Package <span className="text-danger">*</span>
                  </label>
                  {membershipType.length ? (
                    <select
                      className="form-select"
                      name="memType"
                      value={values.memType}
                      onChange={(e) => {
                        handleChange(e);
                        getPkgFee(e);
                        values.discount_amount = "";
                        setDiscountError("");
                        setPaySplitError("");
                        setPayAfterError("");
                        values.pay_after_dis = "0";
                        values.payment_rec = "";
                        values.payment_pend = "";
                        values.payment_split = "";
                        values.payment_split_pend = "";
                      }}
                      onBlur={handleBlur}
                    >
                      {membershipType.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select
                      className="form-select"
                      name="memType"
                      value={values.memType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select Membership Type</option>
                    </select>
                  )}

                  {errors.memType && touched.memType ? (
                    <p className="text-danger">{errors.memType}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Package Full Price <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    value={membershipFee}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Start Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Start date"
                    name="sdate"
                    value={values.sdate}
                    onChange={(e) => {
                      handleChange(e);
                      changeStartDate(e);
                    }}
                    onBlur={handleBlur}
                  />

                  {errors.sdate && touched.sdate ? (
                    <p className="text-danger">{errors.sdate}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label className="form-label">
                    End Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Enter End date"
                    value={endDate}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Discount Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    name="discount_type"
                    value={values.discount_type}
                    onChange={(e) => {
                      handleChange(e);
                      values.discount_amount = "";
                      setDiscountError("");
                      setPaySplitError("");
                      setPayAfterError("");
                      values.pay_after_dis = "0";
                      values.payment_rec = "";
                      values.payment_pend = "";
                      values.payment_split = "";
                      values.payment_split_pend = "";
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="">Select Discount Type</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Amount">Amount</option>
                  </select>
                  {errors.discount_type && touched.discount_type ? (
                    <p className="text-danger">{errors.discount_type}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Discount <span className="text-danger">*</span>
                  </label>
                  <input
                    onWheel={(e) => e.target.blur()}
                    className="form-control"
                    type="number"
                    name="discount_amount"
                    value={values.discount_amount}
                    onChange={(e) => {
                      handleChange(e);
                      verifyDiscount(e);
                      setPaySplitError("");
                      setPayAfterError("");
                      values.payment_rec = "";
                      values.payment_pend = "";
                      values.payment_split = "";
                      values.payment_split_pend = "";
                    }}
                    onBlur={handleBlur}
                  />

                  {discountError !== "" ? (
                    <p className="text-danger">{discountError}</p>
                  ) : null}

                  {errors.discount_amount && touched.discount_amount ? (
                    <p className="text-danger">{errors.discount_amount}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Payable Amount After Discount
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="pay_after_dis"
                    value={values.pay_after_dis}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    disabled={true}
                  />

                  {errors.pay_after_dis && touched.pay_after_dis ? (
                    <p className="text-danger">{errors.pay_after_dis}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Discount Approve By
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="approveBy"
                    value={values.approveBy}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />

                  {errors.approveBy && touched.approveBy ? (
                    <p className="text-danger">{errors.approveBy}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Referenced By
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="referencedBy"
                    value={values.referencedBy}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />

                  {errors.referencedBy && touched.referencedBy ? (
                    <p className="text-danger">{errors.referencedBy}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Sales Notes
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="salesnotes"
                    value={values.salesnotes}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />

                  {errors.salesnotes && touched.salesnotes ? (
                    <p className="text-danger">{errors.salesnotes}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Sale Date
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    name="salesdate"
                    value={values.salesdate}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />

                  {errors.salesdate && touched.salesdate ? (
                    <p className="text-danger">{errors.salesdate}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>

        <div className="col-12 text-center mb-3">
          <button
            type="submit"
            className="btn btn-primary px-5 mb-1 me-2 addclient-button"
          >
            Sell CFT Package
          </button>
        </div>
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </form>
    </div>
  );
}
