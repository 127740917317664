import { useFormik } from "formik";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { NoteState } from "../../context/notes/NoteState";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import UserServices from "../../UserServices/UserServices";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import MenuButton from "../MenuButton/MenuButton";
import { checkOut } from "../schemas";

export default function CafeDepositCheckOut() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [creditTo, setcreditTo] = useState("1");

  let navigate = useNavigate();

  const [payAfterError, setPayAfterError] = useState("");

  const [paySplitError, setPaySplitError] = useState("");

  const { id, type } = useParams();

  const getCart = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsCart(id);

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.client_fname + " " + value.client_lname,
            package_name: value.package_name,
            price: value.price,
            discount: value.discount,
            net_price: value.net_price,
            price_with_tax: value.price_with_tax,
            note: value.note,
            tax: value.tax,
          });
        });

        setMyData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        navigate("/deposit/" + id + "/" + type);
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // const getPaymentMethodinitial = async () => {
    //   try {
    //     const responce = await UserServices.paymentNames();
    //     const res = responce.data.data;
    //     const results = [];
    //     res.map((value) => {
    //       results.push({
    //         id: value.id,
    //         name: value.name,
    //       });
    //     });
    //     setPaymentMethods([...results]);
    //     setPaymentMethodsSplit([...results]);
    //   } catch (err) {
    //     console.log(err);

    //     setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
    //     setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
    //   }
    // };
    const getPaymentMethodinitial = async () => {
      try {
        const responce = await UserServices.paymentNames();
        const res = responce.data.data;
        const results = res.filter((value) => value.id !== 81); // Filter out payment method with id 81
        setPaymentMethods([...results]);
        setPaymentMethodsSplit([...results]);
      } catch (err) {
        console.log(err);

        setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
        setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
      }
    };
    getCart();
    if (!branch_id) {
      Swal.fire({
        title: "Branch has null?",
        text: "Hit logout and login again to enter in branch!",
        icon: "error",
        confirmButtonColor: "#ed1c24",
        confirmButtonText: "Login again",
      });
    } else {
      getPaymentMethodinitial(branch_id);
    }
  }, []);
  const calPendPay = async (e) => {
    const granTotal =
      creditTo === "0"
        ? myData.reduce((a, v) => (a = a + v.net_price), 0)
        : myData.reduce((a, v) => (a = a + v.price_with_tax), 0);

    var pay_price = e.target.value;
    if (pay_price !== "") {
      if (granTotal !== "") {
        if (pay_price > granTotal) {
          values.payment_pend = "";
          setPayAfterError(
            "Payment must be equal to or less than:" + granTotal
          );
        } else if (pay_price < 0) {
          values.payment_pend = "";
          setPayAfterError("Payment must be equal to or geater than 0");
        } else {
          values.payment_pend = granTotal - pay_price;

          setPayAfterError("");
        }
      } else if (pay_price < 0) {
        values.payment_pend = "";
        setPayAfterError("Payment must be equal to or geater than 0");
      } else {
        setPayAfterError("");
      }
    } else if (pay_price < 0) {
      values.payment_pend = "";
      setPayAfterError("Payment must be equal to or geater than 0");
    } else {
      values.payment_pend = "";
      setPayAfterError("");
    }
  };

  const calSplitPend = async (e) => {
    var pay_price = e.target.value;
    if (pay_price !== "") {
      if (values.payment_pend !== "") {
        if (pay_price > values.payment_pend) {
          values.payment_split_pend = "";
          setPaySplitError(
            "Payment must be equal to or less than:" + values.payment_pend
          );
        } else if (pay_price < 0) {
          values.payment_split_pend = "";
          setPaySplitError("Payment must be equal to or geater than 0");
        } else {
          values.payment_split_pend = values.payment_pend - pay_price;
          setPaySplitError("");
        }
      } else if (pay_price < 0) {
        values.payment_split_pend = "";
        setPaySplitError("Payment must be equal to or geater than 0");
      } else {
        setPaySplitError("");
      }
    } else if (pay_price < 0) {
      values.payment_split_pend = "";
      setPaySplitError("Payment must be equal to or geater than 0");
    } else {
      values.payment_split_pend = "";
      setPaySplitError("");
    }
  };

  const delEntry = (record_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(record_id);
      }
    });
  };

  const deleteRecord = async (record_id) => {
    try {
      setLoading(true);
      const responce = await UserServices.deleteClientCartItem(record_id);

      if (responce.status === 204) {
        Swal.fire({
          title: "Success!",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getCart();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const destroyCart = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        allDestroy(id);
      }
    });
  };

  const allDestroy = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.cartDestroy(id);

      if (responce.status === 204) {
        Swal.fire({
          title: "Success!",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getCart();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1 mb-0">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>

              <th
                scope="col"
                onClick={() => requestSort("client_fname")}
                className={getClassNamesFor("client_fname")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Package Price
              </th>

              <th
                scope="col"
                onClick={() => requestSort("price_with_tax")}
                className={getClassNamesFor("price_with_tax")}
              >
                Deposited Amount
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.name}</td>
                  <td>
                    {el.package_name !== null ? el.package_name : "Guest Pass"}
                    {el.note === "" ? null : (
                      <>
                        <i
                          className="bi bi-info-square note-here"
                          data-tooltip-id={el.id}
                          data-tooltip-content={el.note}
                        ></i>
                        <Tooltip
                          id={el.id}
                          place="right"
                          className="note-tooltip"
                          clickable
                        />
                      </>
                    )}
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"3"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>

                  <td>
                    <CurrencyFormat
                      value={
                        creditTo === "0" ? el.net_price : el.price_with_tax
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"3"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>

                  <td>
                    <button
                      className="text-danger custom-button-image"
                      // className="btn  d-inline-block btn-sm custom-action-button remove-item"
                      type="button"
                      onClick={() => {
                        delEntry(el.id);
                      }}
                    >
                      {" "}
                      <i className="bi bi-trash-fill"></i>
                      {/* <i className="bi bi-x-circle"></i> */}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const sellAllPkgs = async (values) => {
    if (paySplitError === "" && payAfterError === "") {
      setLoading(true);
      const toal_discount = myData.reduce((a, v) => (a = a + v.discount), 0);
      const toal_price = myData.reduce((a, v) => (a = a + v.price), 0);
      const toal_tax =
        creditTo === "0" ? 0 : myData.reduce((a, v) => (a = a + v.tax), 0);

      const grand_total =
        creditTo === "0"
          ? myData.reduce((a, v) => (a = a + v.net_price), 0)
          : myData.reduce((a, v) => (a = a + v.price_with_tax), 0);
      try {
        const responce = await UserServices.checkOutOrder(
          branch_id,
          id,
          toal_price,
          toal_discount,
          grand_total,
          values,
          type,
          toal_tax
        );

        toast.success("Amount Deposited Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/deposit_invoice/purchases/" + responce.data.order_id, {
            replace: true,
          });
        }, 2000);
      } catch (err) {
        toast.error("Unproccessable content!", { position: "top-center" });
        console.log(err);
        setLoading(false);
      }
    }
  };

  const initialValues = {
    pay_met: "31",
    payment_rec: "",
    cheque_no: "0",
    split_cheque_no: "0",
    payment_pend: "",
    split_pay: "31",
    payment_split: "",
    payment_split_pend: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: checkOut,
      onSubmit: (values, action) => {
        if (
          values.payment_pend == 0 ||
          values.payment_split - values.payment_pend == 0
        ) {
          sellAllPkgs(values);
        } else {
          Swal.fire({
            title: "Pending!",
            text: "Remaining balance must be 0!",
            icon: "warning",
            confirmButtonColor: "#ed1c24",
          });
        }
      },
    });
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Invoice </h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
          <form className="" onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-12 mb-2">
                    {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      defaultChecked={true}
                      id="flexCheckDefault"
                      style={{ width: "17px", height: "17px" }}
                    />
                    <label
                      className="form-check-label ms-2"
                      htmlFor="flexCheckDefault"
                    >
                      Include Tax
                    </label>
                  </div> */}
                  </div>
                  <div className="col-lg-8">
                    <div className="row g-3 trainee-add">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Payment Method</label>
                          {paymentMethods.length ? (
                            <select
                              className="form-select"
                              name="pay_met"
                              value={values.pay_met}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {paymentMethods.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              name="pay_met"
                              value={values.pay_met}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select Payment Method</option>
                            </select>
                          )}

                          {errors.pay_met && touched.pay_met ? (
                            <p className="text-danger">{errors.pay_met}</p>
                          ) : null}
                        </div>
                      </div>
                      {values.pay_met === "32" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <div className="form-group custom-group">
                              <label
                                htmlFor="form-label"
                                className="form-label"
                              >
                                Cheque Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="cheque_no"
                                value={values.cheque_no}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />

                              {errors.cheque_no && touched.cheque_no ? (
                                <p className="text-danger">
                                  {errors.cheque_no}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Payment <span className="text-danger">*</span>
                          </label>
                          <input
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            type="number"
                            name="payment_rec"
                            value={values.payment_rec}
                            onChange={(e) => {
                              handleChange(e);
                              calPendPay(e);
                              setPaySplitError("");
                              values.payment_split = 0;
                              values.payment_split_pend = 0;
                            }}
                            onBlur={handleBlur}
                          />
                          {payAfterError !== "" ? (
                            <p className="text-danger">{payAfterError}</p>
                          ) : null}
                          {errors.payment_rec && touched.payment_rec ? (
                            <p className="text-danger">{errors.payment_rec}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Deposited Balance
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="payment_pend"
                            value={values.payment_pend}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={true}
                          />

                          {errors.payment_pend && touched.payment_pend ? (
                            <p className="text-danger">{errors.payment_pend}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Payment Split Method
                          </label>

                          {paymentMethodsSplit.length ? (
                            <select
                              className="form-select"
                              name="split_pay"
                              value={values.split_pay}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {paymentMethodsSplit.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              name="split_pay"
                              value={values.split_pay}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              <option>Select Payment Method</option>
                            </select>
                          )}

                          {errors.split_pay && touched.split_pay ? (
                            <p className="text-danger">{errors.split_pay}</p>
                          ) : null}
                        </div>
                      </div>

                      {values.split_pay === "32" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <div className="form-group custom-group">
                              <label
                                htmlFor="form-label"
                                className="form-label"
                              >
                                Cheque Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="split_cheque_no"
                                value={values.split_cheque_no}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />

                              {errors.split_cheque_no &&
                              touched.split_cheque_no ? (
                                <p className="text-danger">
                                  {errors.split_cheque_no}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Payment
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="payment_split"
                            value={values.payment_split}
                            onChange={(e) => {
                              handleChange(e);
                              calSplitPend(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {paySplitError !== "" ? (
                            <p className="text-danger">{paySplitError}</p>
                          ) : null}
                          {errors.payment_split && touched.payment_split ? (
                            <p className="text-danger">
                              {errors.payment_split}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Deposited Balance
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="payment_split_pend"
                            value={values.payment_split_pend}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={true}
                          />

                          {errors.payment_split_pend &&
                          touched.payment_split_pend ? (
                            <p className="text-danger">
                              {errors.payment_split_pend}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="grand-total-cart w-100">
                      <div className="cart-entry">
                        Deposit Amount{" "}
                        <CurrencyFormat
                          value={myData.reduce((a, v) => (a = a + v.price), 0)}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                        <div className="clearfix"></div>
                      </div>
                    </div>

                    <div className="grand-totl-fine text-end w-100">
                      <button
                        type="button"
                        className="btn btn-primary cancel-order px-5 mb-1 me-2 addclient-button"
                        onClick={destroyCart}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary px-5 mb-1  addclient-button"
                      >
                        Check Out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
