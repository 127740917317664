import React, { useContext, useRef, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { assignTimeSlotToClient } from "../schemas";
import { useEffect } from "react";
import $ from "jquery";
import { useNavigate, useParams } from "react-router";

export default function AssignDaysToSlots() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);

  const { id, tim_id, start, type } = useParams();
  const navigate = useNavigate();

  const [days, setDays] = useState([
    { id: "Monday" },
    { id: "Tuesday" },
    { id: "Wednesday" },
    { id: "Thursday" },
    { id: "Friday" },
    { id: "Saturday" },
    { id: "Sunday" },
  ]);
  const [daysError, setDaysError] = useState(false);

  const timeSlotsAssign = async () => {
    var selectedDays = $(
      ".add-time-slot-days .form-check input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedDays.length) {
      setDaysError(true);
    } else {
      setDaysError(false);

      try {
        setLoading(true);
        const response = await UserServices.assignDaysToClientSlot(
          id,
          selectedDays
        );

        if (response.status === 201) {
          setLoading(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          if (type === "PT") {
            navigate("/trainer-appointments");
          } else if (type === "Befit") {
            navigate("/befit-appointments");
          } else if (type === "SPT") {
            navigate("/spt-appointments");
          } else {
            navigate("/add-gx-slots");
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
        } else if (err.response.status === 500) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    }
  };

  const checkDays = async (tim_id, start) => {
    try {
      const responce = await UserServices.dayCheckForSlot(tim_id, start);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value,
        });
        return null;
      });
      setDays([...results]);
    } catch (err) {
      console.log(err);
      setDays([]);
    }
  };
  useEffect(() => {
    if (branch_id) {
      checkDays(tim_id, start);
    } else {
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Assign Days</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <div className="form-group custom-group add-time-slot-days">
                      Days
                      <span className="text-danger">*</span>
                      {days ? (
                        <div className="row">
                          {days.map((el, index) => {
                            return (
                              <div className="col-lg-2 mt-2" key={index}>
                                <div className="form-check">
                                  <label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={el.id}
                                    />
                                    {el.id}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                      {daysError ? (
                        <p className="text-danger">Days are required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <button
                      onClick={timeSlotsAssign}
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
