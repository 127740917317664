import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import UserServices from "../../UserServices/UserServices";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import CurrencyFormat from "react-currency-format";
import { Tooltip } from "react-tooltip";
import DepositPkg from "./DepositPkg";

export default function Deposit() {
    const [selectedService, setSelectedService] = useState("");
    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState(false);
    const { id, type } = useParams();
    const [myData, setMyData] = useState([]);
    const [catagrieAdded, setCatagrieAdded] = useState([]);
  

    // eslint-disable-next-line
    const [limit, setLimit] = useState("10");
  
    const getCart = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientsCart(id);
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.client_fname + " " + value.client_lname,
              package_name: value.package_name,
              price: value.price,
              discount: value.discount,
              net_price: value.price_with_tax,
              note: value.note,
              tax: value.tax,
            });
            return null;
          });
  
          setCart(true);
          setSelectedService("");
          setMyData([...results]);
  
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setSelectedService("");
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
          setCart(false);
        } else {
          setLoading(false);
          setCart(false);
        }
      }
    };
    const getCatagriesAdded = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getclientCatagriesList(id);
        // console.log(responce);
        let myDataarr;
        if (responce.status === 200) {
          myDataarr = responce.data;
          setCatagrieAdded(myDataarr);
          $("select").prop("selectedIndex", 0);
        }
      } catch (err) {
        console.log(err);
        $("select").prop("selectedIndex", 0);
        if (err.response.status === 404) {
          setLoading(false);
          setCatagrieAdded([]);
        } else {
          setLoading(false);
        }
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      getCart();
  
      getCatagriesAdded();
    }, [id]);
    const delEntry = (record_id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this record ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#303030",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteRecord(record_id);
        }
      });
    };
  
    const deleteRecord = async (record_id) => {
      try {
        setLoading(true);
        const responce = await UserServices.deleteClientCartItem(record_id);
  
        if (responce.status === 204) {
          Swal.fire({
            title: "Success!",
            text: "Record deleted successfully.",
            icon: "success",
            confirmButtonColor: "#ed1c24",
          });
          getCart();
          getCatagriesAdded();
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
  
    const destroyCart = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this record ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#303030",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          allDestroy(id);
        }
      });
    };
  
    const allDestroy = async (id) => {
      try {
        setLoading(true);
        const responce = await UserServices.cartDestroy(id);
  
        if (responce.status === 204) {
          Swal.fire({
            title: "Success!",
            text: "Record deleted successfully.",
            icon: "success",
            confirmButtonColor: "#ed1c24",
          });
          getCart();
          getCatagriesAdded();
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    const Table = ({ data, rowsPerPage }) => {
      const { slice } = useTable(data, 1, rowsPerPage);
      const { items, requestSort, sortConfig } = useSortableData(slice);
  
      const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
      };
  
      return (
        <>
          <table className="table table-striped table-borderless table-style-1 mb-0">
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
  
                <th
                  scope="col"
                  onClick={() => requestSort("client_fname")}
                  className={getClassNamesFor("client_fname")}
                >
                  Client Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("package_name")}
                  className={getClassNamesFor("package_name")}
                >
                 Deposit Package Name
                </th>
  
                <th
                  scope="col"
                  onClick={() => requestSort("price")}
                  className={getClassNamesFor("price")}
                >
                  Deposit Amount
                </th>
                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{el.name}</td>
                    <td>
                      {el.package_name !== null ? el.package_name : "Guest Pass"}
  
                      {el.note === "" ? null : (
                        <>
                          <i
                            className="bi bi-info-square note-here"
                            data-tooltip-id={el.id}
                            data-tooltip-content={el.note}
                          ></i>
                          <Tooltip
                            id={el.id}
                            place="right"
                            className="note-tooltip"
                            clickable
                          />
                        </>
                      )}
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.net_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </td>
  
                    <td>
                      <button
                        className="text-danger custom-button-image"
                        type="button"
                        onClick={() => {
                          delEntry(el.id);
                        }}
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      );
    };
  return (
    <div className="wrapper">
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
          {cart ? (
            <>
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Invoice </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
                <div className="row">
                  <div className="col-12 ">
                    <div className="grand-total-cart">
                      <div className="cart-entry">
                        Deposit Amount{" "}
                        <CurrencyFormat
                          value={myData.reduce(
                            (a, v) => (a = a + v.price),
                            0
                          )}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grand-totl-fine">
                  <div className="col-md-7"></div>
                  <button
                    type="button"
                    className="btn btn-primary cancel-order px-4 "
                    onClick={destroyCart}
                  >
                    Cancel
                  </button>
                  <Link
                    to={"/cafe_check_out/" + id + "/" + type}
                    className="btn btn-primary px-4 ms-2 addclient-button-custom"
                  >
                    Pay Now
                  </Link>
                </div>
              </div>
            </>
          ) : null}

          <div className="card-header py-3">
            <div className="row">
              <div className="col-6">
                <h5 className="mb-0 font-20">Deposit Balance</h5>
              </div>
              <div className="col-6">
                <Link
                  to={-1}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body">
            <p>
              <i
                className="bi bi-exclamation-lg"
                style={{ color: "red" }}
                aria-hidden="true"
              />
              The Fields With <strong style={{ color: "red" }}>*</strong> Must
              Required Or Fill.
            </p>
          </div>
            <DepositPkg cart={getCart} catagry={getCatagriesAdded} />
        </div>
      </div>
    </main>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  </div>
  )
}
