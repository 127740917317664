import React from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { useEffect } from "react";

export default function CafeSidebar() {
  const locate = useLocation();

  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };

  const sidebarHnadle = () => {
    if ($(window).width() < 992) {
      $("#sidebar").addClass("show");
      $(".site-main-design").removeClass("full-show");
    } else {
      $("#sidebar").removeClass("show");
      $(".site-main-design").addClass("full-show");
    }
  };

  useEffect(() => {
    sidebarHnadle();
  }, []);

  $(window).resize(function () {
    sidebarHnadle();
  });

  return (
    <div className="row">
      {/* <!--main start div content--> */}
      <main className="col-md-4 ">
        <div id="sidebar" className="collapse-horizontal border-end collapse">
          <div
            id="sidebar-nav"
            className="sidebar-wrapper list-group border-0 rounded-0 text-sm-start min-vh-100"
          >
            <ul className="metismenu" id="menu">
              <li
                className={`${
                  locate.pathname === "/cafe-dashboard" ? "mm-active" : ""
                } `}
              >
                <Link to="/cafe-dashboard">
                  <div className="parent-icon">
                    <i className="bi bi-house-fill"></i>
                  </div>
                  <div className="menu-title">Dashboard</div>
                </Link>
              </li>
              <li
                className={`${
                  locate.pathname === "/cafe-category" ? "mm-active" : ""
                } `}
              >
                <Link to="/cafe-category">
                  <div className="parent-icon">
                    <i className="bi bi-list-ul"></i>
                  </div>
                  <div className="menu-title">Categories</div>
                </Link>
              </li>
              <li
                className={`${
                  locate.pathname === "/add-cafe-product" ? "mm-active" : ""
                } `}
              >
                <Link to="/add-cafe-product">
                  <div className="parent-icon">
                    <i className="bi bi-basket2-fill"></i>
                  </div>
                  <div className="menu-title">Products</div>
                </Link>
              </li>
              {/* <li
                className={`${
                  locate.pathname === "/cafe-cart" ? "mm-active" : ""
                } `}
              >
                <Link to="/cafe-cart">
                  <div className="parent-icon">
                    <i className="bi bi-cart-fill"></i>
                  </div>
                  <div className="menu-title">Cart</div>
                </Link>
              </li> */}
              <li
                className={`${
                  locate.pathname === "/cafe-sales" ? "mm-active" : ""
                } `}
              >
                <Link to="/cafe-sales">
                  <div className="parent-icon">
                    <i className="bi bi-bar-chart-fill"></i>
                  </div>
                  <div className="menu-title">Sales</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
      {/* <!--Start Back To Top Button--> */}
      <button className="back-to-top custom-button" onClick={backToTop}>
        <i className="bi bi-arrow-up"></i>
      </button>
      {/* <!--end page main div--> */}
    </div>
  );
}
