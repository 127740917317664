import React from 'react';
import { Link } from 'react-router-dom';
import MenuButton from '../MenuButton/MenuButton'
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";

const ReportsAll = () => {
  return (
    <div className='wrapper'>
    <Navbar/>
      <Sidebar/>
      <main className="page-content customer-contnent">
<div className="site-main-design">
 <MenuButton/>
  <div className="card">
    <div className="card-header py-3">
      <h5 className="mb-0 font-20"> 
        Reports
      </h5>
    </div>
    <div className="card-body">
      <div className="reports-all">
        <div className="row">
          <div className="col-md-3">
            <div className="reports-all-main">
              <ul className="nav nav-tabs nav-danger" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link className="nav-link active" data-bs-toggle="tab" to="#dangerhome" role="tab" aria-selected="true">
                    <div className="tab-icon"><i className="bi bi-star-fill font-18 me-1" />
                    </div>
                    <div className="tab-title">Favorite Reports</div>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" data-bs-toggle="tab" to="#dangerprofile" role="tab" aria-selected="false">
                    <div className="tab-icon"><i className="bi bi-cart-fill font-18 me-1" />
                    </div>
                    <div className="tab-title">Sales</div>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" data-bs-toggle="tab" to="#dangerpayment" role="tab" aria-selected="false">
                    <div className="tab-icon"><i className="bi bi-cash font-18 me-1" />
                    </div>
                    <div className="tab-title">Payment</div>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" data-bs-toggle="tab" to="#dangercontact" role="tab" aria-selected="false">
                    <div className="tab-icon"><i className="bi bi-person-fill font-18 me-1" />
                    </div>
                    <div className="tab-title">Clients</div>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link className="nav-link" data-bs-toggle="tab" to="#dangerstaff" role="tab" aria-selected="false">
                    <div className="tab-icon"><i className="bi bi-person-badge-fill font-18 me-1" />
                    </div>
                    <div className="tab-title">Staff</div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="tab-content py-3">
              <div className="tab-pane fade show active" id="dangerhome" role="tabpanel">
                <div className="col-xl-7">
                  <h5 className="mb-0 font-20 fw-bolder"> 
                    Favorite Reports
                  </h5>
                  <p className="mt-2">Easily access the reports you care most about. Simply click the star icon next to any of our 80+ reports to add or remove it from your favorites.</p>
                  <div className="report-overview">
                    <div className="report-title">
                      <div className="add-fav">
                        <i className="bi bi-star-fill" />
                      </div>
                      <Link to="/sales-reports">
                        Sales
                        <span>View Report</span>
                      </Link>
                    </div>
                    <p>
                      Find out how much cash should be in your cash register.
                    </p>
                  </div>
                  <div className="report-overview">
                    <div className="report-title">
                      <div className="add-fav">
                        <i className="bi bi-star-fill" />
                      </div>
                      <Link to="/clients-reports">
                        Client Reports
                        <span>View Report</span>
                      </Link>
                    </div>
                    <p>
                      View all your sales for a given date range.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="dangerprofile" role="tabpanel">
                <h5 className="mb-0 font-20 fw-bolder"> 
                  Sales Reports
                </h5>
                <div className="report-filters">
                  <span>FILTERS</span>
                  <ul className="nav nav-tabs nav-danger" role="tablist">
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link active" data-bs-toggle="tab" to="#all-sales" role="tab" aria-selected="true">
                        <div className="tab-title">All Sales Reports</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#administrative" role="tab" aria-selected="false">
                        <div className="tab-title">Administrative</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#general-sales" role="tab" aria-selected="false">
                        <div className="tab-title">General Sales</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#sale-analysis" role="tab" aria-selected="false">
                        <div className="tab-title">Sale Analysis</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#post-sale" role="tab" aria-selected="false">
                        <div className="tab-title">Post-sale</div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-7">
                  <div className="tab-pane fade active show" id="all-sales" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <Link to="/sales-reports">
                          Sales
                          <span >View Report</span>
                        </Link>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className='custom-button'>
                          Monthly Sales
                          <span >View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className='custom-button'>
                          Daily Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className='custom-button'>
                          Annual Sales
                          <span className="">View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className='custom-button'>full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="administrative" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button  className="custom-button">
                          Daily Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Annual Sales
                          <span >View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className='custom-button'>full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="general-sales" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <Link to="/sales-reports">
                          Sales
                          <span>View Report</span>
                        </Link>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Monthly Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Annual Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className='custom-button'>full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="sale-analysis" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <Link to="/sales-reports">
                          Sales
                          <span>View Report</span>
                        </Link>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className='custom-button'>
                          Monthly Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Daily Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className='custom-button'>full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="post-sale" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Monthly Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Daily Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Annual Sales
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className='custom-button'>full list of reports.</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="dangerpayment" role="tabpanel">
                <h5 className="mb-3 font-20 fw-bolder"> 
                  Payment Processing Reports
                </h5>
                <div className="col-xl-7">
                  <div className="report-overview">
                    <div className="report-title">
                      <div className="add-fav">
                        <i className="bi bi-star-fill" />
                      </div>
                      <button className="custom-button">
                        Autopay Detail 
                        <span>View Report</span>
                      </button>
                    </div>
                    <p>
                      Find out how much cash should be in your cash register.
                    </p>
                  </div>
                  <div className="want-more">
                    <div className="graph-pic">
                      <img src="assets/images/graph-rp.svg" className="img-fluid" alt="" />
                    </div>
                    <div className="graph-content">
                      <h3>Want even more reports?</h3>
                      <p>When you upgrade plans, you’ll get access to over 80 reports (with data that’ll help your business grow)</p>
                      <button className='custom-button'>Learn More</button>
                    </div>
                  </div>
                  <div className="report-overview">
                    <div className="report-title">
                      <div className="add-fav">
                        <i className="bi bi-star-fill" />
                      </div>
                      <Link to="/clients-reports">
                        Client Reports
                        <span>View Report</span>
                      </Link>
                    </div>
                    <p>
                      View all your sales for a given date range.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="dangercontact" role="tabpanel">
                <h5 className="mb-3 font-20 fw-bolder"> 
                  Client Reports
                </h5>
                <div className="report-filters">
                  <span>FILTERS</span>
                  <ul className="nav nav-tabs nav-danger" role="tablist">
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link active" data-bs-toggle="tab" to="clients-report" role="tab" aria-selected="true">
                        <div className="tab-title">All Client Reports</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#retention" role="tab" aria-selected="false">
                        <div className="tab-title">Visits & Retention</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#marketing" role="tab" aria-selected="false">
                        <div className="tab-title">Marketing</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#client-accounts" role="tab" aria-selected="false">
                        <div className="tab-title">Client Accounts</div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-7">
                  <div className="tab-pane fade active show" id="all-client" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Contact Logs
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Membership
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Cancellations
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Generate a list of all client cancellations as well as which staff member processed them. You can also undo a cancellation from this report.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Client Indexes
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        See customized categories of my clientele, at a glance.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className='custom-button'>full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="retention" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Client Indexes
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        See customized categories of my clientele, at a glance.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="marketing" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Membership
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how many members are at your business and what type of memberships they have.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          New Members
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how many members are at your business and what type of memberships they have.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="client-accounts" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Account Balances
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out which clients have positive and negative account balances at your business.
                      </p>
                    </div>
                    <div className="want-more">
                      <div className="graph-pic">
                        <img src="assets/images/graph-rp.svg" className="img-fluid" alt="" />
                      </div>
                      <div className="graph-content">
                        <h3>Want even more reports?</h3>
                        <p>When you upgrade plans, you’ll get access to over 80 reports (with data that’ll help your business grow)</p>
                        <button className="custom-button">Learn More</button>
                      </div>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Contact Logs
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        View notes regarding emails, phones calls, and chats that you and your staff have had with your clients.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Event Payments
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out which clients have an outstanding balance on a payment plan.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="dangerstaff" role="tabpanel">
                <h5 className="mb-3 font-20 fw-bolder"> 
                  Staff Reports
                </h5>
                <div className="report-filters">
                  <span>FILTERS</span>
                  <ul className="nav nav-tabs nav-danger" role="tablist">
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link active" data-bs-toggle="tab" to="#all-staff" role="tab" aria-selected="true">
                        <div className="tab-title">All Staff Reports</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#productivity" role="tab" aria-selected="false">
                        <div className="tab-title">Productivity</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#payroll" role="tab" aria-selected="false">
                        <div className="tab-title">Payroll</div>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link className="nav-link" data-bs-toggle="tab" to="#addminis" role="tab" aria-selected="false">
                        <div className="tab-title">Administrative</div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-7">
                  <div className="tab-pane fade active show" id="all-staff" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Payroll
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much to pay your staff members for a given date range.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Time Clock
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Tips
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Generate a list of all client cancellations as well as which staff member processed them. You can also undo a cancellation from this report.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Commission
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        See customized categories of my clientele, at a glance.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="productivity" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Ratings and Reviews
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        View feedback about your business that was submitted through the MINDBODY app
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="payroll" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Payroll
                          <span className="">View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much to pay your staff members for a given date range.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Time Clock
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Find out how much cash should be in your cash register.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star-fill" />
                        </div>
                        <button className="custom-button">
                          Tips
                          <span className="">View Report</span>
                        </button>
                      </div>
                      <p>
                        Generate a list of all client cancellations as well as which staff member processed them. You can also undo a cancellation from this report.
                      </p>
                    </div>
                    <div className="want-more">
                      <div className="graph-pic">
                        <img src="assets/images/graph-rp.svg" className="img-fluid" alt="" />
                      </div>
                      <div className="graph-content">
                        <h3>Want even more reports?</h3>
                        <p>When you upgrade plans, you’ll get access to over 80 reports (with data that’ll help your business grow)</p>
                        <button className="custom-button">Learn More</button>
                      </div>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Commission
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        See customized categories of my clientele, at a glance.
                      </p>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="addminis" role="tabpanel">
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Schedule at a Glance
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        See what services your staff members are scheduled to render today.
                      </p>
                    </div>
                    <div className="report-overview">
                      <div className="report-title">
                        <div className="add-fav">
                          <i className="bi bi-star" />
                        </div>
                        <button className="custom-button">
                          Phone Book
                          <span>View Report</span>
                        </button>
                      </div>
                      <p>
                        Get a list of all staff members' phone numbers, emails, and mailing addresses.
                      </p>
                    </div>
                    <div className="want-more">
                      <div className="graph-pic">
                        <img src="assets/images/graph-rp.svg" className="img-fluid" alt="" />
                      </div>
                      <div className="graph-content">
                        <h3>Want even more reports?</h3>
                        <p>When you upgrade plans, you’ll get access to over 80 reports (with data that’ll help your business grow)</p>
                        <button className="custom-button">Learn More</button>
                      </div>
                    </div>
                    <div className="see-full">
                      See the 
                      <button className="custom-button">full list of reports.</button>
                    </div>
                  </div>    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </main>
    </div>
  )
}

export default ReportsAll
