import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import MenuButton from "../MenuButton/MenuButton";
import { postAssesment } from "../schemas";
import { Link } from "react-router-dom";

export default function PostAssessmentForm(props) {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  // const { id } = useParams();
  const { id, length } = props;
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [myBranches, setMyBranches] = useState([]);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    branches();
  }, []);

  const initialValues = {
    Weight: "",
    Height: "",
    Age: "",
    BMI: "",
    Fat: "",
    Chest: "",
    Rhr: "",
    Mhr: "",
    Whr: "",
    Vfat: "",
    Arm: "",
    Waist: "",
    Glutes: "",
    Thigh: "",
    branch_id: branch_id,
    date: "",
    max_push_ups: "",
    max_push_ups_one_min: "",
    one_rm_squat: "",
    one_rm_bench_press: "",
    fit_and_reach: "",
  };

  const addClientAssessment = async (values, action) => {
    try {
      setLoading(true);
      setSubmission(true);
      const response = await UserServices.addPostAssessment(values, id);

      setLoading(false);
      toast.success("Added Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      action.resetForm();
      // navigate("/viewAssessment/" + id);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: postAssesment,
      onSubmit: (values, action) => {
        addClientAssessment(values, action);
      },
    });
  return (
    <>
      <ToastContainer />
      <form className="" onSubmit={handleSubmit}>
        <div className="card-body">
          <h5>
            Measurements
            {length > 0 || submission ? (
              <Link
                to={`/client-assessment/${id}`}
                className="ms-md-2 btn btn-primary d-inline-block float-end btn-sm"
              >
                {/* <i className="bi bi-printer" />  */}
                View Assessments
              </Link>
            ) : null}
            <div className="clearfix" />
          </h5>

          <p>
            <i
              className="bi bi-exclamation-lg text-danger"
              aria-hidden="true"
            />
            The Fields With <strong className="text-danger">*</strong>
            Must Required Or Fill.
          </p>

          <div className="row g-3">
            <div className="col-12">
              <h6 className="fw-semibold font-16 mb-0">General Information</h6>
            </div>
            <div className="col-12 col-lg-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Branch Name<span className="text-danger">*</span>
                </label>
                {myBranches ? (
                  <select
                    className="form-select"
                    name="branch_id"
                    disabled={branch_id ? true : false}
                    value={values.branch_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {myBranches.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.branch_id && touched.branch_id ? (
                  <p className="text-danger">{errors.branch_id}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter date"
                  autoComplete="off"
                  name="date"
                  value={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.date && touched.date ? (
                  <p className="text-danger">{errors.date}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Age <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Age"
                  autoComplete="off"
                  name="Age"
                  value={values.Age}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Age && touched.Age ? (
                  <p className="text-danger">{errors.Age}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group ">
                <label className="form-label">
                  Height<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Height"
                    autoComplete="off"
                    name="Height"
                    value={values.Height}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Inches</span>
                </div>
                {errors.Height && touched.Height ? (
                  <p className="text-danger">{errors.Height}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Weight <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Weight"
                    autoComplete="off"
                    name="Weight"
                    value={values.Weight}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Kg</span>
                </div>
                {errors.Weight && touched.Weight ? (
                  <p className="text-danger">{errors.Weight}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  FAT <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Fat%"
                    autoComplete="off"
                    name="Fat"
                    value={values.Fat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">%</span>
                </div>
                {errors.Fat && touched.Fat ? (
                  <p className="text-danger">{errors.Fat}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  BMI <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="NUMBER"
                    className="form-control"
                    placeholder="Enter BMI"
                    autoComplete="off"
                    name="BMI"
                    value={values.BMI}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">kg/m2</span>
                </div>
                {errors.BMI && touched.BMI ? (
                  <p className="text-danger">{errors.BMI}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  V-FAT <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter V-FAT"
                    autoComplete="off"
                    name="Vfat"
                    value={values.Vfat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">%</span>
                </div>
                {errors.Vfat && touched.Vfat ? (
                  <p className="text-danger">{errors.Vfat}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12">
              <h6 className="fw-semibold font-16 mb-0">Measurements</h6>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Chest <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Chest"
                    autoComplete="off"
                    name="Chest"
                    value={values.Chest}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Inches</span>
                </div>
                {errors.Chest && touched.Chest ? (
                  <p className="text-danger">{errors.Chest}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Arm <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Arm"
                    autoComplete="off"
                    name="Arm"
                    value={values.Arm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Inches</span>
                </div>
                {errors.Arm && touched.Arm ? (
                  <p className="text-danger">{errors.Arm}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Waist <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Waist"
                    autoComplete="off"
                    name="Waist"
                    value={values.Waist}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Inches</span>
                </div>
                {errors.Waist && touched.Waist ? (
                  <p className="text-danger">{errors.Waist}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Glutes <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Glutes"
                    autoComplete="off"
                    name="Glutes"
                    value={values.Glutes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Inches</span>
                </div>
                {errors.Glutes && touched.Glutes ? (
                  <p className="text-danger">{errors.Glutes}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Thigh <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Thigh"
                    autoComplete="off"
                    name="Thigh"
                    value={values.Thigh}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Inches</span>
                </div>
                {errors.Thigh && touched.Thigh ? (
                  <p className="text-danger">{errors.Thigh}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12">
              <h6 className="fw-semibold font-16 mb-0">
                Cardio Vascular Assements:
              </h6>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  RHR <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter RHR"
                    autoComplete="off"
                    name="Rhr"
                    value={values.Rhr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">BPM</span>
                </div>
                {errors.Rhr && touched.Rhr ? (
                  <p className="text-danger">{errors.Rhr}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  MHR <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter MHR"
                    autoComplete="off"
                    name="Mhr"
                    value={values.Mhr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">BPM</span>
                </div>
                {errors.Mhr && touched.Mhr ? (
                  <p className="text-danger">{errors.Mhr}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  WHR <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter WHR"
                    autoComplete="off"
                    name="Whr"
                    value={values.Whr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* <span className="input-group-text">BPM</span> */}
                </div>
                {errors.Whr && touched.Whr ? (
                  <p className="text-danger">{errors.Whr}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12">
              <h6 className="fw-semibold font-16 mb-0">Physical Assessments</h6>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Max Push Ups</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Max Push Ups"
                    autoComplete="off"
                    name="max_push_ups"
                    value={values.max_push_ups}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Reps</span>
                </div>
                {errors.max_push_ups && touched.max_push_ups ? (
                  <p className="text-danger">{errors.max_push_ups}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Max Sit Ups</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Max Sit Ups"
                    autoComplete="off"
                    name="max_push_ups_one_min"
                    value={values.max_push_ups_one_min}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Reps</span>
                </div>
                {errors.max_push_ups_one_min && touched.max_push_ups_one_min ? (
                  <p className="text-danger">{errors.max_push_ups_one_min}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">1RM Squat</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter 1RM Squat"
                    autoComplete="off"
                    name="one_rm_squat"
                    value={values.one_rm_squat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Kg</span>
                </div>
                {errors.one_rm_squat && touched.one_rm_squat ? (
                  <p className="text-danger">{errors.one_rm_squat}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">1RM Bench Press</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter 1RM Bench Press"
                    autoComplete="off"
                    name="one_rm_bench_press"
                    value={values.one_rm_bench_press}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-group-text">Kg</span>
                </div>
                {errors.one_rm_bench_press && touched.one_rm_bench_press ? (
                  <p className="text-danger">{errors.one_rm_bench_press}</p>
                ) : null}
              </div>
            </div>


            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Sit And Reach</label>
                <div className="input-group d-flex align-items-center">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Sit And Reach"
                    autoComplete="off"
                    name="fit_and_reach"
                    value={values.fit_and_reach}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* <span className="input-group-text"></span> */}
                </div>
                {errors.fit_and_reach && touched.fit_and_reach ? (
                  <p className="text-danger">{errors.fit_and_reach}</p>
                ) : null}
              </div>
            </div>


          </div>
        </div>
        <div className="clearfix" />

        <div className="col-12 text-center mb-3">
          <button
            type="submit"
            className="btn btn-primary px-5 mb-1 me-2 addclient-button"
          >
            Add Results
          </button>
        </div>
      </form>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
