import React, { useContext, useState, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { addClassInSlot, addGfxClass } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-js-pagination";

import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import jsPDF from "jspdf";
import $ from "jquery";

import moment from "moment";

const AddClassesInGxSlots = () => {
  const { state } = useContext(NoteState);
  const [loading, setLoading] = useState(false);
  const [slotsNames, setSlotsNames] = useState([]);
  const [myBranches, setMyBranches] = useState([]);
  const [days, setDays] = useState([]);

  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { branch_id } = state;

  // update
  const [selectedBranches, setSelectedBranches] = useState("");

  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [selectedName, setSelectedName] = useState("");

  const [session, setsession] = useState("");

  const [packageId, setPackageId] = useState("");

  var clickEvent = $.Event("click");

  const closeButtonRef = useRef(null);
  const getPkgs = async (id) => {
    setLoading(true);
    try {
      setDays([]);

      const responce = await UserServices.getClassInSlot(id);
      if (responce.status === 200) {
        let data = responce.data.data;

        setSelectedBranches(data.package.branch_id);
        setSelectedTrainer(data.package_id);
        setSelectedName(data.name);
        setsession(data.day);
        setPackageId(data.id);


        getDaysList(data.package_id);
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const getPkg = async (page, limit, pkgId = "") => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getclassesListWithSlots(
        branch_id,
        newLimit,
        pageNo,
        pkgId
      );
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branches_name: values.package?.branch_name,
            branch_id: values.branch_id,
            package_name: values.package?.slot_name,

            day: values.day,
            name: values.name,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const getSlotsList = async (br_Id, tr_id) => {
    setDays([]);
    try {
      const responce = await UserServices.allClassesList(br_Id, "15", tr_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSlotsNames([{ name: "Select Slot ", id: "" }, ...results]);
    } catch (err) {
      console.log(err);

      setSlotsNames([{ name: "No Slot Found", id: "" }]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);

    branches();
    getSlotsList(branch_id, "");
  }, []);

  const getDaysList = async (id) => {
    try {
      const responce = await UserServices.getDaysOfSlots(id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value,
        });
        return null;
      });
      setDays([...results]);
    } catch (err) {
      console.log(err);
      setDays([]);
    }
  };

  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteClassInSlot(id, "2");
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };

  const addGxClass = async (values, action) => {
    try {
      setLoading(true);

      const response = await UserServices.assignClassToSlot(values);

      if (response.status === 201) {
        setLoading(false);

        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();

        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Unproccessable content!", { position: "top-center" });
    }
  };

  const initialValues = {
    branch_id: branch_id,
    day: "",
    package_name: "",
    slot_id: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addClassInSlot,
      onSubmit: (values, action) => {
        addGxClass(values, action);
      },
    });

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("GX-Classes.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="All GX Classes"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Slot Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Class Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("day")}
                className={getClassNamesFor("day")}
              >
                Day
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branches_name}</td>
                  <td>{el.package_name}</td>
                  <td>{el.name}</td>

                  <td>{el.day}</td>

                  <td>
                    <button
                      className="updt-btn inactive-btn"
                      onClick={() => getPkgs(el.id)}
                    >
                      <i className="bi bi-eye text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const UpdatePackage = async (addPkgValues) => {
    setLoading(true);

    try {
      const response = await UserServices.updateClassInSlot(
        addPkgValues,
        packageId
      );
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getPkg(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const addPkginitialValues = {
    branch_id: selectedBranches,
    slot_id: selectedTrainer,
    package_name: selectedName,
    day: session,
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: addPkginitialValues,
    validationSchema: addClassInSlot,
    onSubmit: (addPkgValues) => {
      UpdatePackage(addPkgValues);
    },
  });

  return (
    <>
      <div className="wrapper">
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Add GX Class</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name <span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          value={values.branch_id || ""}
                          disabled={branch_id ? true : false}
                          onChange={(e) => {
                            handleChange(e);
                            getSlotsList(e.target.value, "");
                            values.slot_id = "";
                            values.day = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      Slot Allocate <span className="text-danger">*</span>
                      {slotsNames ? (
                        <select
                          className="form-select"
                          name="slot_id"
                          value={values.slot_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            getDaysList(e.target.value);
                            values.day = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {slotsNames.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.slot_id && touched.slot_id ? (
                        <p className="text-danger">{errors.slot_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Class Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enetr Class Name"
                        name="package_name"
                        value={values.package_name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.package_name && touched.package_name ? (
                        <p className="text-danger">{errors.package_name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      Day Allocate <span className="text-danger">*</span>
                      {days ? (
                        <select
                          className="form-select"
                          name="day"
                          value={values.day || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Day</option>
                          {days.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.id}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.day && touched.day ? (
                        <p className="text-danger">{errors.day}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">All GX Classes</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-1 col-lg-3  mb-3">
                    <div className="form-group custom-group">
                      {slotsNames ? (
                        <select
                          className="form-select"
                          onChange={(e) => {
                            getPkg("1", limit, e.target.value);
                          }}
                        >
                          {slotsNames.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-3 ms-auto mb-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                      <button
                        className="btn btn-primary file-download float-end mb-0"
                        onClick={() => {
                          generatePdf(document.getElementById("report-POS"));
                        }}
                      >
                        <i className="bi bi-file-earmark-pdf"></i> PDF
                      </button>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={addPkgHandleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={addPkgValues.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                          getSlotsList(e.target.value, "");
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    Slot Allocate <span className="text-danger">*</span>
                    {slotsNames ? (
                      <select
                        className="form-select"
                        name="slot_id"
                        value={addPkgValues.slot_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {slotsNames.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.slot_id && addPkgTouched.slot_id ? (
                      <p className="text-danger">{addPkgErrors.slot_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Class Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Class Name"
                      name="package_name"
                      value={addPkgValues.package_name}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    />

                    {addPkgErrors.package_name && addPkgTouched.package_name ? (
                      <p className="text-danger">{addPkgErrors.package_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    Day Allocate <span className="text-danger">*</span>
                    {days ? (
                      <select
                        className="form-select"
                        name="day"
                        value={addPkgValues.day || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        <option value="">Select Day</option>
                        <option  value={addPkgValues.day}> {addPkgValues.day}</option>
                        {days.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.id}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.day && addPkgTouched.day ? (
                      <p className="text-danger">{addPkgErrors.day}</p>
                    ) : null}
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default AddClassesInGxSlots;
