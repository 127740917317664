import React, { useEffect, useState, useRef, useContext } from "react";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { clientReport } from "../schemas";
import jsPDF from "jspdf";
import "jspdf-autotable";

const StaffAttendance = () => {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  const [names, setNames] = useState([]);
  const inputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");
  const [show, setShow] = useState(false);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [option, setOption] = useState("");
  const [radioOption, setRadioOption] = useState("Detail");
  const [loading, setLoading] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const [myData, setMyData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [DAteSeted, setDAteSeted] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);

  const staffNames = async () => {
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    staffNames();
  }, []);
  const setDates = async (param) => {
    setDAteSeted(true);
    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
      if (err.message === "Network Error") {
        navigate("/dashboard");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };

  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      setShow(true);
      let e = document.getElementById("answerInput");
      var input = e,
        list = input.getAttribute("list"),
        options = document.querySelectorAll("#" + list + " option"),
        hiddenInput = document.getElementById(
          input.getAttribute("id") + "-hidden"
        ),
        inputValue = input.value;

      // hiddenInput.value = inputValue;

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (option.innerText === inputValue) {
          hiddenInput.value = await option.getAttribute("data-value");
          break;
        } else {
          hiddenInput.value = "-1";
        }
      }

      let staff_id = await document.getElementById("answerInput-hidden").value;

      try {
        const responce = await UserServices.getStaffAttendence(
          branch_id,
          values,
          staff_id,
          limit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/attendance/ExportAttendance?category=2&type=Staff&branch_id=" +
              branch_id +
              "&member_id=" +
              staff_id +
              "&start_time=" +
              values.start_time +
              "&end_time=" +
              values.end_time +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&gender=" +
              values.gender
          );
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              member_id: value.member_id,
              first_name: value.attendee.first_name,
              last_name: value.attendee.last_name,
              checkin_time_12h: value.checkin_time_12h,
              date: value.date,
              checkout_time_12h: value.checkout_time_12h,
              gender: value.gender,
              number: value.number,
              verified_by: value.verified_by,
              Designation: value.Designation,
              id: value.attendee.id,
              card_number: value.card_number,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);

          if (radioOption === "Detail") {
            setOption("Detail");
          } else {
            setOption("Summary");
          }
        }
      } catch (err) {
        if (radioOption === "Detail") {
          setOption("Detail");
        } else {
          setOption("Summary");
        }

        if (err.response.status === 401) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const summaryReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      setShow(true);
      let e = document.getElementById("answerInput");
      var input = e,
        list = input.getAttribute("list"),
        options = document.querySelectorAll("#" + list + " option"),
        hiddenInput = document.getElementById(
          input.getAttribute("id") + "-hidden"
        ),
        inputValue = input.value;

      // hiddenInput.value = inputValue;

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (option.innerText === inputValue) {
          hiddenInput.value = await option.getAttribute("data-value");
          break;
        } else {
          hiddenInput.value = "-1";
        }
      }

      let staff_id = await document.getElementById("answerInput-hidden").value;

      try {
        const responce = await UserServices.getStaffAttendenceSummary(
          branch_id,
          values,
          staff_id,
          limit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/attendance/ExportAttendance?category=2&type=Staff&branch_id=" +
              branch_id +
              "&member_id=" +
              staff_id +
              "&start_time=" +
              values.start_time +
              "&end_time=" +
              values.end_time +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&gender=" +
              values.gender
          );
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              count: value.count,
              date: value.date,
            });
            return null;
          });

          setSummaryData([...results]);
          setLoading(false);

          if (radioOption === "Detail") {
            setOption("Detail");
          } else {
            setOption("Summary");
          }
        }
      } catch (err) {
        if (radioOption === "Detail") {
          setOption("Detail");
        } else {
          setOption("Summary");
        }

        if (err.response.status === 401) {
          setExportLink();
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setExportLink();
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const changePagination = (e) => {
    setCurrentPage(e);

    let pageNo = e;

    const getPaginationData = async () => {
      setLoading(true);
      let staff_id = document.getElementById("answerInput-hidden").value;

      try {
        const responce = await UserServices.getStaffAttendence(
          branch_id,
          values,
          staff_id,
          limit,
          pageNo
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              member_id: value.member_id,
              first_name: value.attendee.first_name,
              last_name: value.attendee.last_name,
              checkin_time_12h: value.checkin_time_12h,
              date: value.date,
              checkout_time_12h: value.checkout_time_12h,
              gender: value.gender,
              number: value.number,
              verified_by: value.verified_by,
              Designation: value.Designation,
              id: value.attendee.id,
              card_number: value.card_number,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);
      let staff_id = document.getElementById("answerInput-hidden").value;
      try {
        const responce = await UserServices.getStaffAttendence(
          branch_id,
          values,
          staff_id,
          newLimit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              member_id: value.member_id,
              first_name: value.attendee.first_name,
              last_name: value.attendee.last_name,
              checkin_time_12h: value.checkin_time_12h,
              date: value.date,
              checkout_time_12h: value.checkout_time_12h,
              gender: value.gender,
              number: value.number,
              verified_by: value.verified_by,
              Designation: value.Designation,
              id: value.attendee.id,
              card_number: value.card_number,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");

      // Loop through all the tables in the section and add them to the PDF

      // Add a new page for each table (skip first table as it is already on the first page)
      const captionText = table.getAttribute("data-caption");
      // Add the table caption as regular text above the table
      doc.text(  captionText, 10, 10);
      const tableOptions = {
        headStyles: { fillColor: [6, 161, 128], textColor: 255 },
        styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });

      doc.save("Staff_Attendance_Report.pdf");
    };

    return (
      <>
        <div className="row mb-2">
          <div className="col-md-1 col-lg-1">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-4 ms-auto text-end">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <a
              href={exportLink}
              rel="noreferrer"
              // target="_blank"
              className="btn btn-primary file-download"
            >
              <i className="bi bi-download" /> Excel
            </a>
          </div>
        </div>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Staff Attendance Report"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>

              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Name
              </th>
              {/* <th
                scope="col"
                onClick={() => requestSort("Designation")}
                className={getClassNamesFor("Designation")}
              >
                Designation
              </th> */}

              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Check Date
              </th>

              <th
                scope="col"
                onClick={() => requestSort("checkin_time_12h")}
                className={getClassNamesFor("checkin_time_12h")}
              >
                Check In Time
              </th>

              <th
                scope="col"
                onClick={() => requestSort("checkout_time_12h")}
                className={getClassNamesFor("checkout_time_12h")}
              >
                Check Out Time
              </th>

              <th
                scope="col"
                onClick={() => requestSort("card_number")}
                className={getClassNamesFor("card_number")}
              >
                Card Number
              </th>
              <th scope="col" className="no_sort">
                Verified By
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    <Link to={"/staff-home/" + el.id}>
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>

                  {/* <td>{el.Designation}</td> */}
                  {/* <td>{el.date}</td> */}
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>

                  <td>{el.checkin_time_12h}</td>
                  <td>{el.checkout_time_12h}</td>
                  <td>{el.card_number}</td>
                  <td>{el.verified_by}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const SummaryTable = ({ data, rowsPerPage }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");
      const tableOptions = {
        headStyles: { fillColor: [6, 161, 128], textColor: 255 },
        styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });

      doc.save("section_tables.pdf");
    };

    return (
      <>
        <div className="row mb-2">
          <div className="col-12 col-md-4 ms-auto text-end">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <a
              href={exportLink}
              rel="noreferrer"
              // target="_blank"
              className="btn btn-primary file-download"
            >
              <i className="bi bi-download" /> Excel
            </a>
          </div>
        </div>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("count")}
                className={getClassNamesFor("count")}
              >
                Total Records
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{1 ? index + 1 : -1 * limit + index + 1}</td>
                  <td>{el.count} Staff</td>
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };
  const initialValues = {
    sdate: startDate,
    edate: endDate,
    member_id: "",
    start_time: "",
    end_time: "",
    gender: "",
    options: "Detail",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport(values);
        summaryReport(values);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Staff Attendance Report</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="link-hirarcy">
                  <span className="text-success">Reports</span> » Attendance
                  Report
                </div>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="options"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="options"
                          className="option[17]"
                          value="Detail"
                          defaultChecked={true}
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="" />
                <div className="col-md-4 col-lg-5" />
                <div className="col-md-4 col-lg-5" />
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Staff Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="member_id"
                      autoComplete="off"
                      value={values.member_id || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      list="members_name"
                      id="answerInput"
                    />
                    <input
                      type="hidden"
                      name="answer"
                      id="answerInput-hidden"
                    />

                    <datalist id="members_name">
                      {names.map((el, index) => {
                        return (
                          <option
                            key={index}
                            data-value={el.id}
                            value={el.name}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                    </datalist>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Select Gender</label>
                    <select
                      className="form-select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">From</label>
                    <input
                      type="time"
                      step={1}
                      ref={inputRef}
                      className="form-control"
                      placeholder="Start Time"
                      name="start_time"
                      value={values.start_time}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">To</label>
                    <input
                      type="time"
                      step={1}
                      ref={inputRef}
                      className="form-control"
                      placeholder="Start Time"
                      name="end_time"
                      value={values.end_time}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 text-center">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 mb-1 me-2"
                  >
                    Go
                  </button>
                </div>
              </form>
              <div className="card-body">
                {show && (
                  <div className="row">
                    {option === "Detail" ? (
                      <>
                        <div className="table-responsive mt-3">
                          <Table data={myData} rowsPerPage={limit} />
                        </div>
                      </>
                    ) : (
                      <div className="table-responsive mt-3">
                        <SummaryTable data={summaryData} />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-4 col-lg-5" />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default StaffAttendance;
