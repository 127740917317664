import React, { useContext, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { useEffect } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Filler,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function FinanceDashboard() {
  const [activeButton, setActiveButton] = useState("Month");
  const [bankBalance, setBankBalance] = useState("");
  const [officeBalance, setOfficeBalance] = useState("");
  const [lastCreditAmount, setLastCreditAmount] = useState("");
  const [lastDebitAmount, setLastDebitAmount] = useState("");
  const [officeSales, setOfficeSales] = useState("");
  const [creditBankBalance, setCreditBankBalance] = useState("");
  const [creditSalesBalance, setCreditSalesBalance] = useState("");
  const [debitBankBalance, setDebitBankBalance] = useState("");
  const [debitSalesBalance, setDebitSalesBalance] = useState("");
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [weeklySales, setWeeklySales] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [monthlyExpenses, setMonthlyExpenses] = useState([]);
  const [weeklyExpenses, setWeeklyExpenses] = useState([]);
  const [grandTotalExpenses, setGrandTotalExpenses] = useState(0);
  const [grandTotalSales, setGrandTotalSales] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState([]);

  var CurrencyFormat = require("react-currency-format");

  const getBankrecord = async (startDate, endDate) => {
    setLoading(true);
    try {
      if (!startDate || !endDate) {
        const today = new Date();
        startDate = today.toISOString().split("T")[0];
        endDate = startDate;
      }

      const response = await UserServices.balanceBank(
        branch_id,
        startDate,
        endDate
      );
      if (response.status === 200) {
        const { last_credit_amount, last_debit_amount, total_balance } =
          response.data;
        setBankBalance(total_balance);
        setDebitBankBalance(last_debit_amount);
        setCreditBankBalance(last_credit_amount);
        setLoading(false);
      }
    } catch (err) {
      setBankBalance("No record found");
      setDebitBankBalance(0);
      setCreditBankBalance(0);
    }
  };
  const getOfficeRecord = async (startDate, endDate) => {
    setLoading(true);
    try {
      if (!startDate || !endDate) {
        const today = new Date();
        startDate = today.toISOString().split("T")[0];
        endDate = startDate;
      }

      const response = await UserServices.balanceOffice(
        branch_id,
        startDate,
        endDate
      );
      if (response.status === 200) {
        const { lastCreditAmount, lastDebitAmount, totalBalance } =
          response.data;
        setOfficeBalance(totalBalance);
        setLastCreditAmount(lastCreditAmount);
        setLastDebitAmount(lastDebitAmount);
        setLoading(false);
      }
    } catch (err) {
      setOfficeBalance("No record found");
      setLastCreditAmount(0);
      setLastDebitAmount(0);
    }
  };
  const getSalesRecord = async (startDate, endDate) => {
    setLoading(true);
    try {
      if (!startDate || !endDate) {
        const today = new Date();
        startDate = today.toISOString().split("T")[0];
        endDate = startDate;
      }

      const response = await UserServices.balanceSales(
        branch_id,
        startDate,
        endDate
      );
      if (response.status === 200) {
        const { lastCreditAmount, lastDebitAmount, totalBalance } =
          response.data;
        setOfficeSales(totalBalance);
        setDebitSalesBalance(lastDebitAmount);
        setCreditSalesBalance(lastCreditAmount);
        setLoading(false);
      }
    } catch (err) {
      setOfficeSales("No record found");
    }
  };
  const totalSalesExpenses = async (startDate, endDate) => {
    setLoading(true);
    try {
      if (!startDate || !endDate) {
        const today = new Date();
        startDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-1";
        endDate = startDate;
      }
      const responce = await UserServices.monthSaleExpensesForDashboard(
        branch_id,
        startDate,
        endDate
      );
      const res = responce.data.data;
      const expensesArr = [];
      const sales = [];

      res.expenses.map((value) => {
        return expensesArr.push({
          category: value.category,
          total_quantity: parseInt(value.total_quantity),
          total_price: parseInt(value.total_price),
        });
      });
      res.sales.map((value) => {
        return sales.push({
          category: value.category,
          total_quantity: parseInt(value.total_quantity),
          total_price: parseInt(value.total_price),
        });
      });

      setSales([...sales]);
      setExpenses([...expensesArr]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setSales([]);
      setExpenses([]);
      setLoading(false);
    }
  };
  const getPaymentMethods = async (startDate, endDate) => {
    setLoading(true);
    try {
      if (!startDate || !endDate) {
        const today = new Date();
        startDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-1";
        endDate = today.toISOString().split("T")[0];
      }
      const response = await UserServices.paymentMethodDetails(
        branch_id,
        startDate,
        endDate
      );
      let myDataarr;

      if (response.status === 200) {
        myDataarr = response.data.data;
        const res = myDataarr;
        const results = [];

        res.map((value) => {
          results.push({
            payment_method: value.payment_method,
            total_payment: value.total_payment,
          });
          return null;
        });

        setPaymentMethod([...results]);
        setLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setPaymentMethod([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const handleClickToday = () => {
    const today = new Date();
    const startDate = today.toISOString().split("T")[0];
    const endDate = startDate;
    getBankrecord(startDate, endDate);
    getOfficeRecord(startDate, endDate);
    getSalesRecord(startDate, endDate);
    getPaymentMethods(startDate, endDate);
    setActiveButton("Today");
  };
  const handleClickWeek = () => {
    const today = new Date();
    const firstDateOfMonth = new Date(
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-1"
    );
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    let startDate, endDate;

    if (today - firstDateOfMonth > oneWeekInMilliseconds) {
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 6
      )
        .toISOString()
        .split("T")[0];
      endDate = today.toISOString().split("T")[0];
    } else {
      startDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-1";
      endDate = today.toISOString().split("T")[0];
    }
    getBankrecord(startDate, endDate);
    getOfficeRecord(startDate, endDate);
    getSalesRecord(startDate, endDate);
    getPaymentMethods(startDate, endDate);
    setActiveButton("Week");
  };
  const handleClickMonth = () => {
    const today = new Date();
    const startOfMonth =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-1";
    const startDate = startOfMonth;
    const endDate = today.toISOString().split("T")[0];
    getBankrecord(startDate, endDate);
    getOfficeRecord(startDate, endDate);
    getSalesRecord(startDate, endDate);
    getPaymentMethods(startDate, endDate);
    setActiveButton("Month");
  };
  const handleClickQuarter = () => {
    const today = new Date();
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      today.getDate() + 1
    );

    const endDate = today.toISOString().split("T")[0];
    getBankrecord(startDate, endDate);
    getOfficeRecord(startDate, endDate);
    getSalesRecord(startDate, endDate);
    getPaymentMethods(startDate, endDate);
    setActiveButton("Quarter");
  };
  const totalMonthlySalesExpenses = async () => {
    setLoading(true);
    try {
      const today = new Date();
      const startOfMonth =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-1";
      const startDate = startOfMonth;
      const endDate = today.toISOString().split("T")[0];
      const response = await UserServices.monthSaleExpensesForDashboard(
        branch_id,
        startDate,
        endDate
      );

      const res = response.data.data;
      const expensesMap = new Map(); // Map to store category-wise expenses
      const salesMap = new Map(); // Map to store category-wise sales

      res.expenses.forEach((value) => {
        const category = value.category;
        const totalQuantity = parseInt(value.total_quantity);
        const totalPrice = parseInt(value.total_price);

        if (expensesMap.has(category)) {
          const existingValue = expensesMap.get(category);
          existingValue.total_quantity += totalQuantity;
          existingValue.total_price += totalPrice;
        } else {
          expensesMap.set(category, {
            category: category,
            total_quantity: totalQuantity,
            total_price: totalPrice,
          });
        }
      });

      res.sales.forEach((value) => {
        const category = value.category;
        const totalQuantity = parseInt(value.total_quantity);
        const totalPrice = parseInt(value.total_price);

        if (salesMap.has(category)) {
          const existingValue = salesMap.get(category);
          existingValue.total_quantity += totalQuantity;
          existingValue.total_price += totalPrice;
        } else {
          salesMap.set(category, {
            category: category,
            total_quantity: totalQuantity,
            total_price: totalPrice,
          });
        }
      });

      const expensesArr = Array.from(expensesMap.values());
      const salesArr = Array.from(salesMap.values());

      const salesTotal = salesArr.reduce(
        (total, item) => total + item.total_price,
        0
      );
      const totalExpenses = expensesArr.reduce(
        (total, item) => total + item.total_price,
        0
      );

      setGrandTotalExpenses(totalExpenses);
      setGrandTotalSales(salesTotal);
      setMonthlySales([...salesArr]);
      setMonthlyExpenses([...expensesArr]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setMonthlySales([]);
      setMonthlyExpenses([]);
      setLoading(false);
    }
  };
  const totalWeeklySalesExpenses = async () => {
    setLoading(true);
    try {
      const today = new Date();
      const firstDateOfMonth = new Date(
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-1"
      );
      const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
      let startDate, endDate;

      if (today - firstDateOfMonth > oneWeekInMilliseconds) {
        startDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 6
        )
          .toISOString()
          .split("T")[0];
        endDate = today.toISOString().split("T")[0];
      } else {
        startDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-1";
        endDate = today.toISOString().split("T")[0];
      }

      const response = await UserServices.monthSaleExpensesForDashboard(
        branch_id,
        startDate,
        endDate
      );
      const res = response.data.data;
      const expensesArr = [];
      const sales = [];

      res.expenses.map((value) => {
        return expensesArr.push({
          category: value.category,
          total_quantity: parseInt(value.total_quantity),
          total_price: parseInt(value.total_price),
        });
      });

      res.sales.map((value) => {
        return sales.push({
          category: value.category,
          total_quantity: parseInt(value.total_quantity),
          total_price: parseInt(value.total_price),
        });
      });

      setWeeklySales([...sales]);
      setWeeklyExpenses([...expensesArr]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWeeklySales([]);
      setWeeklyExpenses([]);
      setLoading(false);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleClickMonth();
    totalSalesExpenses();
    totalMonthlySalesExpenses();
    totalWeeklySalesExpenses();
    getPaymentMethods();
  }, []);

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="row">
            <div className="col-md-6">
              <div className="card radius-10 p-4">
                <Bar
                  type="bar"
                  data={{
                    labels: monthlySales.map((el) =>
                      el.category === "1"
                        ? "Gym"
                        : el.category === "2"
                        ? "PT"
                        : el.category === "3"
                        ? "Guest Pass"
                        : el.category === "4"
                        ? "Small Group PT"
                        : el.category === "5"
                        ? "Nutritionist"
                        : el.category === "6"
                        ? "Registration"
                        : el.category === "7"
                        ? "Bootcamp"
                        : el.category === "8"
                        ? "Freezing"
                        : el.category === "9"
                        ? "General"
                        : el.category === "10"
                        ? "Cafe"
                        : el.category === "12"
                        ? "LesMills"
                        : el.category === "11"
                        ? "CFT"
                        : "N/A"
                    ),
                    datasets: [
                      {
                        label: "Monthly",
                        backgroundColor: "#e8002b",
                        data: monthlySales.map((item) =>
                          parseInt(item.total_price)
                        ),
                        barThickness: 20,
                        borderRadius: 3,
                      },
                      {
                        label: "Weekly",
                        backgroundColor: "#70ad47",
                        data: weeklySales.map((item) =>
                          parseInt(item.total_price)
                        ),
                        barThickness: 20,
                        borderRadius: 3,
                      },
                      {
                        label: "Daily",
                        backgroundColor: "#000",
                        data: sales.map((item) => parseInt(item.total_price)),
                        barThickness: 20,
                        borderRadius: 3,
                      },
                    ],
                  }}
                  options={{
                    indexAxis: "x",
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        barSpacing: 0.4, // Adjust the spacing between the bars
                      },
                      y: {
                        grid: {
                          borderDash: [3, 3],
                          drawBorder: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Sales",
                        font: {
                          size: 18,
                        },
                      },
                    },
                  }}
                  labels="months"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card radius-10 p-4">
                <Bar
                  type="bar"
                  data={{
                    labels: monthlyExpenses.map((el) => el.category),
                    datasets: [
                      {
                        label: "Monthly",
                        backgroundColor: "#e8002b",
                        data: monthlyExpenses.map((item) =>
                          parseInt(item.total_price)
                        ),
                        barThickness: 20,
                        borderRadius: 3,
                      },
                      {
                        label: "Weekly",
                        backgroundColor: "#70ad47",
                        data: weeklyExpenses.map((item) =>
                          parseInt(item.total_price)
                        ),
                        barThickness: 20,
                        borderRadius: 3,
                      },
                      {
                        label: "Daily",
                        backgroundColor: "#000",
                        data: expenses.map((item) =>
                          parseInt(item.total_price)
                        ),
                        barThickness: 20,
                        borderRadius: 3,
                      },
                    ],
                  }}
                  options={{
                    indexAxis: "x",
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        barSpacing: 0.4, // Adjust the spacing between the bars
                      },
                      y: {
                        grid: {
                          borderDash: [3, 3],
                          drawBorder: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Expense",
                        font: {
                          size: 18,
                        },
                      },
                    },
                  }}
                  labels="months"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex mb-2">
                <div className="mt-1 filter-label">Filter By:</div>
                <button
                  type="submit"
                  className={`btn finance-btn rounded-0 generate_report ${
                    activeButton === "Today" ? "active" : ""
                  }`}
                  onClick={handleClickToday}
                >
                  Today
                </button>
                <button
                  type="submit"
                  className={`btn finance-btn rounded-0 ms-2 generate_report ${
                    activeButton === "Week" ? "active" : ""
                  }`}
                  onClick={handleClickWeek}
                >
                  Week
                </button>
                <button
                  type="submit"
                  className={`btn finance-btn rounded-0 ms-2 generate_report ${
                    activeButton === "Month" ? "active" : ""
                  }`}
                  onClick={handleClickMonth}
                >
                  Month
                </button>
                <button
                  type="submit"
                  className={`btn finance-btn rounded-0 ms-2 generate_report ${
                    activeButton === "Quarter" ? "active" : ""
                  }`}
                  onClick={handleClickQuarter}
                >
                  Quarter
                </button>
              </div>
            </div>
          </div>
          <div className="card radius-10 custom-shadow">
            <div className="manage-task">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 custom-col">
                    <Link to="/bank-ledger">
                      <div className="d-flex card-heading-1">
                        <h4 className="text-black">Bank</h4>
                      </div>
                      <div className="card-text-1 mt-2">Total Balance</div>
                      <div className="card-text-2">
                        <CurrencyFormat
                          value={bankBalance}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"2s"}
                        />
                      </div>
                      <hr></hr>
                      <div className="d-flex">
                        <div>
                          <div className="card-text-1 mt-2">Last Debit</div>
                          <div className="card-text-3 mt-2">
                            <CurrencyFormat
                              value={debitBankBalance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              thousandSpacing={"2s"}
                            />
                          </div>
                        </div>
                        <div className="ms-auto">
                          <div className="card-text-1 mt-2">Last Credit</div>
                          <div className="card-text-3 mt-2">
                            <CurrencyFormat
                              value={creditBankBalance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              thousandSpacing={"2s"}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* ......... */}
                  <div className="col-lg-4 custom-col">
                    <Link to="/view-cash-ledger">
                      <div className="d-flex card-heading-1">
                        <h4 className="text-black">Office </h4>
                      </div>
                      <div className="card-text-1 mt-2">Total Balance</div>
                      <div className="card-text-2">
                        <CurrencyFormat
                          value={officeBalance}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"2s"}
                        />
                      </div>
                      <hr></hr>
                      <div className="d-flex">
                        <div>
                          <div className="card-text-1 mt-2">Last Debit</div>
                          <div className="card-text-3 mt-2">
                            {" "}
                            <CurrencyFormat
                              value={lastDebitAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              thousandSpacing={"2s"}
                            />
                          </div>
                        </div>
                        <div className="ms-auto">
                          <div className="card-text-1 mt-2">Last Credit</div>
                          <div className="card-text-3 mt-2">
                            {" "}
                            <CurrencyFormat
                              value={lastCreditAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              thousandSpacing={"2s"}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* .... */}
                  <div className="col-lg-4">
                    <Link to="/manage-sales">
                      <div className="d-flex card-heading-1">
                        <h4 className="text-black">Sales Counter </h4>
                      </div>
                      <div className="card-text-1 mt-2">Total Balance</div>
                      <div className="card-text-2">
                        <CurrencyFormat
                          value={officeSales}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"2s"}
                        />
                      </div>
                      <hr></hr>
                      <div className="d-flex">
                        <div>
                          <div className="card-text-1 mt-2">Last Debit</div>
                          <div className="card-text-3 mt-2">
                            {" "}
                            <CurrencyFormat
                              value={debitSalesBalance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              thousandSpacing={"2s"}
                            />
                          </div>
                        </div>
                        <div className="ms-auto">
                          <div className="card-text-1 mt-2">Last Credit</div>
                          <div className="card-text-3 mt-2">
                            <CurrencyFormat
                              value={creditSalesBalance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              thousandSpacing={"2s"}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card radius-10 custom-shadow">
            <div className="manage-task">
              <div className="card-body d-flex">
                <div className="col-6 custom-col">
                  <Link to="/balance-sheet">
                    <div className="d-flex text-black">
                      <h4>Monthly Profit & Loss</h4>
                    </div>
                    <div className="grand-total-cart bg-white w-100 mt-md-0">
                      <div>
                        <div className="cart-entry-finance mt-4">
                          Total Sales
                          <CurrencyFormat
                            value={grandTotalSales}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>
                        <div className="cart-entry-finance mt-4">
                          Total Expenses{" "}
                          <CurrencyFormat
                            value={grandTotalExpenses}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>
                        <div className="cart-entry-finance mt-4">
                          Net Profit
                          <CurrencyFormat
                            value={grandTotalSales - grandTotalExpenses}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <Line
                        data={{
                          labels: [
                            "Total Sales",
                            "Total Expense",
                            
                          ],
                          datasets: [
                            {
                              label: "Sales",
                              data: [grandTotalSales],
                              borderColor: 'rgb(255, 99, 132)',
                              backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                              label: "Expense",
                              data: [grandTotalExpenses],
                              borderColor: "#000",
                              backgroundColor: "#fff0f0",
                            },
                          ],
                        }}
                        // options={{
                        //   responsive: true,
                        //   plugins: {},
                        //   indexAxis: "x",
                        //   scales: {
                        //     x: {
                        //       grid: {
                        //         display: false,
                        //       },
                        //     },
                        //     y: {
                        //       grid: {
                        //         borderDash: [3, 3],
                        //         drawBorder: false,
                        //       },
                        //     },
                        //   },
                        // }}
                      /> */}
                    </div>
                  </Link>
                </div>
                <div className="col-6 ">
                  <div>
                    <h4 className="ms-2 text-black">
                      Payment Method Breakdown
                    </h4>
                    <div className="outer-pie">
                      <Pie
                        type="pie"
                        data={{
                          labels: paymentMethod.map(
                            (item) => item.payment_method
                          ),
                          datasets: [
                            {
                              data: paymentMethod.map(
                                (item) => item.total_payment
                              ),
                              backgroundColor: [
                                "#ED1C24",
                                "#70ad47",
                                "#5b9bd5",
                                "#a5a5a5",
                                "#ed7d31",
                                "#fcd303",
                                "#ff00cd",
                              ],
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          layout: {
                            padding: {
                              top: 0,
                              bottom: 0,
                            },
                          },
                          responsive: true,
                          plugins: {
                            datalabels: {
                              color: "black",
                              font: {
                                size: 10,
                              },
                              formatter: (value, context) => {
                                const dataset =
                                  context.chart.data.datasets[
                                    context.datasetIndex
                                  ];
                                const total = dataset.data.reduce(
                                  (sum, dataPoint) => sum + dataPoint,
                                  0
                                );
                                const percentage =
                                  ((value / total) * 100).toFixed(2) + "%";
                                return percentage;
                              },
                              anchor: "end",
                              align: "start",
                              offset: 20,
                            },
                            legend: {
                              display: true,
                              position: "left",
                              labels: {
                                font: {
                                  size: 16, // Adjust the font size here
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
