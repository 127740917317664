import React from "react";
import { useState } from "react";
import UserServices from "../../../UserServices/UserServices";
import { useEffect } from "react";
import { useContext } from "react";
import { NoteState } from "../../../context/notes/NoteState";
import { Link } from "react-router-dom";
import MenuButton from "../../MenuButton/MenuButton";
import { useFormik } from "formik/dist";

import { addMealPlan } from "../../schemas";
import { toast } from "react-toastify";

export default function AddMealPlanBK() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [catgries, setCatgries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [name, setName] = useState([]);

  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Meal"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Option", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Option Found", id: "" }]);
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  useEffect(() => {
    branches();
    categories();
    nameClients();
  }, []);

  const adddMeal = async (values, action) => {
    let e = document.getElementById("answerInput");
    var input = e,
      list = input.getAttribute("list"),
      options = document.querySelectorAll("#" + list + " option"),
      hiddenInput = document.getElementById(
        input.getAttribute("id") + "-hidden"
      ),
      inputValue = input.value;

    // hiddenInput.value = inputValue;

    for (var i = 0; i < options.length; i++) {
      var option = options[i];

      if (option.innerText === inputValue) {
        hiddenInput.value = await option.getAttribute("data-value");
        break;
      } else {
        hiddenInput.value = "";
      }
    }

    let client_id = await document.getElementById("answerInput-hidden").value;
    try {
      setLoading(true);
      await UserServices.addMealPlan(values, client_id);

      setLoading(false);
      toast.success("Added Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
    } catch (err) {
      setLoading(false);

      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };

  const initialValues = {
    branch_id: branch_id,
    meal_category_id: "",
    client_name: "",
    month: "",
    week: "",
    day: "",
    time: "",
    specification: "",
    note: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addMealPlan,
      onSubmit: (values, action) => {
        adddMeal(values, action);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Add Meals Plan
                <Link
                  to="/view-meal-plan"
                  className="btn btn-primary d-inline-block float-end btn-sm mt-3 mt-md-0"
                >
                  View Meals Plan
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <form
                className="row g-3"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    {catgries ? (
                      <select
                        className="form-select"
                        name="meal_category_id"
                        value={values.meal_category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {catgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.meal_category_id && touched.meal_category_id ? (
                      <p className="text-danger">{errors.meal_category_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Client Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Client Name"
                      name="client_name"
                      value={values.client_name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      list="member_id"
                      id="answerInput"
                    />

                    <input
                      type="hidden"
                      name="answer"
                      id="answerInput-hidden"
                    />
                    <datalist id="member_id">
                      {name.map((el, index) => {
                        return (
                          <option
                            key={index}
                            data-value={el.id}
                            value={el.name}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                    </datalist>

                    {errors.client_name && touched.client_name ? (
                      <p className="text-danger">{errors.client_name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Month<span className="text-danger">*</span>
                    </label>
                    <input
                      type="month"
                      className="form-control"
                      placeholder="Enter month"
                      name="month"
                      value={values.month}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <select
                      className="form-select"
                      name="month"
                      value={values.month || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Month</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select> */}
                    {errors.month && touched.month ? (
                      <p className="text-danger">{errors.month}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Week<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Week"
                      name="week"
                      value={values.week}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.week && touched.week ? (
                      <p className="text-danger">{errors.week}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Day<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day"
                      name="day"
                      value={values.day}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.day && touched.day ? (
                      <p className="text-danger">{errors.day}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Enter Time"
                      name="time"
                      value={values.time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.time && touched.time ? (
                      <p className="text-danger">{errors.time}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Specification<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Specification"
                      name="specification"
                      value={values.specification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.specification && touched.specification ? (
                      <p className="text-danger">{errors.specification}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Notes</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      name="note"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.note && touched.note ? (
                      <p className="text-danger">{errors.note}</p>
                    ) : null}
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
