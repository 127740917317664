import React, { useEffect, useState } from "react";

import $ from "jquery";
import Exercises from "./Exercises";

export default function NewFitnessPlanForm({
  formDataIndex,
  onFormChange,

  catagrey,
  subCatgries,
  day,
}) {
  const [selectedSubCatgries, setSelectedSubCatgries] = useState([]);
  const [exercisePlan, setExercisePlan] = useState([]);

  const addSubCatagroy = async (catagries) => {
    const updatedSubCatagries = exercisePlan.map((catagroyItem) => {
      // Check if the day already exists in the fitnessPlan array
      const existingDay = catagries.find(
        (day) => day === catagroyItem.subCatagroyId
      );

      // If the day exists, return the catagroyItem with updated day field
      if (existingDay) {
        return {
          ...catagroyItem,
          subCatagroyId: existingDay,
        };
      }
      // If the day doesn't exist, return the catagroyItem as it is
      return catagroyItem;
    });

    // Add any new days that are not already in the fitnessPlan array
    catagries.forEach((day) => {
      const dayExists = updatedSubCatagries.some(
        (catagroyItem) => catagroyItem.subCatagroyId === day
      );
      if (!dayExists) {
        updatedSubCatagries.push({ subCatagroyId: day });
      }
    });

    // Update the fitnessPlan state with the updated array
    setExercisePlan(updatedSubCatagries);
  };

  const getExercisesList = async (selectedDay) => {
    var selectedSubCatgriesWithName = await $(
      `.sub-catgries-select.${selectedDay} ul li input:checkbox:checked`
    )
      .map(function () {
        return this.value;
      })
      .get();

    if (selectedSubCatgries.length && selectedSubCatgriesWithName.length) {
      const filteredArray = selectedSubCatgries.filter(
        (item) => !selectedSubCatgriesWithName.includes(item)
      );

      if (filteredArray.length) {
        await filteredArray.map((value) => {
          setSelectedSubCatgries(
            selectedSubCatgries.filter((val) => val !== value)
          );
          setExercisePlan(
            exercisePlan.filter((val) => val.subCatagroyId !== value)
          );
          onFormChange(
            formDataIndex,
            exercisePlan.filter((val) => val.subCatagroyId !== value),
            "exercisesList"
          );
        });
      } else {
        setSelectedSubCatgries(selectedSubCatgriesWithName);
        addSubCatagroy(selectedSubCatgriesWithName);
      }
    } else if (
      !selectedSubCatgries.length &&
      selectedSubCatgriesWithName.length
    ) {
      setSelectedSubCatgries(selectedSubCatgriesWithName);
      addSubCatagroy(selectedSubCatgriesWithName);
    } else {
      await setSelectedSubCatgries([]);
      await setExercisePlan([]);
      onFormChange(formDataIndex, [], "exercisesList");
    }
  };
  useEffect(() => {
    getExercisesList(day);
  }, [day]);

  const handleExerciseChange = (exeInd, val, fieldName) => {
    const updatedFormDataw = [...exercisePlan];

    updatedFormDataw[exeInd][fieldName] = val;

    // setExercisePlan(updatedFormDataw);

    onFormChange(formDataIndex, updatedFormDataw, "exercisesList");
  };

  return (
    <div className="card">
      <div className="card-header py-3">
        <h5 className="mb-0 ">{day}</h5>
      </div>
      <div className="card-body">
        <div className="col-12">
          <h5 style={{ marginBottom: "-5px" }}>
            Select{" "}
            {catagrey === 115
              ? "Muscle "
              : catagrey === 116
              ? "Equipmet "
              : catagrey === 117
              ? "Modality "
              : ""}
          </h5>
        </div>
        {subCatgries.length ? (
          <>
            <div className="col-md-12 mt-1 mb-3">
              <div className={`custom-raio sub-catgries-select ${day}`}>
                <ul className="d-block">
                  {subCatgries.map((value, index) => (
                    <li key={index} className="d-inline-block pe-4 mt-3">
                      <input
                        type="checkbox"
                        id={day + value.id}
                        value={value.id + ":" + value.name}
                        onChange={() => {
                          getExercisesList(day);
                        }}
                      />
                      <label htmlFor={day + value.id}>{value.name}</label>
                      <div className="check"></div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              {exercisePlan.map((value, index) => (
                <Exercises
                  data={value}
                  key={index}
                  onFormChange={handleExerciseChange}
                  dataIndex={index}
                  catagrey={catagrey}
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
