import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addPettyCashFlow } from "../schemas";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { useEffect } from "react";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function BranchLedger() {
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const [myBranches, setMyBranches] = useState([]);
    const [loading, setLoading] = useState(false);
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString("en-CA");
    const [bank, setBank] = useState([]);

    const branches = async () => {
        try {
          const responce = await UserServices.branchName();
          const res = responce.data.data;
          const results = [];
    
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.name,
            });
            return null;
          });
          setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
        }
      };
      const bankDetails = async () => {
        try {
          const responce = await UserServices.bankList(branch_id);
          const res = responce.data.data;
          const results = [];
          res.map((value) => {
            results.push({
              id: value.id,
              bank_name: value.bank_name,
              account_no: value.account_no,
            });
            return null;
          });
          setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
          setBank([{ bank_name: "No Bank Found", id: "" }]);
        }
      };
      useEffect(() => {
        branches();
        bankDetails();
      }, []);
      const addCashFlow = async (addCashValues, action) => {
        try {
          setLoading(true);
          const response = await UserServices.GthirteenCashAdd(addCashValues);
    
          if (response.status === 201) {
            setLoading(false);
            toast.success("Added Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            action.resetForm();
          }
        } catch (err) {
          setLoading(false);
          if (err.response.status === 422) {
          } else if (err.response.status === 500) {
            // toast.error("Unproccessable content!", { position: "top-center" });
          } else if (err.response.status === 400) {
            // toast.error("Unproccessable content!", { position: "top-center" });
          }
        }
      };
      const addCashFlowinitialValues = {
        branch_id: branch_id,
        amount: "",
        transaction_type: "",
        description: "",
        type: "",
        date: defaultValue,
        bank_account_id:"",
      };
    
      const {
        values: addCashValues,
        errors: addCashErrors,
        touched: addCashTouched,
        handleBlur: addCashHandleBlur,
        handleChange: addCashHandleChange,
        handleSubmit: addCashHandleSubmit,
      } = useFormik({
        initialValues: addCashFlowinitialValues,
        validationSchema: addPettyCashFlow,
        onSubmit: (addCashValues, action) => {
          addCashFlow(addCashValues, action);
        },
      });
  return (
    <div className="wrapper">
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
        <div className="card-header py-3">
          <div className="row">
            <div className="col-10">
              <h5 className="mb-0 font-20">Add G-13 Cash</h5>
            </div>
            <div className="col-md-2 d-flex justify-content-end">
              <Link
              to="/branch-cash-ledger"
              className="btn btn-primary d-inline-block btn-sm p-2"
              >
               View Ledger
              </Link>
            </div>
          </div>
        </div>
          <div className="card-body">
            <p>
              <i
                className="bi bi-exclamation-lg text-danger"
                aria-hidden="true"
              />
              The Fields With <strong className="text-danger">*</strong>
              Must Required Or Fill.
            </p>

            <form
              className="row g-3"
              onSubmit={(e) => {
                addCashHandleSubmit(e);
              }}
            >
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={addCashValues.branch_id || ""}
                      onChange={(e) => {
                        addCashHandleChange(e);
                      }}
                      onBlur={addCashHandleBlur}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {addCashErrors.branch_id && addCashTouched.branch_id ? (
                    <p className="text-danger">{addCashErrors.branch_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Amount<span className="text-danger">*</span>
                  </label>
                  <input
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                    name="amount"
                    min={0}
                    value={addCashValues.amount}
                    onChange={addCashHandleChange}
                    onBlur={addCashHandleBlur}
                  />
                  {addCashErrors.amount && addCashTouched.amount ? (
                    <p className="text-danger">{addCashErrors.amount}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Type<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    name="type"
                    value={addCashValues.type || ""}
                    onChange={(e) => {
                      addCashHandleChange(e);
                    }}
                    onBlur={addCashHandleBlur}
                  >
                    <option value="">Select Type</option>
                    <option value="Credit">Credit</option>
                    <option value="Debit">Debit</option>
                  </select>
                  {addCashErrors.type && addCashTouched.type ? (
                    <p className="text-danger">{addCashErrors.type}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Transaction<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="transaction_type"
                            value={addCashValues.transaction_type || ""}
                            onChange={(e) => {
                              addCashHandleChange(e);
                            }}
                            onBlur={addCashHandleBlur}
                          >
                            <option value="-1">Select Transaction</option>
                            <option value="Bank Account">Bank Account</option>
                            <option value="Sales Counter">Sales Counter</option>
                            <option value="Office Counter">
                              Office Counter
                            </option>
                            <option value="Personal">Personal</option>
                            <option value="Mr Waqas Personal (Account)">Mr Waqas Personal (Account)</option>
                            <option value="Mr Waqas Credit Card">Mr Waqas Credit Card</option>
                            <option value="Mr Arif">Mr Arif</option>
                            <option value="Mr Bilal">Mr Bilal</option>
                          </select>
                          {addCashErrors.transaction_type &&
                          addCashTouched.transaction_type ? (
                            <p className="text-danger">
                              {addCashErrors.transaction_type}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {addCashValues.transaction_type === "Bank Account" ? (
                        <div className="col-12 col-md-6">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Bank Details<span className="text-danger">*</span>
                            </label>
                            {bank ? (
                              <select
                                className="form-select"
                                name="bank_account_id"
                                value={addCashValues.bank_account_id || ""}
                                onChange={(e) => {
                                  addCashHandleChange(e);
                                }}
                                onBlur={addCashHandleBlur}
                              >
                                {bank.map((el, index) => {
                                  return (
                                    <option key={index} value={el.id}>
                                      {el.bank_name} {el.account_no}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : null}
                            {addCashErrors.bank_account_id &&
                            addCashTouched.bank_account_id ? (
                              <p className="text-danger">
                                {addCashErrors.bank_account_id}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="date"
                    name="date"
                    value={addCashValues.date}
                    onChange={addCashHandleChange}
                    onBlur={addCashHandleBlur}
                  />
                  {addCashErrors.date && addCashTouched.date ? (
                    <p className="text-danger">{addCashErrors.date}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="description"
                    value={addCashValues.description}
                    onChange={addCashHandleChange}
                    onBlur={addCashHandleBlur}
                  />
                  {addCashErrors.description && addCashTouched.description ? (
                    <p className="text-danger">{addCashErrors.description}</p>
                  ) : null}
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-12 ">
                <button
                  type="submit"
                  className="btn btn-primary px-5 rounded-0 mb-1"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  </div>
  )
}
