import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { payLiabilities } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

export default function PayLiabilities() {
  const navigate = useNavigate();
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(defaultValue);
  const [bank, setBank] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [amount, setAmount] = useState("0");
  const [receivedAmount, setReceivedAmount] = useState("0");
  const [remainingAmount, setRemainingAmount] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    if (enteredAmount > remainingAmount) {
      setErrorMessage("Entered amount exceeds the remaining amount.");
    } else {
      setErrorMessage("");
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const bankDetails = async () => {
    try {
      const responce = await UserServices.bankList(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          bank_name: value.bank_name,
          account_no: value.account_no,
        });
        return null;
      });
      setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setBank([{ bank_name: "No Bank Found", id: "" }]);
    }
  };
  const totalAmount = async () => {
    try {
      const response = await UserServices.totalInstallment(id);
      const res = response.data;
      if (res && typeof res === "object") {
        const {
          liability_amount,
          liability_installments_payed_amount,
          liability_pending_amount,
        } = res;
        setAmount(liability_amount);
        setReceivedAmount(liability_installments_payed_amount);
        setRemainingAmount(liability_pending_amount);
      } else {
        console.log("Invalid response format:", res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    branches();
    bankDetails();
    totalAmount();
  }, []);

  const payLiability = async (values, action) => {
    if(errorMessage === ""){
      try {
        setLoading(true);
        const response = await UserServices.laibilitiesPay(values);
  
        if (response.status === 201) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          setTimeout(() => {
            navigate("/view-liabilities")
           }, 1500);
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
        } else if (err.response.status === 500) {
          toast.error("Network Error!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  const initialValues = {
    branch_id: branch_id,
    liability_id: id,
    amount: "",
    date: startDate,
    resource: "",
    bank_account_id: "",
    cheque_no: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: payLiabilities,
      onSubmit: (values, action) => {
        payLiability(values, action);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Pay Liabilities
                <Link
                  to="/view-liabilities"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  View Liabilities
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="expenses-num">
                    Total Amount :{" "}
                    <span>
                      <CurrencyFormat
                        value={amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="expenses-num">
                    Received Amount :{" "}
                    <span>
                      <CurrencyFormat
                        value={receivedAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="expenses-num">
                    Remaining Amount :{" "}
                    <span>
                      <CurrencyFormat
                        value={remainingAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Amount<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="amount"
                      min={0}
                      value={values.amount}
                      onChange={(e) => {
                        handleChange(e);
                        handleAmountChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    {errors.amount && touched.amount ? (
                      <p className="text-danger">{errors.amount}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Resource<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="resource"
                      value={values.resource || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="-1">Select Transaction</option>
                      <option value="Bank Account">Bank Account</option>
                      <option value="Sales Counter">Sales Counter</option>
                      <option value="Office Counter">Office Counter</option>
                      <option value="Personal">Personal</option>
                    </select>
                    {errors.resource && touched.resource ? (
                      <p className="text-danger">{errors.resource}</p>
                    ) : null}
                  </div>
                </div>
                {values.resource === "Bank Account" ? (
                  <>
                    <div className="col-12 col-md-6">
                      <div className="form-group custom-group">
                        <label className="form-label">Bank Details</label>
                        {bank ? (
                          <select
                            className="form-select"
                            name="bank_account_id"
                            value={values.bank_account_id || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {bank.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.bank_name} {el.account_no}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group custom-group">
                        <label className="form-label">Cheque Number</label>
                        <input
                          onWheel={(e) => e.target.blur()}
                          type="text"
                          className="form-control"
                          placeholder="Enter Cheque Number"
                          name="cheque_no"
                          value={values.cheque_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="11-03-2022"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.date && touched.date ? (
                      <p className="text-danger">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
