import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addCafePkg } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer} from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { NoteState } from "../../context/notes/NoteState";
import CafeNav from "../Includes/Navbar/CafeNav";
import CafeSidebar from "../Includes/Sidebar/CafeSidebar";
import CafeMenu from "./CafeMenu";

export default function UpdateCafeProduct() {
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [myBranches, setMyBranches] = useState([]);
    const [nameError, setNameError] = useState("");
    const { id } = useParams();
    const [categories, setCategories] = useState([]);
  
    const [selectedBranches, setSelectedBranches] = useState("");
    const [Price, setPrice] = useState("");
    const [packageName, setPackageName] = useState("");
    const [category, setCategory] = useState("");
    const [cafeCategory, setCafeCategory] = useState("");
    const [file, setFile] = useState("");

    const changeCategory = (e) => {
        setCategory(e.target.value);
      };
    
      const getPkg = async () => {
        setLoading(true);
        try {
          const responce = await UserServices.getCafeInfo(id);
          console.log(responce);
          if (responce.status === 200) {
            let data = responce.data.data.data;
            data.map((values) => {
              setSelectedBranches(values.branch_id);
              setPrice(values.price);
              setPackageName(values.package_name);
              setCategory(values.branch_id);
              setCafeCategory(values.cafe_category_id);
            });
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      const branches = async () => {
        try {
          const responce = await UserServices.branchName();
          const res = responce.data.data;
          const results = [];
    
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.name,
            });
          });
          setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
        }
      };
      const nameVerify = async (e) => {
        var check_name = e.target.value;
        var branch_Id = category;
        try {
          const response = await UserServices.checkPackageName(
            id,
            check_name,
            branch_Id,
            "6"
          );
          if (response.status === 200) {
            setNameError("");
          }
        } catch (err) {
          if (err.response.status === 409) {
            setNameError("Name already exists");
          }
        }
      };
      const CafeCtgry = async () => {
        try {
          const responce = await UserServices.CafeCategory(branch_id);
          const res = responce.data.data;
          const results = [];
    
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.name,
            });
            return null;
          });
          setCategories([{ name: "Select Category", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
        }
      };
      useEffect(() => {
        getPkg();
        branches();
        CafeCtgry();
      }, []);
    
      const handleUpload = async (e) => {
        setFile(e.target.files[0]);
      };
    
      const UpdatePackage = async (values, action) => {
        const formData = new FormData();
    
        if (nameError === "") {
          setLoading(true);
          if (file !== "") {
            formData.append("file", file);
            formData.append("fileName", file.name);
          }
    
          formData.append("branch_id", values.branch_id);
          formData.append("cafe_category_id", values.cafe_category_id);
          formData.append("package_name", values.package_name);
          formData.append("price", values.price);
          formData.append("category", "10");
          formData.append("duration", "0");
          try {
            const response = await UserServices.updateCafePackages(formData, id);
            console.log(response);
            if (response.status === 204) {
              setLoading(false);
              toast.success("Updated Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
              setTimeout(() => {
               navigate("/add-cafe-product")
              }, 1500);
            }
          } catch (err) {
            if (err.response?.status === 422) {
              toast.error("Some data is missing", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
    
              setLoading(false);
            } else {
              toast.error("Some thing went wronge !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
            }
          }
        }
      };
      const initialValues = {
        branch_id: selectedBranches,
        package_name: packageName,
        price: Price,
        cafe_category_id: cafeCategory,
      };
    
      const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
          enableReinitialize: true,
          initialValues: initialValues,
          validationSchema: addCafePkg,
          onSubmit: (values, action) => {
            UpdatePackage(values, action);
          },
        });

  return (
       <>
      <div className="wrapper">
        <CafeNav />
        <CafeSidebar />
        <ToastContainer/>
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <CafeMenu />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Update Cafe</h5>
              </div>
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          value={values.branch_id || ""}
                          disabled={branch_id ? true : false}
                          onChange={(e) => {
                            handleChange(e);
                            changeCategory(e);
                            setSelectedBranches(e.target.value);
                            values.package_name = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Category<span className="text-danger">*</span>
                      </label>
                      {categories ? (
                        <select
                          className="form-select"
                          name="cafe_category_id"
                          value={values.cafe_category_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {categories.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.cafe_category_id && touched.cafe_category_id ? (
                        <p className="text-danger">{errors.cafe_category_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Product Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="package_name"
                        value={values.package_name}
                        onChange={(e) => {
                          handleChange(e);
                          nameVerify(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {nameError ? (
                        <div className="errorMsg text-danger">{nameError}</div>
                      ) : null}
                      {errors.package_name && touched.package_name ? (
                        <p className="text-danger">{errors.package_name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">Product Image</label>
                      <input
                        className="form-control"
                        type="file"
                        onClick={(e) => {
                          e.target.value = null;
                          setFile("");
                        }}
                        onChange={handleUpload}
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Price<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Price"
                        name="price"
                        min={0}
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.price && touched.price ? (
                        <p className="text-danger">{errors.price}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  )
}
