import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { Link } from "react-router-dom";

const UserRole = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const { state } = useContext(NoteState);

  // eslint-disable-next-line
  const { branch_id } = state;

  const getUsers = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getUserRoles();
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            role: values.role,
            name: values.name,
            image: values.image,
            email: values.email,
          });
          return null;
        });
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getUserRolesData(
          //   branch_id,
          limit,
          // "1",
          pageNo
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              role: values.role,
              name: values.name,
              image: values.image,
              email: values.email,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getUserRolesData(
          //   branch_id,
          newLimit,
          1
          // "1",
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              role: values.role,
              name: values.name,
              image: values.image,
              email: values.email,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr className="">
              <th scope="col" className="no_sort ">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("role")}
                className={getClassNamesFor("role")}
              >
                User Role
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index} className="">
                  <td className="custom-table-row-1">
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>

                  <td>
                    <div className="d-flex">
                      <div className="image-for-table">
                        {el.image ? (
                          <img
                            src={el.image}
                            alt="img"
                            className=" image-fluid custom-iamge-fortable"
                          />
                        ) : (
                          <img
                            src="/assets/images/avator1.jpg"
                            alt="img"
                            className=" image-fluid custom-iamge-fortable"
                          />
                        )}
                      </div>
                      <div className="ms-3 mt-3">{el.name}</div>
                    </div>
                  </td>
                  <td>{el.email ? el.email : "N/A"}</td>
                  <td>
                    {el.role === "1" && "Admin"}
                    {el.role === "2" && "Fitness Manager"}
                    {el.role === "3" && "Sales Representative"}
                  </td>
                  <td>
                    <div className="d-flex">
                      <Link
                        to={"/update-role/" + el.id}
                        className="d-flex updt-btn"
                      >
                        <i className="bi bi-gear text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Modify role
                        </span>
                      </Link>
                      <button
                        type="button"
                        className="ms-3 d-flex inactive-btn"
                      >
                        <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Activate
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize ">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Super Admin</h5>
            </div>
            <div className="table-responsive mt-3">
              <div className="col-md-1 col-lg-1 mb-2 ms-2">
                <div className="input-group">
                  <select
                    className="form-select"
                    value={limit}
                    onChange={(e) => changeLimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>
              <Table data={myData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default UserRole;
