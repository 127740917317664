import React, { useContext } from "react";

import { useState } from "react";

import { toast } from "react-toastify";
import FitnessPlanForm from "./FitnessPlanForm";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MenuButton from "../../MenuButton/MenuButton";
import UserServices from "../../../UserServices/UserServices";
import { NoteState } from "../../../context/notes/NoteState";
import { useFormik } from "formik";
import { addFitnessPlan } from "../../schemas";

export default function AddFitnessPlan() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [fitnessPlan, setFitnessPlan] = useState([
    { id: "lib0" },
    { id: "lib1" },
    { id: "lib2" },
    { id: "lib3" },
    { id: "lib4" },
    { id: "lib5" },
    { id: "lib6" },
  ]);
  const [fitnessPlanError, setFitnessPlanError] = useState([]);
  const [myBranches, setMyBranches] = useState([]);
  const [libCount, setLibCount] = useState(7);
  const [catgries, setCatgries] = useState([]);
  const [trainerName, setTrainerName] = useState([]);
  const [myPackage, setMyPackage] = useState([]);

  const add = async () => {
    setFitnessPlan([
      ...fitnessPlan,
      {
        id: "lib" + libCount,
      },
    ]);
    setLibCount(libCount + 1);
  };
  const remove = async (id) => {
    const updatedRecords = await fitnessPlan.filter(
      (record) => record.id != id
    );

    await setFitnessPlan(updatedRecords);

    setFitnessPlanError([]);
  };

  const handleFormChange = (formDataIndex, val, fieldName) => {
    const updatedFormDataw = [...fitnessPlan];

    updatedFormDataw[formDataIndex][fieldName] = val;

    setFitnessPlan(updatedFormDataw);
  };

  const handleSubmitAllForms = async () => {
    const filteredArray = fitnessPlan
      .map((obj, index) => ({ ...obj, originalIndex: index }))
      .filter(
        (obj) =>
          obj.category_id ||
          obj.sub_category_id ||
          obj.exercise_id ||
          obj.day ||
          obj.sets ||
          obj.reps ||
          obj.duration ||
          obj.intensity ||
          obj.work_interval ||
          obj.rest_interval ||
          obj.description
      );

    const formattedArray = filteredArray.map((obj) => ({
      ...obj,
    }));
    if (formattedArray.length) {
      try {
        let e = document.getElementById("answerInput");
        var input = e,
          list = input.getAttribute("list"),
          options = document.querySelectorAll("#" + list + " option"),
          hiddenInput = document.getElementById(
            input.getAttribute("id") + "-hidden"
          ),
          inputValue = input.value;

        // hiddenInput.value = inputValue;

        for (var i = 0; i < options.length; i++) {
          var option = options[i];

          if (option.innerText === inputValue) {
            hiddenInput.value = await option.getAttribute("data-value");
            break;
          } else {
            hiddenInput.value = "";
          }
        }

        let client_id = await document.getElementById("answerInput-hidden")
          .value;
        setLoading(true);

        const responce = await UserServices.addMultipleFitnessPlans(
          formattedArray,
          values,
          client_id
        );

        if (responce.status === 201) {
          setLoading(false);

          Swal.fire({
            icon: "success",
            title: "Added Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/fitness-plan");
        }
      } catch (err) {
        console.log(err);
        setLoading(false);

        if (err?.response?.status === 422) {
          let res = err?.response?.data?.errors;

          setFitnessPlanError(res);
        } else if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Fill atleast one row!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const categories = async (branch_id) => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Exercise"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Option", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Option Found", id: "" }]);
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const packagename = async (e) => {
    var trainer_Id = e;

    try {
      const responce = await UserServices.getOrderName(trainer_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          name: value.client_name,
          id: value.client_id,
        });
        return null;
      });
      setMyPackage([...results]);
    } catch (err) {
      console.log(err);
      setMyPackage([]);
    }
  };

  useEffect(() => {
    branches();
    categories(branch_id);
    gymTrainer(branch_id);
    if (role === "9") {
      packagename(UId);
    }
  }, []);

  const initialValues = {
    branch_id: branch_id,
    trainer_id: "",
    client_name: "",
    start_date: "",
    end_date: "",
    note: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addFitnessPlan,
      onSubmit: () => {
        handleSubmitAllForms();
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Add Fitness Plan
                <Link
                  to="/fitness-plan"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  View Fitness Plan
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <div className="row  mb-3">
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name <span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          gymTrainer(e.target.value);
                          categories(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    Available Trainers <span className="text-danger">*</span>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={values.trainer_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                          packagename(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.trainer_id && touched.trainer_id ? (
                      <p className="text-danger">{errors.trainer_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Client Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Client Name"
                      name="client_name"
                      list="member_id"
                      autoComplete="off"
                      id="answerInput"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <input type="hidden" name="answer" id="answerInput-hidden" />
                  <datalist id="member_id">
                    {myPackage.map((el, index) => {
                      return (
                        <option key={index} data-value={el.id} value={el.name}>
                          {el.name}
                        </option>
                      );
                    })}
                  </datalist>
                  {errors.client_name && touched.client_name ? (
                    <p className="text-danger">{errors.client_name}</p>
                  ) : null}
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Start Date<span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      name="start_date"
                      autoComplete="off"
                      value={values.start_date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.start_date && touched.start_date ? (
                      <p className="text-danger">{errors.start_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      End Date<span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      className="form-control"
                      placeholder="End Date"
                      name="end_date"
                      autoComplete="off"
                      value={values.end_date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.end_date && touched.end_date ? (
                      <p className="text-danger">{errors.end_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">Note</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="End Note"
                      name="note"
                      value={values.note}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.note && touched.note ? (
                      <p className="text-danger">{errors.note}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              {fitnessPlan.length ? (
                <div style={{ overflow: "auto" }}>
                  <table className="table-bordered w-100 table-striped add-expense-table">
                    {fitnessPlan.map((paln, index) => (
                      <FitnessPlanForm
                        formDataIndex={index}
                        key={paln.id}
                        idUnit={paln.id}
                        catgries={catgries}
                        removeForm={remove}
                        onFormChange={handleFormChange}
                        fitnessPlanError={fitnessPlanError[index]}
                      />
                    ))}
                  </table>
                </div>
              ) : null}
              <div className="row">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-primary px-4 reverse-bg mt-0 mb-3 mt-3"
                    onClick={handleSubmit}
                  >
                    Submit All
                  </button>
                </div>
                <div className="col-6 text-end">
                  <button
                    type="button"
                    className="btn btn-primary px-4 reverse-bg mt-0 mb-3 mt-3"
                    onClick={add}
                  >
                    Add New Row
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
