import React, { useContext } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MenuButton from "../../MenuButton/MenuButton";
import UserServices from "../../../UserServices/UserServices";
import { NoteState } from "../../../context/notes/NoteState";
import { useFormik } from "formik";
import { newAddFitnessPlan } from "../../schemas";
import NewFitnessPlanForm from "./NewFitnessPlanForm";
import $ from "jquery";

export default function CreatePlan() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [myBranches, setMyBranches] = useState([]);

  const [trainerName, setTrainerName] = useState([]);
  const [myPackage, setMyPackage] = useState([]);

  const handleSubmitAllForms = async () => {
    try {
      let e = document.getElementById("answerInput");
      var input = e,
        list = input.getAttribute("list"),
        options = document.querySelectorAll("#" + list + " option"),
        hiddenInput = document.getElementById(
          input.getAttribute("id") + "-hidden"
        ),
        inputValue = input.value;

      // hiddenInput.value = inputValue;

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (option.innerText === inputValue) {
          hiddenInput.value = await option.getAttribute("data-value");
          break;
        } else {
          hiddenInput.value = "";
        }
      }

      let client_id = await document.getElementById("answerInput-hidden").value;
      setLoading(true);

      const responce = await UserServices.createPlanForClient(
        values,
        client_id
      );

      if (responce.status === 201) {
        setLoading(false);

        navigate("/add-fitness-plan/" + responce.data.plan_id);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const packagename = async (e) => {
    var trainer_Id = e;

    try {
      const responce = await UserServices.getOrderName(trainer_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          name: value.client_name,
          id: value.client_id,
        });
        return null;
      });
      setMyPackage([...results]);
    } catch (err) {
      console.log(err);
      setMyPackage([]);
    }
  };

  useEffect(() => {
    branches();
    gymTrainer(branch_id);
    if (role === "9") {
      packagename(UId);
    }
  }, []);

  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" && UId ? UId : "",
    client_name: "",
    start_date: "",
    end_date: "",
    note: "",
  };


  

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: newAddFitnessPlan,
      onSubmit: () => {
        handleSubmitAllForms();
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent fitness-plan-add-section">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Add Fitness Plan</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form className="row  mb-3" onSubmit={handleSubmit}>
                <div className="col-12 col-lg-4 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name <span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          gymTrainer(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                  <div className="form-group custom-group">
                    Available Trainers <span className="text-danger">*</span>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={values.trainer_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                          packagename(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.trainer_id && touched.trainer_id ? (
                      <p className="text-danger">{errors.trainer_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Client Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Client Name"
                      name="client_name"
                      list="member_id"
                      autoComplete="off"
                      id="answerInput"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <input type="hidden" name="answer" id="answerInput-hidden" />
                  <datalist id="member_id">
                    {myPackage.map((el, index) => {
                      return (
                        <option key={index} data-value={el.id} value={el.name}>
                          {el.name}
                        </option>
                      );
                    })}
                  </datalist>
                  {errors.client_name && touched.client_name ? (
                    <p className="text-danger">{errors.client_name}</p>
                  ) : null}
                </div>
                <div className="col-12 col-lg-4 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Start Date<span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      name="start_date"
                      autoComplete="off"
                      value={values.start_date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.start_date && touched.start_date ? (
                      <p className="text-danger">{errors.start_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      End Date<span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      className="form-control"
                      placeholder="End Date"
                      name="end_date"
                      autoComplete="off"
                      value={values.end_date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.end_date && touched.end_date ? (
                      <p className="text-danger">{errors.end_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Note</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="End Note"
                      name="note"
                      value={values.note}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.note && touched.note ? (
                      <p className="text-danger">{errors.note}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-primary px-4 reverse-bg"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
