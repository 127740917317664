import React, { useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

import CurrencyFormat from "react-currency-format";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import Swal from "sweetalert2";

export default function InActiveInAccounts() {
  const [loading, setLoading] = useState(false);

  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");

  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [clientName, setClientName] = useState("");
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  let sDate = moment("01-01-" + date.getFullYear()).format("YYYY-MM-DD");

  const [startDate, setstartDate] = useState(sDate);
  const [endDate, setendDate] = useState(eDate);

  const getAccountDetails = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsAccounts(
        id,
        "0",
        // startDate,
        // endDate,
        limit,
        "1"
      );

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        setClientName(
          myDataarr[0]?.client_first_name + " " + myDataarr[0]?.client_last_name
        );
        const results = [];

        res.map((value) => {
          results.push({
            price: value.price,
            order_date: value.date,
            net_price: value.net_price,
            sale_date: value.sale_date,
            start_date: value.start_date,
            end_date: value.end_date,
            note: value.note,
            package_name: value.package_name,
            note: value.note,
            discount: value.discount,
            tax: value.tax ? value.tax : 0,

            order_id: value.order_id,
            id: value.id,
            category: value.category,
          });
        });

        setMyData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getAccountDetails();
  }, []);

  const changePagination = (e) => {
    setCurrentPage(e);

    let pageNo = e;

    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientsAccounts(
          id,
          "0",
          // startDate,
          // endDate,
          limit,
          pageNo
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,
              sale_date: value.sale_date,
              start_date: value.start_date,
              end_date: value.end_date,
              note: value.note,
              package_name: value.package_name,
              note: value.note,
              discount: value.discount,
              tax: value.tax ? value.tax : 0,

              order_id: value.order_id,
              id: value.id,
              category: value.category,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };

  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    // console.log(limit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientsAccounts(
          id,
          "0",
          // startDate,
          // endDate,
          newLimit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,
              sale_date: value.sale_date,
              start_date: value.start_date,
              end_date: value.end_date,
              note: value.note,
              package_name: value.package_name,
              note: value.note,
              discount: value.discount,
              tax: value.tax ? value.tax : 0,

              order_id: value.order_id,
              id: value.id,
              category: value.category,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };

  const changeStartDate = async (e) => {
    // console.log("start date is :" + startDate);
    // console.log("end date is :" + endDate);
    var NewSDdte = moment(e.target.value).format("YYYY-MM-DD");
    if (Date.parse(NewSDdte) <= Date.parse(endDate)) {
      setstartDate(NewSDdte);
      setLoading(true);
      try {
        const responce = await UserServices.getClientsAccounts(
          id,
          "0",
          NewSDdte,
          endDate,
          limit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          setClientName(
            myDataarr[0]?.client_first_name +
              " " +
              myDataarr[0]?.client_last_name
          );
          const results = [];

          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,
              sale_date: value.sale_date,
              start_date: value.start_date,
              end_date: value.end_date,
              note: value.note,
              package_name: value.package_name,
              note: value.note,
              discount: value.discount,
              tax: value.tax ? value.tax : 0,

              order_id: value.order_id,
              id: value.id,
              category: value.category,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start date shoukd be less then end date!",
      });
    }
  };
  const changeEndDate = async (e) => {
    var NewEDdte = moment(e.target.value).format("YYYY-MM-DD");
    if (Date.parse(NewEDdte) >= Date.parse(startDate)) {
      setendDate(NewEDdte);
      setLoading(true);
      try {
        const responce = await UserServices.getClientsAccounts(
          id,
          "0",
          startDate,
          NewEDdte,
          limit,
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          setClientName(
            myDataarr[0]?.client_first_name +
              " " +
              myDataarr[0]?.client_last_name
          );
          const results = [];

          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,
              sale_date: value.sale_date,
              start_date: value.start_date,
              end_date: value.end_date,
              note: value.note,
              package_name: value.package_name,
              note: value.note,
              discount: value.discount,
              tax: value.tax ? value.tax : 0,

              order_id: value.order_id,
              id: value.id,
              category: value.category,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date shoukd be greater then start date!",
      });
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="link-hirarcy pt-0 mb-2">
          Account Details »{" "}
          <span className="text-danger">Inactive Packages</span>
        </div>
        <div className="row mb-4">
          <div className="col-md-4  col-lg-4">
            <label className="form-label">From</label>
            <input
              type="date"
              className="form-control"
              placeholder="date"
              value={startDate}
              onChange={(e) => {
                changeStartDate(e);
              }}
            />
          </div>

          <div className="col-md-4  col-lg-4">
            <label className="form-label">To</label>
            <input
              type="date"
              className="form-control"
              placeholder="date"
              value={endDate}
              onChange={(e) => {
                changeEndDate(e);
              }}
            />
          </div>

          <div className="col-md-1 col-lg-1 ms-auto">
            <label className="form-label">Limit</label>
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless table-style-1 caption-top">
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("order_id")}
                  className={getClassNamesFor("order_id")}
                >
                  Order Number
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("package_name")}
                  className={getClassNamesFor("package_name")}
                >
                  Package Name
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("category")}
                  className={getClassNamesFor("category")}
                >
                  Package Type
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("sale_date")}
                  className={getClassNamesFor("sale_date")}
                >
                  Purchase Date
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("start_date")}
                  className={getClassNamesFor("start_date")}
                >
                  Start Date
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("end_date")}
                  className={getClassNamesFor("end_date")}
                >
                  End Date
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("price")}
                  className={getClassNamesFor("price")}
                >
                  Package Price
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("discount")}
                  className={getClassNamesFor("discount")}
                >
                  Discount
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("tax")}
                  className={getClassNamesFor("tax")}
                >
                  GST
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("net_price")}
                  className={getClassNamesFor("net_price")}
                >
                  Net Price
                </th>
                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>

                    <td>{el.order_id}</td>
                    <td>
                      {el.package_name !== null
                        ? el.package_name
                        : "Guest Pass"}

                      {el.note === "" ? null : (
                        <>
                          <i
                            className="bi bi-info-square note-here"
                            data-tooltip-id={el.id}
                            data-tooltip-content={el.note}
                          ></i>
                          <Tooltip
                            id={el.id}
                            place="right"
                            className="note-tooltip"
                            clickable
                          />
                        </>
                      )}
                    </td>
                    <td>
                      {el.category === "1"
                        ? "Gym"
                        : el.category === "2"
                        ? "Trainer"
                        : el.category === "3"
                        ? "Guest"
                        : el.category === "4"
                        ? "Small Group PT"
                        : el.category === "5"
                        ? "Nutritionist"
                        : el.category === "6"
                        ? "Registration"
                        : el.category === "7"
                        ? "Bootcamp"
                        : el.category === "12"
                        ? "LesMills"
                        : null}
                    </td>

                    <td>{moment(el.sale_date).format("DD-MM-YYYY")}</td>
                    <td>
                      {el.start_date === "0000-00-00"
                        ? "N/A"
                        : moment(el.start_date).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {el.end_date === "0000-00-00"
                        ? "N/A"
                        : moment(el.end_date).format("DD-MM-YYYY")}
                    </td>

                    <td>
                      <CurrencyFormat
                        value={el.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"2s"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.discount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"2s"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.tax}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"2s"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.net_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"2s"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </td>

                    <td>
                      {el.start_date === "0000-00-00" &&
                      el.end_date === "0000-00-00" ? (
                        <span className="text-muted">
                          <i
                            className="bi bi-pencil-fill"
                            style={{ pointerEvents: "none" }}
                          ></i>
                        </span>
                      ) : (
                        <Link
                          className="text-warning"
                          to={"/update-dates/" + el.id}
                        >
                          <i className="bi bi-pencil-fill"></i>
                        </Link>
                      )}
                      <Link
                        to={"/invoice/account-details/" + el.id}
                        className="text-success ms-2"
                      >
                        <i className="bi bi-printer-fill"></i>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {totalResults > limit && totalPages > 1 ? (
          <div className="mt-2">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
          </div>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className="card">
      <div className="card-body">
        <Table data={myData} rowsPerPage={limit} />
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
