import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import $ from "jquery";

const MenuButton = () => {
  const { state } = useContext(NoteState);
  const { branch_id, role } = state;
  const [clientName, setClientName] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showHistoryList, setShowHiostoryList] = useState(false);
  const inputRef = useRef(null);
  const [data, setData] = useState([]);

  const getClientsNames = async (e) => {
    try {
      const response = await UserServices.overAllClientSearch(
        branch_id,
        e.target.value
      );
      const res = response.data.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
          phone: value.phone,
        });
      });
      setClientName([...results]);
      setShowList(true);
    } catch (err) {
      if (err.response.status === 404) {
        // Error handling logic
      }
      console.log(err);
      setClientName([]);
    }
  };

  const getSearchHistory = async () => {
    try {
      const responce = await UserServices.getHistory(branch_id);
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            phone: values.phone,
          });
          return null;
        });

        setData([...results]);
        setShowHiostoryList(true);
      }
    } catch (err) {
      if (err.response.status === 404) {
      }
    }
  };
  useEffect(() => {
    $(window).on("scroll", function () {
      $(this).scrollTop() > 300
        ? $(".back-to-top").addClass("showbtn")
        : $(".back-to-top").removeClass("showbtn");
    });

    // Add event listener to document object
    document.addEventListener("click", handleClick);
    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (event) => {
    // Hide list when user clicks outside the input field and the list
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      !event.target.closest(".client-searched-record")
    ) {
      setShowList(false);
    }
  };

  const handleMenu = () => {
    $(".site-main-design").toggleClass("full-show");
    $(".site-footer-main").toggleClass("full-show");
  };

  $(".generate_report").click(function () {
    $(".generate_report").removeClass("active");
    $(this).addClass("active");
    $(".multi-button").addClass("active");
  });

  const addSearchHistory = async (id) => {
    try {
      const response = await UserServices.addHistory(id);

      if (response.status === 201) {
      }
    } catch (err) {
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Click occurred outside the input
        setShowHiostoryList(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div className="wrapper">
      <div className="input-group mb-4 mt-2">
        <button
          id="menu-open-close"
          data-bs-target="#sidebar"
          data-bs-toggle="collapse"
          className="border d-none d-lg-inline-block rounded-0 p-1 text-decoration-none bg-danger text-white"
          onClick={handleMenu}
        >
          <i className="bi bi-list bi-lg py-2 p-1" /> Menu
        </button>

        <input
          type="text"
          className={`form-control rounded-0 border-0 ${
            role !== "9" && role !== "10" && role !== "11"
              ? "visible"
              : "invisible"
          }`}
          placeholder="Search"
          onChange={(e) => {
            if (role !== "9" && role !== "10" && role !== "11") {
              getClientsNames(e);
              setShowHiostoryList(false);
            }
          }}
          onFocus={() => {
            if (role !== "9" && role !== "10") {
              getSearchHistory();
              setShowHiostoryList(true);
            }
          }}
          ref={inputRef}
        />

        <button
          id="menu-open-close"
          data-bs-target="#sidebar"
          data-bs-toggle="collapse"
          className="border rounded-0 p-1 text-decoration-none bg-danger text-white d-lg-none "
          onClick={handleMenu}
        >
          <i className="bi bi-list bi-lg py-2 p-1" /> Menu
        </button>
      </div>
      {showList ? (
        <>
          <ul className="client-searched-record">
            {clientName.length ? (
              clientName.map((el, index) => (
                <li key={index}>
                  <Link
                    to={"/client-home/" + el.id}
                    onClick={() => addSearchHistory(el.id)}
                  >
                    {el.name}
                    <span>{el.phone}</span>
                    <div className="clearfix"></div>
                  </Link>
                </li>
              ))
            ) : (
              <li className="no-record-li">No record found</li>
            )}
          </ul>
        </>
      ) : null}
      {showHistoryList ? (
        <>
          <div>
            <ul className="client-searched-record">
              <li className="recnt-history">
                Recently Searched <i className="bi bi-clock-history "></i>
              </li>
              {data.length ? (
                data.map((el, index) => (
                  <li key={index}>
                    <Link to={"/client-home/" + el.id}>
                      {el.name}
                      <span>{el.phone}</span>
                      <div className="clearfix"></div>
                    </Link>
                  </li>
                ))
              ) : (
                <li className="no-record-li-1">No search history found</li>
              )}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MenuButton;
