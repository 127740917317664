import React, { useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { clientSearch } from "../schemas";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";

export default function DepositClientBalance() {
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [myData, setMyData] = useState([]);
    const [clientName, setClientName] = useState([]);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState("25");
    const [totalResults, setTotalResults] = useState("0");
    const [totalPages, setTotalPages] = useState("1");
  
  /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      const nameClients = async () => {
        try {
          const responce = await UserServices.getClientsName(branch_id);
  
          const res = responce.data.data;
          const results = [];
          res.map((value) => {
            results.push({
              name: value.first_name + " " + value.last_name,
            });
            return null;
          });
          setClientName([...results]);
        } catch (err) {
          console.log(err);
          setClientName([]);
        }
      };
      nameClients();
    }, []);
  
    const changePagination = (e) => {
      setCurrentPage(e);
  
      let pageNo = e;
  
      const getStaffData = async () => {
        setLoading(true);
  
        try {
          const responce = await UserServices.clientSearch(
            values,
            branch_id,
            "1",
            limit,
            pageNo
          );
          console.log(responce);
          let myDataarr;
  
          if (responce.status === 200) {
            myDataarr = responce.data.data.data;
            const res = myDataarr;
            const results = [];
  
            res.map((value) => {
              results.push({
                id: value.id,
                first_name: value.first_name,
                last_name: value.last_name,
                email: value.email,
                desigination: value.desigination,
                phone: value.phone,
                gender: value.gender,
                address: value.address,
                city: value.city,
              });
              return null;
            });
  
            setMyData([...results]);
  
            setLimit(responce.data.data.per_page);
            setTotalResults(responce.data.totalRecord);
            setTotalPages(responce.data.totalPages);
            setCurrentPage(responce.data.data.current_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setTotalResults("0");
            setTotalPages("1");
            setMyData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      getStaffData();
    };
    const changeLimit = (e) => {
      let newLimit = e;
      setLimit(newLimit);
      const getStaffData = async () => {
        setLoading(true);
  
        try {
          const responce = await UserServices.clientSearch(
            values,
            branch_id,
            "1",
            newLimit,
            "1"
          );
  
          let myDataarr;
  
          if (responce.status === 200) {
            myDataarr = responce.data.data.data;
            const res = myDataarr;
            const results = [];
  
            res.map((value) => {
              results.push({
                id: value.id,
                first_name: value.first_name,
                last_name: value.last_name,
                email: value.email,
                desigination: value.desigination,
                phone: value.phone,
                gender: value.gender,
                address: value.address,
                city: value.city,
              });
              return null;
            });
  
            setMyData([...results]);
            setTotalResults(responce.data.totalRecord);
            setTotalPages(responce.data.totalPages);
            setCurrentPage(responce.data.data.current_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setTotalResults("0");
            setTotalPages("1");
            setMyData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      getStaffData();
    };
  
    const searchClient = async (values) => {
      setLoading(true);
  
      try {
        const response = await UserServices.clientSearch(
          values,
          branch_id,
          "1",
          limit,
          "1"
        );
        setShow(true);
        console.log(response);
        let myDataarr;
  
        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              uid: value.uid,
              phone: value.phone,
            });
            return null;
          });
          setMyData([...results]);
  
          setLimit(response.data.data.per_page);
          setTotalResults(response.data.totalRecord);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.data.current_page);
  
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setShow(true);
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
  
    const Table = ({ data, rowsPerPage }) => {
      const { slice } = useTable(data, 1, rowsPerPage);
      const { items, requestSort, sortConfig } = useSortableData(slice);
  
      const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
      };
  
      return (
        <>
          <table className="table table-striped table-borderless table-style-1">
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  No
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("first_name")}
                  className={getClassNamesFor("first_name")}
                >
                  Client ID
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("desigination")}
                  className={getClassNamesFor("desigination")}
                >
                  Membership Number
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("email")}
                  className={getClassNamesFor("email")}
                >
                  Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("email")}
                  className={getClassNamesFor("email")}
                >
                  Phone
                </th>
                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>
                    <td>{el.id}</td>
                    <td>{el.uid}</td>
                    <td>
                      <Link to={"/client-home/" + el.id}>
                        {el.first_name} {el.last_name}
                      </Link>
                    </td>
                    <td>{el.phone}</td>
                    <td>
                      <Link
                        to={"/deposit/" + el.id + "/new"}
                        className=" renew-pkg custom-block-button"
                      >
                        Go
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              onChange={(e) => {
                changePagination(e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </>
      );
    };
  
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
      useFormik({
        enableReinitialize: true,
        initialValues: { bySearch: "3", searchVal: "" },
        validationSchema: clientSearch,
        onSubmit: (values) => {
          searchClient(values);
        },
      });
  return (
    <div className="wrapper">
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">Search Clients for Deposit Cafe Balance</h5>
          </div>
          <div className="card-body">
            <h5>Search</h5>
            <form className="row g-3 trainee-add" onSubmit={handleSubmit}>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <select
                    className="form-select"
                    name="bySearch"
                    value={values.bySearch || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Search By</option>
                    <option value="1">By Client ID</option>
                    <option value="2">By Membership No</option>
                    <option value="3">By Name</option>
                  </select>
                  {errors.bySearch && touched.bySearch ? (
                    <p className="text-danger">{errors.bySearch}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <input
                    type="text"
                    className="form-control"
                    name="searchVal"
                    autoComplete="off"
                    value={values.searchVal || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    list="searchVal"
                  />
                  <datalist id="searchVal">
                    {clientName.map((el, index) => {
                      return (
                        <option key={index} value={el.name}>
                          {el.name}
                        </option>
                      );
                    })}
                  </datalist>

                  {errors.searchVal && touched.searchVal ? (
                    <p className="text-danger">{errors.searchVal}</p>
                  ) : null}
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-12 text-center">
                <button
                  type="submit"
                  className="btn btn-primary px-5 rounded-0 mb-1"
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-5 rounded-0 mb-1 ms-2"
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </button>
              </div>
            </form>
            {show && (
              <>
                <div className="row">
                  <div className="col-md-8 col-lg-8"></div>
                  {totalResults > limit ? (
                    <div className="col-md-4 col-lg-4 mb-2">
                      <select
                        className="form-select"
                        onChange={(e) => {
                          changeLimit(e.target.value);
                        }}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="table-responsive mt-3">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  </div>
  )
}
