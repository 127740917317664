import React, { useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
// import { Link } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import "./StaffCard.css";
import moment from "moment";

const StaffCard = () => {
  const [date, setDate] = useState("");
  const [department, setDepartment] = useState("");
  const [uid, setUid] = useState("");
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { branch_id, id } = useParams();
  const cardData = async () => {
    try {
      const responce = await UserServices.getPrintStaffCards(branch_id, id);
      console.log(responce);
      const res = responce.data.data;
      res.map((value) => {
        setDate(moment(value.joining).format("MM-DD-YYYY"));
        setDepartment(value.department);
        const cleanedMemberId = value.uid.replace(/-/g, ""); // "abc123def456ghi789"
        setUid(cleanedMemberId);
        const uppercaseMemberId = cleanedMemberId.toUpperCase(); // "ABC123DEF456GHI789"
        setUid(uppercaseMemberId);
        setImage(value.image);
        setFirstName(value.first_name);
        setLastName(value.last_name);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    cardData();
  }, []);

  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Print Card
                {/* <Link
                to="/viewtrainer"
                className="btn btn-danger d-inline-block float-end btn-sm"
              >
                <i className="bi bi-x" />
                Done
              </Link> */}
                <button
                  className="btn btn-success d-inline-block float-end btn-sm"
                  onClick={() => window.print()}
                >
                  Print Card
                </button>
              </h5>
            </div>
            <div className="card-body">
              <div className="container">
                <div
                  id="card"
                  style={{
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    width: "490px",
                    height: "309px",
                    margin: "20px auto",
                  }}
                >
                  <div
                    style={{
                      width: "490px",
                      height: "309px",
                      backgroundImage: "url(/assets/images/card.jpg)",
                      margin: "0 auto",

                      display: "block",
                      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                      WebkitPrintColorAdjust: "exact",
                      WebkitFilter: "opacity(1)",
                    }}
                  >
                    <div
                      style={{
                        width: "27px",
                        height: "200px",
                        backgroundColor: "#06a180",
                        position: "absolute",
                        top: "18%",
                        borderTopRightRadius: "30px",
                        borderBottomRightRadius: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "200px",
                        height: "27px",
                        backgroundColor: "black",
                        position: "absolute",
                        right: "0",
                        bottom: "0",
                        borderTopLeftRadius: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        left: "8%",
                        top: "20%",
                        position: "absolute",
                      }}
                    >
                      <img
                        src="/assets/images/logo.png"
                        alt="logo"
                        style={{ width: "75%" }}
                      ></img>
                    </div>
                    <div
                      style={{
                        left: "8%",
                        top: "44%",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Staff Id:
                      </div>
                    </div>
                    <div
                      style={{
                        left: "32%",
                        top: "44%",
                        position: "absolute",
                        color: "black",
                      }}
                    >
                      <p>{uid}</p>
                    </div>
                    <div
                      style={{
                        left: "8%",
                        top: "55%",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Department:
                      </div>
                      <div
                        style={{
                          left: "136%",
                          top: "0",
                          position: "absolute",
                          color: "black",
                          width: "120px",
                        }}
                      >
                        <p>{department}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        left: "8%",
                        top: "66%",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Joining Date:
                      </div>
                    </div>
                    <div
                      style={{
                        left: "32%",
                        top: "66%",
                        position: "absolute",
                        color: "black",
                      }}
                    >
                      <p>{date}</p>
                    </div>
                    <div
                      style={{
                        width: "173px",
                        height: "173px",
                        backgroundColor: "#06a180",
                        position: "absolute",
                        borderRadius: "50%",
                        top: "8%",
                        left: "61%",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "170px",
                        height: "170px",
                        position: "absolute",
                        borderRadius: "50%",
                        top: "10%",
                        left: "61%",
                        overflow: "hidden",
                        backgroundImage: `url("${
                          image ? image : "/assets/images/placeholder2.png"
                        }")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {/* <img
                      alt="img"
                      style={{
                        width: "170px",
                        height: "170px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        backgroundSize: "cover",
                        backgroundPosition: " center center",
                      }}
                    ></img> */}
                    </div>
                    <div
                      style={{
                        top: "71%",
                        position: "absolute",
                        left: "58%",
                        width: "200px",
                      }}
                    >
                      <div
                        style={{
                          color: "#06a180",
                          fontWeight: "500",
                          fontSize: "21px",
                          textAlign: "center",
                        }}
                      >
                        {firstName} {lastName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button  className="btn btn-danger d-inline-block btn-sm" onClick={() => window.print()}>Print Card</button> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default StaffCard;
