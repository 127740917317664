import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useParams } from "react-router";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import { addCrads } from "../schemas";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";

const NewCard = () => {
  const [loading, setLoading] = useState(false);
  const [Name, setName] = useState("");
  const { id, type } = useParams();
  const [cardError, setCardError] = useState("");
  // const [descript, setDescript] = useState("N/A");
  const { state} = useContext(NoteState);
  const { branch_id } = state;

  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");


  const getCards = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getCards(
         id,
         type,
         branch_id,
         newLimit,
         pageNo);
        let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            number: values.number,
            description: values.description,
            date: values.date,
            status: values.status,
            member_type: values.member_type,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const makeActive = async (card_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.activeCard(card_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Unblocked Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCards(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeCard = (card_Id) => {
        makeActive(card_Id);
  };
  const makeInActive = async (card_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inactiveCard(card_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Blocked Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCards(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveCard = (package_Id) => {
        makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteCards(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCards(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const getUsers = async () => {
    let myDataarr;
    setLoading(true);
    try {
      const responce = await UserServices.getClientInfo(id);
      console.log(responce);
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;

        res.map((values) => {
          setName(values.first_name + " " + values.last_name);
          return null;
        });

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getStaff = async () => {
    let myDataarr;
    setLoading(true);
    try {
      const responce = await UserServices.getStaffInfo(id);
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;

        res.map((values) => {
          setName(values.first_name + " " + values.last_name);
          return null;
        });

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCards(currentPage, limit);
    getUsers();
    getStaff();
  }, []);
  const addCard = async (values, action) => {
    if (cardError === "" )
    try {
      setLoading(true);
      const response = await UserServices.addClientCard(
        values,
        id,
        type,
        branch_id
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getCards(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
         toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  
  const verifyCard = async (e) => {
    var check_Card = e.target.value;
    try {
      const response = await UserServices.checkCard(check_Card);
      if (response.status === 200) {
        setCardError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setCardError("Card Number already exists");
      }
    }
  };
  const initialValues = {
    number: "",
    description: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addCrads,
      onSubmit: (values, action) => {
        addCard(values, action);
      },
    });
    const Table = ({ data, rowsPerPage }) => {
      const { slice } = useTable(data, 1, rowsPerPage);
      const { items, requestSort, sortConfig } = useSortableData(slice);
  
      const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
      };
  
      return (
        <>
          <table className="table table-striped table-borderless table-style-1">
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("number")}
                  className={getClassNamesFor("number")}
                >
                  Card Number
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("description")}
                  className={getClassNamesFor("description")}
                >
                  Description
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("occurrence_date")}
                  className={getClassNamesFor("occurrence_date")}
                >
                  Assigning Date
                </th>
                <th scope="col" className="no_sort">
                  Action
                </th>
                <th scope="col" className="no_sort">
                  Print
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>
                    <td>{el.number}</td>
                    <td>{el.description}</td>
                    <td>{el.date}</td>
                    <td>
                      {el.status === "1" ? (
                        <button
                          className="block-btn"
                          onClick={() => inactiveCard(el.id)}
                        >
                         <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Block
                        </span>
                        </button>
                      ) : (
                        <button
                          className="active-btn"
                          onClick={() => activeCard(el.id)}
                        >
                         <i className="bi bi-check2-circle text-success"></i>
                          <span className="text-success inative-text ms-1">
                          Unblock
                        </span>
                        </button>
                      )}
                      <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                    </td>
                   
                    <td>
                        <Link
  
                          to={
                            el.member_type === "1"
                              ? "/print-client-card/" + id
                              : "/print-staff-card/" + id
                          }
                          className="print-btn"
                        >
                           <i className="bi bi-printer"></i> <span className="inative-text">Print Card</span>
                        </Link>
                    </td>
                   
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              onChange={(e) => {
              getCards(e, limit);
            }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </>
      );
    };
  return (
    <div className="wrapper">
    <ToastContainer/>
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
          <div className="card-header py-3">
          {type === "1" ? <h5 className="mb-0 font-20"> Client: {Name}</h5> : <h5 className="mb-0 font-20"> Staff: {Name}</h5>}
            </div>
         
            <div className="card-body">
            <h5>Assign a new card</h5>
              <form
                className="row g-3 trainee-add mt-3"
                onSubmit={handleSubmit}
              >
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Card Number<span className="text-danger">*</span></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Card Number"
                      name="number"
                      min={0}
                      value={values.number}
                      onChange={(e) => {
                          handleChange(e);
                          verifyCard(e);
                        }}
                      onBlur={handleBlur}
                    />
                    <div className="errorMsg text-danger">{cardError}</div>
                    {errors.number && touched.number ? (
                      <p className="text-danger">{errors.number}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter description"
                      name="description"
                      value={values.description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description ? (
                      <p className="text-danger">{errors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Card Information</h5>
            </div>
            <div className="card-body">
        {totalResults > limit ? (
          <div className="col-md-4 col-lg-4 mt-3">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => getCards("1", e.target.value)}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => window.location.reload(false)}
              >
                Reset
              </button>
            </div>
          </div>
        ) : null}
        <div className="table-responsive mt-3">
          <Table data={myData} rowsPerPage={limit} />
        </div>
      </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default NewCard;
