import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import UserServices from "../../UserServices/UserServices";
import MenuButton from "../MenuButton/MenuButton";
import { updateDates } from "../schemas";

export default function UpdateDates() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [clientName, setClientName] = useState("");
  const [pkghNamee, setPkghNamee] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [sale, setSale] = useState("");
  const [durtion, setDurtion] = useState("");

  let navigate = useNavigate();

  const getDates = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getOrderDates(id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        setClientName(
          myDataarr.client_first_name + " " + myDataarr.client_last_name
        );
        setPkghNamee(myDataarr.package_name);
        setStart(myDataarr.start_date);
        setEnd(myDataarr.end_date);

        setSale(myDataarr.sale_date);
        setDurtion(myDataarr.duration);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const handleDurationChange = (e) => {
    var date = e.target.value;
    setStart(date);
    const edDate = new Date(date);
    const futureEDate = edDate.getDate() + durtion;
    edDate.setDate(futureEDate);
    const defaultEValue = edDate.toLocaleDateString("en-CA");

    setEnd(defaultEValue);
  };
  useEffect(() => {
    getDates();
  }, []);

  const updateDate = async (values) => {
    setLoading(true);

    try {
      const responce = await UserServices.orderDateUpdate(id, values);
      if (responce.status === 204) {
        toast.success("Package Dates Updated!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (err) {
      toast.error("Unproccessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };

  const initialValues = {
    sDate: start,
    eDate: end,
    // sale_date: sale,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: updateDates,
      onSubmit: (values, action) => {
        updateDate(values);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Client: {clientName}
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-danger d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-x" /> Back
                </button>
              </h5>
            </div>
            <div className="card-body">
              <span className="font-20 w-100 p-0 d-block">
                Package: {pkghNamee !== null ? pkghNamee : "Guest Pass"}
              </span>
              <hr />
              <form className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row g-3 trainee-add">
                      <div className="col-12 col-md-6">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Start Date <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            name="sDate"
                            value={values.sDate}
                            onChange={(e) => {
                              handleChange(e);
                              handleDurationChange(e);
                            }}
                            onBlur={handleBlur}
                          />

                          {errors.sDate && touched.sDate ? (
                            <p className="text-danger">{errors.sDate}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            End Date <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            name="eDate"
                            value={values.eDate}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={true}
                          />

                          {errors.eDate && touched.eDate ? (
                            <p className="text-danger">{errors.eDate}</p>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="col-12 col-md-6">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Sale Date <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            name="sale_date"
                            value={values.sale_date}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />

                          {errors.sale_date && touched.sale_date ? (
                            <p className="text-danger">{errors.sale_date}</p>
                          ) : null}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 mb-1 me-2"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}