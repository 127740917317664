import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useTable from "../../hooks/useTable";

const ViewCard = () => {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const { id, type } = useParams();

  const getUsers = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getCards(id, type);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            number: values.number,
            description: values.description,
            date: values.date,
            status: values.status,
            member_type: values.member_type,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers();
  }, []);
  const makeActive = async (card_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.activeCard(card_Id);
      if (responce.status === 204) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Unblocked successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeCard = (card_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to Unblock this card ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Unblock it!",
    }).then((result) => {
      if (result.isConfirmed) {
        makeActive(card_Id);
      }
    });
  };
  const makeInActive = async (card_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inactiveCard(card_Id);
      console.log(responce);
      if (responce.status === 204) {
        setLoading(false);

        Swal.fire({
          title: "Success!",
          text: " Blocked successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveCard = (package_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to block this card ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Block it!",
    }).then((result) => {
      if (result.isConfirmed) {
        makeInActive(package_Id);
      }
    });
  };

  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getCardsData(
          // "1",
          limit,
          pageNo
        );
        console.log(responce);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              number: values.number,
              description: values.description,
              date: values.date,
              status: values.status,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    // console.log(limit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getCardsData(newLimit, "1", 1);

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              number: values.number,
              description: values.description,
              date: values.date,
              status: values.status,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("number")}
                className={getClassNamesFor("number")}
              >
                Card Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("occurrence_date")}
                className={getClassNamesFor("occurrence_date")}
              >
                Assigning Date
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
              <th scope="col" className="no_sort">
                Print
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.number}</td>
                  <td>{el.description}</td>
                  <td>{el.date}</td>
                  <td>
                    {el.status === "1" ? (
                      <button
                        className="block-btn"
                        onClick={() => inactiveCard(el.id)}
                      >
                       <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Block
                      </span>
                      </button>
                    ) : (
                      <button
                        className="active-btn"
                        onClick={() => activeCard(el.id)}
                      >
                       <i className="bi bi-check2-circle text-success"></i>
                        <span className="text-success inative-text ms-1">
                        Unblock
                      </span>
                      </button>
                    )}
                  </td>
                  <td>
                    {/* <button className="print-button"> */}
                      <Link

                        to={
                          el.member_type === "1"
                            ? "/print-client-card/" + id
                            : "/print-staff-card/" + id
                        }
                        className="print-btn"
                      >
                         <i className="bi bi-printer"></i> <span className="inative-text">Print Card</span>
                      </Link>
                    {/* </button> */}
                  </td>
                  {/* <td>
  <button className="print-button">
    {el.status === "1" ? (
      <Link
        to={
          el.member_type === "1"
            ? "/print-client-card/" + id
            : "/print-staff-card/" + id
        }
        className="dropdown-item"
      >
        <i className="bi bi-printer-fill"></i> Print Card
      </Link>
    ) : (
      <span className="dropdown-item disabled">
        <i className="bi bi-printer-fill"></i> Print Card
      </span>
    )}
  </button>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <div className="card-body">
        {totalResults > limit ? (
          <div className="col-md-4 col-lg-4 mt-3">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => window.location.reload(false)}
              >
                Reset
              </button>
            </div>
          </div>
        ) : null}
        <div className="table-responsive mt-3">
          <Table data={myData} rowsPerPage={limit} />
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ViewCard;
