/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { useFormik } from "formik";
import moment from "moment";
import $ from "jquery";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { assignToClass } from "../schemas";

const GxClasses = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const [myBranches, setMyBranches] = useState([]);

  const [trainerName, setTrainerName] = useState([]);
  const [classesNames, setClassesNames] = useState([]);

  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;

  const getUsers = async (page, limit, values) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);

    try {
      const responce = await UserServices.getGxClasses(
        newLimit,
        pageNo,
        values
      );

      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];
        await res.map((value) => {
          results.push({
            id: value.id,
            branch_name: value.branch_name,
            branch_id: value.branch_id,
            trainer_name: value.trainer_name,
            trainer_id: value.trainer_id,

            package_name: value.name,

            booking_capacity: value.booking_capacity,
            duration: value.duration,
            session_count: value.session_count,
            order_details: value.order_details,
            time_slot: value.time_slot,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.per_page);
        setTotalResults(responce.data.total_record);
        setTotalPages(responce.data.total_pages);
        setCurrentPage(responce.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id, 1);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const getClassesList = async (br_Id, tr_id) => {
    try {
      const responce = await UserServices.registrationPkgs(br_Id, "15", tr_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setClassesNames([{ name: "Select Slot ", id: "" }, ...results]);
    } catch (err) {
      console.log(err);

      setClassesNames([{ name: "No Slot Found", id: "" }]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit, values);
    branches();
    gymTrainer(branch_id);
    getClassesList(values.branch_id, values.trainer_id);
  }, []);

  //..........................................................................................................
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };
  const [timeSlot, setTimeSlot] = useState([]);
  var clickEvent = $.Event("click");

  const getTimeSolts = async (trId, brId, orId) => {
    setLoading(true);
    try {
      const responce = await UserServices.trainerTimeList(trId);

      const res = responce.data.data;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          time: values.start_time + " To " + values.end_time,
        });
        return null;
      });
      setTimeSlot([{ time: "Select Time Slot", id: "" }, ...results]);
      addFieldValue("branch_id", brId);
      addFieldValue("user_id", trId);
      addFieldValue("order_id", orId);
      $("#membership").trigger(clickEvent);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setTimeSlot([{ time: "No Time Slot Found", id: "" }]);
      toast.error("No Time Slot Found", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [6, 161, 128], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("GX-Classes.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table  table-borderless table-style-1"
          id="report-POS"
          data-caption="GX Classes"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer_name")}
                className={getClassNamesFor("trainer_name")}
              >
                Trainer Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Class Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("booking_capacity")}
                className={getClassNamesFor("booking_capacity")}
              >
                Booking Space
              </th>
              <th
                scope="col"
                onClick={() => requestSort("duration")}
                className={getClassNamesFor("duration")}
              >
                Duration
              </th>

              <th
                scope="col"
                onClick={() => requestSort("session_count")}
                className={getClassNamesFor("session_count")}
              >
                Total Sessions
              </th>

              <th scope="col" className="no_sort">
                Time Slot
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <>
                  <tr>
                    <td>
                      <div className="d-flex">
                        <span className="ms-2">
                          {currentPage === 1
                            ? index + 1
                            : (currentPage - 1) * limit + index + 1}
                        </span>
                        {el.order_details.length ? (
                          <div
                            className="ms-2"
                            onClick={() => {
                              showInstalDetails(el.id);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-chevron-down fw-bold"></i>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>

                    <td>{el.branch_name}</td>
                    <td>{el.trainer_name}</td>
                    <td>{el.package_name}</td>
                    <td>{el.booking_capacity}</td>
                    <td>{el.duration} Days</td>
                    <td>{el.session_count}</td>

                    <td>
                      {el.time_slot.length ? (
                        el.time_slot[0].start_time +
                        " To " +
                        el.time_slot[0].end_time
                      ) : (
                        <>
                          <button
                            data-tooltip-id={el.id + el.trainer_id}
                            data-tooltip-content="Assign Time Slot"
                            className="ms-2 inactive-btn"
                            onClick={() => {
                              getTimeSolts(el.trainer_id, el.branch_id, el.id);
                            }}
                          >
                            <span className="text-danger inative-text ms-1">
                              Pending
                            </span>
                          </button>
                          <Tooltip
                            id={el.id + el.trainer_id}
                            place="top"
                            className="note-tooltip"
                            clickable
                          />
                        </>
                      )}
                    </td>
                  </tr>
                  {el.order_details.length ? (
                    <tr
                      className="instalmnt-info table table-striped"
                      id={el.id}
                    >
                      <td colSpan="9">
                        <tr>
                          <th
                            style={{
                              width: "20%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Order ID
                          </th>
                          <th
                            style={{
                              width: "40%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Client Name
                          </th>
                          <th
                            style={{
                              width: "30%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Start Date
                          </th>
                          <th
                            style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "20%",
                            }}
                          >
                            End Date
                          </th>

                          <th
                            style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "10%",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                        {el.order_details.map((inl, index) => (
                          <tr key={index}>
                            <td>{inl.id}</td>
                            <td>{inl.client_name}</td>

                            <td>
                              {moment(inl.start_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{moment(inl.end_date).format("DD-MM-YYYY")}</td>
                            <td>
                              <Link
                                className="updt-btn inactive-btn ms-0"
                                to={`/preassessment/${inl.client_id}`}
                              >
                                <i className="bi bi-plus text-success"></i>{" "}
                                <span className="text-success  updt-text">
                                  Add Assessments
                                </span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                </>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit, values);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    class_id: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: "",
      onSubmit: async (values) => {
        getUsers("1", limit, values);
      },
    });

  const closeButtonRef = useRef(null);

  const timeSlotsAssign = async (addPkgValues) => {
    try {
      setLoading(true);
      const response = await UserServices.assignSlotToPkg(addPkgValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit, values);
        closeButtonRef.current.click();
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something wnet wrong..", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const addPkginitialValues = {
    branch_id: "",
    user_id: "",
    time_id: "",
    order_id: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    setFieldValue: addFieldValue,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: addPkginitialValues,
    validationSchema: assignToClass,
    onSubmit: (addPkgValues) => {
      timeSlotsAssign(addPkgValues);
    },
  });

  return (
    <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">GX Slots</h5>
              </div>
              <div className="card-body">
                <form
                  className="row g-3 trainee-add mb-2"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Branch Name</label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);

                            gymTrainer(e.target.value);
                            getClassesList(e.target.value, values.trainer_id);
                            values.trainer_id = "";
                            values.class_id = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Trainer</label>

                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={values.trainer_id}
                          onChange={(e) => {
                            handleChange(e);
                            getClassesList(values.branch_id, e.target.value);
                            values.class_id = "";
                          }}
                          onBlur={handleBlur}
                          disabled={role === "9" && UId ? true : false}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}

                      {errors.trainer_id && touched.trainer_id ? (
                        <p className="text-danger">{errors.trainer_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Slots</label>

                      {classesNames ? (
                        <select
                          className="form-select"
                          name="class_id"
                          value={values.class_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {classesNames.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-1">
                    <button
                      type="submit"
                      className="btn btn-primary mx-md-2 file-download mt-md-4 w-100"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto">
                    <div className="input-group mt-md-4">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getUsers("1", e.target.value, values)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className=" col-md-1 text-end">
                    <button
                      className="btn btn-primary file-download mt-md-4 w-100"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                      type="button"
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </form>
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Assign Time Slot
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={addPkgHandleSubmit}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={addPkgValues.branch_id || ""}
                        disabled={true}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    Available Trainers<span className="text-danger">*</span>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValues.user_id || ""}
                        disabled={true}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.user_id && addPkgTouched.user_id ? (
                      <p className="text-danger">{addPkgErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    Available Time Slots<span className="text-danger">*</span>
                    {timeSlot ? (
                      <select
                        className="form-select"
                        name="time_id"
                        value={addPkgValues.time_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {timeSlot.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.time}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.time_id && addPkgTouched.time_id ? (
                      <p className="text-danger">{addPkgErrors.time_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Assign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default GxClasses;
