import React, { useEffect, useState, useContext, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import PaymentInfo from "./PaymentInfo";
import moment from "moment";
import Swal from "sweetalert2";
import { NoteState } from "../../context/notes/NoteState";
import $ from "jquery";
import { useFormik } from "formik";
import { updateDate } from "../schemas";
import { toast } from "react-toastify";

const ClientPurchases = () => {
  const [loading, setLoading] = useState(false);

  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("10");

  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [clientName, setClientName] = useState("");
  const [clientBalance, setClientBalance] = useState("");

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  let sDate = moment("01-01-" + date.getFullYear()).format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState("");
  const [packageId, setPackageId] = useState("");
  const [startDate, setstartDate] = useState(sDate);
  const [endDate, setendDate] = useState(eDate);
  const { state } = useContext(NoteState);
  const { branch_id } = state;

  var clickEvent = $.Event("click");
  var CurrencyFormat = require("react-currency-format");
  const closeButtonRef = useRef(null);

  const getRecord = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsPurchasesinfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;
        data.map((values) => {
          setSelectedDate(values.date);
          setPackageId(values.id);
        });
        $("#change-date").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getRecordForDelete = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsPurchasesinfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;
        data.map((values) => {
          DeleteRecord(values.id);
        });
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getUsers = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsPurchases(
        id,
        limit,
        "1",
        startDate,
        endDate
      );

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        // eslint-disable-next-line array-callback-return
        res.map((value) => {
          results.push({
            price: value.price,
            order_date: value.date,
            net_price: value.net_price,
            discount: value.discount,
            tax: value.tax ? value.tax : 0,
            order_id: value.id,
          });
        });
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const verifyName = async () => {
    try {
      const response = await UserServices.checkAccountBlance(id);
      if (response.status === 200) {
        setClientBalance(response.data);
      }
    } catch (err) {
      if (err.response.status === 409) {
      }
    }
  };
  const getClientsNames = async () => {
    try {
      const response = await UserServices.getClientName(branch_id, id);
      const myDataarr = response.data.data.data;

      if (myDataarr.length > 0) {
        const firstName = myDataarr[0].first_name;
        const lastName = myDataarr[0].last_name;

        setClientName(`${firstName} ${lastName}`);
      } else {
        setClientName("");
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        // Handle 404 error if needed
      }
      console.log(err);
      setClientName("");
    }
  };
  useEffect(() => {
    getUsers();
    verifyName();
    getClientsNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePagination = (e) => {
    setCurrentPage(e);

    let pageNo = e;

    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientsPurchases(
          id,
          limit,
          pageNo,
          startDate,
          endDate
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,

              discount: value.discount,
              tax: value.tax ? value.tax : 0,
              order_id: value.id,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeStartDate = async (e) => {
    var NewSDdte = moment(e.target.value).format("YYYY-MM-DD");
    if (Date.parse(NewSDdte) <= Date.parse(endDate)) {
      setstartDate(NewSDdte);
      setLoading(true);
      try {
        const responce = await UserServices.getClientsPurchases(
          id,
          limit,
          "1",
          NewSDdte,
          endDate
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,
              discount: value.discount,
              tax: value.tax ? value.tax : 0,
              order_id: value.id,
            });
          });

          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start date should be less then end date!",
      });
    }
  };
  const changeEndDate = async (e) => {
    var NewEDdte = moment(e.target.value).format("YYYY-MM-DD");
    if (Date.parse(NewEDdte) >= Date.parse(startDate)) {
      setendDate(NewEDdte);
      setLoading(true);
      try {
        const responce = await UserServices.getClientsPurchases(
          id,
          limit,
          "1",
          startDate,
          NewEDdte
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            results.push({
              price: value.price,
              order_date: value.date,
              net_price: value.net_price,
              discount: value.discount,
              tax: value.tax ? value.tax : 0,
              order_id: value.id,
            });
          });
          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="row mb-4">
          <div className="col-md-4  col-lg-4">
            <label className="form-label">From</label>
            <input
              type="date"
              className="form-control"
              placeholder="date"
              value={startDate}
              onChange={(e) => {
                changeStartDate(e);
              }}
            />
          </div>

          <div className="col-md-4  col-lg-4">
            <label className="form-label">To</label>
            <input
              type="date"
              className="form-control"
              placeholder="date"
              value={endDate}
              onChange={(e) => {
                changeEndDate(e);
              }}
            />
          </div>
        </div>
        <table className="table table-striped table-borderless table-style-1 caption-top">
          <caption className="font-20">Orders Informations</caption>
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("order_id")}
                className={getClassNamesFor("order_id")}
              >
                Order Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("order_date")}
                className={getClassNamesFor("order_date")}
              >
                Order Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Total Price
              </th>

              <th
                scope="col"
                onClick={() => requestSort("discount")}
                className={getClassNamesFor("discount")}
              >
                Discount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("tax")}
                className={getClassNamesFor("tax")}
              >
                GST
              </th>
              <th
                scope="col"
                onClick={() => requestSort("net_price")}
                className={getClassNamesFor("net_price")}
              >
                Grand Total
              </th>
              <th
                scope="col"
                onClick={() => requestSort("net_price")}
                className={getClassNamesFor("net_price")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.order_id}</td>
                  <td>{moment(el.order_date).format("DD-MM-YYYY")}</td>
                  <td>
                    <CurrencyFormat
                      value={el.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.discount}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.tax}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.net_price}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        getRecord(el.order_id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Edit Date
                      </span>
                    </button>
                    <Link
                      to={"/invoice/purchases/" + el.order_id}
                      className="ms-2 print-btn"
                    >
                      <i className="bi bi-printer"></i>{" "}
                      <span className="inative-text">Generate Slip</span>
                    </Link>
                    <button
                      type="button"
 onClick={() => {
  getRecordForDelete(el.order_id);
                      }}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                    {/* <div   onClick={() => DeleteRecord()}>
                      Delete
                    </div> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const UpdateRecord = async (values) => {
    setLoading(true);
    try {
      const response = await UserServices.UpdateClientPurchaseDate(
        values,
        packageId
      );
      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Your request has been submitted please wait for approval!",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            getUsers();
            // Trigger the close button click event
            closeButtonRef.current.click();
          }
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const DeleteRecord = async (id) => {
    setLoading(true);
    try {
      const response = await UserServices.DeleteClientPurchase(
        id
      );
      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Your request has been submitted please wait for approval!",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            getUsers();
            // Trigger the close button click event
            closeButtonRef.current.click();
          }
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    date: selectedDate,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: updateDate,
      onSubmit: (values) => {
        UpdateRecord(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <div className="row">
                <div className="col-6">
                  <h5 className="mb-0 font-22">Client: {clientName}</h5>
                </div>
                <div className="col-6">
                  <Link
                    to={-1}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="link-hirarcy pt-0 mb-2">
                Balance »{" "}
                <span className="text-success">
                  <CurrencyFormat
                    value={clientBalance}
                    displayType={"text"}
                    thousandSpacing={"2s"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                  />
                </span>
              </div>
              <div className="table-responsive">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
          <PaymentInfo />
        </div>
      </main>

      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="change-date"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop1"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="staticBackdropLabel1">
                Edit Date
              </h2>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 ">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Start date"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.date && touched.date ? (
                      <p className="text-danger">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Edit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ClientPurchases;
