import React, { useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { addBranch } from "../schemas";
import MenuButton from "../MenuButton/MenuButton";
import Swal from "sweetalert2/dist/sweetalert2.js";

const UpdateBranches = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [codeError, setCodeError] = useState("");
    const [branchName, setBranchName] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [branchPhone, setBranchPhone] = useState("");
    const [branchCity, setBranchCity] = useState("");
    // const [branchCountry, setBranchCountry] = useState("");
    const [branchStreet, setBranchStreet] = useState("");
    const [ntn, setNtn] = useState("");
    const [strn, setStrn] = useState("");
    const { id } = useParams();

  /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const getBranches = async (id) => {
          setLoading(true);
          try {
            const responce = await UserServices.getBrnchInfo(id);
            if (responce.status === 200) {
              let data = responce.data.data;
              data.map((values) => {
                setBranchName(values.name);
                setBranchCode(values.code);
                setBranchPhone(values.phone);
                setBranchCity(values.city);
                setBranchStreet(values.address);
                setNtn(values.ntn);
                setStrn(values.strn);
                return null;
              });
    
              setLoading(false);
            }
          } catch (err) {
            if (err.response.status === 404) {
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        };
        getBranches(id);
      }, []);
      const initialValues = {
        name: branchName,
        code: branchCode,
        phone: branchPhone,
        city: branchCity,
        address: branchStreet,
        ntn: ntn,
        strn: strn,
      };
      const verifyCode = async (e) => {
        var check_Code = e.target.value;
        try {
          const response = await UserServices.checkBranchCode(check_Code);
          if (response.status === 200) {
            setCodeError("");
          }
        } catch (err) {
          if (err.response.status === 404) {
            setCodeError("Code already exists");
          }
        }
      };
      const updatebranch = async (values) => {

        setLoading(true);
    
        try {
          const response = await UserServices.UpdateBrnch(values, id);
          console.log(response);
          if (response.status === 204) {
            setLoading(false);
            Swal.fire({
              title: "Updated !",
              text: "Record updated successfully.",
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "#ed1c24",
            }).then((result) => {
              if (result.isConfirmed) {
                // window.location.reload(false);
                navigate("/manage-branches");
              }
            });
            
            // navigate("/manage-branches");
          }
        } catch (err) {
          if (err.response.status === 422) {
            toast.error("Some data is missing", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
    
            setLoading(false);
          } else {
            toast.error("Some thing went wronge !", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
          }
        }
      };
    //..............................................................................................................
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: addBranch,
      onSubmit: (values, action) => {
        updatebranch(values, action);
      },
    });
  return (
    <>
    <div className='wrapper'>
      <ToastContainer />
        <main className="page-content customer-contnent">
        <div className="site-main-design">
        <MenuButton />
        <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Update Branch</h5>
              </div>
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Name<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Branch Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-none">
                    <div className="form-group custom-group">
                      <label className="form-label">Code<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Branch Code"
                        name="code"
                        value={values.code}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCode(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {codeError ? (
                        <div className="errorMsg text-danger">{codeError}</div>
                      ) : null}
                      {errors.code && touched.code ? (
                        <p className="text-danger">{errors.code}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Branch City"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.city && touched.city ? (
                        <p className="text-danger">{errors.city}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Address</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Branch Address"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.street && touched.street ? (
                        <p className="text-danger">{errors.street}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">NTN</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Add NTN"
                        name="ntn"
                        value={values.ntn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.ntn && touched.ntn ? (
                        <p className="text-danger">{errors.ntn}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">STRN</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Add STRN"
                        name="strn"
                        value={values.strn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.strn && touched.strn ? (
                        <p className="text-danger">{errors.strn}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p className="text-danger">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mt-4"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
        </div>
        </main>
    </div>
    <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  )
}

export default UpdateBranches
