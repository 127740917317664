import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import MenuButton from "../MenuButton/MenuButton";
import { getPayment } from "../schemas";

export default function RecievePayments() {
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  let navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [recieved, setRecieved] = useState("");
  const [Pendinbg, setPendinbg] = useState("");

  const [payAfterError, setPayAfterError] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const getPayments = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getOrderPayments(id);

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        if (myDataarr.pending === 0) {
          Swal.fire({
            title: "Error!",
            text: "No Remaining Balance.",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setClientName(myDataarr.client_name);
          setPendinbg(myDataarr.pending);
          setRecieved(myDataarr.received);
        }

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    // const getPaymentMethodinitial = async (br_Id) => {
    //   try {
    //     const responce = await UserServices.paymentNames(br_Id);
    //     const res = responce.data.data;
    //     const results = [];
    //     res.map((value) => {
    //       results.push({
    //         id: value.id,
    //         name: value.name,
    //       });
    //     });
    //     setPaymentMethods([...results]);
    //   } catch (err) {
    //     console.log(err);

    //     setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
    //   }
    // };
    const getPaymentMethodinitial = async () => {
      try {
        const responce = await UserServices.paymentNames();
        const res = responce.data.data;
        const results = res.filter((value) => value.id !== 81 && value.id !== 82); // Filter out payment method with id 81
        setPaymentMethods([...results]);
      } catch (err) {
        console.log(err);

        setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
      }
    };
    getPayments();
    getPaymentMethodinitial(branch_id);
  }, []);

  const calPendPay = async (e) => {
    var pay_price = e.target.value;
    if (pay_price !== "") {
      if (Pendinbg !== "") {
        if (pay_price > Pendinbg) {
          values.payment_pend = "";
          setPayAfterError("Payment must be equal to or less than:" + Pendinbg);
        } else if (pay_price < 0) {
          values.payment_pend = "";
          setPayAfterError("Payment must be equal to or geater than 0");
        } else {
          values.payment_pend = Pendinbg - pay_price;
          setPayAfterError("");
        }
      } else if (pay_price < 0) {
        values.payment_pend = "";
        setPayAfterError("Payment must be equal to or geater than 0");
      } else {
        setPayAfterError("");
      }
    } else if (pay_price < 0) {
      values.payment_pend = "";
      setPayAfterError("Payment must be equal to or geater than 0");
    } else {
      values.payment_pend = "";
      setPayAfterError("");
    }
  };

  const addPayment = async (values) => {
    if (payAfterError === "") {
      setLoading(true);

      try {
        const responce = await UserServices.paymentPendAdd(
          id,
          branch_id,
          values
        );

        if (responce.status === 201) {
          toast.success("Package Sold Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/invoice/payments/" + responce.data.id, {
              replace: true,
            });
          }, 1000);
        }
      } catch (err) {
        toast.error("Unproccessable content!", { position: "top-center" });
        console.log(err);
        setLoading(false);
      }
    }
  };

  const initialValues = {
    pay_met: "31",
    payment_rec: "",
    cheque_no: "0",
    payment_pend: "0",
    recieving_date: dateSelected,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: getPayment,
      onSubmit: (values, action) => {
        addPayment(values);
      },
    });

  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Client: {clientName}</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="expenses-num">
                    Total Price :{" "}
                    <span>
                      <CurrencyFormat
                        value={recieved + Pendinbg}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="expenses-num">
                    Payment Received :{" "}
                    <span>
                      <CurrencyFormat
                        value={recieved}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-3">
                  <div className="expenses-num">
                    Remaining Balance :{" "}
                    <span>
                      <CurrencyFormat
                        value={Pendinbg}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <form className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row g-3 trainee-add">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Payment Method</label>
                          {paymentMethods.length ? (
                            <select
                              className="form-select"
                              name="pay_met"
                              value={values.pay_met}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {paymentMethods.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              name="pay_met"
                              value={values.pay_met}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select Payment Method</option>
                            </select>
                          )}

                          {errors.pay_met && touched.pay_met ? (
                            <p className="text-danger">{errors.pay_met}</p>
                          ) : null}
                        </div>
                      </div>
                      {values.pay_met === "32" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <div className="form-group custom-group">
                              <label
                                htmlFor="form-label"
                                className="form-label"
                              >
                                Cheque Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="cheque_no"
                                value={values.cheque_no}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />

                              {errors.cheque_no && touched.cheque_no ? (
                                <p className="text-danger">
                                  {errors.cheque_no}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Payment <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="payment_rec"
                            value={values.payment_rec}
                            onChange={(e) => {
                              handleChange(e);
                              calPendPay(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {payAfterError !== "" ? (
                            <p className="text-danger">{payAfterError}</p>
                          ) : null}
                          {errors.payment_rec && touched.payment_rec ? (
                            <p className="text-danger">{errors.payment_rec}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Remaining Balance
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="payment_pend"
                            value={values.payment_pend}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={true}
                          />

                          {errors.payment_pend && touched.payment_pend ? (
                            <p className="text-danger">{errors.payment_pend}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label htmlFor="form-label" className="form-label">
                            Date <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            name="recieving_date"
                            value={values.recieving_date}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />

                          {errors.recieving_date && touched.recieving_date ? (
                            <p className="text-danger">
                              {errors.recieving_date}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 mb-1 me-2"
                    >
                      Get Payment
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
