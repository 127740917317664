import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import { freezingFee } from "../schemas";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import { NoteState } from "../../context/notes/NoteState";
import { toast } from "react-toastify";

export default function SellFreezinfFee(props) {
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const startFutureDate = date.getDate();
  date.setDate(startFutureDate);
  let defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);
  const [clientName, setClientName] = useState("");
  const [nameError, setNameError] = useState("");
  const [pkgName, setPkgName] = useState("");
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const { id } = useParams();
  const [duration, setDuration] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [membershipFee, setMembershipFee] = useState("0");
  const [paySplitError, setPaySplitError] = useState("");
  const [payAfterError, setPayAfterError] = useState("");
  // eslint-disable-next-line
  const [membershipType, setMembershipType] = useState([]);
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");
  const [pkgId, setPkgId] = useState("");
  // eslint-disable-next-line
  const [packageName, setPackageName] = useState("");

  const verifyDiscount = async (e) => {
    var discount_price = e.target.value;
    if (discount_price !== "") {
      if (
        values.discount_type === "Percentage" &&
        values.discount_type !== "" &&
        membershipFee !== ""
      ) {
        if (discount_price > 100) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or less than 100%"
          );
        } else if (discount_price < 0) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or geater than 0%"
          );
        } else {
          values.pay_after_dis =
            membershipFee - (membershipFee / 100) * discount_price;
          setDiscountError("");
        }
      } else if (membershipFee !== "") {
        if (parseInt(discount_price) > membershipFee) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or less than Package Full Price:" +
              membershipFee
          );
        } else if (discount_price < 0) {
          values.pay_after_dis = "0";
          setDiscountError(
            "Maximum discount must be equal to or geater than 0"
          );
        } else {
          values.pay_after_dis = membershipFee - discount_price;
          setDiscountError("");
        }
      } else if (discount_price < 0) {
        values.pay_after_dis = "0";
        setDiscountError("Maximum discount must be equal to or geater than 0");
      } else {
        setDiscountError("");
      }
    } else if (discount_price < 0) {
      values.pay_after_dis = "0";
      setDiscountError("Maximum discount must be equal to or geater than 0");
    } else {
      values.pay_after_dis = "0";
      setDiscountError("");
    }
  };
  const validateDuration = (value) => {
    if (value < 2 || value > 100) {
      setErrorMessage("Please enter a value between 2 and 100");
    } else {
      setErrorMessage("");
    }
  };
  const handleDurationChange = (event) => {
    const value = event.target.value;
    setDuration(value);

    const startDate = new Date(values.start_date);
    const endDate = new Date(startDate.getTime() + value * 24 * 60 * 60 * 1000);

    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();

    const formattedEndDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;

    // defaultValue = formattedEndDate.toLocaleDateString("en-CA");
    setEndDate(formattedEndDate);
  };
  const handleDurationBlur = () => {
    validateDuration(duration);
  };
  const handleStartChange = (event) => {
    const value = event.target.value;

    const startDate = new Date(value);
    const endDate = new Date(
      startDate.getTime() + values.duration * 24 * 60 * 60 * 1000
    );

    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();

    const formattedEndDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;

    setEndDate(formattedEndDate);
  };
  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsNameById(branch_id, id);

      setClientName(
        responce.data.data[0].first_name + " " + responce.data.data[0].last_name
      );
    } catch (err) {
      console.log(err);
      setClientName("");
    }
  };
  const nameVerify = async () => {
    // setCurrentPage();
    try {
      const responce = await UserServices.checkClientName(id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          order_id: values.order_id,
          package_name: values.package_name,
        });
        return null;
      });
      setPkgName([{ package_name: "Select Name", order_id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getPackageName = async () => {
    try {
      const responce = await UserServices.freezingPkgName();
      const res = responce.data.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          package_name: value.package_name,
          price: value.price,
        });
        return null;
      });
      setPackageName([{ name: "Select Package", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getMembershipType = async () => {
    try {
      const responce = await UserServices.freezingPkgName(branch_id, "8");

      const res = responce.data.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          package_name: value.package_name,
          price: value.price,
        });
        return null;
      });
      setMembershipType([{ name: "Select Session ", id: "" }, ...results]);
      setPrice(results[0].price);
      setFieldValue("price", results[0].price);
      setMembershipFee(results[0].price);
      setPkgId(results[0].id);
      setName(results[0].package_name);
    } catch (err) {
      console.log(err);

      setMembershipType([{ name: "No Session Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    nameClients();
    nameVerify();
    getPackageName();
    getMembershipType();
  }, []);

  const sellFreezing = async (values, action) => {
    if (
      errorMessage === "" &&
      nameError === "" &&
      paySplitError === "" &&
      payAfterError === ""
    )
      setLoading(true);
    try {
      const response = await UserServices.freezingPkgSell(
        values,
        id,
        branch_id,
        price,
        pkgId,
        "8",
        endDate
      );
      toast.success("Freezing Added!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        props.cart();
        props.catagry();
      }, 1500);
    } catch (err) {
      toast.error("Unproccessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };
  const initialValues = {
    client_id: clientName,
    order_id: "",
    price: "",
    duration: "",
    start_date: startDate,
    end_date: endDate,
    salesnotes: "",
    discount_type: "Amount",
    discount_amount: "",
    pay_after_dis: "0",
    salesdate: dateSelected,
    memType: pkgId,
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: freezingFee,
    onSubmit: (values, action) => {
      sellFreezing(values);
    },
  });
  return (
    <form className="" onSubmit={handleSubmit}>
      <>
        <div className="card-header py-3">
          <h5 className="mb-0 font-20">Sell Freezing</h5>
        </div>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Client Name</label>
                <span className="text-danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  disabled
                  name="client_id"
                  value={clientName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                {nameError ? (
                  <div className="errorMsg text-danger">{nameError}</div>
                ) : null}
                {errors.client_id && touched.client_id ? (
                  <p className="text-danger">{errors.client_id}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Order Name</label>
                {pkgName ? (
                  <select
                    className="form-select"
                    name="order_id"
                    value={values.order_id}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  >
                    {pkgName.map((el, index) => {
                      return (
                        <option key={index} value={el.order_id}>
                          {el.package_name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.order_id && touched.order_id ? (
                  <p className="text-danger">{errors.order_id}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Package Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Freezing Fee"
                  name="memType"
                  value={name}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Freezing Fee</label>
                <span className="text-danger">*</span>
                <input
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  type="number"
                  className="form-control"
                  placeholder="Enter Freezing Fee"
                  name="price"
                  value={values.price}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);

                    values.discount_amount = "";
                    setDiscountError("");
                    setPaySplitError("");
                    setPayAfterError("");
                    values.pay_after_dis = "0";
                    values.payment_rec = "";
                    values.payment_pend = "";
                    values.payment_split = "";
                    values.payment_split_pend = "";
                    setPrice(e.target.value);
                    setMembershipFee(e.target.value);
                  }}
                />
                {errors.price && touched.price ? (
                  <p className="text-danger">{errors.price}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Number of days</label>
                <input
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  type="number"
                  className="form-control"
                  placeholder=" Enter number of days"
                  name="duration"
                  value={values.duration}
                  onChange={(e) => {
                    handleChange(e);
                    handleDurationChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    handleDurationBlur(e);
                  }}
                />
                {errorMessage ? (
                  <p className="text-danger">{errorMessage}</p>
                ) : null}
                {errors.duration && touched.duration ? (
                  <p className="text-danger">{errors.duration}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Start Date</label>
                <span className="text-danger">*</span>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Starting date"
                  name="start_date"
                  value={values.start_date}
                  onChange={(e) => {
                    handleChange(e);
                    handleStartChange(e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.start_date && touched.start_date ? (
                  <p className="text-danger">{errors.start_date}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">End Date</label>
                <span className="text-danger">*</span>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Ending date"
                  name="end_date"
                  value={endDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={true}
                />
                {errors.end_date && touched.end_date ? (
                  <p className="text-danger">{errors.end_date}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">Sales Note</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Sales Note"
                  name="salesnotes"
                  value={values.salesnotes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.salesnotes && touched.salesnotes ? (
                  <p className="text-danger">{errors.salesnotes}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label className="form-label">
                  Discount Type <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  name="discount_type"
                  value={values.discount_type}
                  onChange={(e) => {
                    handleChange(e);
                    values.discount_amount = "";
                    setDiscountError("");
                    setPaySplitError("");
                    setPayAfterError("");
                    values.pay_after_dis = "0";
                    values.payment_rec = "";
                    values.payment_pend = "";
                    values.payment_split = "";
                    values.payment_split_pend = "";
                  }}
                  onBlur={handleBlur}
                >
                  <option value="">Select Discount Type</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Amount">Amount</option>
                </select>
                {errors.discount_type && touched.discount_type ? (
                  <p className="text-danger">{errors.discount_type}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  Discount <span className="text-danger">*</span>
                </label>
                <input
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  type="number"
                  name="discount_amount"
                  value={values.discount_amount}
                  onChange={(e) => {
                    handleChange(e);
                    verifyDiscount(e);
                    setPaySplitError("");
                    setPayAfterError("");
                    values.payment_rec = "";
                    values.payment_pend = "";
                    values.payment_split = "";
                    values.payment_split_pend = "";
                  }}
                  onBlur={handleBlur}
                />

                {discountError !== "" ? (
                  <p className="text-danger">{discountError}</p>
                ) : null}

                {errors.discount_amount && touched.discount_amount ? (
                  <p className="text-danger">{errors.discount_amount}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  Payable Amount After Discount
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="pay_after_dis"
                  value={values.pay_after_dis}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  disabled={true}
                />

                {errors.pay_after_dis && touched.pay_after_dis ? (
                  <p className="text-danger">{errors.pay_after_dis}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group custom-group">
                <label htmlFor="form-label" className="form-label">
                  Sale Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="salesdate"
                  value={values.salesdate}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />

                {errors.salesdate && touched.salesdate ? (
                  <p className="text-danger">{errors.salesdate}</p>
                ) : null}
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-12 text-center">
              <button type="submit" className="btn btn-primary px-5 mb-1 me-2">
                Sell Freezing
              </button>
            </div>
          </div>
        </div>
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </>
    </form>
  );
}
