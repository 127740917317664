import React, { useContext } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import MenuButton from "../../MenuButton/MenuButton";
import UserServices from "../../../UserServices/UserServices";
import { NoteState } from "../../../context/notes/NoteState";
import NewFitnessPlanForm from "./NewFitnessPlanForm";
import $ from "jquery";

export default function NewAddFitnessPlan() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { plan_id } = useParams();

  const [fitnessPlan, setFitnessPlan] = useState([]);
  const [fitnessPlanError, setFitnessPlanError] = useState([]);

  const [subCatgries, setSubCatgries] = useState([]);

  const [catgries, setCatgries] = useState([]);

  const [catgriesSelected, setCatgriesSelected] = useState("");

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleFormChange = (formDataIndex, val, fieldName) => {
    const updatedFormDataw = [...fitnessPlan];

    updatedFormDataw[formDataIndex][fieldName] = val;
    setFitnessPlan(updatedFormDataw);
  };

  const handleSubmitAllForms = async () => {
    if (fitnessPlan.length) {
      try {
        setLoading(true);

        const responce = await UserServices.addDetailForFitnessPlan(
          fitnessPlan,
          plan_id,
          catgriesSelected
        );

        if (responce.status === 201) {
          setLoading(false);

          Swal.fire({
            icon: "success",
            title: "Added Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/fitness-plan");
        }
      } catch (err) {
        console.log(err.response.data.errors);
        setLoading(false);

        if (err?.response?.status === 422) {
          let res = err?.response?.data?.errors;

          setFitnessPlanError(res);
          const backToTop = () => {
            return (
              $("html, body").animate(
                {
                  scrollTop: 0,
                },
                600
              ),
              !1
            );
          };
          backToTop();
        } else if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Please add atleast one record!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const categories = async (branch_id) => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Exercise"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([...results]);
    } catch (err) {
      console.log(err);
      setCatgries([]);
    }
  };

  const getSubCategories = async (e) => {
    var sb_catgry = e;
    if (sb_catgry !== "") {
      try {
        const responce = await UserServices.SubCategoriesDropdoenNameBranchLess(
          sb_catgry
        );
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setSubCatgries([...results]);
      } catch (err) {
        setSubCatgries([]);
        console.log(err);
      }
    } else {
      setSubCatgries([]);
    }
  };

  useEffect(() => {
    categories(branch_id);
  }, []);

  // old function
  // const addDayForm = async (selectedDays) => {
  //   const days = [];
  //   await selectedDays.map((value) => {
  //     return days.push({
  //       day: value,
  //     });
  //   });

  //   await setFitnessPlan([...days]);
  // };

  const addDayForm = async (selectedDays) => {
    const updatedFitnessPlan = fitnessPlan.map((planItem) => {
      // Check if the day already exists in the fitnessPlan array
      const existingDay = selectedDays.find((day) => day === planItem.day);

      // If the day exists, return the planItem with updated day field
      if (existingDay) {
        return {
          ...planItem,
          day: existingDay,
        };
      }
      // If the day doesn't exist, return the planItem as it is
      return planItem;
    });

    // Add any new days that are not already in the fitnessPlan array
    selectedDays.forEach((day) => {
      const dayExists = updatedFitnessPlan.some(
        (planItem) => planItem.day === day
      );
      if (!dayExists) {
        updatedFitnessPlan.push({ day });
      }
    });

    // Update the fitnessPlan state with the updated array
    setFitnessPlan(updatedFitnessPlan);
  };

  const getDaysVal = async () => {
    var selectedDays = await $(".days-select ul li input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get();

    if (fitnessPlan.length && selectedDays.length) {
      const filteredArray = fitnessPlan.filter(
        (item) => !selectedDays.includes(item.day)
      );
      if (filteredArray.length) {
        await filteredArray.map((value) => {
          setFitnessPlan(fitnessPlan.filter((val) => val.day !== value.day));
        });
      } else {
        addDayForm(selectedDays);
      }
    } else if (!fitnessPlan.length && selectedDays.length) {
      addDayForm(selectedDays);
    } else {
      await setFitnessPlan([]);
    }
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent fitness-plan-add-section">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Add Fitness Plan
                <Link
                  to={"/fitness-plan-detail/" + plan_id}
                  className="btn btn-primary d-inline-block float-end btn-sm ms-2"
                >
                  View Fitness Plan
                </Link>
                <Link
                  className="btn btn-primary d-inline-block float-end btn-sm "
                  to="/manage-exercises"
                >
                  <i className="bi bi-plus"></i> Add New Exercise
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <div className="row  mb-3">
                <div className="col-md-12">
                  <div className="tabs chooce-one">
                    <ul className="tabs-nav">
                      {catgries.map((val, index) => (
                        <li
                          key={index}
                          className={`${
                            catgriesSelected === val.id ? "tab-active" : ""
                          }`}
                        >
                          <button
                            onClick={(e) => {
                              if (catgriesSelected !== val.id) {
                                $(
                                  ".fitness-plan-add-section input:checkbox"
                                ).prop("checked", false);
                                setFitnessPlan([]);
                                getSubCategories(val.id);
                                setCatgriesSelected(val.id);
                              }
                            }}
                          >
                            {val.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {catgriesSelected !== "" ? (
                  <>
                    <div className="col-12">
                      <h5>Select Days</h5>
                    </div>
                    <div className="col-md-12 mt-1 mb-2">
                      <div className="custom-raio days-select">
                        <ul>
                          {weekdays.map((day, index) => (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={day}
                                value={day}
                                onChange={getDaysVal}
                              />
                              <label htmlFor={day}>{day}</label>
                              <div className="check"></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {fitnessPlanError.length
                      ? fitnessPlanError.map((error, index) => (
                          <div className="col-md-6 mt-2 " key={index}>
                            <div className="alert alert-danger mb-0">
                              <strong>{error}</strong>
                            </div>
                          </div>
                        ))
                      : null}
                  </>
                ) : null}
              </div>
              {fitnessPlan.length
                ? fitnessPlan.map((paln, index) => (
                    <NewFitnessPlanForm
                      formDataIndex={index}
                      catagrey={catgriesSelected}
                      subCatgries={subCatgries}
                      key={paln.day}
                      day={paln.day}
                      catgries={catgries}
                      onFormChange={handleFormChange}
                    />
                  ))
                : null}
              {catgriesSelected !== "" && (
                <div className="row">
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary px-4 reverse-bg mt-0 mb-3 mt-3"
                      onClick={handleSubmitAllForms}
                    >
                      Submit All
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
