import React, { useContext, useEffect, useRef, useState } from 'react'
import MenuButton from '../MenuButton/MenuButton';
import { useFormik } from 'formik';
import { addMembershipPkg } from '../schemas';
import { NoteState } from '../../context/notes/NoteState';
import UserServices from '../../UserServices/UserServices';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import useSortableData from '../../hooks/useSortableData';
import useTable from '../../hooks/useTable';
import jsPDF from 'jspdf';
import $ from "jquery";

export default function CafeDeposit() {
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const [loading, setLoading] = useState(false);
    const [myBranches, setMyBranches] = useState([]);
    const [nameError, setNameError] = useState("");
    const [limit, setLimit] = useState("25");
    const [totalResults, setTotalResults] = useState("0");
    const [totalPages, setTotalPages] = useState("1");
    const [myData, setMyData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // for inactive table
    const [inactiveLimit, setInactiveLimit] = useState("25");
    const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
    const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
    const [inactiveData, setInactiveData] = useState([]);
    const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);
    // update
  
    const [selectedBranches, setSelectedBranches] = useState("");
    const [Price, setPrice] = useState("");
    const [packageName, setPackageName] = useState("");
    const [packageId, setPackageId] = useState("");
    const [category, setCategory] = useState("");
  
    var clickEvent = $.Event("click");
    var CurrencyFormat = require("react-currency-format");
  
    const closeButtonRef = useRef(null);
    const getPkgs = async (id) => {
      setLoading(true);
      try {
        const responce = await UserServices.getCafeDepositInfo(id);
        if (responce.status === 200) {
          let data = responce.data.data.data;
          // eslint-disable-next-line array-callback-return
          data.map((values) => {
            setSelectedBranches(values.branch_id);
            setPrice(values.price);
            setPackageName(values.package_name);
            setPackageId(values.id);
            setCategory(values.branch_id);
          });
          $("#membership").trigger(clickEvent);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const changeCategory = (e) => {
      setCategory(e.target.value);
    };
    const getPkg = async (page, limit) => {
      setLoading(true);
      setCurrentPage(page);
      let pageNo = page;
  
      let newLimit = limit;
      setLimit(newLimit);
      try {
        const responce = await UserServices.getCafeDeposit(
          branch_id,
          "1",
          newLimit,
          pageNo
        );
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((values) => {
            results.push({
              id: values.id,
              branch_id: values.branch_id,
              branches_name: values.branches_name,
              package_name: values.package_name,
              duration: values.duration,
              price: values.price,
              membership_types_name: values.membership_types_name,
              category: values.category,
              user_first_name: values.user_first_name,
              user_last_name: values.user_last_name,
              user_id: values.user_id,
              start_time: values.start_time,
              end_time: values.end_time,
              pt_group: values.pt_group,
              trainer_membership_id: values.trainer_membership_id,
            });
            return null;
          });
  
          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getInactivePkg = async (page, limit) => {
      setLoading(true);
      setInactiveCurrentPage(page);
      let pageNo = page;
  
      let newLimit = limit;
      setInactiveLimit(newLimit);
      try {
        const responce = await UserServices.getCafeDeposit(
          branch_id,
          "0",
          newLimit,
          pageNo
        );
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((values) => {
            results.push({
              id: values.id,
              branch_id: values.branch_id,
              branches_name: values.branches_name,
              package_name: values.package_name,
              duration: values.duration,
              price: values.price,
              membership_types_name: values.membership_types_name,
              category: values.category,
              user_first_name: values.user_first_name,
              user_last_name: values.user_last_name,
              user_id: values.user_id,
              start_time: values.start_time,
              end_time: values.end_time,
              pt_group: values.pt_group,
            });
            return null;
          });
  
          setInactiveData([...results]);
          setInactiveLimit(responce.data.data.per_page);
          setInactiveTotalResults(responce.data.totalRecord);
          setInactiveTotalPages(responce.data.totalPages);
          setInactiveCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setInactiveTotalResults("0");
          setInactiveTotalPages("1");
          setInactiveData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const branches = async () => {
      try {
        const responce = await UserServices.branchName();
        const res = responce.data.data;
        const results = [];
  
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      getPkg(currentPage, limit);
      getInactivePkg(inactiveCurrentPage, inactiveLimit);
      branches();
    }, []);
    const makeInActive = async (package_Id) => {
      try {
        setLoading(true);
        const responce = await UserServices.inactivePackage(package_Id);
        if (responce.status === 204) {
          setLoading(false);
          toast.error("Inactivated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getPkg(currentPage, limit);
          getInactivePkg(inactiveCurrentPage, inactiveLimit);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const inactivePackage = (package_Id) => {
      makeInActive(package_Id);
    };
    const deleteRecord = async (id) => {
      try {
        const responce = await UserServices.deletePackages(id);
        if (responce.status === 204) {
          toast.error("Deleted Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getInactivePkg(inactiveCurrentPage, inactiveLimit);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const delFine = (id) => {
      deleteRecord(id);
    };
    const makeActive = async (package_Id) => {
      try {
        setLoading(true);
        const responce = await UserServices.activePackage(package_Id);
        if (responce.status === 204) {
          setLoading(false);
          toast.success("Activated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getPkg(currentPage, limit);
          getInactivePkg(inactiveCurrentPage, inactiveLimit);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const activePackage = (package_Id) => {
      makeActive(package_Id);
    };
    let searchTimer;
    const searchPkg = async (e) => {
      clearTimeout(searchTimer);
  
      searchTimer = setTimeout(async () => {
        var search_name = e.target.value;
        setLoading(true);
        try {
          const response = await UserServices.MemPkgSearch(
            search_name,
            branch_id,
            "1",
            limit,
            1,
            "13"
          );
          let myDataarr;
          if (response.status === 200) {
            myDataarr = response.data.data.data;
            const res = myDataarr;
            const results = [];
            res.map((values) => {
              results.push({
                id: values.id,
                branch_id: values.branch_id,
                branches_name: values.branches_name,
                package_name: values.package_name,
                duration: values.duration,
                price: values.price,
                membership_types_name: values.membership_types_name,
                category: values.category,
                user_first_name: values.user_first_name,
                user_last_name: values.user_last_name,
                user_id: values.user_id,
                start_time: values.start_time,
                end_time: values.end_time,
                pt_group: values.pt_group,
                trainer_membership_id: values.trainer_membership_id,
              });
              return null;
            });
            setMyData([...results]);
            setLimit(response.data.data.per_page);
            setTotalResults(response.data.totalRecord);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.data.current_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response?.status === 404) {
            setTotalResults("0");
            setTotalPages("1");
            setMyData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }, 1000);
    };
    const nameVerify = async (e) => {
        var check_name = e.target.value;
        try {
          const response = await UserServices.checkAddPackageName(
            check_name,
            branch_id,
            "13"
          );
          if (response.status === 200) {
            setNameError("");
          }
        } catch (err) {
          if (err.response?.status === 409) {
            setNameError("Name already exists");
          }
        }
    };
    const addPkginitialValues = {
        branch_id: branch_id,
        package_name: "",
        price: "",
      };
      const addGymPackage = async (addPkgValues, action) => {
        if (nameError === "") {
          try {
            setLoading(true);
            const response = await UserServices.addCafeDeposit(addPkgValues);
    
            if (response.status === 201) {
              setLoading(false);
              toast.success("Added Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              action.resetForm();
              getPkg(currentPage, limit);
            }
          } catch (err) {
            setLoading(false);
            if (err.response.status === 422) {
            } else if (err.response.status === 500) {
              // toast.error("Unproccessable content!", { position: "top-center" });
            } else if (err.response.status === 400) {
              // toast.error("Unproccessable content!", { position: "top-center" });
            }
          }
        }
      };
      const {
        values: addPkgValues,
        errors: addPkgErrors,
        touched: addPkgTouched,
        handleBlur: addPkgHandleBlur,
        handleChange: addPkgHandleChange,
        handleSubmit: addPkgHandleSubmit,
      } = useFormik({
        initialValues: addPkginitialValues,
        validationSchema: addMembershipPkg,
        onSubmit: (addPkgValues, action) => {
          addGymPackage(addPkgValues, action);
        },
      });

      const generatePdf = () => {
        const doc = new jsPDF("p", "mm", "letter");
        const tables = document.querySelectorAll("table");
        const tableOptions = {
          headStyles: { fillColor: [6, 161, 128], textColor: 255 },
          styles: { fontSize: 5 },
        };
        let currentY = 10; // Initialize Y position for the text
        let pageWidth = doc.internal.pageSize.width; // Get the page width
        let pageHeight = doc.internal.pageSize.height; // Get the page height
        tables.forEach((table, index) => {
          const captionText = table.getAttribute("data-caption");
          let tableHeight = doc.autoTable.previous.finalY - currentY;
          if (currentY + tableHeight + 20 > pageHeight) {
            doc.addPage();
            currentY = 10;
          }
          doc.text(   captionText, 10, currentY);
          currentY += 5;
          doc.autoTable({
            html: table,
            startY: currentY,
            ...tableOptions,
          });
          // Draw a line at the bottom of the table to separate it
          const tableBottomY = doc.autoTable.previous.finalY + 5;
          const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
          doc.setDrawColor(0); // Set line color to black
          doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
          currentY = tableBottomY + 10; // Update the Y position for the next table
        });
        doc.save("Cafe_Deposit.pdf");
      };
      const Table = ({ data, rowsPerPage }) => {
        const { slice } = useTable(data, 1, rowsPerPage);
        const { items, requestSort, sortConfig } = useSortableData(slice);
    
        const getClassNamesFor = (name) => {
          if (!sortConfig) {
            return;
          }
          return sortConfig.key === name ? sortConfig.direction : undefined;
        };
    
        return (
          <>
            <table
              className="table table-striped table-borderless table-style-1"
              data-caption="Active Cafe Deposits"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Sr#
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("branch_id")}
                    className={getClassNamesFor("branch_id")}
                  >
                    Branch Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("package_name")}
                    className={getClassNamesFor("package_name")}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("price")}
                    className={getClassNamesFor("price")}
                  >
                    Price
                  </th>
                  <th scope="col" className="no_sort">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length ? (
                  items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td>{el.branches_name}</td>
                      <td>{el.package_name}</td>
                      <td>
                        <CurrencyFormat
                          value={el.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            getPkgs(el.id);
                          }}
                          className="updt-btn inactive-btn"
                        >
                          <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Update
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => inactivePackage(el.id)}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-x-circle text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Inactive
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  getPkg(e, limit);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </>
        );
      };
      const InactiveTable = ({ data, rowsPerPage }) => {
        const { slice } = useTable(data, 1, rowsPerPage);
        const { items, requestSort, sortConfig } = useSortableData(slice);
    
        const getClassNamesFor = (name) => {
          if (!sortConfig) {
            return;
          }
          return sortConfig.key === name ? sortConfig.direction : undefined;
        };
    
        return (
          <>
            <table
              className="table table-striped table-borderless table-style-1"
              data-caption="Inactive Cafe Deposits"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Sr#
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("branch_id")}
                    className={getClassNamesFor("branch_id")}
                  >
                    Branch Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("price")}
                    className={getClassNamesFor("price")}
                  >
                    Price
                  </th>
                  <th scope="col" className="no_sort">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length ? (
                  items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {inactiveCurrentPage === 1
                          ? index + 1
                          : (inactiveCurrentPage - 1) * inactiveLimit + index + 1}
                      </td>
                      <td>{el.branches_name}</td>
                      <td>{el.package_name}</td>
                      <td>
                        <CurrencyFormat
                          value={el.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => activePackage(el.id)}
                          className="active-btn"
                        >
                          <i className="bi bi-check-circle text-success"></i>{" "}
                          <span className="text-success inative-text ms-1">
                            Active
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => delFine(el.id)}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-trash3 text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Delete
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
              <Pagination
                activePage={inactiveCurrentPage}
                itemsCountPerPage={parseInt(inactiveLimit)}
                totalItemsCount={inactiveTotalResults}
                onChange={(e) => {
                  getInactivePkg(e, inactiveLimit);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </>
        );
      };
      const UpdatePackage = async (values, action) => {
        if (nameError === "") {
          setLoading(true);
    
          try {
            const response = await UserServices.UpdatePackages(values, packageId);
            if (response.status === 204) {
              setLoading(false);
              toast.success("Updated Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
              getPkg(currentPage, limit);
              // Trigger the close button click event
              closeButtonRef.current.click();
            }
          } catch (err) {
            if (err.response.status === 422) {
              toast.error("Some data is missing", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
    
              setLoading(false);
            } else {
              toast.error("Some thing went wronge !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
            }
          }
        }
      };
      const initialValues = {
        branch_id: selectedBranches,
        package_name: packageName,
        price: Price,
      };
      const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
          enableReinitialize: true,
          initialValues: initialValues,
          validationSchema: addMembershipPkg,
          onSubmit: (values, action) => {
            UpdatePackage(values, action);
          },
        });
  return (
    <div className='wrapper'>
       <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Add Cafe Deposit</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    addPkgHandleSubmit(e);
                  }}
                >
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={addPkgValues.branch_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            // changeCategory(e);
                            addPkgValues.package_name = "";
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                        <p className="text-danger">{addPkgErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="package_name"
                        autoComplete="off"
                        value={addPkgValues.package_name}
                        onChange={async (e) => {
                          addPkgHandleChange(e);
                          nameVerify(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      />

                      {nameError ? (
                        <div className="errorMsg text-danger">{nameError}</div>
                      ) : null}
                      {addPkgErrors.package_name &&
                      addPkgTouched.package_name ? (
                        <p className="text-danger">
                          {addPkgErrors.package_name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Price<span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className="form-control"
                        placeholder="Enter Price"
                        name="price"
                        min={0}
                        value={addPkgValues.price}
                        onChange={addPkgHandleChange}
                        onBlur={addPkgHandleBlur}
                      />
                      {addPkgErrors.price && addPkgTouched.price ? (
                        <p className="text-danger">{addPkgErrors.price}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <button
              className="btn btn-primary mx-2 file-download float-end mb-3"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Active Cafe Deposits</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 col-lg-2 ms-2 mb-3">
                    <div className="form-group custom-group">
                      <label className="form-label">Search By Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => {
                          addPkgHandleChange(e);
                          searchPkg(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-1 mt-3 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Inactive Cafe Deposits</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-lg-6 ms-3"></div>
                  {/* {totalResults > limit ? ( */}
                  <div className="col-md-1 col-lg-1 mb-2 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={inactiveLimit}
                        onChange={(e) => getInactivePkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <InactiveTable
                      data={inactiveData}
                      rowsPerPage={inactiveLimit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
              {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Cafe Deposits
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={values.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                          changeCategory(e);
                          setSelectedBranches(e.target.value);
                          values.package_name = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="package_name"
                      value={values.package_name}
                      onChange={(e) => {
                        handleChange(e);
                        nameVerify(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {nameError ? (
                      <div className="errorMsg text-danger">{nameError}</div>
                    ) : null}
                    {errors.package_name && touched.package_name ? (
                      <p className="text-danger">{errors.package_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Price<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Price"
                      name="price"
                      min={0}
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.price && touched.price ? (
                      <p className="text-danger">{errors.price}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  )
}
