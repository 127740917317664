import { useFormik } from "formik";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";

import Swal from "sweetalert2";
import { NoteState } from "../../context/notes/NoteState";

import UserServices from "../../UserServices/UserServices";

import { checkOut } from "../schemas";
import CafeNav from "../Includes/Navbar/CafeNav";
import CafeSidebar from "../Includes/Sidebar/CafeSidebar";
import CafeMenu from "./CafeMenu";

export default function CafeCheckOut() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [cafeData, setCafeData] = useState([]);
  let navigate = useNavigate();

  const [payAfterError, setPayAfterError] = useState("");

  const [paySplitError, setPaySplitError] = useState("");

  const getCafeCart = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsCart(2438);

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        // eslint-disable-next-line array-callback-return
        res.map((value) => {
          results.push({
            id: value.id,
            price: value.price,
            discount: value.discount,
            net_price: value.net_price,
          });
        });

        setCafeData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        navigate("/cafe-dashboard");
        setCafeData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const getPaymentMethodinitial = async () => {
      try {
        const responce = await UserServices.paymentNames();
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
        });
        setPaymentMethods([...results]);
        setPaymentMethodsSplit([...results]);
      } catch (err) {
        console.log(err);

        setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
        setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
      }
    };

    getCafeCart();
    if (!branch_id) {
      Swal.fire({
        title: "Branch has null?",
        text: "Hit logout and login again to enter in branch!",
        icon: "error",
        confirmButtonColor: "#ed1c24",
        confirmButtonText: "Login again",
      });
    } else {
      getPaymentMethodinitial(branch_id);
    }
  }, []);

  const calPendPay = async (e) => {
    const granTotal = cafeData.reduce((a, v) => (a = a + v.net_price), 0);

    var pay_price = e.target.value;
    if (pay_price !== "") {
      if (granTotal !== "") {
        if (pay_price > granTotal) {
          values.payment_pend = "";
          setPayAfterError(
            "Payment must be equal to or less than:" + granTotal
          );
        } else if (pay_price < 0) {
          values.payment_pend = "";
          setPayAfterError("Payment must be equal to or geater than 0");
        } else {
          values.payment_pend = granTotal - pay_price;
          setPayAfterError("");
        }
      } else if (pay_price < 0) {
        values.payment_pend = "";
        setPayAfterError("Payment must be equal to or geater than 0");
      } else {
        setPayAfterError("");
      }
    } else if (pay_price < 0) {
      values.payment_pend = "";
      setPayAfterError("Payment must be equal to or geater than 0");
    } else {
      values.payment_pend = "";
      setPayAfterError("");
    }
  };

  const calSplitPend = async (e) => {
    var pay_price = e.target.value;
    if (pay_price !== "") {
      if (values.payment_pend !== "") {
        if (pay_price > values.payment_pend) {
          values.payment_split_pend = "";
          setPaySplitError(
            "Payment must be equal to or less than:" + values.payment_pend
          );
        } else if (pay_price < 0) {
          values.payment_split_pend = "";
          setPaySplitError("Payment must be equal to or geater than 0");
        } else {
          values.payment_split_pend = values.payment_pend - pay_price;
          setPaySplitError("");
        }
      } else if (pay_price < 0) {
        values.payment_split_pend = "";
        setPaySplitError("Payment must be equal to or geater than 0");
      } else {
        setPaySplitError("");
      }
    } else if (pay_price < 0) {
      values.payment_split_pend = "";
      setPaySplitError("Payment must be equal to or geater than 0");
    } else {
      values.payment_split_pend = "";
      setPaySplitError("");
    }
  };

  const sellAllPkgs = async (values) => {
    if (paySplitError === "" && payAfterError === "") {
      if (
        parseInt(values.payment_pend) === 0 ||
        parseInt(values.payment_split_pend) === 0
      ) {
        setLoading(true);
        const toal_discount = cafeData.reduce(
          (a, v) => (a = a + v.discount),
          0
        );
        const toal_price = cafeData.reduce((a, v) => (a = a + v.price), 0);
        const grand_total = cafeData.reduce((a, v) => (a = a + v.net_price), 0);
        try {
          const responce = await UserServices.checkOutOrder(
            branch_id,
            2438,
            toal_price,
            toal_discount,
            grand_total,
            values,
            "new"
          );

          toast.success("Package Sold Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/cafe-invoice/purchases/" + responce.data.order_id, {
              replace: true,
            });
          }, 2000);
        } catch (err) {
          toast.error("Unproccessable content!", { position: "top-center" });
          console.log(err);
          setLoading(false);
        }
      } else {
        toast.error("Payment is pending!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      }
    }
  };

  const initialValues = {
    pay_met: "31",
    payment_rec: "",
    cheque_no: "0",
    split_cheque_no: "0",
    payment_pend: "",
    split_pay: "31",
    payment_split: "",
    payment_split_pend: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: checkOut,
      onSubmit: (values, action) => {
        sellAllPkgs(values);
      },
    });
  return (
    <div className="wrapper">
      <CafeNav />
      <CafeSidebar />
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <CafeMenu />
          <form className="" onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-header py-3">
                <h5 className=" ms-3 fs-4">
                  Total: {cafeData.reduce((a, v) => (a = a + v.net_price), 0)}
                </h5>
              </div>
              <div className="card-body mt-3">
                <div className="col-lg-12">
                  <div className="row g-3 trainee-add">
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Payment Method</label>
                        {paymentMethods.length ? (
                          <select
                            className="form-select"
                            name="pay_met"
                            value={values.pay_met}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {paymentMethods.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            name="pay_met"
                            value={values.pay_met}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option>Select Payment Method</option>
                          </select>
                        )}

                        {errors.pay_met && touched.pay_met ? (
                          <p className="text-danger">{errors.pay_met}</p>
                        ) : null}
                      </div>
                    </div>
                    {values.pay_met === "32" ? (
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <div className="form-group custom-group">
                            <label htmlFor="form-label" className="form-label">
                              Cheque Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="cheque_no"
                              value={values.cheque_no}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            />

                            {errors.cheque_no && touched.cheque_no ? (
                              <p className="text-danger">{errors.cheque_no}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Payment <span className="text-danger">*</span>
                        </label>
                        <input
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          type="number"
                          name="payment_rec"
                          value={values.payment_rec}
                          onChange={(e) => {
                            handleChange(e);
                            calPendPay(e);
                            setPaySplitError("");
                            values.payment_split = "";
                            values.payment_split_pend = "";
                          }}
                          onBlur={handleBlur}
                        />
                        {payAfterError !== "" ? (
                          <p className="text-danger">{payAfterError}</p>
                        ) : null}
                        {errors.payment_rec && touched.payment_rec ? (
                          <p className="text-danger">{errors.payment_rec}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Remaining Balance
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="payment_pend"
                          value={values.payment_pend}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          disabled={true}
                        />

                        {errors.payment_pend && touched.payment_pend ? (
                          <p className="text-danger">{errors.payment_pend}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Payment Split Method
                        </label>

                        {paymentMethodsSplit.length ? (
                          <select
                            className="form-select"
                            name="split_pay"
                            value={values.split_pay}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {paymentMethodsSplit.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            name="split_pay"
                            value={values.split_pay}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option>Select Payment Method</option>
                          </select>
                        )}

                        {errors.split_pay && touched.split_pay ? (
                          <p className="text-danger">{errors.split_pay}</p>
                        ) : null}
                      </div>
                    </div>

                    {values.split_pay === "32" ? (
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <div className="form-group custom-group">
                            <label htmlFor="form-label" className="form-label">
                              Cheque Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="split_cheque_no"
                              value={values.split_cheque_no}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            />

                            {errors.split_cheque_no &&
                            touched.split_cheque_no ? (
                              <p className="text-danger">
                                {errors.split_cheque_no}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Payment
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="payment_split"
                          value={values.payment_split}
                          onChange={(e) => {
                            handleChange(e);
                            calSplitPend(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {paySplitError !== "" ? (
                          <p className="text-danger">{paySplitError}</p>
                        ) : null}
                        {errors.payment_split && touched.payment_split ? (
                          <p className="text-danger">{errors.payment_split}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Remaining Balance
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="payment_split_pend"
                          value={values.payment_split_pend}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          disabled={true}
                        />

                        {errors.payment_split_pend &&
                        touched.payment_split_pend ? (
                          <p className="text-danger">
                            {errors.payment_split_pend}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="grand-totl-fine text-end w-100">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 mb-1  addclient-button"
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
